import { createAction } from "../../utils";

// Types
export const types = {
  RESET_RECIPIENTS_REDUC: "RESET_RECIPIENTS_REDUC",

  SET_RECIPIENT_UPDATE_DATA: "SET_RECIPIENT_UPDATE_DATA",

  GET_RECIPIENTS_REQUEST: "GET_RECIPIENTS_REQUEST",
  GET_RECIPIENTS_SUCCESS: "GET_RECIPIENTS_SUCCESS",
  GET_RECIPIENTS_FAILURE: "GET_RECIPIENTS_FAILURE",

  GET_RECIPIENT_REQUEST: "GET_RECIPIENT_REQUEST",
  GET_RECIPIENT_SUCCESS: "GET_RECIPIENT_SUCCESS",
  GET_RECIPIENT_FAILURE: "GET_RECIPIENT_FAILURE",

  RECIPIENTS_CREATE_REQUEST: "RECIPIENTS_CREATE_REQUEST",
  RECIPIENTS_CREATE_SUCCESS: "RECIPIENTS_CREATE_SUCCESS",
  RECIPIENTS_CREATE_FAILURE: "RECIPIENTS_CREATE_FAILURE",

  RECIPIENTS_UPDATE_REQUEST: "RECIPIENTS_UPDATE_REQUEST",
  RECIPIENTS_UPDATE_SUCCESS: "RECIPIENTS_UPDATE_SUCCESS",
  RECIPIENTS_UPDATE_FAILURE: "RECIPIENTS_UPDATE_FAILURE",

  RECIPIENT_DELETE_REQUEST: "RECIPIENT_DELETE_REQUEST",
  RECIPIENT_DELETE_SUCCESS: "RECIPIENT_DELETE_SUCCESS",
  RECIPIENT_DELETE_FAILURE: "RECIPIENT_DELETE_FAILURE",

  UPDATE_CURRENT_RECIPIENT_SUCCESS: "UPDATE_CURRENT_RECIPIENT_SUCCESS",

  RESET_CURRENT_RECIPIENT: "RESET_CURRENT_RECIPIENT",

  SET_CURRENT_TAB_REDUC: "SET_CURRENT_TAB_REDUC",

  GET_BENEFICIARIES_REQUEST: "GET_BENEFICIARIES_REQUEST",
  GET_BENEFICIARIES_SUCCESS: "GET_BENEFICIARIES_SUCCESS",
  GET_BENEFICIARIES_FAILURE: "GET_BENEFICIARIES_FAILURE",

  GET_RECIPIENTS_BY_MERCHANT_ID_REQUEST:
    "GET_RECIPIENTS_BY_MERCHANT_ID_REQUEST",
  GET_RECIPIENTS_BY_MERCHANT_ID_SUCCESS:
    "GET_RECIPIENTS_BY_MERCHANT_ID_SUCCESS",
  GET_RECIPIENTS_BY_MERCHANT_ID_FAILURE:
    "GET_RECIPIENTS_BY_MERCHANT_ID_FAILURE",

  GET_MORE_RECIPIENTS_REQUEST: "GET_MORE_RECIPIENTS_REQUEST",
  GET_MORE_RECIPIENTS_SUCCESS: "GET_MORE_RECIPIENTS_SUCCESS",

  SEARCH_RECIPIENTS_REQUEST: "SEARCH_RECIPIENTS_REQUEST",
  SEARCH_RECIPIENTS_SUCCESS: "SEARCH_RECIPIENTS_SUCCESS",
  SEARCH_RECIPIENTS_FAILURE: "SEARCH_RECIPIENTS_FAILURE",

  UPDATE_SEARCH_RECIPIENT: "UPDATE_SEARCH_RECIPIENT",

  RECIPIENTS_DELETE_REQUEST: "RECIPIENTS_DELETE_REQUEST",
  RECIPIENTS_DELETE_SUCCESS: "RECIPIENTS_DELETE_SUCCESS",

  UPDATE_DP_RECIPIENT_REDUC: "UPDATE_DP_RECIPIENT_REDUC",
  DELETE_DP_RECIPIENT_REDUC: "DELETE_DP_RECIPIENT_REDUC"
};

// Actions
const actions = {
  updateDefaultPackageRecipientSuccess: (data) => createAction(types.UPDATE_DP_RECIPIENT_REDUC, {data}),
  deleteDefaultPackageRecipientSuccess: (data) => createAction(types.DELETE_DP_RECIPIENT_REDUC, {data}),
  updateSearchRecipient: (data) =>
    createAction(types.UPDATE_SEARCH_RECIPIENT, { data }),

  searchRecipientsRequest: (value) =>
    createAction(types.SEARCH_RECIPIENTS_REQUEST, { value }),
  searchRecipientsSuccess: (data) =>
    createAction(types.SEARCH_RECIPIENTS_SUCCESS, { data }),
  searchRecipientsFailure: () => createAction(types.SEARCH_RECIPIENTS_FAILURE),

  resetCurrentRecipient: () => createAction(types.RESET_CURRENT_RECIPIENT),

  setCurrentTabReduc: (tabKey) =>
    createAction(types.SET_CURRENT_TAB_REDUC, { tabKey }),

  resetRequestSuccessReduc: () => createAction(types.RESET_RECIPIENTS_REDUC),

  getBeneficiariesRequest: (contractor_id) =>
    createAction(types.GET_BENEFICIARIES_REQUEST, { contractor_id }),
  getBeneficiariesSuccess: (data) =>
    createAction(types.GET_BENEFICIARIES_SUCCESS, { data }),
  getBeneficiariesFailure: () => createAction(types.GET_BENEFICIARIES_FAILURE),

  getMoreRecipientsRequest: (ids) =>
    createAction(types.GET_MORE_RECIPIENTS_REQUEST, { ids }),
  getMoreRecipientsSuccess: (data) =>
    createAction(types.GET_MORE_RECIPIENTS_SUCCESS, { data }),
  getRecipientsRequest: (team_id) =>
    createAction(types.GET_RECIPIENTS_REQUEST, { team_id }),
  getRecipientsSuccess: (data) =>
    createAction(types.GET_RECIPIENTS_SUCCESS, { data }),
  getRecipientsFailure: () => createAction(types.GET_RECIPIENTS_FAILURE),

  getRecipientsByMerchantIDRequest: (merchant_id) =>
    createAction(types.GET_RECIPIENTS_BY_MERCHANT_ID_REQUEST, { merchant_id }),
  getRecipientsByMerchantIDSuccess: (data) =>
    createAction(types.GET_RECIPIENTS_BY_MERCHANT_ID_SUCCESS, { data }),
  getRecipientsByMerchantIDFailure: () =>
    createAction(types.GET_RECIPIENTS_BY_MERCHANT_ID_FAILURE),

  getRecipientRequest: (id) =>
    createAction(types.GET_RECIPIENT_REQUEST, { id }),
  getRecipientSuccess: (data) =>
    createAction(types.GET_RECIPIENT_SUCCESS, { data }),
  getRecipientFailure: () => createAction(types.GET_RECIPIENT_FAILURE),

  recipientsCreateRequest: (values, callback = null) =>
    createAction(types.RECIPIENTS_CREATE_REQUEST, { values, callback }),
  recipientsCreateSuccess: (data) =>
    createAction(types.RECIPIENTS_CREATE_SUCCESS, { data }),
  recipientsCreateFailure: () => createAction(types.RECIPIENTS_CREATE_FAILURE),

  recipientsUpdateRequest: (values, callback = null) =>
    createAction(types.RECIPIENTS_UPDATE_REQUEST, { values, callback }),
  recipientsUpdateSuccess: (data) =>
    createAction(types.RECIPIENTS_UPDATE_SUCCESS, { data }),
  recipientsUpdateFailure: () => createAction(types.RECIPIENTS_UPDATE_FAILURE),

  recipientsUpdateCurrentRecipientSuccess: (data) =>
    createAction(types.UPDATE_CURRENT_RECIPIENT_SUCCESS, { data }),

  deleteRecipientsRequest: (data, callback = null) =>
    createAction(types.RECIPIENTS_DELETE_REQUEST, { data, callback }),
  deleteRecipientsSuccess: (data) =>
    createAction(types.RECIPIENTS_DELETE_SUCCESS, { data }),

  deleteRecipientRequest: (data, callback = null) =>
    createAction(types.RECIPIENT_DELETE_REQUEST, { data, callback }),
  deleteRecipientSuccess: (data) =>
    createAction(types.RECIPIENT_DELETE_SUCCESS, { data }),
  deleteRecipientFailure: () => createAction(types.RECIPIENT_DELETE_FAILURE),
};
export default actions;
