import { takeLatest, put, call, select } from "redux-saga/effects";
import { CoursiersService } from "../../services";
import { LoaderActions } from "../loader";
import { AuthActions } from "../auth";
import { types, default as CoursiersActions } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import moment from "moment-timezone";

function* getAllRequest({ team_id }) {
  yield put(CoursiersActions.setLoading(true));
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(CoursiersService.getAll, {
    team_id,
  });
  if (result) {
    yield put(CoursiersActions.getAllSuccess(result.coursier));
  } else if (error) {
    showError("get_coursiers_error", strings, null, error);
    yield put(CoursiersActions.getAllFailure());
  }
  yield put(LoaderActions.loaded());
}

function* createRequest({ values, callback }) {
  const locale = yield select((state) => state.locale);
  const auth = yield select((state) => state.auth);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(CoursiersService.create, {
    ...values,
    team_id: auth.team_id,
  });
  if (result) {
    showSuccess("create_coursier_success", strings);
    if (callback) {
      callback();
    }
    yield put(CoursiersActions.createSuccess(result.data));
    yield put(LoaderActions.loaded());
  } else if (error) {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("create_coursier_error", strings, null, error);
    }
    yield put(CoursiersActions.createFailure());
    yield put(LoaderActions.loaded());
  }
}

function* modifyRequest({ values, record, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(CoursiersService.modify, {
    ...record,
    ...values,
  });
  if (result) {
    showSuccess("coursier_update_success", strings);
    if (callback) {
      callback();
    }
    yield put(
      CoursiersActions.modifySuccess(
        result.update_coursier.returning[0]
      )
    );
    if (values.password !== record.password && values.password.length > 0) {
      let data = {
        user: record.user,
        password: values.password,
      };
      yield put(AuthActions.updatePassword(data));
    }
    yield put(LoaderActions.loaded());
  } else if (error) {
    console.log("error", error)
    console.log("message", error.message)
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("coursier_update_error", strings, null, error);
    }
    yield put(CoursiersActions.modifyFailure());
    yield put(LoaderActions.loaded());
  }
}

function* deleteRequest({ values, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(CoursiersService.delete, {
    id: values.id,
    deleted_at: moment().format(),
  });
  if (result) {
    showSuccess("coursier_delete_success", strings);
    if (callback) {
      callback();
    }
    yield put(
      CoursiersActions.deleteSuccess(
        result.update_coursier.returning[0]
      )
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("coursier_delete_error", strings, null, error);
    yield put(CoursiersActions.deleteFailure());
    yield put(LoaderActions.loaded());
  }
}

const sagas = [
  takeLatest(types.GET_ALL_REQUEST, getAllRequest),
  takeLatest(types.CREATE_REQUEST, createRequest),
  takeLatest(types.MODIFY_REQUEST, modifyRequest),
  takeLatest(types.DELETE_REQUEST, deleteRequest),
];
export default sagas;
