import {gql} from "apollo-boost";
import moment from "moment-timezone";
import {authenticatedMutation, authenticatedQuery} from "./middleware";

const RETURNING_VIEW_RECIPIENT = `
  id
  created_at
  created_by
  updated_at
  company
  email
  notes
  name
  phone_number
  address
  team_id
  type
  merchant_id
  default_packages
  package_type_receivers
  opening_hours
`

const RETURNING_RECIPIENT = `
  id
  created_at
  created_by
  updated_at
  company
  name
  email
  phone_number
  address
  team_id
  opening_hours
  notes
  type
  package_type_receivers {
    package_type {
      id
      icon_fa
      icon
      desc
      name
      volumn
      weight
    }
    receiver_recipient_id
    package_type_id
    receiver_merchant_id
  }
  default_packages {
    package {
      id
      fragile
      is_default
      kind_id
      declared_value
      package_type_id
      volumn
      weight
      number
    }
  }
  merchant_id
  merchant {
    company
    users(where: {role: {_eq: "merchant_manager"}}) {
      id
      name
      phone_number
      address
      team_id
      created_at
      updated_at
      role
    }
  }
`

/**
 * GRAPHQL QUERIES
 */
const queries = {
    searchRecipients: ({
                           value,
                           merchant_id,
                       }) => `view_recipients(where: {_or: [{address_value: {_ilike: "%${value}%"}}, {email: {_ilike: "%${value}%"}}, {name: {_ilike: "%${value}%"}}, {phone_number: {_ilike: "%${value}%"}}, {company: {_ilike: "%${value}%"}}], _and: {merchant_id: {_eq: ${merchant_id}}}}) {
      ${RETURNING_VIEW_RECIPIENT}
  }`,
    recipient: (id) => `recipients(where: {id: {_eq: ${id}}}) {
    ${RETURNING_RECIPIENT}
  }`,
    moreRecipients: (ids) => `recipients(where: {id: {_in: [${ids.join(",")}]}}) {
    ${RETURNING_RECIPIENT}
  }`,
    recipients: (team_id) => `recipients(where: {team_id: {_eq: ${team_id}}}) {
    ${RETURNING_RECIPIENT}
  }`,
    recipientsByMerchantID: (
        merchant_id
    ) => `recipients(where: {merchant_id: {_eq: ${merchant_id}}}) {
      ${RETURNING_RECIPIENT}
  }`,
    recipientsByCreatedBy: (
        created_by
    ) => `recipients(where: {created_by: {_eq: ${created_by}}}) {
      ${RETURNING_RECIPIENT}
    }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
    recipientCreate: gql`
    mutation insert_recipient_one($recipient: recipients_insert_input!) {
      insert_recipients_one(object: $recipient) {
        ${RETURNING_RECIPIENT}
      }
    }
  `,
    recipientUpdate: gql`
    mutation update_recipient_by_pk(
      $id: Int!
      $recipient: recipients_set_input!
    ) {
      update_recipients_by_pk(pk_columns: { id: $id }, _set: $recipient) {
        ${RETURNING_RECIPIENT}
      }
    }
  `,

    recipientDelete: gql`
    mutation update_recipients($id: Int!, $deleted_at: timestamptz!) {
      update_recipients(
        where: { id: { _eq: $id } }
        _set: { deleted_at: $deleted_at }
      ) {
        returning {
          id
        }
      }
    }
  `,
    recipientsDelete: gql`
    mutation update_recipients($ids: [Int!], $deleted_at: timestamptz!) {
      update_recipients(
        where: { id: { _in: $ids } }
        _set: { deleted_at: $deleted_at }
      ) {
        returning {
          id
        }
      }
    }
  `,
};

const services = {
    moreRecipients: (ids) => authenticatedQuery(queries.moreRecipients(ids)),
    recipient: (recipient) => authenticatedQuery(queries.recipient(recipient.id)),
    recipients: (recipient) => authenticatedQuery(queries.recipients(recipient.team_id)),
    searchRecipients: ({value, merchant_id}) =>
        authenticatedQuery(queries.searchRecipients({value, merchant_id})),
    recipientsByMerchantID: (merchant_id) =>
        authenticatedQuery(queries.recipientsByMerchantID(merchant_id)),
    recipientsByCreatedBy: (created_by) =>
        authenticatedQuery(queries.recipientsByCreatedBy(created_by)),
    recipientCreate: (recipient) => authenticatedMutation(mutations.recipientCreate, {recipient}),
    recipientUpdate: (values) => authenticatedMutation(mutations.recipientUpdate, values),
    recipientDelete: (data) => authenticatedMutation(mutations.recipientDelete, {
        id: data,
        deleted_at: moment().format(),
    }),
    recipientsDelete: (data) => authenticatedMutation(mutations.recipientsDelete, {
        ids: data,
        deleted_at: moment().format(),
    }),
};
export default services;
