import { takeLatest, put, call, select } from "redux-saga/effects";
import { CrmShopService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as CrmShopActions } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";

function* getCrmShopRequest({ user_id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(CrmShopService.crmShop, { user_id });
  if (result) {
    yield put(CrmShopActions.getCrmShopSuccess(result.crm_shop));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_crm_shop_error", strings, null, error);
    yield put(CrmShopActions.getCrmShopFailure());
    yield put(LoaderActions.loaded());
  }
}

function* createCrmShopRequest({ values }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(CrmShopService.createCrmShop, { values });
  if (result) {
    showSuccess("create_crm_shop_success", strings);
    yield put(
      CrmShopActions.createCrmShopSuccess(result.insert_crm_shop.returning[0])
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("create_crm_shop_error", strings, null, error);
    yield put(CrmShopActions.createCrmShopFailure());
    yield put(LoaderActions.loaded());
  }
}

function* updateCrmShopRequest({ values }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(CrmShopService.updateCrmShop, { values });
  if (result) {
    yield put(
      CrmShopActions.updateCrmShopSuccess(result.update_crm_shop.returning[0])
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("update_crm_shop_error", strings, null, error);
    yield put(CrmShopActions.updateCrmShopFailure());
    yield put(LoaderActions.loaded());
  }
}

function* deleteCrmShopRequest({ values }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(CrmShopService.deleteCrmShop, { values });
  if (result) {
    yield put(
      CrmShopActions.deleteCrmShopSuccess(result.delete_crm_shop.returning[0])
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("update_crm_shop_error", strings, null, error);
    yield put(CrmShopActions.deleteCrmShopFailure());
    yield put(LoaderActions.loaded());
  }
}

const sagas = [
  takeLatest(types.GET_CRM_SHOP_REQUEST, getCrmShopRequest),
  takeLatest(types.CREATE_CRM_SHOP_REQUEST, createCrmShopRequest),
  takeLatest(types.UPDATE_CRM_SHOP_REQUEST, updateCrmShopRequest),
  takeLatest(types.DELETE_CRM_SHOP_REQUEST, deleteCrmShopRequest),
];

export default sagas;
