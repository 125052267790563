import {
  InboxOutlined,
  MailOutlined,
  DropboxOutlined,
  DashOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import enveloppe from "../assets/images/typeColis/enveloppe.svg";
import enveloppe_select from "../assets/images/typeColis/enveloppe-selected.svg";
import box from "../assets/images/typeColis/box.svg";
import box_select from "../assets/images/typeColis/box-selected.svg";
import bigBox from "../assets/images/typeColis/big-box.svg";
import bigBox_select from "../assets/images/typeColis/big-box-selected.svg";
import other from "../assets/images/typeColis/other.svg";
import other_select from "../assets/images/typeColis/other-selected.svg";
import shopping from "../assets/images/typeColis/shopping-bag.svg";
import shopping_select from "../assets/images/typeColis/shopping-bag-selected.svg";
import shopping_1 from "../assets/images/typeColis/shopping-bag-1.svg";
import shopping_1_select from "../assets/images/typeColis/shopping-bag-1-selected.svg";
import shopping_2_5 from "../assets/images/typeColis/shopping-bag-2-5.svg";
import shopping_2_5_select from "../assets/images/typeColis/shopping-bag-2-5-selected.svg";
import shopping_6_10 from "../assets/images/typeColis/shopping-bag-6-10.svg";
import shopping_6_10_select from "../assets/images/typeColis/shopping-bag-6-10-selected.svg";

const isHostTDP = window.location.hostname === process.env.REACT_APP_HOST_TDP;

const typeColis = {
  enveloppe: {
    nameTDP: "Jusqu'à 1 sac",
    weightTDP: 5,
    volumeTDP: 200,
    unselected: isHostTDP ? shopping_1 : enveloppe,
    selected: isHostTDP ? shopping_1_select : enveloppe_select,
    icon: <MailOutlined style={{ color: "white" }} />,
    weight: 0.1,
    volumn: 10,
  },
  box: {
    nameTDP: "De 2 à 5 sacs",
    weightTDP: 10,
    volumeTDP: 400,
    unselected: isHostTDP ? shopping_2_5 : box,
    selected: isHostTDP ? shopping_2_5_select : box_select,
    icon: <InboxOutlined style={{ color: "white" }} />,
    weight: 2,
    volumn: 20,
  },
  "big-box": {
    nameTDP: "Très grand format",
    weightTDP: 50,
    volumeTDP: 2000,
    unselected: bigBox,
    selected: bigBox_select,
    icon: <DropboxOutlined style={{ color: "white" }} />,
    weight: 8,
    volumn: 80,
  },
  "shopping-bag": {
    nameTDP: "De 6 à 10 sacs",
    weightTDP: 20,
    volumeTDP: 800,
    unselected: isHostTDP ? shopping_6_10 : shopping,
    selected: isHostTDP ? shopping_6_10_select : shopping_select,
    icon: <ShoppingOutlined style={{ color: "white" }} />,
    weight: 4,
    volumn: 30,
  },
  other: {
    nameTDP: "Hors Standard",
    unselected: other,
    selected: other_select,
    icon: <DashOutlined style={{ color: "white" }} />,
    weight: null,
    volumn: null,
  },
};

export default typeColis;
