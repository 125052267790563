import { gql } from "apollo-boost";
import {authenticatedMutation, authenticatedQuery} from "./middleware";
import {query} from "../utils";

const RETURNING_PRICING = `
  id
  team_id
  isAdmin
  base
  overcost
  discount
`

const RETURNING_ZONE_PRICING = `
  id
  zone_id
  pricing
  area_team {
    name
    team_id
  }
`

/**
 * GRAPHQL QUERIES
 */
const queries = {
  zonePricingByTeamId: (
    teamId
  ) => `zone_pricing(where: {area_team: {team_id: {_eq: ${teamId}}}}) {
        id
        zone_id
        pricing
        area_team {
          name
          team_id
        }
    }`,
  pricing: `pricing {
      ${RETURNING_PRICING}
  }`,
  pricingByTeamID: (team_id) => `pricing(where:  {team_id: {_eq: ${team_id}}}) {
      ${RETURNING_PRICING}
  }`,
  zonePricing: (id) => `zone_pricing(where: {zone_id: {_eq: ${id}}}) {
      ${RETURNING_ZONE_PRICING}
  }`,
  pricingAdmin: `pricing(where:  {isAdmin: {_eq: true}}) {
      ${RETURNING_PRICING}
  }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  updatePricing: gql`
    mutation update_pricing($id: Int!, $pricing: pricing_set_input!) {
      update_pricing(where: { id: { _eq: $id } }, _set: $pricing) {
        returning {
          ${RETURNING_PRICING}
        }
      }
    }
  `,
  createPricing: gql`
    mutation insert_pricing_one($pricing: pricing_insert_input!) {
      insert_pricing_one(object: $pricing) {
        ${RETURNING_PRICING}
      }
    }
  `,
  updateZonePricing: gql`
    mutation update_zone_pricing($id: Int!, $pricing: zone_pricing_set_input!) {
      update_zone_pricing(where: { id: { _eq: $id } }, _set: $pricing) {
        returning {
          ${RETURNING_ZONE_PRICING}
        }
      }
    }
  `,
};

const services = {
  getPricingByTeamID: (id) => authenticatedQuery(queries.pricingByTeamID(id)),
  getZonePricing: (id) => query(queries.zonePricing(id)),
  getPricingAdmin: () => authenticatedQuery(queries.pricingAdmin),
  getZonePricingByTeamId: (teamId) =>
      authenticatedQuery(queries.zonePricingByTeamId(teamId)),
  updatePricing: (pricing) =>
      authenticatedMutation(mutations.updatePricing, { id: pricing.id, pricing }),
  createPricing: (pricing) => authenticatedMutation(mutations.createPricing, { pricing }),
  updateZonePricing: (pricing, id) =>
      authenticatedMutation(mutations.updateZonePricing, { id: id, pricing: pricing }),
};
export default services;
