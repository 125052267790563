import { takeLatest, put, call, select } from "redux-saga/effects";
import { AreasService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as AreasActions } from "./actions";
import { showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import { getNameZoneByAddressRequest } from "../../utils/zone-helper";

function* getAreasRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(AreasService.getAreas);
  if (result) {
    yield put(AreasActions.getAreasSuccess(result.area_team));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_areas_error", strings, null, error);
    yield put(AreasActions.getAreasFailure());
    yield put(LoaderActions.loaded());
  }
}

function* checkContainAreasRequest({ values }) {
  let inZone = false;
  let zone = null;
  let count_addr = 0;
  for (let index = 0, length = values.length; index < length; index++) {
    if (values[index] && values[index].data) {
      const res = yield getNameZoneByAddressRequest({ value: values[index] });
      if (res && res.name) {
        count_addr++;
        zone = res.name;
      } else {
        break;
      }
    }
  }
  if (count_addr === values.length) {
    inZone = true;
  }
  yield put(AreasActions.checkContainAreasSuccess(inZone, zone));
}

const sagas = [
  takeLatest(types.GET_AREAS_REQUEST, getAreasRequest),
  takeLatest(types.CHECK_AREAS_REQUEST, checkContainAreasRequest),
];
export default sagas;
