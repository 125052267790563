import { Button, Card, Divider, Form, Image, InputNumber, Modal, Select, Tag, Typography } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState } from 'react';
import { firstCharUpper } from '../../../utils/function-helper';
import fragileSelected from '../../../assets/images/typeColis/fragile-selected.svg';
import fragileSvg from '../../../assets/images/typeColis/fragile.svg';
import { isFoodIcon, isNoFoodIcon, styleCard } from '../../../utils/css-function-tools';
import hiddenPriceStoreList from '../../../utils/hiddenPriceStoreList';
import { getZonePricingByAddress } from '../../../utils/zone-helper';
import CardPackageType from '../CardPackageType';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

const isHostTDP = window.location.hostname === process.env.REACT_APP_HOST_TDP;

export default function ModalPackage(props) {
	const { strings, onValidate, onCancel, packageType, pricing, current_user, visible, data, receiver } = props;
	const packageKinds = useSelector(({ packageType }) => packageType.packageKinds);
	const [typePackages, setTypePackages] = useState([]);
	const [isFood, setIsFood] = useState(isHostTDP ? !isHostTDP : null);
	const [typeColis, setTypeColis] = useState(null);
	const [optionFood, setOptionFood] = useState(null);
	const [pricingOvercost, setPricingOvercost] = useState({
		...pricing?.overcost,
	});
	const [pricingDiscount, setPricingDiscount] = useState({
		...pricing?.discount,
	});
	const [fragile, setFragile] = useState();
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	//const [open, setOpen] = useState(true);
	const { Title, Text } = Typography;
	const { Option } = Select;

	useEffect(() => {
		if (data?.package) {
			const tmp = data.package;
			if (tmp.package_type_id) setTypeColis(tmp.package_type_id);
			let food = null;
			if (packageKinds && tmp.kind_id) food = packageKinds[tmp.kind_id].kind;
			else food = tmp.food;
			if (food) {
				if (food === 'no-alimentaire') {
					setIsFood(false);
				} else {
					setIsFood(true);
					setOptionFood(food);
				}
				tmp.food = food;
			}
			if (![undefined, null].includes(tmp.fragile)) {
				setFragile(tmp.fragile);
			}
			form.setFieldsValue(tmp);
		}
		let tmpPackageTypes = {};
		if (data?.package_type_receivers) {
			data.package_type_receivers.forEach((v) => {
				tmpPackageTypes[v.package_type.id] = v.package_type;
			});
		}
		// if (isHostTDP) {
		//   Object.values(current_user.default_packages || {})?.forEach((v) => {
		//     tmpPackageTypes[v.id] = v;
		//   });
		//   if (current_user?.default_packages) {
		//     current_user.default_packages.forEach((v) => {
		//       tmpPackageTypes[v.package_type.id] = v.package_type;
		//     });
		//   }
		// }
		Object.values(packageType || {})?.forEach((v) => {
			tmpPackageTypes[v.id] = v;
		});
		setTypePackages(Object.values(tmpPackageTypes));
		// eslint-disable-next-line
	}, [data]);

	const handleCancelModal = () => {
		onCancel && onCancel();
	};

	useEffect(() => {
		if (pricing) {
			setPricingDiscount({ ...pricing.discount });
			setPricingOvercost({ ...pricing.overcost });
		}
	}, [pricing]);

	useEffect(() => {
		async function fetchData() {
			if (pricing) {
				const tmpPricing = await getZonePricingByAddress(
					receiver && receiver.address ? receiver.address : null,
					current_user.team_id,
				);
				let tmpOvercost = { ...pricingOvercost };
				let tmpDiscount = { ...pricingDiscount };
				if (tmpPricing) {
					for (const [key, value] of Object.entries(tmpPricing.pricing)) {
						if (key in tmpDiscount) tmpDiscount[key] = value;
						if (key in tmpOvercost) tmpOvercost[key] = value;
					}
				} else {
					tmpOvercost = { ...pricing.overcost };
					tmpDiscount = { ...pricing.discount };
				}
				setPricingOvercost(tmpOvercost);
				setPricingDiscount(tmpDiscount);
			}
		}

		fetchData();
		// eslint-disable-next-line
	}, [receiver]);

	const priceOfValue = (nameOfValue, selected) => {
		if ((isHostTDP && nameOfValue !== 'fragile') || !pricing) return null;
		const price = pricing[nameOfValue]
			? pricing[nameOfValue]
			: pricingOvercost[nameOfValue]
			? pricingOvercost[nameOfValue]
			: pricingDiscount[nameOfValue]
			? pricingDiscount[nameOfValue]
			: 0;
		const symbole = parseInt(price) === 0 ? '' : parseInt(price) >= 1 ? '+' : '-';
		return price !== 0 && price !== 'devis' && !hiddenPriceStoreList.includes(current_user.merchant_id) ? (
			<Text
				style={
					selected !== undefined
						? { color: selected ? '#005347' : 'inherit' }
						: {
								fontSize: 12,
								fontWeight: 300,
						  }
				}
			>
				{symbole + price + strings('money')}
			</Text>
		) : null;
	};

	const handleValidate = async () => {
		const formData = await form.validateFields();
		setFragile(null);
		setOptionFood(null);
		setTypeColis(null);
		setIsFood(isHostTDP ? !isHostTDP : null);
		form.resetFields();
		onValidate &&
			onValidate({
				...formData,
				fragile: fragile,
			});
	};

	const title = () => {
		return (
			<span>
				{data?.package && data?.package?.key ? (
					<Tag className={'number-package'}>{data.package.key}</Tag>
				) : null}{' '}
				{strings(data?.package && data?.package?.key ? 'update_package' : 'add_a_package')}
			</span>
		);
	};

	const handleClickUpdatePackageType = () => {
		if (dispatch) {
			dispatch(push(`/packageType`));
		}
	};

	return (
		<Modal
			width="500px"
			title={title()}
			visible={visible}
			/**/
			closable={true}
			maskClosable={false}
			keyboard={true}
			/**/
			footer={
				<>
					<Button onClick={handleCancelModal}>{strings('cancel')}</Button>
					<Button data-cy={'validate-modal-package'} type={'primary'} onClick={handleValidate}>
						{strings('save')}
					</Button>
				</>
			}
			style={{ top: 20 }}
			onCancel={handleCancelModal}
		>
			<Form form={form} layout="vertical">
				<container className="flex-container-row">
					<div className="flex-container-col">
						{!isHostTDP && (
							<>
								<Form.Item
									className="center-card"
									name="food"
									rules={[
										{
											required: true,
											message: "Veuillez choisir un type d'alimentaire",
										},
									]}
								>
									<Card.Grid
										/** style={{ ...styleCard(isFood === true), height: 100 }} */
										style={{ ...styleCard(isFood === true), height: 90 }}
										onClick={() => {
											setIsFood(true);
											form.setFieldsValue({ food: null });
										}}
										id={'grid-card-alim'}
									>
										<div>
											{isFoodIcon(isFood === true ? '#005347' : null)}
											<p
												className="text-card"
												style={{
													color: isFood === true ? '#005347' : 'inherit',
												}}
											>
												Alimentaire
											</p>
											{priceOfValue(optionFood, isFood === true)}
										</div>
									</Card.Grid>
									<Card.Grid
										style={{ ...styleCard(isFood === false), height: 90 }}
										onClick={() => {
											setIsFood(false);
											form.setFieldsValue({ food: 'no-alimentaire' });
										}}
										data-cy={'grid-card-no-alim'}
									>
										<div>
											{isFood === false ? isNoFoodIcon('#005347') : isNoFoodIcon()}
											<p
												className="text-card"
												style={{
													color: isFood === false ? '#005347' : 'inherit',
												}}
											>
												Non alimentaire
											</p>
											{priceOfValue('no-alimentaire', isFood === false)}
										</div>
									</Card.Grid>
								</Form.Item>
								{isFood ? (
									<Form.Item style={{ marginBottom: 5, marginTop: -20 }}>
										<Select
											className={'select-home'}
											optionFilterProp="children"
											placeholder="Type d'alimentaire"
											value={optionFood}
											onChange={(value) => {
												form.setFieldsValue({ food: value });
												setOptionFood(value);
											}}
										>
											<Option value="sec">
												Sec{' '}
												{
													<text
														style={{
															fontStyle: 'italic',
															fontSize: 12,
															fontWeight: 300,
														}}
													>
														{priceOfValue('sec')}
													</text>
												}
											</Option>
											<Option value="frais">Frais {priceOfValue('frais')}</Option>
											<Option value="surgele">Surgelé {priceOfValue('surgele')}</Option>
										</Select>
									</Form.Item>
								) : (
									<div style={{ height: 1 }} /> /* 32 */
								)}
							</>
						)}
					</div>
					<div className="flex-container-row">
						<Form.Item>
							<div className="center-card">
								<Card.Grid
									style={{
										...styleCard(fragile === true),
										height: 90,
										width: 90,
									}}
									data-cy={'grid-card-fragile'}
									onClick={() => {
										setFragile(true);
										form.setFieldsValue({ fragile: true });
									}}
								>
									<div>
										<Image
											style={{ marginRight: 12 }}
											src={fragile === true ? fragileSelected : fragileSvg}
											preview={false}
										/>
										<span
											className="text-card"
											style={{
												color: fragile === true ? '#005347' : 'inherit',
											}}
										>
											{firstCharUpper(strings('fragile'))}
										</span>
										{isHostTDP ? null : priceOfValue('fragile', fragile === true)}
									</div>
								</Card.Grid>
								<Card.Grid
									style={{
										...styleCard(fragile === false),
										height: 90,
										width: 90,
									}}
									id={'grid-card-non-fragile'}
									onClick={() => {
										setFragile(false);
										form.setFieldsValue({ fragile: false });
									}}
								>
									<div>
										<span
											className="text-card"
											style={{
												color: fragile === false ? '#005347' : 'inherit',
											}}
										>
											{strings('not_fragile')}
										</span>
										{isHostTDP ? null : priceOfValue('no-fragile', fragile === false)}
									</div>
								</Card.Grid>
							</div>
						</Form.Item>
					</div>
					<Divider plain />
					<Title level={5} className={'title-home-shop'}>
						{strings('type_of_package')}
					</Title>
					<Form.Item
						className="center-card-package margin-bot-5"
						name="package_type_id"
						required={true}
						rules={[
							{ required: false },
							() => ({
								validator() {
									if (!typeColis) {
										return Promise.reject(strings('type_of_package_required'));
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<div className="flex-container-row">
							{typePackages &&
								typePackages
									?.sort((a, b) => {
										if (a?.weight === null) {
											return 1;
										}
										if (b?.weight === null) {
											return -1;
										}
										if (a?.weight < b?.weight) {
											return -1;
										}
										if (a?.weight > b?.weight) {
											return 1;
										}
										return 0;
									})
									?.map((m, index) => (
										<Card.Grid
											key={`type-colis-${index}`}
											data-cy={`grid-card-dimension-${index}`}
											style={styleCard(typeColis === m.id)}
											onClick={() => {
												setTypeColis(m.id);
												form.setFieldsValue({ package_type_id: m.id });
											}}
										>
											<CardPackageType
												selected={typeColis === m.id}
												strings={strings}
												color={typeColis === m.id ? '#0A5447' : '#C4C4C4'}
												data={m}
											/>
										</Card.Grid>
									))}
							{typePackages.length === 0 && <Text>{strings('assign_package_types')}</Text>}
						</div>
						{isHostTDP ? null : (
							<Button onClick={handleClickUpdatePackageType}>{strings('update_package_types')}</Button>
						)}
					</Form.Item>
					<div className="center-card">
						<Title level={5} className={'title-home-shop'} style={{ marginTop: 8 }}>
							{strings('weight')}
						</Title>
						<div>
							<Form.Item
								name={'weight'}
								style={{ marginBottom: 1 }}
								required={true}
								rules={[{ required: true, message: 'Poids obligatoire' }]}
							>
								<InputNumber
									data-cy={'input-weight'}
									className={'select-home'}
									step={'0.01'}
									placeholder={strings('kilo')}
								/>
							</Form.Item>
						</div>
					</div>
				</container>
			</Form>
		</Modal>
	);
}
