import { mutation, methods } from "../utils";
import { service } from "./middleware";

const { POST } = methods;
const mutations = {
  produceMail: (template, value) => {
    const t = `
        mutation {
          produce(arg1: {
            topic: "messenger_mail",
            message: "${JSON.stringify({
              template,
              value,
            })
              .replaceAll('\\"', '"')
              .replaceAll('"', '\\"')}"
          }) {
            message
          }
        }
        `;
    return t;
  },
};

const routes = {
  mails: process.env.REACT_APP_BASE_MESSENGER_URL + "/mails",
  push: process.env.REACT_APP_BASE_API_URL + "/users/push_notification",
};

const services = {
  sendEmail: ({ type, toSend }) =>
    mutation(mutations.produceMail(type, toSend)),
  sendEmails: (body) => service(POST, routes.mails, body, {}, {}),
  triggerPushNotification: (body) => service(POST, routes.push, body, {}, {}),
};
export default services;
