import { createSelector } from 'reselect'

export const selectPricing = createSelector(
    (state) => state.pricing,
    (pricing) => {
        return pricing
    }
)

export const selectZonePricing = createSelector(
    (state) => state.pricing.zonePricing,
    (zonePricing) => {
        return zonePricing
    }
)

export const selectPricingIsAdmin = createSelector(
    (state) => state.pricing.pricing.isAdmin || false,
    (isAdmin) => {
        return isAdmin
    }
)
