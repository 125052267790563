import { createSelector } from "reselect";
import moment from "moment-timezone";
import { get } from "lodash";

export const selectSimpleCourses = createSelector(
  (state) => (state.tasks.courses ? state.tasks.courses : {}),
  (courses) => {
    return courses;
  }
);

export const selectHomeCourses = createSelector(
  (state) => ({
    courses: state.tasks.courses,
    user: state.user,
  }),
  ({ courses, user }) => {
    let arrayCourses = Object.values(courses);
    if (arrayCourses.length > 0) {
      let tmpCourse = [];
      arrayCourses.forEach((v, i) => {
        if (!v) return;
        let tmpTasks = [];
        let history = null;
        let stateTab = v.tasks
          .filter((task) => task.type === "collecte")
          .map((task) => task.state);
        let datePickup = v.tasks
            .filter((task) => task.type === "collecte")
            .map((task) => ([task.start_date, task.end_date]));
        v.tasks.forEach((vTask) => {
          let tmp = vTask;
          const dispatchers =
            user && user.users && Array.isArray(user.users)
              ? user.users.filter(
                  (u) => u && u.team_id && v.team_id && u.team_id === v.team_id
                )
              : [];
          tmp.phone_dispatcher =
            dispatchers.length > 0 ? dispatchers[0].phone_number : null;
          tmp.course_id = v.id;
          tmp.devis = v.devis;
          tmp.coursier_id = v.coursier_id || null;
          tmp.team_id = v.team_id || null;
          tmp.created_at = v.created_at;
          tmp.key = vTask.id;
          tmp.stateHub = get(
            v,
            ["orderByOrderId", "delivery_status", "status"],
            null
          );
          tmp.start_date = vTask.start_date
            ? moment(vTask.start_date)
            : null;
          tmp.end_date = vTask.end_date
            ? moment(vTask.end_date)
            : null;
          tmp.datePickup = datePickup[0]
          if (tmp.history && Object.keys(tmp.history).length > 0) {
            history = { ...history, ...tmp.history };
            let lastEvent = null;
            Object.keys(history).forEach((event) => {
              if (
                !lastEvent ||
                (lastEvent && history[event].datetime > lastEvent)
              ) {
                lastEvent = history[event].datetime;
              }
            });
            if (lastEvent) {
              tmp.lastEventType = tmp.type;
            }
          }
          tmp.statePickUpTab = stateTab;
          if (tmp.type === "livraison") {
            tmpTasks.push(tmp);
          }
        });
        tmpCourse = [...tmpCourse, ...tmpTasks];
        tmpCourse.sort(
          (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
        );
      });
      return tmpCourse;
    }
  }
);

export const selectCourses = createSelector(
  (state) => ({
    courses: state.tasks.courses,
    filter: state.tasks && state.tasks.filter ? state.tasks.filter : {},
  }),
  ({ courses, filter }) => {
    let noFilter =
      !filter ||
      (!filter.team_id &&
        (!filter.state || filter.state.length === 0) &&
        !filter.date);
    let tmp = Object.values(courses).filter((v) => {
      if (!v || v.tasks.length === 0) return false;
      let keep = noFilter;
      let noDate = true;
      let state_exist_in_course = false;
      v.tasks.forEach((vTask) => {
        let state_exist = null;
        let state = vTask.state !== null ? vTask.state : null;
        let start_date = vTask.start_date || null;
        let end_date = vTask.end_date || null;
        let date_exist =
          filter.date &&
          filter.date.length === 2 &&
          start_date &&
          end_date &&
          moment(filter.date[0]).valueOf() <=
            moment(start_date).valueOf() &&
          moment(filter.date[1]).valueOf() >=
            moment(end_date).valueOf();
        if (state !== null && state !== undefined) {
          state_exist =
            filter.state &&
            filter.state.length > 0 &&
            filter.state.includes(state);
          if (state_exist) {
            state_exist_in_course = true;
          }
        }
        if (
          (!filter.state || filter.state.length === 0 || state_exist) &&
          (!filter.date || date_exist)
        ) {
          keep = true;
        }
        if (start_date || end_date) {
          noDate = false;
        }
      });
      if (noDate && filter.withoutDate && state_exist_in_course) {
        return true;
      } else if (noDate && !filter.withoutDate) {
        return false;
      }
      return keep;
    });
    //tmp = tmp.filter(v => v.devis !== 1)
    tmp = tmp.filter((v) => {
      if (!v) return false;
      if (v.devis === 1) return false;
      let coursier_exist_in_course = false;
      v.tasks.forEach((vTask) => {
        let coursier_id = vTask.coursier_id || null;
        if (coursier_id !== null && coursier_id !== undefined) {
          coursier_exist_in_course =
            filter.coursier && filter.coursier === vTask.coursier_id;
        }
      });
      if (filter.coursier) {
        return coursier_exist_in_course;
      } else {
        return true;
      }
    });
    let color = false;
    tmp = tmp
      .sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())
      .sort((a, b) => (b.order && a.order ? b.order - a.order : 0))
      .map((v) => {
        color = !color;
        return {
          ...v,
          key: v.id,
          color: color,
          tasks: v.tasks
            .sort((a, b) => a.order - b.order)
            .filter((v) => v.type !== "depot"),
        };
      });
    return tmp;
  }
);

export const selectCoursesFilter = createSelector(
  (state) => (state.tasks.filter ? state.tasks.filter : {}),
  (filter) => {
    return filter;
  }
);

export const selectCourse = createSelector(
  (state) => state.tasks.current_course,
  (course) => {
    let tmp = [];
    let tmpPackages = [];
    if (course && course.tasks) {
      course.tasks.forEach((vTask) => {
        if (vTask.type !== "depot") {
          const start_date = vTask.start_date ? moment(vTask.start_date) : null
          const end_date = vTask.end_date ? moment(vTask.end_date) : null
          let formatedData = {
            key: vTask.id,
            ...vTask,
            start_date: start_date,
            end_date: end_date,
            date: start_date || end_date || null,
            start: start_date,
            end: end_date
          };
          if (vTask.packagesByPickupId) {
            vTask.packagesByPickupId.forEach((a) => {
              tmpPackages[a.id] = { ...a, key: a.id };
            });
            formatedData.packages_pickup = vTask.packagesByPickupId.map(
              (p) => p.id
            );
            formatedData.packagesByPickupId = vTask.packagesByPickupId.map(
              (p) => ({ ...p, key: p.id })
            );
          }
          if (vTask.packagesByDeliveryId) {
            vTask.packagesByDeliveryId.forEach((a) => {
              tmpPackages[a.id] = { ...a, key: a.id };
            });
            formatedData.packages_delivery = vTask.packagesByDeliveryId.map(
              (p) => p.id
            );
            formatedData.packagesByDeliveryId = vTask.packagesByDeliveryId.map(
              (p) => ({ ...p, key: p.id })
            );
          }
          tmp.push(formatedData);
        }
      });
      return { coursePackages: tmpPackages, courseTasks: tmp, course: course };
    }
    return { coursePackages: [], courseTasks: [], course: {} };
  }
);

export const selectCoursesInvoice = createSelector(
  (state) => ({
    courses: state.tasks.courses || {},
    moreCourses: state.tasks.moreCourses || {},
  }),
  ({ courses, moreCourses }) => {
    for (const [key] of Object.entries(courses)) {
      courses[key] = { ...courses[key], key: courses[key].id };
    }
    for (const [key] of Object.entries(moreCourses)) {
      moreCourses[key] = { ...moreCourses[key], key: moreCourses[key].id };
    }
    return { ...courses, ...moreCourses };
  }
);

export const selectCoursesByMerchant = createSelector(
    (state) => ({
      courses: state.tasks.courses || {},
    }),
    ({ courses }) => {
      let tmpCourses = []
      Object.values(courses).forEach((v) => {
        let tmpTasks = [];
        v.tasks.forEach((vTask) => {
          let tmp = vTask;
          tmp.course_id = v.id;
          tmp.devis = v.devis;
          tmp.food = v.food;
          tmp.package_types_id = v.package_types_id;
          tmp.coursier_id = v.coursier_id || null;
          tmp.team_id = v.team_id || null;
          tmp.created_at = v.created_at;
          tmp.key = vTask.id;
          tmp.start_date = vTask.start_date
              ? moment(vTask.start_date)
              : null;
          tmp.end_date = vTask.end_date
              ? moment(vTask.end_date)
              : null;
          tmpTasks.push(tmp);
        });
        tmpCourses = [...tmpCourses, ...tmpTasks];
      });
      return { courses: Object.values(courses), tasks: tmpCourses };
    }
);

export const selectLoadingTasks = createSelector(
  (state) => state.tasks.loading,
  (loading) => {
    return loading;
  }
);

export const selectStats = createSelector(
    ({ tasks }) => tasks.stats,
    (stats) => stats
);

export const selectCountPlanned = createSelector(
    (state) => state.tasks.countPlanned,
    (countPlanned) => {
      return countPlanned;
    }
);

export const selectCountProcessing = createSelector(
    (state) => state.tasks.countProcessing,
    (countProcessing) => {
      return countProcessing;
    }
);

export const selectActiveTab = createSelector(
    (state) => state.tasks.activeTab,
    (activeTab) => activeTab
)
