import { takeLatest, put, call, select, all, debounce } from 'redux-saga/effects';
import { TasksService, ProduceService, UserService, PackageService, HubsService } from '../../services';
import { LoaderActions } from '../loader';
import moment from 'moment-timezone';
import { types, default as TasksActions } from './actions';
import { showSuccess, showError, showInfo } from '../../utils';
import { task as dicoTasks } from '../../utils/dico-table-data';
import {
	createPackageCourse,
	createPackagesCourseDispatch,
	updatePackagesCourseDispatch,
	getMerchantById,
} from '../../utils/function-helper';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import printJS from 'print-js';
import { find, get, pick } from 'lodash';

function* getStatisticsRequest() {
	const { current_user } = yield select((state) => state.user);
	let wherePlanned = {
		_and: [{ tasks: { state: { _in: [0, 1] } } }, { merchant_id: { _eq: current_user.merchant_id } }],
	};
	let whereProcessing = {
		_and: [{ tasks: { state: { _eq: 2 } } }, { merchant_id: { _eq: current_user.merchant_id } }],
	};
	const [error, result] = yield call(TasksService.getCoursesCounts, {
		wherePlanned,
		whereProcessing,
	});
	if (result) {
		const { courses_planned, courses_processing } = result;
		const counts = {
			countPlanned: get(courses_planned, ['aggregate', 'count'], 0),
			countProcessing: get(courses_processing, ['aggregate', 'count'], 0),
		};
		yield put(TasksActions.getStatisticsSuccess(counts));
	} else if (error) {
		console.log('error', error);
	}
}

function* getStatisticsShopRequest() {
	const { current_user } = yield select((state) => state.user);
	let wherePlanned = {
		_and: [{ tasks: { state: { _in: [0, 1] } } }, { merchant_id: { _eq: current_user.merchant_id } }],
	};
	let whereProcessing = {
		_and: [{ tasks: { state: { _eq: 2 } } }, { merchant_id: { _eq: current_user.merchant_id } }],
	};
	const [error, result] = yield call(TasksService.getCoursesCounts, {
		wherePlanned,
		whereProcessing,
	});
	if (result) {
		const { courses_planned, courses_processing } = result;
		const counts = {
			countPlanned: get(courses_planned, ['aggregate', 'count'], 0),
			countProcessing: get(courses_processing, ['aggregate', 'count'], 0),
		};

		yield put(TasksActions.getStatisticsSuccess(counts));
	} else if (error) {
		console.log('error', error);
	}
}

function* getMoreRequest({ ids }) {
	yield put(LoaderActions.loading());
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	const [error, result] = yield call(TasksService.getMore, ids);
	if (error) {
		showError('get_tasks_error', strings, null, error);
		yield put(TasksActions.getMoreFailure());
	} else {
		yield put(TasksActions.getMoreSuccess(result.courses));
	}
	yield put(LoaderActions.loaded());
}

function* getStatisticsPlatformRequest() {
	const { current_user } = yield select((state) => state.user);
	const basicWhere = {
		course: { team_id: { _eq: current_user.team_id } },
		type: { _nin: ['collecte', 'depot'] },
	};
	const week = {
		_and: [{ start_date: { _gte: moment().startOf('week') } }, { start_date: { _lte: moment().endOf('week') } }],
	};
	const day = {
		_and: [{ start_date: { _gte: moment().startOf('day') } }, { start_date: { _lte: moment().endOf('day') } }],
	};
	const [errorStats, resultStats] = yield call(TasksService.getAllCoursesCounts, {
		whereNotAttributeDay: {
			_and: [{ state: { _eq: 0 } }, basicWhere, day],
		},
		whereAttributeDay: {
			_and: [{ state: { _eq: 1 } }, basicWhere, day],
		},
		whereInProgressDay: {
			_and: [{ state: { _eq: 2 } }, basicWhere, day],
		},
		whereValidateDay: {
			_and: [{ state: { _eq: 3 } }, basicWhere, day],
		},
		whereErrorDay: {
			_and: [{ state: { _eq: 4 } }, basicWhere, day],
		},
		whereNotAttributeWeek: {
			_and: [{ state: { _eq: 0 } }, basicWhere, week],
		},
		whereAttributeWeek: {
			_and: [{ state: { _eq: 1 } }, basicWhere, week],
		},
		whereInProgressWeek: {
			_and: [{ state: { _eq: 2 } }, basicWhere, week],
		},
		whereValidateWeek: {
			_and: [{ state: { _eq: 3 } }, basicWhere, week],
		},
		whereErrorWeek: {
			_and: [{ state: { _eq: 4 } }, basicWhere, week],
		},
	});
	if (resultStats && !errorStats) {
		const tmp = {
			week: {
				0: get(resultStats, ['tasks_0_week', 'aggregate', 'count'], null) || null,
				1: get(resultStats, ['tasks_1_week', 'aggregate', 'count'], null) || null,
				2: get(resultStats, ['tasks_2_week', 'aggregate', 'count'], null) || null,
				3: get(resultStats, ['tasks_3_week', 'aggregate', 'count'], null) || null,
				4: get(resultStats, ['tasks_4_week', 'aggregate', 'count'], null) || null,
			},
			day: {
				0: get(resultStats, ['tasks_0_day', 'aggregate', 'count'], null) || null,
				1: get(resultStats, ['tasks_1_day', 'aggregate', 'count'], null) || null,
				2: get(resultStats, ['tasks_2_day', 'aggregate', 'count'], null) || null,
				3: get(resultStats, ['tasks_3_day', 'aggregate', 'count'], null) || null,
				4: get(resultStats, ['tasks_4_day', 'aggregate', 'count'], null) || null,
			},
		};
		yield put(TasksActions.getStatisticsCoursesSuccess(tmp));
	}
}

function* getAllRequest({ values, dispatch, saveFilter }) {
	yield put(TasksActions.setLoading(true));
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	let condition = { _and: [] };
	if (values.coursier) {
		condition = {
			...condition,
			_and: [...condition['_and'], { tasks: { coursier_id: { _eq: values.coursier } } }],
		};
	}
	if (values.merchant_id) {
		condition = { ...condition, _and: [...condition['_and'], { merchant_id: { _eq: values.merchant_id } }] };
	}
	if (values.team_id) {
		condition = { ...condition, _and: [...condition['_and'], { team_id: { _eq: values.team_id } }] };
	}
	if (values.recipient_id) {
		condition = {
			...condition,
			_and: [...condition['_and'], { tasks: { recipient_id: { _eq: values.recipient_id } } }],
		};
	}
	if (values.created_by) {
		condition = { ...condition, _and: [...condition['_and'], { created_by: { _eq: values.created_by } }] };
	}
	if (values.showDekiCourse) {
		condition = {
			...condition,
			_and: [...condition['_and'], { merchant: { users: { team_id: { _is_null: true } } } }],
		};
	}
	if (values.search) {
		condition = {
			...condition,
			_or: [
				{ tasks: { name: { _ilike: `%${values.search}%` } } },
				{ tasks: { company: { _ilike: `%${values.search}%` } } },
				{ merchant: { company: { _ilike: `%${values.search}%` } } },
				{ merchant: { users: { name: { _ilike: `%${values.search}%` } } } },
				{ identifier: { _ilike: `%${values.search}%` } },
			],
		};
	}
	if (values.start_at && values.end_at) {
		condition = {
			...condition,
			_and: [
				...condition['_and'],
				{
					_and: [
						{ tasks: { start_date: { _gte: `${values.start_at}` } } },
						{ tasks: { start_date: { _lte: `${values.end_at}` } } },
					],
				},
			],
		};
	}

	if (Array.isArray(values?.state) && values.state.length > 0) {
		condition = { ...condition, _and: [...condition['_and'], { tasks: { state: { _in: values.state } } }] };
	}
	if (values.date && Array.isArray(values.date) && values.date.length > 0) {
		condition = {
			...condition,
			_and: [
				...condition['_and'],
				{
					_and: [
						{ tasks: { start_date: { _gte: `${values.date[0].format()}` } } },
						{ tasks: { start_date: { _lte: `${values.date[1].format()}` } } },
					],
				},
			],
		};
	}
	let filterUpdated = {
		...values,
	};
	yield call(
		TasksService.getAll,
		{
			where: condition,
			limit: filterUpdated?.pagination?.pageSize ?? null,
			offset:
				!filterUpdated?.pagination?.current && !filterUpdated?.pagination?.current
					? null
					: parseInt((filterUpdated?.pagination?.current - 1) * filterUpdated?.pagination?.pageSize ?? ''),
			updateQuery: (data) => {
				dispatch(TasksActions.getAllSuccess(data.courses));
			},
			errorQuery: (err) => {
				showError('get_tasks_error', strings, null, err);
				dispatch(TasksActions.getAllFailure());
			},
		},
		dispatch,
	);
	const [error, result] = yield call(TasksService.getCount, {
		where: condition,
	});
	if (result?.courses_aggregate?.aggregate?.count && !error) {
		filterUpdated = {
			...filterUpdated,
			pagination: {
				...values?.pagination,
				total: result?.courses_aggregate?.aggregate?.count ?? 0,
			},
		};
	}
	if (saveFilter) {
		yield put(TasksActions.updateFiltersReduc(filterUpdated));
	}
}

function* getDoneCourseRequest({ values, dispatch }) {
	yield put(TasksActions.setLoading(true));
	const strings = yield select((state) => getTranslate(state.locale));
	let condition = { _and: [{ tasks: { state: { _in: [3, 4] } } }] };
	if (values.recipient_id) {
		condition = {
			...condition,
			_and: [...condition['_and'], { tasks: { recipient_id: { _eq: values.recipient_id } } }],
		};
	}
	if (values.merchant_id) {
		condition = { ...condition, _and: [...condition['_and'], { merchant_id: { _eq: values.merchant_id } }] };
	}
	if (values.date && Array.isArray(values.date) && values.date.length > 0) {
		condition = {
			...condition,
			_and: [
				...condition['_and'],
				{
					_and: [
						{ tasks: { start_date: { _gte: `${values.date[0].format()}` } } },
						{ tasks: { start_date: { _lte: `${values.date[1].format()}` } } },
					],
				},
			],
		};
	}
	let filterUpdated = {
		...values,
	};

	yield call(TasksService.getAll, {
		where: condition,
		limit: filterUpdated?.pagination?.pageSize ?? null,
		offset:
			!filterUpdated?.pagination?.current && !filterUpdated?.pagination?.current
				? null
				: parseInt((filterUpdated?.pagination?.current - 1) * filterUpdated?.pagination?.pageSize ?? ''),
		updateQuery: (data) => {
			dispatch(TasksActions.getAllSuccess(data.courses));
		},
		errorQuery: (err) => {
			showError('get_tasks_error', strings);
			dispatch(TasksActions.getAllFailure());
		},
	});
	const [error, result] = yield call(TasksService.getCount, {
		where: condition,
	});
	if (result?.courses_aggregate?.aggregate?.count && !error) {
		filterUpdated = {
			...filterUpdated,
			pagination: {
				...values?.pagination,
				total: result?.courses_aggregate?.aggregate?.count ?? 0,
			},
		};
	}
	yield put(TasksActions.updateFiltersReduc(filterUpdated));
}
function* getPlannedCourseRequest({ values, dispatch }) {
	yield put(TasksActions.setLoading(true));
	const strings = yield select((state) => getTranslate(state.locale));
	let condition = { _and: [{ tasks: { state: { _in: [0, 1] } } }] };
	if (values.recipient_id) {
		condition = {
			...condition,
			_and: [...condition['_and'], { tasks: { recipient_id: { _eq: values.merchant_id } } }],
		};
	}
	if (values.merchant_id) {
		condition = { ...condition, _and: [...condition['_and'], { merchant_id: { _eq: values.merchant_id } }] };
	}
	if (values.date && Array.isArray(values.date) && values.date.length > 0) {
		condition = {
			...condition,
			_and: [
				...condition['_and'],
				{
					_and: [
						{ tasks: { start_date: { _gte: `${values.date[0].format()}` } } },
						{ tasks: { start_date: { _lte: `${values.date[1].format()}` } } },
					],
				},
			],
		};
	}
	let filterUpdated = {
		...values,
	};
	yield call(TasksService.getAll, {
		where: condition,
		limit: filterUpdated?.pagination?.pageSize ?? null,
		offset:
			!filterUpdated?.pagination?.current && !filterUpdated?.pagination?.current
				? null
				: parseInt((filterUpdated?.pagination?.current - 1) * filterUpdated?.pagination?.pageSize ?? ''),
		updateQuery: (data) => {
			dispatch(TasksActions.getAllSuccess(data.courses));
		},
		errorQuery: (err) => {
			showError('get_tasks_error', strings);
			dispatch(TasksActions.getAllFailure());
		},
	});
	const [error, result] = yield call(TasksService.getCount, {
		where: condition,
	});
	if (result?.courses_aggregate?.aggregate?.count && !error) {
		filterUpdated = {
			...filterUpdated,
			pagination: {
				...values?.pagination,
				total: result?.courses_aggregate?.aggregate?.count ?? 0,
			},
		};
	}
	yield put(TasksActions.updateFiltersReduc(filterUpdated));
}
function* getProcessingCourseRequest({ values, dispatch }) {
	yield put(TasksActions.setLoading(true));
	const strings = yield select((state) => getTranslate(state.locale));
	let condition = { _and: [{ tasks: { state: { _eq: 2 } } }] };
	if (values.recipient_id) {
		condition = {
			...condition,
			_and: [...condition['_and'], { tasks: { recipient_id: { _eq: values.merchant_id } } }],
		};
	}
	if (values.merchant_id) {
		condition = { ...condition, _and: [...condition['_and'], { merchant_id: { _eq: values.merchant_id } }] };
	}
	if (values.date && Array.isArray(values.date) && values.date.length > 0) {
		condition = {
			...condition,
			_and: [
				...condition['_and'],
				{
					_and: [
						{ tasks: { start_date: { _gte: `${values.date[0].format()}` } } },
						{ tasks: { start_date: { _lte: `${values.date[1].format()}` } } },
					],
				},
			],
		};
	}
	let filterUpdated = {
		...values,
	};
	yield call(TasksService.getAll, {
		where: condition,
		limit: filterUpdated?.pagination?.pageSize ?? null,
		offset:
			!filterUpdated?.pagination?.current && !filterUpdated?.pagination?.current
				? null
				: parseInt((filterUpdated?.pagination?.current - 1) * filterUpdated?.pagination?.pageSize ?? ''),
		updateQuery: (data) => {
			dispatch(TasksActions.getAllSuccess(data.courses));
		},
		errorQuery: (err) => {
			showError('get_tasks_error', strings);
			dispatch(TasksActions.getAllFailure());
		},
	});
	const [error, result] = yield call(TasksService.getCount, {
		where: condition,
	});
	if (result?.courses_aggregate?.aggregate?.count && !error) {
		filterUpdated = {
			...filterUpdated,
			pagination: {
				...values?.pagination,
				total: result?.courses_aggregate?.aggregate?.count ?? 0,
			},
		};
	}
	yield put(TasksActions.updateFiltersReduc(filterUpdated));
}
function* getOneRequest({ values, dispatch }) {
	yield put(LoaderActions.loading());
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield call(TasksService.getOne, {
		id: values.id,
		updateQuery: (data) => {
			dispatch(TasksActions.getOneSuccess(data.courses[0], []));
		},
		errorQuery: (err) => {
			showError('get_tasks_error', strings, null, err);
			dispatch(TasksActions.getOneFailure());
		},
	});
	yield put(LoaderActions.loaded());
}

function* getGeoByCoursierAndTasksRequest({ values, dispatch }) {
	yield put(LoaderActions.loading());
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield call(TasksService.getGeoByCoursiersAndTasks, {
		coursierIds: values.coursierIds,
		taskIds: values.taskIds,
		updateQuery: (data) => {
			if (data && data.geolocation && Array.isArray(data.geolocation)) {
				dispatch(TasksActions.getGeoByCoursiersAndTasksSuccess(data));
			}
		},
		errorQuery: (err) => {
			showError('get_geo_error', strings, null, err);
			dispatch(TasksActions.getGeoByCoursiersAndTasksFailure());
		},
	});
	yield put(LoaderActions.loaded());
}

function reformatNewCourse(course, packages) {
	packages.forEach((pack) => {
		const indexPickup = pack.pickup_id ? course.tasks.findIndex((task) => task.id === pack.pickup_id) : null;
		const indexDelivery = pack.delivery_id ? course.tasks.findIndex((task) => task.id === pack.delivery_id) : null;
		if (indexDelivery >= 0) {
			if (course.tasks[indexDelivery].packagesByDeliveryId)
				course.tasks[indexDelivery].packagesByDeliveryId = [
					...course.tasks[indexDelivery].packagesByDeliveryId,
					pack,
				];
			else course.tasks[indexDelivery].packagesByDeliveryId = [pack];
		}
		if (indexPickup >= 0) {
			if (course.tasks[indexPickup].packagesByPickupId)
				course.tasks[indexPickup].packagesByPickupId = [...course.tasks[indexPickup].packagesByPickupId, pack];
			else course.tasks[indexPickup].packagesByPickupId = [pack];
		}
	});
	return course;
}

function* createRequest({ values, course, team, price, isDevis, dispatch }) {
	yield put(LoaderActions.loading());
	const strings = yield select((state) => getTranslate(state.locale));
	const { current_user } = yield select((state) => state.user);
	const assignAuto = current_user.role !== 'dispatcher' && !current_user.team_id;
	let mailsToSend = [];
	let tasksTab = [];
	let devis = isDevis;
	let hubID = null;

	for (let i = 0, l = values.length; i < l; i++) {
		if (values[i].type === 'collecte' && values[i].hub_id) {
			hubID = values[i].hub_id;
		}
		const tmp = {
			...values[i],
			uid: uuidv4(),
			state: values[i].coursier_id ? 1 : 0,
			start_date: values[i].start_date.format(),
			end_date: values[i].end_date.format(),
			merchant_id: values[i].merchant_id || null,
			recipient_id: values[i].recipient_id || null,
		};

		if (tmp.receiver_code && tmp.receiver_code.length > 0) {
			const tab = tmp.receiver_code.split('-');
			if (tab[0] === 'm' && !values[i].merchant_id) {
				tmp.merchant_id = tab[1];
				tmp.recipient_id = null;
			} else if (tab[0] === 'r' && !values[i].recipient_id) {
				tmp.merchant_id = null;
				tmp.recipient_id = tab[1];
			}
		}
		tasksTab.push(tmp);
	}

	//const res = yield generateIdentifier();
	const prefixIdentifier = process.env.REACT_APP_TYPE === 'dispatcher' ? 'DISP' : 'SHOP';
	let filter = {
		identifier: `${prefixIdentifier}-${moment().format('YYMMDD-ssmmHH')}`,
		devis: 0,
		price: price || 0,
		cost: null,
		team_id: assignAuto ? null : current_user.team_id,
		order: 1,
		nb_bon: course.nb_bon ? course.nb_bon : null,
		merchant_id: course.merchant_id ? course.merchant_id : null,
		food: course.food ? course.food : null,
		fragile: course.fragile !== null ? course.fragile : null,
		coursier_id: course.coursier_id ? course.coursier_id : null,
		created_by: current_user.id ? current_user.id : null,
		insurance: course.insurance ? course.insurance : null,
		updated_by: null,
		tasks: { data: tasksTab.map((v) => pick(v, dicoTasks)) },
	};
	if (hubID) {
		const [errorDS, responseDS] = yield call(TasksService.getDeliveryStatus);
		if (responseDS && !errorDS) {
			const ds = responseDS.delivery_status.find((v) => v.status === 'in_delivery');
			filter = {
				...filter,
				orderByOrderId: {
					data: {
						delivery_status_id: ds.id,
						hub_id: hubID,
					},
				},
			};
		}
	}
	const [errorTask, responseTask] = yield call(TasksService.create, filter);
	if (errorTask) {
		showError('create_task_error', strings, null, errorTask);
		yield put(TasksActions.createFailure());
		yield put(LoaderActions.loaded());
		return;
	}
	let newCourse = responseTask.insert_courses_one;

	if (current_user.role !== 'dispatcher') {
		let resultPackage = yield createPackageCourse(tasksTab, newCourse, strings);
		if (resultPackage === false) {
			return;
		}
		newCourse = reformatNewCourse(newCourse, resultPackage);
	} else {
		let resultPackage = yield createPackagesCourseDispatch(tasksTab, newCourse, strings);
		if (resultPackage === false) {
			return;
		}
		newCourse = reformatNewCourse(newCourse, resultPackage);
	}

	for (let i = 0, l = tasksTab.length; i < l; i++) {
		if (tasksTab[i].type !== 'collecte' && tasksTab[i].email && tasksTab[i].email.length > 0) {
			const task = newCourse.tasks.filter((v) => v.uid === tasksTab[i].uid)[0];
			if (task.email || window.location.hostname === process.env.REACT_APP_HOST_TDP) {
				//Mail to recipient
				mailsToSend.push({
					type: 'create-course-to-recipient-mail',
					toSend: {
						subject: 'Nouvelle course',
						name: task.name ? task.name : task.company ? task.company : '',
						task: {
							...task,
							address: {
								value: task?.address?.value,
								text: task?.address?.text,
							},
						},
						email: tasksTab[i].email,
						link: task.merchant_id
							? `${process.env.REACT_APP_SHOP_URL}/#/courseMerchant/${newCourse.id}`
							: `${process.env.REACT_APP_SHOP_URL}/#/track/${tasksTab[i].uid}`,
					},
				});
			}
		}
	}
	let task = newCourse.tasks.filter((v) => v.type !== 'collecte')[0];
	if (current_user.role === 'dispatcher' && course.merchant_id) {
		const merchant = yield getMerchantById(course.merchant_id);
		//Mail to merchant (ENS)
		if (merchant && merchant.email) {
			mailsToSend.push({
				type: 'create-course-to-merchant-mail',
				toSend: {
					subject: 'Nouvelle course',
					name: merchant.name ? merchant.name : merchant.company ? merchant.company : '',
					address: task.address ? task.address.value : '',
					end_date: task.end_date ? task.end_date : null,
					start_date: task.start_date ? task.start_date : null,
					email: merchant.email,
					link: `${process.env.REACT_APP_SHOP_URL}/#/courseMerchant/${newCourse.id}`,
				},
			});
		}
	}

	if (devis === true) {
		// Comment devis for a while
		//if (window.location.hostname === process.env.REACT_APP_HOST_TDP) {
		showInfo('demande_livraison', strings);
		//} else {
		//showInfo('demande_devis', strings)
		//}
		mailsToSend.push({
			type: 'create-task-devis',
			toSend: {
				subject: 'Demande de devis',
				tasks: newCourse.tasks?.map((task) => ({
					...task,
					address: { value: task?.address?.value, text: task?.address?.text },
				})),
				email: process.env.REACT_APP_EMAIL_ADMIN,
				email_merchant: current_user.email,
				link: `${process.env.REACT_APP_ADMIN_URL}/#/course/${newCourse.id}`,
			},
		});
		yield call(ProduceService.triggerPushNotification, [
			{
				admin: true,
				message: "Une nouvelle course à gérer vient d'arriver",
			},
		]);
	}
	yield put(
		TasksActions.createSuccess(
			{ ...newCourse, packages: course?.packages ?? [] },
			current_user.role === 'merchant_manager' || current_user.role === 'merchant_staff',
		),
	);
	if (process.env.REACT_APP_TYPE === 'merchant') {
		yield put(TasksActions.getOneRequest({ id: newCourse.id }, dispatch));
	}

	if (current_user.role !== 'merchant_manager' && current_user.role !== 'merchant_staff') {
		yield put(push('/dispatcher'));
	} else {
		mailsToSend.push({
			type: 'create-course-to-merchant-mail',
			toSend: {
				subject: 'Nouvelle course',
				name: current_user?.name ?? '',
				address: task?.address.value ?? '',
				end_date: task?.end_date ?? null,
				start_date: task?.start_date ?? null,
				email: current_user.email,
				link: `${process.env.REACT_APP_SHOP_URL}/#/courseMerchant/${newCourse.id}`,
			},
		});
		if (team) {
			var [errorDispatchers, responseDispatchers] = yield call(UserService.dispatchersByTeamID, team);
			if (errorDispatchers) {
				showError('get_tasks_error', strings, null, errorDispatchers);
			}
			for (var i = 0, l = responseDispatchers.dispatchers.length; i < l; i++) {
				if (responseDispatchers.dispatchers[i].user.email) {
					mailsToSend.push({
						type: 'create-course-to-dispatcher-mail',
						toSend: {
							subject: 'Nouvelle course',
							tasks: newCourse?.tasks?.map((task) => ({
								...task,
								address: {
									value: task?.address?.value,
									text: task?.address?.text,
								},
							})),
							email: responseDispatchers.dispatchers[i].user.email,
							link: `${process.env.REACT_APP_PLATFORM_URL}/#/course/${newCourse.id}`,
						},
					});
				}
			}
		}
	}

	if (hubID) {
		try {
			const taskCollecte = newCourse.tasks.find(task => task.type === 'collecte' && task.name.toLowerCase().startsWith('hub'.toLowerCase()));
			console.log('==> taskCollecte', taskCollecte);

			if (taskCollecte) {
				const packagesByPickupId = taskCollecte.packagesByPickupId;

				let totalWeight = 0;
				let boxCount = 0;

				const uniqueDeliveryIds = new Set();
				packagesByPickupId.forEach(pkg => {
					totalWeight += pkg.weight;
					uniqueDeliveryIds.add(pkg.delivery_id);
				});

				const orderCount = uniqueDeliveryIds.size;

				const hubName = taskCollecte.name;
				const hubPlace = taskCollecte.address.value;
				const receptionDate = new Date(taskCollecte.start_date).toLocaleDateString('fr-FR', {
					weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				});
				const recipients = {};

				newCourse.tasks.forEach(task => {
					if (task.type === 'livraison') {
						const recipientName = task.name;
						const recipientBoxCount = task.packagesByDeliveryId.length;
						if (!recipients[recipientName]) {
							recipients[recipientName] = { name: recipientName, boxCount: 0 };
						}
						recipients[recipientName].boxCount += recipientBoxCount;
						boxCount += recipientBoxCount;
					}
				});

				const email = taskCollecte.email;

				mailsToSend.push({
					type: 'emails/toHub/notificationDelivery',
					toSend: {
						subject: 'Information livraison à venir',
						hubName,
						hubPlace,
						receptionDate,
						totalWeight,
						boxCount,
						orderCount,
						recipients: Object.values(recipients),
						email,
					},
				});
			}

		} catch (error) {
		}
	}

	const deliveryTasks = newCourse.tasks.filter(({ type }) => type === 'livraison');

	if (process.env.REACT_APP_NODE_ENV === "production" && deliveryTasks) {
		for (const deliveryTask of deliveryTasks) {
			yield generatePdfTaskRequest({ task: deliveryTask, isDymo: true, display: false });
		}
	}

	mailsToSend.push({
		type: 'emails/toAdmin/newDelivery',
		toSend: {
			subject: 'Nouvelle course',
			email: 'thibaud.dorian@deki.team',
			createdBy: current_user.name,
		},
	});

	yield call(ProduceService.sendEmails, mailsToSend);
	if (assignAuto) {
		yield call(TasksService.autoAssign, {
			course_ids: [newCourse.id],
			merchant_id: current_user.merchant_id,
		});
	}
	showSuccess('create_task_success', strings);
	yield put(LoaderActions.loaded());
}


function* accept({ id }) {
	yield call(TasksService.accept, id);
}

function* deny({ course }) {
	const { current_user } = yield select((state) => state.user);
	yield call(TasksService.deny, {
		course,
		team_id: current_user?.team_id,
	});
}

function* modifyRequest({ values, course, team, isImport, deleteByTable }) {
	const locale = yield select((state) => state.locale);
	const { current_user } = yield select((state) => state.user);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());
	try {
		let tasksTab = [];
		const mutationArray = [];
		const idsDelete = [];
		for (let i = 0, l = values.length; i < l; i++) {
			if (values[i] && values[i].delete && values[i].id) {
				idsDelete.push(values[i].id);
			} else {
				let history = values[i].history;
				if (values[i].modified === true) {
					history = {
						...history,
						modified: [...get(history, 'modified', []), moment().format()],
					};
				}
				if (values[i].key === values[i].id) {
					mutationArray.push({
						type: 'modify',
						service: TasksService.modifyTask,
						data: {
							values: {
								...values[i],
								uid: values[i].uid ? values[i].uid : uuidv4(),
								history,
								state: values[i].coursier_id && values[i].state === 0 ? 1 : values[i].state,
							},
						},
					});
				} else {
					mutationArray.push({
						type: 'create',
						service: TasksService.createTask,
						data: {
							values: {
								...values[i],
								uid: uuidv4(),
								course_id: course.id,
								history,
								state: values[i].coursier_id ? 1 : 0,
							},
						},
					});
				}
			}
		}

		if (idsDelete.length > 0)
			mutationArray.push({ type: 'delete', service: TasksService.deleteTask, data: idsDelete });
		if (mutationArray.filter(({ type }) => ['create', 'modify'].includes(type)).length === 0) {
			mutationArray.push({ type: 'delete', service: TasksService.delete, data: { id: course.id } });
		} else {
			let filter = {
				id: course.id,
				devis: course.devis === 1 && team ? 2 : course.devis,
				price: course.price || null,
				cost: course.cost || null,
				team_id: team ? team : null,
				nb_bon: course.nb_bon ? course.nb_bon : null,
				food: course.food ? course.food : null,
				fragile: course.fragile !== null ? course.fragile : null,
				merchant_id: course.merchant_id ? course.merchant_id : null,
				coursier_id: course.coursier_id ? course.coursier_id : null,
				created_by: course.created_by || null,
				updated_by: current_user.id ? current_user.id : null,
			};
			mutationArray.push({
				service: TasksService.modify,
				data: {
					filter,
					tasksTab,
				},
			});
		}

		const responses = yield all(mutationArray.map(({ service, data }) => call(service, data)));

		for (const resIndex in responses) {
			const [error, response] = responses[resIndex];
			if (error) {
				showError('update_course_error', strings, null, error);
				yield put(TasksActions.modifyFailure());
				yield put(LoaderActions.loaded());
				return;
			}
			const dataDeleteCourse = get(response, ['delete_courses', 'returning', 0], null);
			const dataUpdateCourse = get(response, ['update_courses', 'returning', 0], null);
			if (dataDeleteCourse) {
				yield put(TasksActions.deleteSuccess(dataDeleteCourse));
				showSuccess('delete_task_success', strings);
			}
			if (dataUpdateCourse) {
				let resultPackage = yield updatePackagesCourseDispatch(tasksTab, dataUpdateCourse, strings);
				if (resultPackage === false) {
					showError('update_course_error', strings);
					yield put(TasksActions.modifyFailure());
					yield put(LoaderActions.loaded());
					return;
				}
				if (deleteByTable) showSuccess('delete_task_success', strings);
				else showSuccess('maj_course_success', strings);
			}
		}
		yield put(LoaderActions.loaded());
		yield put(push('/dispatcher'));
	} catch (e) {
		yield put(LoaderActions.loaded());
		showError('update_course_error', strings);
	}
}

function* deleteTaskRequest({ values, deleteCourseId }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());
	let ids = [];
	if (values.id) {
		ids.push(values.id);
	}

	var [newErrorDf] = yield call(TasksService.deleteTask, ids);
	if (newErrorDf) {
		showError('delete_tasks_error', strings);
		yield put(LoaderActions.loaded());
		return;
	}
	if (deleteCourseId) {
		var [errorDelete] = yield call(TasksService.delete, {
			id: deleteCourseId,
		});
		if (errorDelete) {
			showError('delete_course_error', strings);
			yield put(TasksActions.deleteTaskFailure());
			yield put(LoaderActions.loaded());
			return;
		}
	}
	showSuccess('delete_task_success', strings);
	yield put(TasksActions.deleteTaskSuccess(values, deleteCourseId));
	yield put(LoaderActions.loaded());
}

function* modifyTaskRequest({ values }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());
	let history = values.history;
	history = {
		...history,
		modified: [...get(history, 'modified', []), moment().format()],
	};

	var [error, response] = yield call(TasksService.modifyTask, { values: { ...values, history } });
	if (error) {
		showError('modify_task_error', strings);
		yield put(TasksActions.modifyTaskFailure());
		yield put(LoaderActions.loaded());
		return;
	}
	showSuccess('modify_task_success', strings);
	yield put(TasksActions.modifyTaskSuccess(response.update_tasks.returning[0]));
	yield put(LoaderActions.loaded());
}

function* modifyOnlyRequest({ values, callback }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());

	var [error, response] = yield call(TasksService.modifyOnly, { values });
	if (error) {
		showError('modify_course_error', strings);
		yield put(TasksActions.modifyOnlyFailure());
		yield put(LoaderActions.loaded());
		return;
	}
	if (callback) {
		callback();
	}
	showSuccess('modify_course_success', strings);
	yield put(TasksActions.modifyOnlySuccess(response.update_courses.returning[0]));
	yield put(LoaderActions.loaded());
}

function* updateOrderRequest({ values }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());

	let mutation = `mutation {`;
	values.forEach((v, index) => {
		mutation =
			mutation +
			`
    update_${index + 1}: update_courses(where: {id: {_eq: ${v.id}}}, _set: {order: ${v.order}}) {
      returning {
        id
      }
    }`;
	});
	mutation = mutation + `}`;

	const [error] = yield call(TasksService.modify, {
		mutation,
	});
	if (error) {
		showError('modify_order_error', strings);
		yield put(TasksActions.updateOrderFailure());
		yield put(LoaderActions.loaded());
		return;
	}
	showSuccess('modify_order_success', strings);
	yield put(TasksActions.updateOrderSuccess(values));
	yield put(LoaderActions.loaded());
}

function* generateReceiptRequest({ id, merchant_id, taskIds = null }) {
	yield put(LoaderActions.loading());
	const width = window.innerWidth;
	const [error, response] = yield call(TasksService.generateReceipt, {
		id,
		merchant_id,
		taskIds,
		qrcodePath: `${window.origin}/#/track/`,
	});
	if (!error && response && response.data) {
		var link = document.createElement('a');
		link.href = process.env.REACT_APP_FLYDRIVE_API_URL + '/receipt/' + response.data.name;
		link.download = response.data.name;
		link.target = '_blank';
		width < 870
			? // Open a new windows for print the document
			link.dispatchEvent(new MouseEvent('click'))
			: // Open the print view diretly into the same view
			printJS(link.href);
	} else {
		const locale = yield select((state) => state.locale);
		const strings = getTranslate(locale);
		showError('generate_receipt_error', strings);
	}
	yield put(LoaderActions.loaded());
}

function* generatePdfTaskRequest({ task, isDymo, display = true }) {
	yield put(LoaderActions.loading());
	const [errorKinds, responseKinds] = yield call(PackageService.getPackageKinds);

	let tmp = {};
	const package_kinds_dico = {
		frais: 'Frais',
		'no-alimentaire': 'Non alimentaire',
		sec: 'Sec',
		surgele: 'Surgelè',
	};
	yield responseKinds.package_kinds.forEach((v) => {
		tmp[v.id.toString()] = package_kinds_dico[v.kind];
	});
	task.packages_kinds_dico = tmp;
	const [error, response] = yield call(TasksService.generatePdfTask, {
		task,
		isDymo,
	});

	if (!display && !error && response && response.data) {
		yield put(TasksActions.generatePdfTaskSuccess(response.data.name));
	} else if (!error && !errorKinds && response && response.data) {
		const link = document.createElement('a');
		link.href = process.env.REACT_APP_FLYDRIVE_API_URL + '/tasks/' + response.data.name;
		link.download = response.data.name;
		link.target = '_blank';
		printJS(link.href);
		yield put(TasksActions.generatePdfTaskSuccess(response.data.name));
	} else {
		const locale = yield select((state) => state.locale);
		const strings = getTranslate(locale);
		showError('generate_pdf_task_error', strings);
	}
	yield put(LoaderActions.loaded());
}

function* generateMailPdfTaskRequest({ task }) {
	yield put(LoaderActions.loading());
	const [errorKinds, responseKinds] = yield call(PackageService.getPackageKinds);
	let tmp = {};
	const package_kinds_dico = {
		frais: 'Frais',
		'no-alimentaire': 'Non alimentaire',
		sec: 'Sec',
		surgele: 'Surgelè',
	};
	yield responseKinds.package_kinds.forEach((v) => {
		tmp[v.id.toString()] = package_kinds_dico[v.kind];
	});
	task.packages_kinds_dico = tmp;
	const [error, response] = yield call(TasksService.generatePdfTask, task);
	if (!error && !errorKinds && response && response.data) {
		var link = {};
		link.href = process.env.REACT_APP_FLYDRIVE_API_URL + '/tasks/' + response.data.name;
		link.download = response.data.name;
		link.target = '_blank';
		yield call(ProduceService.sendEmails, [
			{
				type: 'send-qr-code',
				toSend: {
					subject: 'QR Code de la commande',
					email: task.email,
					link: link,
				},
			},
		]);
	} else {
		const locale = yield select((state) => state.locale);
		const strings = getTranslate(locale);
		showError('generate_pdf_task_error', strings);
	}
	yield put(LoaderActions.loaded());
}

function* getRecipientCourseByUIDRequest({ uid }) {
	yield put(LoaderActions.loading());
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	const [error, response] = yield call(TasksService.getRecipientCourseByUID, uid);
	if (!error && response && response.data) {
		yield put(TasksActions.getRecipientCourseByUIDSuccess(response.data));
	} else {
		yield put(TasksActions.getRecipientCourseByUIDFailure());
		showError('get_recipient_course_by_uid_error', strings);
	}
	yield put(LoaderActions.loaded());
}

function* rateTaskByUIDRequest({ uid, rate, rate_note }) {
	yield put(LoaderActions.loading());
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	const [error, response] = yield call(TasksService.rateTask, {
		uid,
		rate,
		rate_note,
	});
	if (!error && response && response.data) {
		showSuccess('rate_task_success', strings);
		yield put(TasksActions.rateTaskByUIDSuccess(response.data));
	} else {
		yield put(TasksActions.rateTaskByUIDFailure(response.data));
		showError('rate_task_error', strings);
	}
	yield put(LoaderActions.loaded());
}

function* swapOrderCoursier({ values, dispatch }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	const [error, response] = yield call(TasksService.swapOrderCoursier, values.data);
	if (error) {
		showError('modify_order_error', strings);
	}
	if (response) {
		showSuccess('modify_order_success', strings);
	}
	yield put(TasksActions.getAllRequest({ coursier: values.coursier_id }, dispatch));
}

function* updateTaskByRecipientRequest({ uid, start_date, end_date, callback }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	const [error] = yield call(TasksService.updateTaskByRecipient, {
		uid,
		start_date,
		end_date,
	});
	if (error) {
		showError('modify_task_error', strings);
	} else {
		if (callback) {
			callback();
		}
		yield put(TasksActions.updateTaskByRecipientSuccess({ uid, start_date, end_date }));
		showSuccess('modify_task_success', strings);
	}
}

function* importCoursesShop({ values }) {
	const locale = yield select((state) => state.locale);
	const { id, merchant_id } = yield select((state) => state.user.current_user);
	const strings = getTranslate(locale);
	const [error] = yield call(TasksService.importCoursesShop, {
		values,
		created_by: id,
		merchant_id,
	});
	if (error) {
		const errorMessages = (
			<>
				{get(error, ['response', 'data', 'errors'], []).map((v) => (
					<>
						Ligne {v.line}: {strings(v.key)} {strings(v.message)}
						<br />
					</>
				))}
			</>
		);
		showInfo('import_courses_error', strings, errorMessages);
	} else {
		showSuccess('import_courses_success', strings);
	}
}

const sagas = [
	takeLatest(types.UPDATE_TASK_BY_RECIPIENT_REQUEST, updateTaskByRecipientRequest),
	takeLatest(types.RATE_TASK_BY_UID_REQUEST, rateTaskByUIDRequest),
	takeLatest(types.GENERATE_RECEIPT_REQUEST, generateReceiptRequest),
	takeLatest(types.MODIFY_TASK_REQUEST, modifyTaskRequest),
	debounce(700, types.GET_ALL_REQUEST, getAllRequest),
	takeLatest(types.GET_MORE_REQUEST, getMoreRequest),
	takeLatest(types.GET_ONE_REQUEST, getOneRequest),
	takeLatest(types.ACCEPT, accept),
	takeLatest(types.DENY, deny),
	takeLatest(types.GET_RECIPIENT_COURSE_BY_UID_REQUEST, getRecipientCourseByUIDRequest),
	takeLatest(types.CREATE_REQUEST, createRequest),
	takeLatest(types.MODIFY_REQUEST, modifyRequest),
	takeLatest(types.DELETE_TASK_REQUEST, deleteTaskRequest),
	takeLatest(types.UPDATE_ORDER_REQUEST, updateOrderRequest),
	takeLatest(types.MODIFY_ONLY_REQUEST, modifyOnlyRequest),
	takeLatest(types.GENERATE_PDF_TASK_REQUEST, generatePdfTaskRequest),
	takeLatest(types.GENERATE_MAIL_PDF_TASK_REQUEST, generateMailPdfTaskRequest),
	takeLatest(types.GET_STATISTICS_PLATFORM_REQUEST, getStatisticsPlatformRequest),
	takeLatest(types.GET_GEO_BY_COURSIERS_AND_TASKS_REQUEST, getGeoByCoursierAndTasksRequest),
	takeLatest(types.SWAP_ORDER_COURSIER, swapOrderCoursier),
	debounce(850, types.GET_DONE_COURSE_REQUEST, getDoneCourseRequest),
	debounce(850, types.GET_PLANNED_COURSE_REQUEST, getPlannedCourseRequest),
	debounce(850, types.GET_PROCESSING_COURSE_REQUEST, getProcessingCourseRequest),
	debounce(850, types.GET_STATISTICS_SHOP_REQUEST, getStatisticsShopRequest),
	debounce(850, types.GET_STATISTICS_REQUEST, getStatisticsRequest),
	takeLatest(types.IMPORT_COURSES_SHOP, importCoursesShop),
];

export default sagas;
