import { takeLatest, put, call, select } from "redux-saga/effects";
import { PricingService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as PricingActions } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import _ from "lodash";

function* getPricingRequest({ team_id }) {
  //yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  let [error, result] = team_id
    ? yield call(PricingService.getPricingByTeamID, team_id)
    : yield call(PricingService.getPricingAdmin);

  if (result && result.pricing.length === 0) {
    [error, result] = yield call(PricingService.getPricingAdmin);
  }

  if (result && result.pricing.length >= 1) {
    yield put(PricingActions.getPricingSuccess(result.pricing[0]));
  } else if (error) {
    yield put(PricingActions.getPricingFailure());
    showError("get_pricing_error", strings, null, error);
  }
  //yield put(LoaderActions.loaded());
}

function* updatePricingRequest({ newPricingValuesForm }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error] = yield call(
    PricingService.updatePricing,
    _.omit(newPricingValuesForm, "__typename")
  );
  if (error) {
    showError("modify_pricing_error", strings, null, error);
    yield put(PricingActions.updatePricingFailure());
    yield put(LoaderActions.loaded());
  } else {
    showSuccess("modify_pricing_success", strings);
    yield put(PricingActions.updatePricingSuccess(newPricingValuesForm));
    yield put(LoaderActions.loaded());
  }
}

function* createPricingRequest({ newPricingValuesForm }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(
    PricingService.createPricing,
    _.omit(newPricingValuesForm, "__typename")
  );

  if (error) {
    showError("modify_pricing_error", strings, null, error);
    yield put(PricingActions.createPricingFailure());
  } else {
    showSuccess("modify_pricing_success", strings);
    yield put(
      PricingActions.createPricingSuccess(
        _.omit(result.insert_pricing_one, "__typename")
      )
    );
  }
  yield put(LoaderActions.loaded());
}

function* getZonePricingRequest({ zone_id }) {
  yield put(LoaderActions.loading());
  let [error, result] = yield call(PricingService.getZonePricing, zone_id);

  if (result && result.zone_pricing.length > 0) {
    yield put(PricingActions.getZonePricingSuccess(result.zone_pricing[0]));
  } else if (error) {
    yield put(PricingActions.getZonePricingFailure());
  }
  yield put(LoaderActions.loaded());
}

function* getZonePricingByTeamIdRequest({ teamId }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  let [error, result] = yield call(
    PricingService.getZonePricingByTeamId,
    teamId
  );

  if (result && result.zone_pricing) {
    yield put(
      PricingActions.getZonePricingByTeamIdSuccess(result.zone_pricing)
    );
  } else if (error) {
    yield put(PricingActions.getZonePricingByTeamIdFailure());
    showError("get_zone_pricing_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

function* updateZonePricingByTeamIdRequest({ newZonePricing, id, pricing }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(
    PricingService.updateZonePricing,
    { pricing: _.omit(newZonePricing, "__typename") },
    id
  );

  const [errorPricing, resultPricing] = yield call(
    PricingService.updatePricing,
    pricing
  );

  if (error || errorPricing) {
    showError("modify_zone_pricing_error", strings, null, error || errorPricing);
    yield put(PricingActions.updateZonePricingByTeamIdFailure());
  } else {
    showSuccess("modify_zone_pricing_success", strings);
    yield put(
      PricingActions.updatePricingSuccess(
        resultPricing.update_pricing.returning[0]
      )
    );
    yield put(
      PricingActions.updateZonePricingByTeamIdSuccess(
        result.update_zone_pricing.returning[0]
      )
    );
  }
  yield put(LoaderActions.loaded());
}

const sagas = [
  takeLatest(types.GET_PRICING_REQUEST, getPricingRequest),
  takeLatest(types.GET_ZONE_PRICING_REQUEST, getZonePricingRequest),
  takeLatest(types.UPDATE_PRICING_REQUEST, updatePricingRequest),
  takeLatest(types.CREATE_PRICING_REQUEST, createPricingRequest),
  takeLatest(
    types.UPDATE_ZONE_PRICING_BY_TEAMID_REQUEST,
    updateZonePricingByTeamIdRequest
  ),
  takeLatest(
    types.GET_ZONE_PRICING_BY_TEAMID_REQUEST,
    getZonePricingByTeamIdRequest
  ),
];
export default sagas;
