const string_fr = {
  /* Generic Message */
  generic_error_contact: "thomas.boyon@mellonmellon.com",
  generic_error_subject: "Erreur",
  generic_error_message: "Général",
  generic_error_contact_message:
    "Erreur durant le fonctionnement de l'application",
  unauthorized:
    "Vous n'êtes pas autorisé à afficher cette page, veuillez vous connecter.",
  authentication_failed: "Login ou mot de passe incorrect.",
  generic_error:
    "Une erreur inconnue s'est produite lors du chargement des données.",
  fields_missing: "Merci de renseigner tous les champs du formulaire.",
  any_contractor: "Aucun donneur d'ordre",
  /* Buttons */
  sign_in: "JE M'IDENTIFIE",
  connect: "CONNEXION",
  contractor: "DONNEUR D'ORDRE",
  remarks: "REMARQUES",
  Remarks: "Remarques",
  the_last_month: "Le mois dernier",
  current_month: "Mois en cours",
  change_password: "Modifier votre mot de passe",
  next: "Suivant",

  /* Authentication */
  password_different: "Mot de passe différent",
  email_placeholder: "Adresse E-mail",
  username: "Identifiant",
  password_placeholder: "Mot de passe",
  confirm_password_placeholder: "Confirmer le mot de passe",
  invalid_credentials: "Email et/ou mot de passe invalide",
  forgotten_password: "Mot de passe oublié",
  subscribe_succeed: "votre compte a bien été créé.",
  login: "Se connecter",
  users_email_unique: "Erreur sur l'email",
  email_unique: "Veuillez saisir un mail qui n’est pas déjà utilisé",
  password_required: "Mot de passe obligatoire",
  warning_message_password:
    "Laissez le mot de passe vide pour ne pas le modifier",

  /* Password */
  password_modify_error: "La modification de votre mot de passe a échouée",
  password_modify_success: "Votre mot de passe a été modifié avec succès",
  create_delivery_success: "Livraison créée avec succès",
  create_delivery_error:
    "Un problème est survenu sur la sauvegarde de votre livraison",
  get_all_delivery_error:
    "Un problème est survenu lors de la récupération des commandes",

  delete_tasks_success: "La tâche a bien été supprimée",
  delete_tasks_error:
    "Un problème est survenu sur la suppression de la livraison",
  get_all_teams_error:
    "Un problème est survenu sur la récupération des sociétés de livraison",
  modify_task_success: "Tâche modifiée avec succès",
  modify_task_error:
    "Un problème est survenu lors de la sauvegarde de la livraison",
  get_all_delivery_from_error:
    "La date de début dans les filtres doit être inférieur à la date d'aujourd'hui",

  import_tasks_success: "Les tâches ont été sauvegardées avec succès",
  merchant_create_success: "Le client a bien été créé",
  merchant_create_error:
    "Un problème est survenu lors de la création du client",
  merchant_delete_success: "Le client a bien été supprimé",
  merchant_delete_error:
    "Un problème est survenu lors de la suppression du client",
  merchant_update_success: "Le client a été modifié avec succès",
  merchant_update_error:
    "Un problème est survenu lors de la modification du client",
  get_merchants_error:
    "Un problème est survenu lors de la récupération des clients",

  get_all_worker_error:
    "Un problème est survenu lors de la récupération des coursiers",
  get_detail_task_error:
    "Un problème est survenu lors de la récupération du détail de la livraison",
  get_tasks_error:
    "Un problème est survenu lors de la récupération des courses",
  get_geo_error:
    "Un problème est survenu lors de la récupération de la géolocalisation",
  merchant_create_user_success: "Le compte du client a bien été créé",
  merchant_create_user_error:
    "Un problème est survenu lors de la création du compte du client",
  merchant_modify_user_success: "Le compte du client a bien été modifié",
  merchant_modify_user_error:
    "Un problème est survenu lors de la modification du compte du client",

  get_users_error:
    "Un problème est survenu lors de la récupération de l'utilisateur",
  change_password_email_not_fount_error:
    "Cet email n'est associé à aucun compte",
  change_password_error:
    "Un problème est survenu lors de l'envoi du mail, vérifiez l'email'",
  change_password_success: "Un email vous a été envoyé avec succès",

  get_package_type_team_error:
    "Un problème est survenu lors de la récupération des types de colis par société de livraison",
  get_package_type_error:
    "Un problème est survenu lors de la récupération des types de colis",

  get_areas_error:
    "Un problème est survenu lors de la récupération des zones géographiques",

  demande_devis:
    "Votre commande doit faire l'objet d'un devis. L'équipe DEKI va vous contacter",
  demande_livraison: "Votre demande a bien été prise en compte",
  update_user_success: "Votre profil a été modifié avec succès",
  update_user_error:
    "Un problème est survenu lors de la modification de votre profil",

  create_task_success: "Votre course a été créée avec succès",
  maj_course_success: "Votre course a été mise à jour avec succès",
  create_task_error: "Une erreur est survenu lors de la création de la tâche",

  modify_course_error:
    "Une erreur est survenu lors de la modification de la course",
  modify_course_success: "La course a été mise à jour avec succès",

  delete_course_error:
    "Une erreur est survenu lors de la suppression d'une course",
  delete_task_success: "La course a été mise à jour avec succès",

  import_merchants_success: "Les clients ont été ajoutés avec succès",
  import_merchants_error:
    "Un problème est survenu lors de la création des clients",

  1000: "Les valeurs d'un ou de plusieurs paramètres ne sont pas valables.", //"The values of one or more parameters are invalid.",
  1001: "Les valeurs d'un ou de plusieurs paramètres sont ambiguës.", //"The values of one or more parameters are ambiguous.",
  1005: "Les types de données d'un ou de plusieurs paramètres ne sont pas valables.", //"The data types of one or more parameters are invalid.",
  1009: "La date de début doit être inférieur à la date de fin",
  1010: "Numéro de téléphone invalide",
  1011: "Des problèmes avec l'adresse ont été constatés",
  2300: "Vous avez atteint le nombre de requête par seconde maximum. S'il vous plait, ralentissez",

  cancel: "Annuler",
  back: "Retour",
  save: "Sauvegarder",
  apply_discount: "Souhaitez-vous appliquer une remise ?",

  update_password_error:
    "Une erreur est survenu lors de la modification du mot de passe",
  update_password_success: "Le mot de passe a été modifié avec succès",
  coursier_delete_error:
    "Un problème est survenu lors de la suppression du coursier",
  coursier_delete_success: "Le coursier a bien été supprimé",
  coursier_update_error:
    "Un problème est survenu lors de la modification du coursier",
  coursier_update_success: "Le coursier a bien été modifié",
  create_coursier_error:
    "Un problème est survenu lors de la creation du coursier",
  create_coursier_success: "Le coursier a bien été créé",
  get_coursiers_error:
    "Un problème est survenu lors de la récupération des coursiers",
  modify_order_success: "L'ordre des courses a été mis à jour avec succès",
  modify_order_error:
    "Un problème est survenu lors de la modification de l'ordre des courses",
  create_crm_shop_success: "Le client a bien été créé",

  validate_merchant_info_success: "Les informations ont bien été sauvegardées",
  validate_merchant_info_error:
    "Un problème est survenu lors de la sauvegarde des informations",

  cannot_delete_payed: "Vous ne pouvez pas supprimer une facturation payée",

  send_invoice_success: "Le mail a bien été envoyé",
  send_invoice_error: "Un problème est survenu lors de l'envoi du mail",
  create_invoice_error:
    "Un problème est survenu lors de la création de la facture",
  update_invoice_error:
    "Un problème est survenu lors de la modification de la facture",

  uid_package: "Identifiant du colis",
  uid_task: "Identifiant de la livraison",
  download_pdf_delivery: "Télécharger le PDF de la livraison",
  generate_pdf_task_error:
    "Un problème est survenu lors du téléchargement du PDF",
  generate_receipt_error:
    "Un problème est survenu lors de la génération de votre reçu",
  sign: "Signature",
  info: "Informations",
  any_uid: "Aucun UID",

  merchants_delete_error:
    "Un problème est survenu lors de la suppression des clients",
  merchants_delete_success: "Les clients ont bien été supprimés",
  recipient_delete_error:
    "Un problème est survenu lors de la suppression du bénéficiaire",
  recipients_delete_error:
    "Un problème est survenu lors de la suppression des bénéficiaires",
  recipient_delete_success: "Le bénéficiaire a bien été supprimé",
  recipients_delete_success: "Les bénéficiaires ont bien été supprimés",
  recipient_update_error:
    "Un problème est survenu lors de la modification du bénéficiaire",
  recipient_update_success: "Le bénéficiaire a bien été modifié",
  recipient_create_error:
    "Un problème est survenu lors de la création du bénéficiaire",
  recipient_create_success: "Le bénéficiaire a bien été créé",

  new_recipient: "Nouveau destinataire",
  my_recipients: "Mes Destinataires",
  create_a_recipient: "Créer un destinataire",
  update_the_recipient: "Modifier le destinataire",
  search: "Rechercher",
  all_your_recipient: "Tous vos destinataires",
  beneficiaries: "Destinataires",
  address: "Adresse",
  phone: "Téléphone",
  name: "Prénom - Nom",
  company: "Société",
  date: "Date",
  phone_number: "Numéro de téléphone",
  company_required: "Société Obligatoire",
  name_required: "Prénom Nom Obligatoire",
  phone_required: "Téléphone Obligatoire",

  my_coursier: "Mes Coursiers",
  all_your_coursier: "Toutes vos coursiers",
  update_coursier: "Modifier le coursier",
  create_coursier: "Créer un coursier",

  from: "A partir du",
  until: "Jusqu'au",
  add: "Ajouter",

  all_your_merchants: "Tous vos clients",
  new_merchant: "Nouveau client",
  new_coursier: "Nouveau coursier",
  my_merchants: "Mes clients",
  create_a_merchant: "Créer un client",
  update_the_merchant: "Modifier le client",
  email_contact: "Email du contact",
  name_contact: "Prénom Nom du contact",
  name_receiver: "Prénom Nom du destinataire",
  phone_contact: "Téléphone du contact",
  add_mention_doc: "Ajouter une mention au document",
  upon_receipt_of_the_invoice: "A réception de la facture",
  days_after_receipt: "jours après réception",
  payment_deadline_client: "Délai de paiement pour ce client",
  payment_deadline: "Délai de paiement accordé",
  discount_granted: "Remise accordée",
  special_conditions: "Conditions particulières",
  special_conditions_uppercase: "CONDITIONS PARTICULIÈRES",
  info_package: "INFORMATIONS COLIS",
  save_send: "Enregistrer et envoyer",
  register: "Enregistrer",
  taxe: "TVA intracommunautaire",
  not_fragile: "Pas fragile",
  nature_of_the_merchandise: "Nature de la marchandise",
  type_of_package: "Type de colis",
  type_of_package_required: "Type de colis obligatoire",
  format_not_correct: "Le format n'est pas correct",
  phone_must_be_composed_of_12_long:
    "Le numéro doit être composé de 12 caractères",
  phone_must_be_10_digits_long: "Le numéro doit être composé de 10 chiffres",
  of_contact: "du contact",
  firstname_lastname_of: "Prénom et Nom du",
  receiver: "destinataire",
  Receiver: "Destinataire",
  receiver_required: "Destinataire requis",
  receiver_email: "Email du destinataire",
  recipient_address: "Adresse du destinataire",
  client_address: "Adresse du client",
  delivery_address: "Adresse de livraison",
  company_label: "Raison sociale / Nom de l'entreprise",
  individual: "Particulier",
  professional: "Professionnel",
  more_option: "Plus d'options",
  verif_info_profil: "Vérification des informations du profil",
  name_of_receiver: "Prénom et Nom du destinataire",

  your_course: "Votre course",
  add_receiver: "Ajouter un destinataire",
  update_receiver: "Modifier le bénéficiaire",
  add_merchant: "Ajouter un client",
  update_merchant: "Modifier le client",
  confirm: "Confirmer",

  create_recipient: "Créer un bénéficiaire",
  update_recipient: "Modifier le bénéficiaire",
  update_client: "Modifier le client",
  update_your_address: "Modifier votre adresse dans le profil",
  no_recipient: "Aucun destinataire trouvé",
  email_required: "Email obligatoire",
  email_or_phone_required: "Email ou téléphone obligatoire",
  iban_required: "IBAN Obligatoire",
  email_required_to_mail: "Email obligatoire pour l'envoi de mail",
  email_or_phone_required_to_mail:
    "Email ou téléphone obligatoire pour contacter le client",
  address_required: "Adresse obligatoire",
  email_invalid: "Email invalide",
  email_already_used: "Email déjà utilisé",
  required: "obligatoire",
  receiver_phone: "Téléphone du destinataire",
  text_price:
    "Le tarif dépendra de la zone dans laquelle se trouve l'adresse. Pour Marseille il existe deux zones: du 1er au 7e arrondissement, et du 8e au 16e arrondissement.",
  comment_delivery: "Commentaire sur la livraison",
  optional: "Optionnel",
  indicator_coursier: "Indication pour le livreur",
  one_collecte_history: "Collecte demandée",
  one_livraison_history: "Livraison demandée",
  two_collecte_history: "Départ vers l’adresse de collecte du colis",
  two_livraison_history: "Départ vers l’adresse de livraison",
  three_collecte_history: "Collecte du colis à l’adresse indiquée",
  three_livraison_history: "Livraison du colis à l’adresse indiquée",
  four_collecte_history: "Echec de la collecte",
  four_livraison_history: "Echec de la livraison",
  expected_delivery: "Heure de livraison prévue",
  expected_pickup: "Heure de collecte prévue",
  signal_history: "Un signalement a été fait",
  modified_history: "Une modification a été effectuée",
  one_collecte_history_state: "Collecte demandée",
  one_livraison_history_state: "Livraison demandée",
  two_collecte_history_state: "Collecte du colis",
  two_livraison_history_state: "En cours de livraison",
  three_collecte_history_state: "Colis collecté",
  three_livraison_history_state: "Colis livré",
  four_collecte_history_state: "Echec de la collecte",
  four_livraison_history_state: "Echec de la livraison",
  add_package: "Ajouter le colis",
  add_a_package: "Ajouter un colis",
  info_receiver: "Informations sur le destinataire",
  choose_receiver: "Choisissez le destinataire",

  five_text_history: "En cours de livraison",
  waiting_estimate: "Devis en attente",
  valid_estimate: "Devis validé",
  detail_delivery: "Détails de la livraison",
  see_note: "Voir le commentaire",
  fragile: "fragile",
  money: "€",
  no_note: "Aucun commentaire",
  note: "Commentaire",
  notes: "Notes",
  history: "Historique",
  nb_bon: "Nombre de Bons",
  day_delivery: "Jour de livraison",
  day_pickup: "Jour de la collecte",
  day_of_delivery: "Jour de la livraison",
  option_delivery: "Option de livraison",
  option_pickup: "Option de la collecte",
  from_tomorrow: "À partir de demain",

  delivered_by: "Livré par",
  history_title: "Voir l'historique",
  status_title: "Voir le statut",
  select_delivery: "Sélectionner une livraison ou une collecte",
  track_your_delivery: "Suivi en temps réel",
  follow_delivery: "Suivi de votre livraison",

  delivery_done: "Livraison effectuée",
  thank_message: "Merci d'avoir utilisé le service de livraison à domicile",
  give_rate: "Donnez une note à votre livraison",
  leave_comment: "Laissez-nous votre avis",
  send: "Envoyer",
  delete: "Supprimer",
  update: "Modifier",
  yes: "Oui",
  no: "Non",
  no_zone: "Aucune zone",
  are_you_sure_delete: "Êtes vous sûr de vouloir supprimer ?",
  rate_task_success: "Votre avis a bien été enregistré",
  select_package: "Sélectionnez un ou plusieurs colis",
  packages: "colis",
  packages_up: "Colis",
  download_receipt: "Télécharger le justificatif",
  default_package: "Colis par défaut",
  get_package_kind_error:
    "Un problème est survenu lors de la récupération des types de colis",
  delete_invoice_text_one:
    "Vous êtes sur le point de supprimer le document sélectionné",
  delete_invoice_text_one_plurial:
    "Vous êtes sur le point de supprimer les documents sélectionnés",
  delete_invoice_text_two:
    "Êtes vous sûr de vouloir supprimer ce document ? La suppression est définitive et vous ne pourrez plus y avoir accès.",
  delete_invoice_text_two_plurial:
    "Êtes vous sûr de vouloir supprimer ces documents ? La suppression est définitive et vous ne pourrez plus y avoir accès.",
  delete_invoice_button: "Supprimer le document",
  send_invoice_button: "Envoyer le document",
  to_invoice: "Prestations à facturer",
  invoices_title: "Facturation",

  market_value: "Valeur marchande",
  all_packages_market_value: "Valeur marchande totale",
  sizes: "Dimension",
  pickups: "Collectes",
  package_containt: "Contenu du colis",
  are_you_sure: "Etes-vous sûr ?",

  coursier: "Coursier",
  volumn_util: "Volume Utile (cm3)",
  weight_max: "Poids Maximum (Kg)",
  volumn: "Volume (cm3)",
  weight: "Poids (Kg)",
  kilo: "Kg",
  vehicle_type: "Type de véhicule",
  my_vehicle_types: "Mes types de véhicules",
  new_vehicle_type: "Nouveau type de véhicule",
  all_your_vehicle_types: "Tous vos types de véhicule",
  update_vehicle_type: "Modifier le type de véhicule",
  create_vehicle_type: "Créer un type de véhicule",
  simple_name: "Nom",
  update_package: "Modifier le colis",
  no_package: "Aucun colis",

  get_vehicle_types_error:
    "Un problème est survenu lors de la récupération des types de véhicule",
  create_vehicle_type_success: "Le type de véhicule a été créé avec succès",
  create_vehicle_type_error:
    "Un problème est survenu lors de la création du type de véhicule",
  vehicle_type_update_success: "Le type de véhicule a été modifié avec succès",
  vehicle_type_update_error:
    "Un problème est survenu lors de la modification du type de véhicule",
  vehicle_type_delete_success: "Le type de véhicule a été supprimé avec succès",
  vehicle_type_delete_error:
    "Un problème est survenu lors de la suppression du type de véhicule",
  get_teams_error:
    "Un problème est survenu lors de la récupération de la société de livraison",

  get_pricing_error: "La récupération des tarifs a échoué",
  modify_pricing_error:
    "Un problème est survenu lors de la modification des tarifs",
  get_zone_pricing_error:
    "Un problème est survenu lors de la récupération des tarifs de la zone",
  modify_pricing_success: "Les tarifs ont été modifiés avec succès",
  weight_required: "Poids obligatoire",
  volumn_required: "Volume obligatoire",
  add_task: "Ajouter une tâche",
  declared_value_required: "Valeur marchande obligatoire",
  update_task: "Modifier la tâche",
  new_task: "Nouvelle tâche",
  type_required: "Type obligatoire",
  pickup: "Collecte",
  delivery: "Livraison",
  delivery_pickup: "Livraison + Collecte",
  time_slot: "Créneau",
  today: "Aujourd'hui",
  start: "Début",
  end: "Fin",
  hub: "Hub",
  validate: "Valider",
  error_sup_date: "L'heure doit être inférieur à l'heure de fin",
  cancel_update: "Annuler la modification",
  cancel_current_task: "Annuler la tâche en cours",
  valid_update: "Valider la modification",
  add_the_task: "Ajouter la tâche",
  customer: "Client",
  contractor_lowercase: "Donneur d'ordre",
  coursier_default: "Coursier par défaut",
  update_course: "Modifier la course",
  new_course: "Nouvelle course",
  my_course: "Mes Courses",
  all_courses: "Toutes vos courses",
  reference: "Référence",
  type: "Type",
  statut: "Statut",
  actions: "Actions",
  no_data_for_this_date: "Aucune donnée pour cette date",
  error_date: "Erreur date",
  hour_start_end_required:
    "Une heure de début ou de fin est obligatoire si vous remplissez la date du créneau",
  task_add_to_course: "Tâches ajoutées à ma course",
  package_pickup: "Colis à collecter",
  package_delivery: "Colis à déposer",
  last_update: "Dernière MàJ",
  contact_deki: "Contacter DEKI",

  select_coursier: "Selectionnez un coursier",
  no_coursier: "Aucun coursier",
  no_state: "Aucun status",
  enveloppe: "Enveloppe",
  envelope: "Enveloppe",
  box: "Boîte",
  "big-box": "Grosse boîte",
  "shopping-bag": "Sac de course",
  other: "Devis",
  brittle: "Fragile",
  noBrittle: "Non-fragile",
  noFood: "Non-alimentaire",
  shoppingBag: "Sac de shopping",
  sec: "Sec",
  costs: "Frais",
  fresh: "Frais",
  freshFrozen: "Surgelé",
  restricted: "Restreint",
  type_food: "Type d'alimentaire",
  food: "Alimentaire",
  lessAnHour: "Livraison en moins d'une heure",
  otherDays: "Livraison le lendemain",
  outOfAreaDelivery: "Livraison hors zone",
  outArea: "Hors Zone",
  chooseTypeFood: "Veuillez choisir un type d'alimentaire",
  add_default_package: "Ajouter un colis par défaut",
  customModalInitPriceTitle: "Bonjour, vous n'avez aucune tarification.",
  customModalInitPriceContent:
    "Pour une utilisation optimale, il est nécessaire de mettre à jour votre tarification, dans le cas contraire la tarification de la plateforme DEKI seras utilisé.",
  customModalInitPriceContentOnOk:
    "En sélectionnant Continuer vous serez redirigé vers la page de tarification qui vous permettras de mettre vos prix à jour.",
  toContinue: "Continuer",
  notNow: "Pas maintenant",
  duplicate_course: "Dupliquer la course",
  duplicate: "Dupliquer",
  get_invoices_for_export_error: "Erreur lors de l'exportation",

  /* pricing */
  overcost: "Surcoût",
  discount: "Réductions",
  pricing_base: "Prix de base",
  table_pricing: "Grille de tarification",
  modify_zone_pricing_success:
    "Les tarifs de la zone ont été modifiés avec succès",
  modify_zone_pricing_error:
    "Un problème est survenu lors de la modification des tarifs de la zone",

  update_profil: "Modifier le profil",
  profil_update_success: "Le profil a été modifié avec succès",
  between: "entre",
  and: "et",
  fast_delivery: "Livraison RAPIDE",
  fast_delivery_text:
    "Livraison en 2h après la validation de la livraison par le coursier",
  could_note_choose:
    "Vous ne pouvez pas choisir une plage horaire et un délai précis",
  select_plage: "Sélectionnez une plage horaire ou un délai précis",
  range_delivery: "Plage horaire de livraison",
  your_profil: "Votre profil",
  my_profil: "Mon Profil",
  edit_profil: "Edition du profil",
  transmitter: "Émetteur",
  transmitter_required: "Émetteur obligatoire",
  price_bon: "Prix d'un bon",
  nb_bon_total: "Nombre de bons total",
  temp_price: "Prix prévisionnel",
  view_info_merchant: "Voir la fiche client",
  merchant_to_invoice: "Client à facturer",
  calcul_price: "(Nombre de bons) x (Prix du bon)",
  add_price_bon_merchant: "Ajoutez un prix de bon au client",
  no_nb_bon: "Aucun bon",
  merchants_update_success: "Le client a été modifié avec succès",
  this_week: "Cette semaine",
  this_month: "Ce mois",
  CA: "Chiffre d'affaire",
  nb_courses: "Nombre de courses",
  stats_period: "Période des statistiques",
  nb_courses_total: "Nombre de courses total",
  the: "Le",
  delivery_to: "Livraison au",
  choose_timeslot: "Choisissez un créneau horaire",
  choose_date: "Choisissez une date valide",
  current_timeslot: "Créneau actuel",
  suggest_timeslot: "Créneau suggéré",
  zipcode: "Code postal",
  edit_teams: "Edition de la société de livraison",
  task_started: "La livraison a commencée, impossible de modifier le créneau",
  update_team_success: "La société de livraison a été modifiée avec succès",
  update_team_error:
    "Un problème est survenu lors la sauvegarde de la société de livraison",
  display: "Afficher",
  receive: "Recevoir",
  print_delivery: "Imprimer",
  print_ticket: "Imprimer l'étiquette",
  package: "Colis",
  show_deki_course: "Voir seulement les courses de DEKI",
  carbon_saved: "CO2 économisé (g/%)",
  info_delivery: "Contenu de la livraison",
  message_select_receiver:
    "Sélectionnez un destinataire avant d'ajouter un colis",
  accept_course_desc: "En acceptant la course, vous vous engagez à la réaliser",
  accept_course: "Accepter la course",
  deny_course_desc:
    "En refusant la course, vous perdez définitivement la possibilité de la réaliser",
  deny_course: "Refuser la course",
  filters: "Filtres",
  see_info: "Voir les informations",
  more_info: "Informations supplémentaires",
  created_date: "DATE D'ÉMISSION",
  created_by: "CRÉER PAR",
  validate_date: "DATE ET HEURE DE LA VALIDATION",
  validate_user: "PAIEMENT VALIDÉ PAR",
  send_date: "DATE D'ENVOI",
  back_home: "Retour à l'accueil",
  nb_invoice: "Facture N°",
  invoice: "Facture",
  allowed_delete: "La suppression est impossible",
  is_payed: "Marquer comme payée",
  send_mail: "Envoyer par mail",
  payed: "PAYÉE",
  saved_caps: "ENREGISTRÉE",
  courses_to_invoice: "Quelles courses souhaitez-vous facturer ?",
  filter_in_search: "Filtrer dans la recherche",
  start_date: "Date de début",
  end_date: "Date de fin",
  launch_search: "Lancer la recherche",
  select_all: "Tout sélectionner",
  courses_to_invoice_title: "Courses à facturer",
  no_courses_selected: "Aucune course selectionnée",
  task: "Tâche",
  stop_point: "Points d'arrêt",
  course_price: "Prix de la course",
  course_date: "Créée le",
  clients: "Clients",
  client: "Client",
  course_id: "ID de la course",
  sort: "Trier",
  order: "Ordre",
  download: "Télécharger",
  send_files: "Envoi des documents",
  send_file: "Envoi du document",
  from_email: "Email de l'émetteur",
  to_email: "Email du destinataire",
  email_address: "Adresse email",
  message: "Message",
  message_required: "Message Obligatoire",
  city: "Ville",
  pickup_addr: "Adresse de collecte",
  my_address: "Mon adresse",
  other_address: "Une autre adresse",
  deki_hub: "Hub DEKI",
  name_of_contact: "Prénom et Nom du contact",
  note_pickup: "Commentaire sur la collecte",
  all_package_type: "Tous vos types de colis",
  create_package_type: "Créer un type de colis",
  update_package_type: "Modifier le type de colis",
  update_package_types: "Modifier les types de colis",
  icon: "Icone",
  new_package_type: "Nouveau type de colis",
  choose_icon: "Choisir une icone",
  add_icon: "Ajouter une icone",
  update_icon: "Modifier l'icone",
  delete_icon: "Supprimer l'icone",
  preview: "Aperçu",

  create_package_type_success: "Le type de colis a bien été sauvegardé",
  create_package_type_error:
    "Un problème est survenu lors de la sauvegarde du type de colis",
  update_package_type_success: "Le type de colis a bien été modifié",
  update_package_type_error:
    "Un problème est survenu lors de la modification du type de colis",
  delete_package_type_success: "Le type de colis a bien été supprimé",
  delete_package_type_error:
    "Un problème est survenu lors de la suppression du type de colis",

  assign_package_types: "Assigner des types de colis sur le profil du client",
  type_of_package_create_by_merchant: "Type de colis créé par le client",
  type_of_package_create_by_you: "Type de colis créé par vous",
  type_of_package_assign: "Type de colis assigné au client",
  go_package_type_screen: "Aller sur l'écran de gestion des types de colis",
  go_package_type_assign_screen:
    "Aller sur l'écran d'assignation des types de colis",
  nb_package_type: "Nombre de type de colis",
  no_default_package: "Pas de colis par défaut",
  no_package_type: "Aucun type de colis",
  preview_default_package: "Voir le colis par défaut",
  modify_default_package: "Modifier le colis par défaut",
  estimated_price_to_pay: "Tarif à payer estimé",
  estimated_insurance: "Assurance estimée",
  not_specified: "Non spécifié",
  choose_range: "Créneau Horaire",
  choose_range_detail: "Choix en fonction des heures d'ouvertures",
  or_create_range: "Choix personnalisé",
  add_a_default_package: "Ajouter le colis par défaut",
  recap: "Récapitulatif",
  create: "Création",
  of_delivery: "de la livraison",
  new_invoice: "Nouvelle facture",
  invoices_caps: "FACTURES",
  courses_caps: "COURSES",
  no_invoices: "Il y a aucune facture pour le moment",
  no_courses: "Il y a aucune course pour le moment",
  activity: "Activité",
  search_user_error: "Un problème est survenu lors de la recherche",
  delete_course: "Supprimer la course",
  validate_course: "Valider ma course",
  receive_by_mail: "Recevoir par mail",
  client_name: "Nom du client",
  short_nb_bon: "Nbrs de bon",
  dispatch_email: "Email de contact",
  scheduled_deliveries: "Livraisons programmées",
  inprogress_deliveries: "Livraisons en cours",
  completed_deliveries: "Livraisons terminées",
  delete_default_package_merchant_success:
    "Colis par défaut supprimé avec succès",
  delete_default_package_merchant_error:
    "Un problème est survenu lors de la suppression du colis par défaut",
  create_default_package_merchant_success: "Colis par défaut créé avec succès",
  create_default_package_merchant_error:
    "Un problème est survenu lors de la création du colis par défaut",
  add_default_packages: "Ajouter les colis par défaut",
  update_default_packages: "Modifier les colis par défaut",
  update_default_package: "Modifier le colis par défaut",
  manage_default_packages: "Gérer les colis par défaut",
  collecte: "Collecte",
  livraison: "Livraison",
  "livraison/collecte": "Livraison + Collecte",
  followup_of_deliveries: "Suivi des livraisons",
  day: "Jour",
  week: "Semaine",
  update_course_error:
    "Un problème est survenu lors de la modification de la course",
  import_course: "Import",
  import_excel_course_tooltip: "Importation de plusieurs courses depuis un fichier Excel",
  import_excel_course: "Importer",
  import_excel_recipient:"Importer",
  import_excel_recipient_tooltip:"Importation de plusieurs clients depuis un fichier Excel",
  download_template_excel_tooltip: "Télécharger le modèle de fichier Excel",
  download_template_excel: "Télécharger",
  import_courses_success: "Les courses ont été créées avec succès",
  import_courses_error:
    "Un problème est survenu lors de la création des courses",
  notFound: "non trouvé",
  ADRESSE: "Adresse",
  CP: "Code postal",
  VILLE: "Ville",
  CLIENT: "Client",
  DATE: "Date",
};
export default string_fr;
