import { takeLatest, put, call, select } from "redux-saga/effects";
import { withCallback } from "redux-saga-callback";
import { MerchantsService, ProduceService, TasksService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as MerchantsAction } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getNameZoneByAddressRequest } from "../../utils/zone-helper";
import { getTranslate } from "react-localize-redux";
import { push } from "connected-react-router";
import { UserActions } from "../user";
import { AuthActions } from "../auth";
import { get } from "lodash";
import {createOrUpdateDefaultPackage} from "../../utils/function-helper";
import moment from "moment-timezone"

function* getMerchantsRequest({ team_id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(MerchantsService.merchants, { team_id });
  if (result) {
    yield put(MerchantsAction.getMerchantsSuccess(result.merchants));
  } else if (error) {
    showError("get_merchants_error", strings, null, error);
    yield put(MerchantsAction.getMerchantsFailure());
  }
  yield put(LoaderActions.loaded());
}

function* getContractorRequest({ beneficiarie_id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(MerchantsService.getContractor, {
    beneficiarie_id,
  });
  if (result) {
    let tmp = result.merchants;
    for (let i = 0, l = tmp.length; i < l; i++) {
      if (
        tmp[i].users[0] &&
        tmp[i].users[0].address &&
        tmp[i].users[0].address.data
      ) {
        const res = yield getNameZoneByAddressRequest({
          value: tmp[i].users[0].address,
        });
        if (res) {
          tmp[i].zone = res.name;
        }
      }
    }
    yield put(MerchantsAction.getContractorSuccess(tmp));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_contractor_error", strings, null, error);
    yield put(MerchantsAction.getContractorFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getMerchantRequest({ id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(MerchantsService.merchant, { id });
  if (result) {
    let tmp = result.merchants[0];
    if (tmp?.users && 0 in tmp.users && tmp.users[0]?.address) {
      const res = yield getNameZoneByAddressRequest({
        value: tmp.users[0].address,
      });
      if (res) {
        tmp.zone = res.name;
      }
    }
    yield put(
      MerchantsAction.getMerchantSuccess({
        ...tmp,
        user: tmp.users[0],
        user_id: tmp.users[0].id,
      })
    );
  } else if (error) {
    showError("get_merchant_error", strings, null, error);
    yield put(MerchantsAction.getMerchantFailure());
  }
  yield put(LoaderActions.loaded());
}

function* checkEmailAvailableRequest({ email }) {
  // eslint-disable-next-line
  const [_, response = { merchants: [] }] = yield call(
    MerchantsService.getMerchantByEmail,
    email
  );
  yield put(
    MerchantsAction.checkEmailAvailableSuccess(
      response?.merchants?.length === 0
    )
  );
}

function* merchantsCreateRequest({ values, goToProfil, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  let mailsToSend = [];
  yield put(LoaderActions.loading());
  const [error, result] = yield call(MerchantsService.merchantCreate, values);
  console.log({result})
  console.log({error})
  if (result) {
    if (values.email && values.email.length > 0) {
      mailsToSend.push({
        type: "create-merchant-to-merchant-mail",
        toSend: {
          subject: "Nouveau compte commerçant",
          name: values.name,
          email: values.email,
          link: "https://shop.deki.pro/",
        },
      });
      if (process.env.REACT_APP_EMAIL_ADMIN) {
        mailsToSend.push({
          type: "create-merchant-to-admin-mail",
          toSend: {
            subject: "Nouveau compte commerçant",
            email_merchant: values.email,
            email: process.env.REACT_APP_EMAIL_ADMIN,
          },
        });
      }
      yield call(ProduceService.sendEmails, mailsToSend);
    }
    let tmp = result.insert_merchants_one;
    console.log({values})
    if (values?.defaultPackage?.length > 0) {
      const resPackage = yield createOrUpdateDefaultPackage(values, tmp, "merchant");
      if (!resPackage) return;
      tmp.default_packages = resPackage;
    }
    const address = get(tmp, ["users", 0, "address"], null)
    if (address) {
      const res = yield getNameZoneByAddressRequest({
        value: address,
      });
      if (res) {
        tmp.zone = res.name;
      }
    }
    const tmpResponse = {
      ...(0 in tmp.users ? tmp.users[0] : {}),
      phone: 0 in tmp.users ? tmp.users[0]?.phone_number ?? "" : "",
      ...tmp,
      key: tmp.id,
      code: `m-${tmp.id}`,
      user_id: 0 in tmp.users ? tmp.users[0]?.id ?? null : null,
    };
    if (callback) {
      callback(`m-${tmp.id}`, tmpResponse);
    }
    yield put(MerchantsAction.merchantsCreateSuccess(tmp));
    yield put(UserActions.addReceiverList({ ...tmp, code: `m-${tmp.id}` }));
    if (goToProfil) {
      yield put(push("/merchant/" + tmp.id));
    }
    showSuccess("merchant_create_success", strings);
    yield put(LoaderActions.loaded());
    return [false, tmp];
  } else if (error) {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("merchant_create_error", strings, null, error);
    }
    yield put(MerchantsAction.merchantsCreateFailure());
    yield put(LoaderActions.loaded());
    return [true, false];
  }
}

function* merchantQuickUpdateRequest({ values, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(
    MerchantsService.merchantQuickUpdate,
    values
  );

  if (result) {
    let tmp = result.update_merchants.returning[0];
    if (values?.defaultPackage?.length > 0) {
      const resPackage = yield createOrUpdateDefaultPackage(values, tmp, "merchant");
      if (!resPackage) return;
      tmp.default_packages = resPackage;
    }
    if (tmp.users[0] && tmp.users[0].address && tmp.users[0].address.data) {
      const res = yield getNameZoneByAddressRequest({
        value: tmp.users[0].address,
      });
      if (res) {
        tmp.zone = res.name;
      }
    }

    const tmpResponse = {
      ...(0 in tmp.users ? tmp.users[0] : {}),
      phone: 0 in tmp.users ? tmp.users[0]?.phone_number ?? "" : "",
      ...tmp,
      key: tmp.id,
      code: `m-${tmp.id}`,
      user_id: 0 in tmp.users ? tmp.users[0]?.id ?? null : null,
    };
    yield put(UserActions.updateSearchReceiver(tmpResponse));
    if (values.isProfil) {
      yield put(
        UserActions.getCurrentUserSuccess({
          user: result.update_users.returning[0],
        })
      );
    }
    yield put(MerchantsAction.merchantsUpdateSuccess(tmp));
    if (callback) {
      callback(`m-${tmp.id}`, tmpResponse);
    }
    showSuccess(
      values.isProfil ? "profil_update_success" : "merchants_update_success",
      strings
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("merchant_update_error", strings, null, error);
    }
    yield put(MerchantsAction.merchantsUpdateFailure());
    yield put(LoaderActions.loaded());
  }
}

function* updateMerchantsRequest({
  values,
  isNewMerchant,
  isCurrentMerchant,
  isCurrentUser = false,
  callback,
}) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(MerchantsService.merchantUpdate, values);
  if (result) {
    if (isNewMerchant) {
      let mailsToSend = [];
      if (values.email && values.email.length > 0) {
        mailsToSend.push({
          type: "create-merchant-to-merchant-mail",
          toSend: {
            subject: "Nouveau compte commerçant",
            name: values.name,
            email: values.email,
            link: "https://shop.deki.pro/",
          },
        });
        if (process.env.REACT_APP_EMAIL_ADMIN) {
          mailsToSend.push({
            type: "create-merchant-to-admin-mail",
            toSend: {
              subject: "Nouveau compte commerçant",
              email_merchant: values.email,
              email: process.env.REACT_APP_EMAIL_ADMIN,
            },
          });
        }
        yield call(ProduceService.sendEmails, mailsToSend);
      }
    }
    let tmp = result.update_merchants.returning[0];
    if (values?.defaultPackage?.length > 0) {
      const resPackage = yield createOrUpdateDefaultPackage(values, tmp, "merchant");
      if (!resPackage) return;
      tmp.default_packages = resPackage;
    }
    if (tmp.users[0] && tmp.users[0].address && tmp.users[0].address.data) {
      const res = yield getNameZoneByAddressRequest({
        value: tmp.users[0].address,
      });
      if (res) {
        tmp.zone = res.name;
      }
    }
    const user = get(tmp, ["users", 0], null)
    tmp = {
      ...user,
      phone: user ? user?.phone_number ?? "" : "",
      ...tmp,
      code: `m-${tmp.id}`,
      user_id: user ? user?.id ?? null : null,
      user
    }
    if (callback) {
      callback(`m-${tmp.id}`, tmp);
    }
    if (isCurrentUser) {
      yield put(UserActions.merchantsUpdateCurrentUserSuccess(tmp));
    } else if (isCurrentMerchant) {
      yield put(MerchantsAction.merchantsUpdateCurrentMerchantSuccess(tmp));
    } else {
      yield put(MerchantsAction.merchantsUpdateSuccess(tmp));
    }
    yield put(
      UserActions.updateSearchReceiver(tmp)
    );
    showSuccess("merchant_update_success", strings);
    yield put(LoaderActions.loaded());
  } else if (error) {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("merchant_update_error", strings, null, error);
    }
    yield put(MerchantsAction.merchantsUpdateFailure());
    yield put(LoaderActions.loaded());
  }
}

function* validateMerchantInfoRequest({ values, password }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  if (password && password.length > 0) {
    let data = {
      user: values,
      password: password,
    };
    yield put(AuthActions.updatePassword(data));
  }

  const [error, result] = yield call(
    MerchantsService.validateMerchantInfo,
    values
  );
  if (result) {
    showSuccess("validate_merchant_info_success", strings);
    yield put(
      UserActions.validateMerchantInfoSuccess(
        result.update_merchants.returning[0]
      )
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("validate_merchant_info_error", strings, null, error);
    }
    yield put(UserActions.validateMerchantInfoFailure());
    yield put(LoaderActions.loaded());
  }
}

function* importMerchantsRequest({ values }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  const [error, result] = yield call(MerchantsService.importMerchants, values);
  if (result) {
    showSuccess("import_merchants_success", strings);
    yield put(
      MerchantsAction.importMerchantsSuccess(result.insert_clients.returning)
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("import_merchants_error", strings, null, error);
    yield put(MerchantsAction.importClientsFailure());
    yield put(LoaderActions.loaded());
  }
}

function* deleteMerchantRequest({ data, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(MerchantsService.merchantDelete, data.key);
  if (result) {
    showSuccess("merchant_delete_success", strings);
    yield put(
      MerchantsAction.deleteMerchantSuccess(
        result.update_merchants.returning[0]
      )
    );
    if (callback) {
      callback();
    }
  } else if (error) {
    showError("merchant_delete_error", strings, null, error);
    yield put(MerchantsAction.deleteMerchantFailure());
  }
  yield put(LoaderActions.loaded());
}

function* deleteMerchantsRequest({ data, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(MerchantsService.merchantsDelete, data);

  if (result) {
    showSuccess("merchants_delete_success", strings);
    yield put(
      MerchantsAction.deleteMerchantsSuccess(result.update_merchants.returning)
    );
    if (callback) {
      callback();
    }
  } else if (error) {
    showError("merchants_delete_error", strings, null, error);
    yield put(MerchantsAction.deleteMerchantFailure());
  }
  yield put(LoaderActions.loaded());
}

function* createUserMerchantRequest({ values }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  let data = {
    id: values.id,
    name: values.name,
    phone_number: values.phone_number,
    address: values.address,
    email: values.email,
    renew_pwd: false,
    team_id: values.team_id,
    password: "d3k1P4553",
    role: "merchant_manager",
  };
  const [error, result] = yield call(MerchantsService.createUserMerchant, data);
  if (result) {
    let mailsToSend = [];
    if (values.email && values.email.length > 0) {
      mailsToSend.push({
        type: "create-merchant-to-merchant-mail",
        toSend: {
          subject: "Nouveau compte commerçant",
          name: values.name,
          email: values.email,
          link: "https://shop.deki.pro/",
        },
      });
      if (process.env.REACT_APP_EMAIL_ADMIN) {
        mailsToSend.push({
          type: "create-merchant-to-admin-mail",
          toSend: {
            subject: "Nouveau compte commerçant",
            email_merchant: values.email,
            email: process.env.REACT_APP_EMAIL_ADMIN,
          },
        });
      }
      yield call(ProduceService.sendEmails, mailsToSend);
    }
    showSuccess("merchant_create_user_success", strings);
    yield put(MerchantsAction.createUserMerchantSuccess(result.data));
    yield put(LoaderActions.loaded());
  } else if (error) {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("merchant_create_user_error", strings, null, error);
    }
    yield put(MerchantsAction.createUserMerchantFailure());
    yield put(LoaderActions.loaded());
  }
}

function* sendAdminMail({ values }) {
  const strings = yield select((state) => getTranslate(state.locale));
  yield put(LoaderActions.loading());
  const { current_user } = yield select((state) => state.user);
  let mailsToSend = [
    {
      type: "mail-contact-admin",
      toSend: {
        message: values.message,
        subject: values.object,
        name: current_user.name,
        email: process.env.REACT_APP_EMAIL_ADMIN,
        task: values.task,
      },
    },
  ];
  yield call(ProduceService.sendEmails, mailsToSend);
  let history = values.task.history;
  history = {
    ...history, signaled:[
      ...get(history, "signaled", []),
      moment().format()
    ]
  }
  const[error, result] = yield call(TasksService.modifyTask, {
    values:{
      id: values.task.id,
      history: history
    }
  })
  if (result) {
    showSuccess("send_admin_mail_success", strings);
  }else if (error) {
    showError("send_admin_mail_error", strings)
  }
  yield put(LoaderActions.loaded());
}
function* modifyUserMerchantRequest({ values }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  let data = {
    user_id: values.user_id,
    email: values.email,
  };
  const [error, result] = yield call(MerchantsService.updateUserMerchant, data);
  if (result) {
    let tmp = result.update_users.returning[0];
    if (tmp.address && tmp.address.value) {
      const res = yield getNameZoneByAddressRequest({ value: tmp.address });
      if (res) {
        tmp.zone = res.name;
      }
    }
    showSuccess("merchant_modify_user_success", strings);
    yield put(MerchantsAction.modifyUserMerchantSuccess(tmp, values));
    yield put(LoaderActions.loaded());
  } else if (error) {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("merchant_modify_user_error", strings, null, error);
    }
    yield put(MerchantsAction.modifyUserMerchantFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getMoreMerchantsRequest({ ids }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  if (ids.length === 0) {
    yield put(MerchantsAction.getMoreMerchantsSuccess([]));
    yield put(LoaderActions.loaded());
    return;
  }
  const [error, result] = yield call(MerchantsService.moreMerchants, ids);
  if (result) {
    let tmp = result.merchants;
    for (let i = 0, l = tmp.length; i < l; i++) {
      if (
        tmp[i].users[0] &&
        tmp[i].users[0].address &&
        tmp[i].users[0].address.data
      ) {
        const res = yield getNameZoneByAddressRequest({
          value: tmp[i].users[0].address,
        });
        if (res) {
          tmp[i].zone = res.name;
        }
      }
    }
    yield put(LoaderActions.loaded());
    yield put(MerchantsAction.getMoreMerchantsSuccess(tmp));
  } else if (error) {
    yield put(LoaderActions.loaded());
    showError("get_merchants_error", strings);
    yield put(MerchantsAction.getMerchantsFailure());
  }
}

function* searchMerchantsRequest({ value }) {
  const locale = yield select((state) => state.locale);
  const { team_id } = yield select((state) => state.auth);
  const strings = getTranslate(locale);
  if (!value || value.length === 0) {
    yield put(MerchantsAction.searchMerchantsSuccess([]));
    return;
  }
  const [error, result] = yield call(MerchantsService.searchMerchants, {
    value,
    team_id,
  });
  if (result) {
    yield put(MerchantsAction.searchMerchantsSuccess(result.view_users));
  } else {
    if (!error.loginError) {
      showError("search_user_error", strings);
    }
    yield put(MerchantsAction.searchMerchantsFailure());
  }
}

function* getMerchantsToInvoiceRequest({ team_id }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(
    MerchantsService.merchantsToInvoice,
    team_id
  );
  if (result) {
    yield put(MerchantsAction.getMerchantsToInvoiceSuccess(result.merchants));
  } else {
    if (!error.loginError) {
      showError("get_merchants_to_invoice_error", strings);
    }
    yield put(MerchantsAction.getMerchantsToInvoiceFailure());
  }
}

function* getStatisticsMerchantRequest({ id, date }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const tmp = {
    id: id,
    gte: date[0],
    lte: date[1],
  };
  const [error, result] = yield call(MerchantsService.statisticsMerchant, tmp);
  if (result) {
    yield put(MerchantsAction.getStatisticsMerchantSuccess(result.result));
  } else {
    if (!error.loginError) {
      showError("get_statistics_merchant_error", strings);
    }
    yield put(MerchantsAction.getStatisticsMerchantFailure());
  }
}

const sagas = [
  takeLatest(
    types.GET_STATISTICS_MERCHANT_REQUEST,
    getStatisticsMerchantRequest
  ),
  takeLatest(types.SEARCH_MERCHANTS_REQUEST, searchMerchantsRequest),
  takeLatest(
    types.GET_MERCHANTS_TO_INVOICE_REQUEST,
    getMerchantsToInvoiceRequest
  ),
  takeLatest(
    types.MERCHANTS_CREATE_REQUEST,
    withCallback(merchantsCreateRequest)
  ),
  takeLatest(types.MERCHANTS_UPDATE_REQUEST, updateMerchantsRequest),
  takeLatest(types.MERCHANT_DELETE_REQUEST, deleteMerchantRequest),
  takeLatest(types.MERCHANTS_DELETE_REQUEST, deleteMerchantsRequest),
  takeLatest(types.IMPORT_MERCHANTS_REQUEST, importMerchantsRequest),
  takeLatest(types.GET_MERCHANTS_REQUEST, getMerchantsRequest),
  takeLatest(types.GET_MERCHANT_REQUEST, getMerchantRequest),
  takeLatest(types.CREATE_USER_MERCHANT_REQUEST, createUserMerchantRequest),
  takeLatest(types.UPDATE_USER_MERCHANT_REQUEST, modifyUserMerchantRequest),
  takeLatest(types.VALIDATE_MERCHANT_INFO_REQUEST, validateMerchantInfoRequest),
  takeLatest(types.GET_CONTRACTOR_REQUEST, getContractorRequest),
  takeLatest(types.MERCHANT_QUICK_UPDATE_REQUEST, merchantQuickUpdateRequest),
  takeLatest(types.CHECK_EMAIL_AVAILABLE_REQUEST, checkEmailAvailableRequest),
  takeLatest(types.GET_MORE_MERCHANTS_REQUEST, getMoreMerchantsRequest),
  takeLatest(types.SEND_ADMIN_REQUEST, sendAdminMail),
];

export default sagas;
