import { gql } from "apollo-boost";
import {authenticatedMutation, authenticatedQuery} from "./middleware";

const RETURNING_TEAMS = `
  id
  created_at
  updated_at
  name
  email
  team_ID
  zipcode
`

/**
 * GRAPHQL QUERIES
 */
const queries = {
  teams: `teams {
    ${RETURNING_TEAMS}
  }`,
  team: (id) => `teams(where: {id: {_eq: ${id}}}) {
    ${RETURNING_TEAMS}
  }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  updateTeam: gql`
    mutation update_teams($id: Int!, $teams: teams_set_input!) {
      update_teams(where: { id: { _eq: $id } }, _set: $teams) {
        returning {
          ${RETURNING_TEAMS}
        }
      }
    }
  `,
};

const services = {
  getTeams: () => authenticatedQuery(queries.teams),
  getTeam: (id) => authenticatedQuery(queries.team(id)),
  updateTeam: ({ id, zipcode, email }) =>
    authenticatedMutation(mutations.updateTeam, { id, teams: { zipcode, email } }),
};
export default services;
