import { createAction } from "../../utils";

// Types
export const types = {
  GET_PRICING_REQUEST: "GET_PRICING_REQUEST",
  GET_PRICING_SUCCESS: "GET_PRICING_SUCCESS",
  GET_PRICING_FAILURE: "GET_PRICING_FAILURE",

  UPDATE_PRICING_REQUEST: "UPDATE_PRICING_REQUEST",
  UPDATE_PRICING_SUCCESS: "UPDATE_PRICING_SUCCESS",
  UPDATE_PRICING_FAILURE: "UPDATE_PRICING_FAILURE",

  CREATE_PRICING_REQUEST: "CREATE_PRICING_REQUEST",
  CREATE_PRICING_SUCCESS: "CREATE_PRICING_SUCCESS",
  CREATE_PRICING_FAILURE: "CREATE_PRICING_FAILURE",

  GET_ZONE_PRICING_REQUEST: "GET_ZONE_PRICING_REQUEST",
  GET_ZONE_PRICING_SUCCESS: "GET_ZONE_PRICING_SUCCESS",
  GET_ZONE_PRICING_FAILURE: "GET_ZONE_PRICING_FAILURE",

  GET_ZONE_PRICING_BY_TEAMID_REQUEST: "GET_ZONE_PRICING_BY_TEAMID_REQUEST",
  GET_ZONE_PRICING_BY_TEAMID_SUCCESS: "GET_ZONE_PRICING_BY_TEAMID_SUCCESS",
  GET_ZONE_PRICING_BY_TEAMID_FAILURE: "GET_ZONE_PRICING_BY_TEAMID_FAILURE",

  UPDATE_ZONE_PRICING_BY_TEAMID_REQUEST:
    "UPDATE_ZONE_PRICING_BY_TEAMID_REQUEST",
  UPDATE_ZONE_PRICING_BY_TEAMID_SUCCESS:
    "UPDATE_ZONE_PRICING_BY_TEAMID_SUCCESS",
  UPDATE_ZONE_PRICING_BY_TEAMID_FAILURE:
    "UPDATE_ZONE_PRICING_BY_TEAMID_FAILURE",

  RESET_ZONE_PRICING: "RESET_ZONE_PRICING",
};

// Actions
const actions = {
  getPricingRequest: (team_id) =>
    createAction(types.GET_PRICING_REQUEST, { team_id }),
  getPricingSuccess: (data) =>
    createAction(types.GET_PRICING_SUCCESS, { data }),
  getPricingFailure: () => createAction(types.GET_PRICING_FAILURE),
  updatePricingRequest: (newPricingValuesForm) =>
    createAction(types.UPDATE_PRICING_REQUEST, { newPricingValuesForm }),
  updatePricingSuccess: (data) =>
    createAction(types.UPDATE_PRICING_SUCCESS, { data }),
  updatePricingFailure: () => createAction(types.UPDATE_PRICING_FAILURE),
  createPricingRequest: (newPricingValuesForm) =>
    createAction(types.CREATE_PRICING_REQUEST, { newPricingValuesForm }),
  createPricingSuccess: (data) =>
    createAction(types.CREATE_PRICING_SUCCESS, { data }),
  createPricingFailure: () => createAction(types.CREATE_PRICING_FAILURE),

  getZonePricingRequest: (zone_id) =>
    createAction(types.GET_ZONE_PRICING_REQUEST, { zone_id }),
  getZonePricingSuccess: (data) =>
    createAction(types.GET_ZONE_PRICING_SUCCESS, { data }),
  getZonePricingFailure: () => createAction(types.GET_ZONE_PRICING_FAILURE),

  getZonePricingByTeamIdRequest: (teamId) =>
    createAction(types.GET_ZONE_PRICING_BY_TEAMID_REQUEST, { teamId }),
  getZonePricingByTeamIdSuccess: (data) =>
    createAction(types.GET_ZONE_PRICING_BY_TEAMID_SUCCESS, { data }),
  getZonePricingByTeamIdFailure: () =>
    createAction(types.GET_ZONE_PRICING_BY_TEAMID_FAILURE),
  resetZonePricing: () => createAction(types.RESET_ZONE_PRICING),

  updateZonePricingByTeamIdRequest: (newZonePricing, id, pricing) =>
    createAction(types.UPDATE_ZONE_PRICING_BY_TEAMID_REQUEST, {
      newZonePricing,
      id,
      pricing,
    }),
  updateZonePricingByTeamIdSuccess: (data) =>
    createAction(types.UPDATE_ZONE_PRICING_BY_TEAMID_SUCCESS, { data }),
  updateZonePricingByTeamIdFailure: () =>
    createAction(types.UPDATE_ZONE_PRICING_BY_TEAMID_FAILURE),
};

export default actions;
