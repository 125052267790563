import React, { memo } from "react";
import { Divider, Typography } from "antd";
import "./style.css"

const CustomTable = ({ showHeader = true, columns, packages, strings }) => {
  const { Text } = Typography
  const length = packages.length;

  return (
    <div>
      <div className="row">
        {showHeader &&
          columns.map((c, index) => { 
            return <div style={c.style} key={index}>{c.title}</div>; 
          })}
        {showHeader && <Divider className={"margin-divider"} type={"horizontal"} />}
      </div>
      <div className="scroll-div">
        {length > 0 && <br />}
        {packages?.map((p, index) => {
          return (
            <React.Fragment key={`data-${index}`}>
              <div className="space-between">
                {columns.map((c, i) => {
                  return (
                    <div style={c.style} key={`render-${index}-${i}`}>
                      {c.render(p[c.dataIndex], p, index)}
                    </div>
                  );
                })}
              </div>
              {length !== index - 1 && (
                <Divider className={"margin-10"} type={"horizontal"} />
              )}
            </React.Fragment>
          );
        })}
        {length === 0 && (
          <Text className={"text-no-package"}>{strings("no_package")}</Text>
        )}
      </div>
    </div>
  );
};

export default memo(CustomTable);
