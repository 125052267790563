export const resolveId = (router) => {
  return router.location.pathname.split("/").pop();
};

export const resolveObjectFromStore = (router, storage, key) => {
  const id = router.location.pathname.split("/").pop();
  const list = Array.isArray(storage) ? storage : Object.values(storage);
  const search = (p) => {
    return p[key] === id;
  };
  return list.find(search);
};

export const resolveParams = (router) => {
  const data = router.location.pathname.split("/").pop();
  let params = null;
  if (data.includes("-")) {
    params = data.split("-");
  } else {
    params = [data];
  }
  return params;
};
