import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  displayed: 0
};

// Reducers
const displayLoader = (state = initialState, action) => {
  return {...state, displayed: state.displayed + 1 };
};

const hideLoader = (state = initialState, action) => {
  const tmp = state.displayed - 1 < 0 ? 0 : state.displayed - 1
  return {...state, displayed: tmp};
};

export default createReducer(initialState, {
  [types.LOADING]: displayLoader,
  [types.LOADED]: hideLoader,
});
