import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
  hasMore: true
};

const getAllSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
      tmp[p.id] = p
  })
  return {...state, list: tmp, loading: false};
};

const setLoading = (state = initialState, action) => {
  const {loading} = action
  return {...state, loading};
};

const createSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp, loading: false};
};

const modifySuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp, loading: false};
};

const deleteSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  delete tmp[data.id]
  return {...state, list: tmp, loading: false};
};

const getFailed = (state = initialState) => {
  return {...state, loading: false};
};

const failed = (state = initialState) => {
  return {...state, loading: false};
};

export default createReducer(initialState, {
  [types.GET_ALL_FAILURE]: getFailed,
  [types.GET_ALL_SUCCESS]: getAllSuccess,
  [types.CREATE_FAILURE]: failed,
  [types.CREATE_SUCCESS]: createSuccess,
  [types.MODIFY_FAILURE]: failed,
  [types.MODIFY_SUCCESS]: modifySuccess,
  [types.DELETE_FAILURE]: failed,
  [types.DELETE_SUCCESS]: deleteSuccess,
  [types.SET_LOADING]: setLoading
});
