import { createAction } from "../../utils";

// Types
export const types = {
  RESET_MERCHANTS_REDUC: "RESET_MERCHANTS_REDUC",

  GET_MERCHANTS_REQUEST: "GET_MERCHANTS_REQUEST",
  GET_MERCHANTS_SUCCESS: "GET_MERCHANTS_SUCCESS",
  GET_MERCHANTS_FAILURE: "GET_MERCHANTS_FAILURE",

  GET_MERCHANT_REQUEST: "GET_MERCHANT_REQUEST",
  GET_MERCHANT_SUCCESS: "GET_MERCHANT_SUCCESS",
  GET_MERCHANT_FAILURE: "GET_MERCHANT_FAILURE",

  MERCHANTS_CREATE_REQUEST: "MERCHANTS_CREATE_REQUEST",
  MERCHANTS_CREATE_SUCCESS: "MERCHANTS_CREATE_SUCCESS",
  MERCHANTS_CREATE_FAILURE: "MERCHANTS_CREATE_FAILURE",

  IMPORT_MERCHANTS_REQUEST: "IMPORT_MERCHANTS_REQUEST",
  IMPORT_MERCHANTS_SUCCESS: "IMPORT_MERCHANTS_SUCCESS",
  IMPORT_MERCHANTS_FAILURE: "IMPORT_MERCHANTS_FAILURE",

  MERCHANTS_UPDATE_REQUEST: "MERCHANTS_UPDATE_REQUEST",
  MERCHANTS_UPDATE_SUCCESS: "MERCHANTS_UPDATE_SUCCESS",
  MERCHANTS_UPDATE_FAILURE: "MERCHANTS_UPDATE_FAILURE",

  MERCHANT_DELETE_REQUEST: "MERCHANT_DELETE_REQUEST",
  MERCHANT_DELETE_SUCCESS: "MERCHANT_DELETE_SUCCESS",
  MERCHANT_DELETE_FAILURE: "MERCHANT_DELETE_FAILURE",

  MERCHANTS_DELETE_REQUEST: "MERCHANTS_DELETE_REQUEST",
  MERCHANTS_DELETE_SUCCESS: "MERCHANTS_DELETE_SUCCESS",

  CREATE_USER_MERCHANT_REQUEST: "CREATE_USER_MERCHANT_REQUEST",
  CREATE_USER_MERCHANT_SUCCESS: "CREATE_USER_MERCHANT_SUCCESS",
  CREATE_USER_MERCHANT_FAILURE: "CREATE_USER_MERCHANT_FAILURE",

  UPDATE_USER_MERCHANT_REQUEST: "UPDATE_USER_MERCHANT_REQUEST",
  UPDATE_USER_MERCHANT_SUCCESS: "UPDATE_USER_MERCHANT_SUCCESS",
  UPDATE_USER_MERCHANT_FAILURE: "UPDATE_USER_MERCHANT_FAILURE",

  UPDATE_CURRENT_MERCHANT_SUCCESS: "UPDATE_CURRENT_MERCHANT_SUCCESS",

  VALIDATE_MERCHANT_INFO_REQUEST: "VALIDATE_MERCHANT_INFO_REQUEST",

  RESET_CURRENT_MERCHANT: "RESET_CURRENT_MERCHANT",

  SET_CURRENT_TAB_REDUC: "SET_CURRENT_TAB_REDUC",

  GET_CONTRACTOR_REQUEST: "GET_CONTRACTOR_REQUEST",
  GET_CONTRACTOR_SUCCESS: "GET_CONTRACTOR_SUCCESS",
  GET_CONTRACTOR_FAILURE: "GET_CONTRACTOR_FAILURE",

  MERCHANT_QUICK_UPDATE_REQUEST: "MERCHANT_QUICK_UPDATE_REQUEST",

  CHECK_EMAIL_AVAILABLE_REQUEST: "CHECK_EMAIL_AVAILABLE_REQUEST",
  CHECK_EMAIL_AVAILABLE_SUCCESS: "CHECK_EMAIL_AVAILABLE_SUCCESS",

  GET_MORE_MERCHANTS_REQUEST: "GET_MORE_MERCHANTS_REQUEST",
  GET_MORE_MERCHANTS_SUCCESS: "GET_MORE_MERCHANTS_SUCCESS",

  SEARCH_MERCHANTS_REQUEST: "SEARCH_MERCHANTS_REQUEST",
  SEARCH_MERCHANTS_SUCCESS: "SEARCH_MERCHANTS_SUCCESS",
  SEARCH_MERCHANTS_FAILURE: "SEARCH_MERCHANTS_FAILURE",

  GET_MERCHANTS_TO_INVOICE_REQUEST: "GET_MERCHANTS_TO_INVOICE_REQUEST",
  GET_MERCHANTS_TO_INVOICE_SUCCESS: "GET_MERCHANTS_TO_INVOICE_SUCCESS",
  GET_MERCHANTS_TO_INVOICE_FAILURE: "GET_MERCHANTS_TO_INVOICE_FAILURE",

  GET_STATISTICS_MERCHANT_REQUEST: "GET_STATISTICS_MERCHANT_REQUEST",
  GET_STATISTICS_MERCHANT_SUCCESS: "GET_STATISTICS_MERCHANT_SUCCESS",
  GET_STATISTICS_MERCHANT_FAILURE: "GET_STATISTICS_MERCHANT_FAILURE",
  UPDATE_DP_MERCHANT_REDUC: "UPDATE_DP_MERCHANT_REDUC",

  SEND_ADMIN_REQUEST: "MAIL_REQUEST_REQUEST",
};

// Actions
const actions = {
  resetCurrentMerchant: () => createAction(types.RESET_CURRENT_MERCHANT),

  setCurrentTabReduc: (tabKey) =>
      createAction(types.SET_CURRENT_TAB_REDUC, { tabKey }),

  resetRequestSuccessReduc: () => createAction(types.RESET_MERCHANTS_REDUC),

  getContractorRequest: (beneficiarie_id) =>
    createAction(types.GET_CONTRACTOR_REQUEST, { beneficiarie_id }),
  getContractorSuccess: (data) =>
    createAction(types.GET_CONTRACTOR_SUCCESS, { data }),
  getContractorFailure: () => createAction(types.GET_CONTRACTOR_FAILURE),

  getStatisticsMerchantRequest: ({ id, date }) =>
    createAction(types.GET_STATISTICS_MERCHANT_REQUEST, { id, date }),
  getStatisticsMerchantSuccess: (data) =>
    createAction(types.GET_STATISTICS_MERCHANT_SUCCESS, { data }),
  getStatisticsMerchantFailure: () =>
    createAction(types.GET_STATISTICS_MERCHANT_FAILURE),

  getMoreMerchantsRequest: (ids) =>
    createAction(types.GET_MORE_MERCHANTS_REQUEST, { ids }),
  getMoreMerchantsSuccess: (data) =>
    createAction(types.GET_MORE_MERCHANTS_SUCCESS, { data }),
  getMerchantsRequest: (team_id) =>
    createAction(types.GET_MERCHANTS_REQUEST, { team_id }),
  getMerchantsSuccess: (data) =>
    createAction(types.GET_MERCHANTS_SUCCESS, { data }),
  getMerchantsFailure: () => createAction(types.GET_MERCHANTS_FAILURE),

  getMerchantRequest: (id) => createAction(types.GET_MERCHANT_REQUEST, { id }),
  getMerchantSuccess: (data) =>
    createAction(types.GET_MERCHANT_SUCCESS, { data }),
  getMerchantFailure: () => createAction(types.GET_MERCHANT_FAILURE),

  getMerchantsToInvoiceRequest: (team_id) =>
    createAction(types.GET_MERCHANTS_TO_INVOICE_REQUEST, { team_id }),
  getMerchantsToInvoiceSuccess: (data) =>
    createAction(types.GET_MERCHANTS_TO_INVOICE_SUCCESS, { data }),
  getMerchantsToInvoiceFailure: () =>
    createAction(types.GET_MERCHANTS_TO_INVOICE_FAILURE),

  checkEmailAvailableRequest: (email) =>
    createAction(types.CHECK_EMAIL_AVAILABLE_REQUEST, { email }),
  checkEmailAvailableSuccess: (emailAvailable) =>
    createAction(types.CHECK_EMAIL_AVAILABLE_SUCCESS, { emailAvailable }),

  merchantsCreateRequest: (values, goToProfil = false, callback = null) =>
    createAction(types.MERCHANTS_CREATE_REQUEST, {
      values,
      goToProfil,
      callback,
    }),
  merchantsCreateSuccess: (data) =>
    createAction(types.MERCHANTS_CREATE_SUCCESS, { data }),
  merchantsCreateFailure: () => createAction(types.MERCHANTS_CREATE_FAILURE),

  merchantsUpdateRequest: (
    values,
    isNewMerchant,
    isCurrentMerchant = false,
    callback = null,
    isCurrentUser = false
  ) =>
    createAction(types.MERCHANTS_UPDATE_REQUEST, {
      values,
      isNewMerchant,
      isCurrentMerchant,
      callback,
      isCurrentUser
    }),
  merchantsUpdateSuccess: (data) =>
    createAction(types.MERCHANTS_UPDATE_SUCCESS, { data }),
  merchantsUpdateFailure: () => createAction(types.MERCHANTS_UPDATE_FAILURE),
  merchantQuickUpdateRequest: (values, callback = null) =>
    createAction(types.MERCHANT_QUICK_UPDATE_REQUEST, { values, callback }),

  validateMerchantInfoRequest: (values, password = null) =>
    createAction(types.VALIDATE_MERCHANT_INFO_REQUEST, { values, password }),

  merchantsUpdateCurrentMerchantSuccess: (data) =>
    createAction(types.UPDATE_CURRENT_MERCHANT_SUCCESS, { data }),

  importMerchantsRequest: (values) =>
    createAction(types.IMPORT_MERCHANTS_REQUEST, { values }),
  importMerchantsSuccess: (data) =>
    createAction(types.IMPORT_MERCHANTS_SUCCESS, { data }),
  importMerchantsFailure: () => createAction(types.IMPORT_MERCHANTS_FAILURE),

  deleteMerchantsRequest: (data, callback = null) =>
    createAction(types.MERCHANTS_DELETE_REQUEST, { data, callback }),
  deleteMerchantsSuccess: (data) =>
    createAction(types.MERCHANTS_DELETE_SUCCESS, { data }),
  deleteMerchantRequest: (data, callback = null) =>
    createAction(types.MERCHANT_DELETE_REQUEST, { data, callback }),
  deleteMerchantSuccess: (data) =>
    createAction(types.MERCHANT_DELETE_SUCCESS, { data }),
  deleteMerchantFailure: () => createAction(types.MERCHANT_DELETE_FAILURE),

  createUserMerchantRequest: (values) =>
    createAction(types.CREATE_USER_MERCHANT_REQUEST, { values }),
  createUserMerchantSuccess: (data) =>
    createAction(types.CREATE_USER_MERCHANT_SUCCESS, { data }),
  createUserMerchantFailure: () =>
    createAction(types.CREATE_USER_MERCHANT_FAILURE),

  modifyUserMerchantRequest: (values) =>
    createAction(types.UPDATE_USER_MERCHANT_REQUEST, { values }),
  modifyUserMerchantSuccess: (data, client) =>
    createAction(types.UPDATE_USER_MERCHANT_SUCCESS, { data, client }),
  modifyUserMerchantFailure: () =>
    createAction(types.UPDATE_USER_MERCHANT_FAILURE),

  searchMerchantsRequest: (value) =>
    createAction(types.SEARCH_MERCHANTS_REQUEST, { value }),
  searchMerchantsSuccess: (data) =>
    createAction(types.SEARCH_MERCHANTS_SUCCESS, { data }),
  searchMerchantsFailure: () => createAction(types.SEARCH_MERCHANTS_FAILURE),

  sendAdminMail: (values) => createAction(types.SEND_ADMIN_REQUEST, { values }),
};

export default actions;
