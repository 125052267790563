//https://api.mapbox.com/geocoding/v5/mapbox.places/2%20rue%20gambetta%204940.json?access_token= &cachebuster=1605534396686&autocomplete=true

import React, {useState, useEffect, useMemo} from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import {useSelector} from "react-redux";

const { Option } = Select;

const SEARCH_URL = `${process.env.REACT_APP_MAPBPX_SEARCH_URL}`;
const API_KEY = `${process.env.REACT_APP_MAPBOX_KEY}`;

const AddressInput = ({
  onChange,
  size,
  value,
  id,
  placeholder,
  onBlur,
  onSelect = () => {},
  style,
  className,
}) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(value || "");
  const { current_user } = useSelector(({user}) => user)

  const place = useMemo(() => {
    const data = current_user?.address?.data
    if (data && data.context) {
      const placeFind = data.context.find(({id}) => id.includes("place"))
      return placeFind ? placeFind.text : null
    }
    return null
  }, [current_user])

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  let lastFetchId = 0;
  let lastFeatures = {};

  const searchUser = (value, valueSet) => {
    lastFetchId += 1;
    const fetchId = lastFetchId;
    setData([]);
    setFetching(true);

    //this.setState({ data: [], fetching: true });
    const url = encodeURI(
      `${SEARCH_URL}/${value}.json?access_token=${API_KEY}&cachebuster=1605534396686&country=fr${place ? `&place=${place}` : ""}&autocomplete=true&language=fr`
    );
    fetch(url)
      .then((response) => response.json())
      .then((body) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }

        (body?.features || []).forEach((f) => {
          lastFeatures[f.id] = f;
        });
        const data = Object.values(lastFeatures).map((user) => ({
          text: `${user.place_name}`,
          value: user.place_name,
          data: user,
        }));
        setData(data);
        if (data && data[0] && data[0].data) {
          triggerChange({
            data: data[0].data,
            text: data[0].data.place_name,
            value: data[0].data.place_name,
          });
          if (valueSet) {
            setSelected({
              data: data[0].data,
              text: data[0].data.place_name,
              value: data[0].data.place_name,
            });
          }
        } else {
          //setSelected({text: value, value: value})
        }
        setFetching(false);
        //this.setState({ data, fetching: false });
      });
  };

  useEffect(() => {
    if (value && value.value && value.value !== selected.value && !fetching) {
      searchUser(value.value, true);
    }
      // eslint-disable-next-line
  }, [value]);

  const handleChange = (v) => {
    const foundedValue = data.find((d) => d.value === v.key);
    triggerChange(foundedValue);
    setSelected(v);
    setFetching(false);
  };

  const fetchUser = debounce(searchUser, 800);

  const onFocus = (e) => {
    if (selected && selected.value) {
      searchUser(selected.value)
    }
  }

  return (
    <Select
      id={id}
      onFocus={onFocus}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      onBlur={onBlur}
      size={size || "default"}
      showSearch
      defaultActiveFirstOption={false}
      labelInValue
      className={className}
      value={selected || { value: "", label: "" }}
      placeholder={placeholder || "Selectionner une adresse"}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={fetchUser}
      onSelect={(v) => onSelect(data.find((d) => d.value === v.key))}
      onChange={handleChange}
      style={{ width: "100%", ...style }}
    >
      {data.map((d) => (
        <Option selected key={d.value}>
          {d.text}
        </Option>
      ))}
    </Select>
  );
};

export default AddressInput;
