import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  current_team: null,
  loading: false,
  hasMore: true
};

const getTeamsSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
      tmp[p.id] = p
  })
  return {...state, list: tmp, loading: false};
};

const getTeamSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, current_team: data, loading: false};
};

const getFailed = (state = initialState) => {
  return {...state};
};

const updateTeamSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, current_team: data, loading: false};
};

export default createReducer(initialState, {
  [types.GET_TEAMS_FAILURE]: getFailed,
  [types.GET_TEAMS_SUCCESS]: getTeamsSuccess,
  [types.GET_TEAM_FAILURE]: getFailed,
  [types.GET_TEAM_SUCCESS]: getTeamSuccess,
  [types.UPDATE_TEAM_FAILURE]: getFailed,
  [types.UPDATE_TEAM_SUCCESS]: updateTeamSuccess,
});
