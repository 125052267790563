import { createAction } from "../../utils";

// Types
export const types = {
  GET_TEAMS_REQUEST: "GET_TEAMS_REQUEST",
  GET_TEAMS_SUCCESS: "GET_TEAMS_SUCCESS",
  GET_TEAMS_FAILURE: "GET_TEAMS_FAILURE",

  GET_TEAM_REQUEST: "GET_TEAM_REQUEST",
  GET_TEAM_SUCCESS: "GET_TEAM_SUCCESS",
  GET_TEAM_FAILURE: "GET_TEAM_FAILURE",

  UPDATE_TEAM_REQUEST: "UPDATE_TEAM_REQUEST",
  UPDATE_TEAM_SUCCESS: "UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_FAILURE: "UPDATE_TEAM_FAILURE",
};

// Actions
const actions = {
  getTeamsRequest: () => createAction(types.GET_TEAMS_REQUEST),
  getTeamsSuccess: (data) => createAction(types.GET_TEAMS_SUCCESS, { data }),
  getTeamsFailure: () => createAction(types.GET_TEAMS_FAILURE),

  getTeamRequest: (id) => createAction(types.GET_TEAM_REQUEST, { id }),
  getTeamSuccess: (data) => createAction(types.GET_TEAM_SUCCESS, { data }),
  getTeamFailure: () => createAction(types.GET_TEAM_FAILURE),

  updateTeamRequest: (values) =>
    createAction(types.UPDATE_TEAM_REQUEST, values),
  updateTeamSuccess: (data) =>
    createAction(types.UPDATE_TEAM_SUCCESS, { data }),
  updateTeamFailure: () => createAction(types.UPDATE_TEAM_FAILURE),
};

export default actions;
