import { takeLatest, put, call, select } from "redux-saga/effects";
import { PackageService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as PackageActions } from "./actions";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import _, { pick } from "lodash"
import {RecipientsActions} from "../recipients";

function* getPackageTypesRequest() {
  //yield put(LoaderActions.loading())
  const locale = yield select((state) => state.locale);
  const { current_user } = yield select((state) => state.user);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.packageTypesByMerchantId, current_user.merchant_id);
  if (result) {
    yield put(PackageActions.getPackageTypesSuccess(result.package_types));
    //yield put(LoaderActions.loaded())
  } else if (error) {
    showError("get_package_type_error", strings, null, error);
    yield put(PackageActions.getPackageTypesFailure());
    //yield put(LoaderActions.loaded())
  }
}

function* getPackageTypeByMerchantRequest({merchant_id}) {
  yield put(LoaderActions.loading())
  const locale = yield select((state) => state.locale);
  const {team_id} = yield select((state) => state.user.current_user);
  const strings = getTranslate(locale);
  let where = {_or: [{icon: {_eq: "other"}}]}
  if (merchant_id) {
    where = {...where, _or: [...where._or, {merchant_id: { _eq: merchant_id } }]};
  }
  if (team_id) {
    where = {...where, _or: [...where._or, {team_id: { _eq: team_id } }]};
  }
  const [error, result] = yield call(PackageService.packageTypesByMerchantId, where);
  if (result) {
    yield put(PackageActions.getPackageTypesSuccess(result.package_types));
  } else if (error) {
    showError("get_package_type_error", strings, null, error);
    yield put(PackageActions.getPackageTypesFailure());
  }
  yield put(LoaderActions.loaded())
}

function* getPackageTypeByRecipientRequest({recipient_id}) {
  yield put(LoaderActions.loading())
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.packageTypesByRecipientId, recipient_id);
  if (result) {
    yield put(PackageActions.getPackageTypesSuccess(result.package_types));
  } else if (error) {
    showError("get_package_type_error", strings, null, error);
    yield put(PackageActions.getPackageTypesFailure());
  }
  yield put(LoaderActions.loaded())
}

function* getPackageTypeByTeamRequest({team_id}) {
  yield put(LoaderActions.loading())
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const where = { _or: [{icon: {_eq: "other"}}]}
  if (team_id) {
    where._or.push({team_id: { _eq: team_id } })
  }
  const [error, result] = yield call(PackageService.packageTypesByTeamId, where);
  if (result) {
    yield put(PackageActions.getPackageTypesSuccess(result.package_types));
  } else if (error) {
    showError("get_package_type_error", strings, null, error);
    yield put(PackageActions.getPackageTypesFailure());
  }
  yield put(LoaderActions.loaded())
}

function* getPackageKindRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.getPackageKinds);
  if (result) {
    yield put(PackageActions.getPackageKindSuccess(result.package_kinds));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_package_kind_error", strings, null, error);
    yield put(PackageActions.getPackageKindFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getPackageTypesTeamRequest({team_id}) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.packageTypesTeam, {team_id});
  if (result) {
    yield put(
      PackageActions.getPackageTypesTeamSuccess(result.package_types)
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_package_type_team_error", strings, null, error);
    yield put(PackageActions.getPackageTypesTeamFailure());
    yield put(LoaderActions.loaded());
  }
}

function* createPackageTypeRequest({values, callback}) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const filter = {
    ...pick(values, ["merchant_id", "team_id", "icon_fa", "name", "weight", "volumn"])
  }
  const [error, result] = yield call(PackageService.createPackageType, filter);
  if (result) {
    if (callback) {callback()}
    yield put(
        PackageActions.createPackageTypeSuccess(result.insert_package_types_one)
    );
    showSuccess("create_package_type_success", strings);
  } else if (error) {
    yield put(PackageActions.createPackageTypeFailure());
    showError("create_package_type_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

function* updatePackageTypeRequest({values, callback}) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const filter = {
    ...pick(values, ["merchant_id", "team_id", "icon_fa", "name", "weight", "volumn"])
  }
  const [error, result] = yield call(PackageService.updatePackageType, {values: filter, id: values.id});
  if (result) {
    if (callback) {callback()}
    yield put(
        PackageActions.updatePackageTypeSuccess(result.update_package_types_by_pk)
    );
    showSuccess("update_package_type_success", strings);
  } else if (error) {
    yield put(PackageActions.updatePackageTypeFailure());
    showError("update_package_type_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

function* deletePackageTypeRequest({ids, callback}) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.deletePackageType, ids);
  if (result) {
    if (callback) {callback()}
    yield put(
        PackageActions.deletePackageTypeSuccess(result.update_package_types.returning[0])
    );
    showSuccess("delete_package_type_success", strings);
  } else if (error) {
    yield put(PackageActions.deletePackageTypeFailure());
    showError("delete_package_type_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

function* createDefaultPackageRequest({values, callback}) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const tmpKindByID = {}
  const [errorKinds, responseKinds] = yield call(PackageService.getPackageKinds);
  if (errorKinds) {
    yield put(LoaderActions.loaded());
    yield put(PackageActions.deletePackageTypeFailure());
    showError("create_default_package_merchant_error", strings, null, errorKinds);
    return
  }
  responseKinds.package_kinds.forEach((v) => {
    tmpKindByID[v.kind] = v.id;
  });
  let kind_id = null;
  if (values?.food) {
    kind_id = tmpKindByID[values.food];
  }
  const [error, result] = yield call(PackageService.createDefaultPackage, {
    defaultPackage: {
      recipient_id: values.recipient_id,
      merchant_id: values.merchant_id,
      package: {
        data: {
          ..._.pick(values, [
            "weight",
            "number",
            "fragile",
            "kind_id",
            "package_type_id",
            "declared_value",
          ]),
          delivery_status_id: 1,
          is_default: true,
          kind_id,
        },
      },
  }});
  if (result) {
    const tmp = result.insert_default_package_one
    if (callback) {callback(tmp)}
    yield put(
        RecipientsActions.updateDefaultPackageRecipientSuccess(tmp)
    );
    showSuccess("create_default_package_merchant_success", strings);
  } else if (error) {
    yield put(PackageActions.createDefaultPackageFailure());
    showError("create_default_package_merchant_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

function* deleteDefaultPackageRequest({values, callback}) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  let where = { package: {id: {_eq: values.packageId} } }
  if (values.recipientId) {
    where = {...where, recipient_id: {_eq: values.recipientId}};
  }
  if (values.merchantId) {
    where = {...where, merchant_id: {_eq: values.merchantId}};
  }
  const [error, result] = yield call(PackageService.deleteDefaultPackage, {where, packageId: values.packageId});
  if (result) {
    const tmp = result.delete_default_package.returning[0]
    if (callback) {callback(tmp)}
    yield put(
        RecipientsActions.deleteDefaultPackageRecipientSuccess(tmp)
    );
    showSuccess("delete_default_package_merchant_success", strings);
  } else if (error) {
    yield put(PackageActions.deleteDefaultPackageFailure());
    showError("delete_default_package_merchant_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

const sagas = [
  takeLatest(types.CREATE_DP_REQUEST, createDefaultPackageRequest),
  takeLatest(types.DELETE_DP_REQUEST, deleteDefaultPackageRequest),
  takeLatest(types.CREATE_PACKAGE_TYPE_REQUEST, createPackageTypeRequest),
  takeLatest(types.UPDATE_PACKAGE_TYPE_REQUEST, updatePackageTypeRequest),
  takeLatest(types.DELETE_PACKAGE_TYPE_REQUEST, deletePackageTypeRequest),
  takeLatest(types.GET_PACKAGE_TYPE_BY_TEAM_REQUEST, getPackageTypeByTeamRequest),
  takeLatest(types.GET_PACKAGE_TYPE_BY_MERCHANT_REQUEST, getPackageTypeByMerchantRequest),
  takeLatest(types.GET_PACKAGE_TYPE_BY_RECIPIENT_REQUEST, getPackageTypeByRecipientRequest),
  takeLatest(types.GET_PACKAGE_KIND_REQUEST, getPackageKindRequest),
  takeLatest(types.GET_PACKAGE_TYPES_REQUEST, getPackageTypesRequest),
  takeLatest(types.GET_PACKAGE_TYPES_TEAM_REQUEST, getPackageTypesTeamRequest),
];
export default sagas;
