import { createSelector } from 'reselect'
import moment from "moment-timezone";
import {isEmpty} from "lodash";

export const selectInvoicesSelected = (ids) => createSelector(
    (state) => ({invoices: state.invoices.list, imageName: state.user.imageName}),
    ({invoices, imageName}) => {
        const tmp = []
        if (ids && Object.values(invoices).length > 0) {
            const idsArray = ids.split("-");
            idsArray.forEach((v) => {
                let tmpObject = {
                    ...invoices[v],
                    moment: moment,
                    imageName: imageName,
                    payment_date_deadline: moment().add(
                        invoices[v].payment_deadline,
                        "days"
                    ),
                };
                let count = 0;
                let tva = 0;
                invoices[v].invoice_courses.forEach((v) => {
                    count = count + parseInt(v.course.price);
                    tva = tva + (parseInt(v.course.price) * 20) / 100;
                });
                tmpObject = {
                    ...tmpObject,
                    priceTTC: (count + tva - (count * tmpObject.discount) / 100).toFixed(
                        2
                    ),
                };
                tmp.push(tmpObject);
            });
        }
        return tmp
    }
)


export const selectCurrentInvoice = createSelector(
  (state) => ({
    current: state.invoices.current,
    imageName: state.user.imageName,
  }),
  ({ current, imageName }) => {
    if (!current || isEmpty(current)) return current
    let tmp = {
      ...current,
      moment: moment,
      imageName: imageName,
      //payment_date_deadline: moment().add(current.payment_deadline, "days"),
    };
    if (current.sent_date) {
        tmp = {...tmp, payment_date_deadline: moment(current.sent_date).add(current.payment_deadline, "days")}
    } else {
        tmp = {...tmp, payment_date_deadline: moment().startOf("day").add(current.payment_deadline, "days")}
    }
    let count = 0;
    let tva = 0;
    current.invoice_courses.forEach((v) => {
      count = count + parseInt(v.course.price);
      tva = tva + (parseInt(v.course.price) * 20) / 100;
    });
    tmp = {
      ...tmp,
      priceTTC: (count + tva - (count * tmp.discount) / 100).toFixed(2),
    };
    return tmp;
  }
);
