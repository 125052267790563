import {authenticatedMutation, authenticatedQuery} from "./middleware";

/**
 * GRAPHQL QUERIES
 */
const queries = {
  crmShop: (user_id) => `crm_shop(where: {user_id: {_eq: ${user_id}}}) {
    id
    created_at
    updated_at
    company
    user_id
    email
    name
    phone_number
    address
    }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  createCrmShop: (crmShop) => `mutation {
    insert_crm_shop(objects: {
      company: ${crmShop.company ? `"${crmShop.company}"` : `null`},
      name: ${crmShop.name ? `"${crmShop.name}"` : `null`},
      email: ${crmShop.email ? `"${crmShop.email}"` : `null`},
      address: ${
        crmShop.address
          ? `${JSON.stringify(crmShop.address).replace(/"(\w+)"\s*:/g, "$1:")}`
          : `null`
      },
      phone_number: ${
        crmShop.phone_number ? `"${crmShop.phone_number}"` : `null`
      },
      user_id: ${crmShop.user_id ? `${crmShop.user_id}` : `null`},
    })
    {
      returning {
        id
        user_id
        name
        email
        address
        phone_number
        company
      }
    }
  }`,
  updateCrmShop: (crmShop) => `mutation {
    update_crm_shop(where: {id: {_eq: ${crmShop.id}}},
    _set: {
      company: ${crmShop.company ? `"${crmShop.company}"` : `null`},
      name: ${crmShop.name ? `"${crmShop.name}"` : `null`},
      email: ${crmShop.email ? `"${crmShop.email}"` : `null`},
      phone_number: ${
        crmShop.phone_number ? `"${crmShop.phone_number}"` : `null`
      },
      address: ${
        crmShop.address
          ? `${JSON.stringify(crmShop.address).replace(/"(\w+)"\s*:/g, "$1:")}`
          : `null`
      },
      user_id: ${crmShop.user_id ? `${crmShop.user_id}` : `null`},
    })
      {
        returning {
          id
          company
          name
          email
          phone_number
          address
          user_id
        }
      }
    }`,

  deleteCrmShop: (crmShop) => `mutation {
    delete_crm_shop(where: {id: {_eq: ${crmShop.id}}})
    {
      returning {
        id
      }
    }
  }`,
};

const services = {
  crmShop: ({ user_id }) => authenticatedQuery(queries.crmShop(user_id)),
  createCrmShop: ({ values }) => {
    return authenticatedMutation(mutations.createCrmShop(values));
  },
  updateCrmShop: ({ values }) => {
    return authenticatedMutation(mutations.updateCrmShop(values));
  },
  deleteCrmShop: ({ values }) => {
    return authenticatedMutation(mutations.deleteCrmShop(values));
  },
};
export default services;
