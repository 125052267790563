const plageHours = {
  1: {
    text: "Entre 6h et 9h",
    value: 1,
    data: [6, 9],
  },
  2: {
    text: "Entre 9h et 12h",
    value: 2,
    data: [9, 12],
  },
  3: {
    text: "Entre 6h et 12h",
    value: 3,
    data: [6, 12],
  },
  4: {
    text: "Entre 14h et 18h",
    value: 4,
    data: [14, 18],
  },
  5: {
    text: "Entre 18h et 21h",
    value: 5,
    data: [18, 21],
  },
  6: {
    text: "Entre 14h et 21h",
    value: 6,
    data: [14, 21],
  },
  7: {
    text: "Entre 9h et 18h",
    value: 7,
    data: [9, 18],
  },
  8: {
    text: "Entre 9h et 11h",
    value: 8,
    data: [9, 11],
  },
  9: {
    text: "Entre 10h et 12h",
    value: 9,
    data: [10, 12],
  },
  10: {
    text: "Entre 11h et 13h",
    value: 10,
    data: [11, 13],
  },
  11: {
    text: "Entre 12h et 14h",
    value: 11,
    data: [12, 14],
  },
  12: {
    text: "Entre 13h et 15h",
    value: 12,
    data: [13, 15],
  },
  13: {
    text: "Entre 14h et 16h",
    value: 12,
    data: [14, 16],
  },
  14: {
    text: "Entre 15h et 17h",
    value: 12,
    data: [15, 17],
  },
  15: {
    text: "Entre 16h et 18h",
    value: 12,
    data: [16, 18],
  },
  16: {
    text: "Entre 17h et 19h",
    value: 12,
    data: [17, 19],
  },
  17: {
    text: "Entre 18h et 20h",
    value: 12,
    data: [18, 20],
  },
};

export default plageHours;
