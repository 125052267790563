import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  isInZone: true,
  zoneName: null
};

const getAreasSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
    if (tmp[p.team_id] && tmp[p.team_id].length > 0) {
      tmp[p.team_id].push(p)
    } else {
      tmp[p.team_id] = [p]
    }
  })
  return {...state, list: tmp};
};

const checkContainAreasSuccess = (state = initialState, action) => {
  const {data, zone} = action
  return {...state, isInZone: data, zoneName: zone};
};

const getFailed = (state = initialState) => {
  return {...state};
};

export default createReducer(initialState, {
  [types.GET_AREAS_FAILURE]: getFailed,
  [types.GET_AREAS_SUCCESS]: getAreasSuccess,
  [types.CHECK_AREAS_FAILURE]: getFailed,
  [types.CHECK_AREAS_SUCCESS]: checkContainAreasSuccess,
});
