import { createSelector } from 'reselect'

export const selectReceiver = createSelector(
    (state) => state.user && state.user.receiverList ? Object.values(state.user.receiverList) : [],
    (receiver) => {
        return receiver.map((v) => ({key: v.id, ...v, phone: v.phone_number}))
    }
)

export const selectUser = createSelector(
    (state) => state.user,
    (user) => {
        return user
    }
)