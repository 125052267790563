import { createReducer } from 'reduxsauce';
import { types } from './actions';
import { get, omit} from "lodash";

const initialState = {
  current_user: {},
  receiverList: [],
  users: {},
  imageName: null,
  requestSuccess: 0,
  token: null
};

const getFailed = (state = initialState) => {
  return {...state};
};

const getCurrentUserSuccess = (state = initialState, action) => {
  const {user} = action.user;
  let tmp = user || {}
  const dispatcher = get(user, [ "dispatchers",0], null)
  const merchant = get(user, ["merchant"], null)
  const picture_name = get(user, ["picture_name"], null)
  if (dispatcher) {
    tmp = {...tmp, dispatcher_id: dispatcher.id, ...omit(dispatcher, ["id"])}
  }
  if (merchant) {
    tmp = {...tmp, merchant_id: merchant.id, ...omit(merchant, ["id"])}
  }
  return {...state, current_user: tmp, imageName: picture_name ? process.env.REACT_APP_FILE_API_URL + '/pictures/' + picture_name : null};
};

const merchantsUpdateCurrentUserSuccess = (state = initialState, action) => {
  const {merchant} = action;
  const {current_user} = state;
  let tmp = current_user || {}
  if (merchant) {
    tmp = {...tmp, merchant_id: merchant.id, merchant: merchant, ...omit(merchant, ["id"])}
  }
  return {...state, current_user: tmp};
};

const validateMerchantInfoSuccess = (state = initialState, action) => {
  const {data} = action;
  let tmp = {};
  if (data.users && 0 in data.users) {
    tmp = {
      id: data.users[0]?.id,
      role: data.users[0]?.role,
      created_at: data.users[0]?.created_at,
      updated_at: data.users[0]?.updated_at,
      name: data.users[0]?.name,
      phone_number: data.users[0]?.phone_number,
      team_id: data.users[0]?.team_id,
      address: data.users[0]?.address,
      email: data.users[0]?.email,
      new_user: data.users[0]?.new_user,
      merchant_id: data.id,
      company: data.company,
      bank_ID: data.bank_ID,
      notes: data.notes,
    }
  }
  return {...state, current_user: tmp};
};

const getUsersSuccess = (state = initialState, action) => {
  const {data} = action;
  let tmp = []
  data.forEach((v) => {
    tmp[v.id] = v
  })
  return {...state, users: tmp};
};

const createImageSuccess = (state = initialState, action) => {
  const {data} = action;
  return {...state, imageName: data};
};

const getAccessTokenSuccess = (state = initialState, action) => {
  const {data} = action;
  return {...state, token: data};
};

const updateUserSuccess = (state = initialState, action) => {
  const {data, merchant, dispatcher} = action;
  const tmp = {
    id: data.id,
    role: data.role,
    created_at: data.created_at,
    updated_at: data.updated_at,
    name: data.name,
    phone_number: data.phone_number,
    team_id: data.team_id,
    address: data.address,
    email: data.email,
    new_user: data.new_user,
  }
  if (data.merchant && data.merchant.id) {
    tmp.merchant_id = data.merchant.id
    tmp.company = data.merchant.company
    tmp.bank_ID = data.merchant.bank_ID
  }
  if (merchant) {
    tmp.merchant_id = merchant.id
    tmp.bank_ID = merchant.bank_ID
    tmp.company = merchant.company
    tmp.notes = merchant.notes;
    tmp.package_types_id = merchant.package_types_id
    tmp.fragile = merchant.fragile
    tmp.food = merchant.food
  }
  if (dispatcher) {
    tmp.dispatcher_id = dispatcher.id
    tmp.bank_ID = dispatcher.bank_ID
    tmp.matriculation = dispatcher.matriculation
  }
  return {...state, current_user: tmp};
};

const searchReceiverSuccess = (state = initialState, action) => {
  const {data} = action;
  let tmp = []
  data.forEach((v) => {
    tmp[v.code] = v
  })
  return {...state, receiverList: tmp};
};

const updateSearchReceiver = (state = initialState, action) => {
  const {data} = action;
  let tmp = state.receiverList
  if (tmp[data.code]) {
    tmp[data.code] = data
  }
  return {...state, receiverList: tmp};
};

const addReceiverList = (state = initialState, action) => {
  const {data} = action;
  let tmp = state.receiverList
  if (data.code) {
    tmp[data.code] = data
  }
  return {...state, receiverList: tmp};
};

// endregion

export default createReducer(initialState, {
  [types.GET_CURRENT_USER_FAILURE]: getFailed,
  [types.GET_USERS_FAILURE]: getFailed,
  [types.GET_CURRENT_USER_SUCCESS]: getCurrentUserSuccess,
  [types.GET_USERS_SUCCESS]: getUsersSuccess,
  [types.UPDATE_USER_FAILURE]: getFailed,
  [types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [types.VALIDATE_MERCHANT_INFO_FAILURE]: getFailed,
  [types.VALIDATE_MERCHANT_INFO_SUCCESS]: validateMerchantInfoSuccess,
  [types.CREATE_IMAGE_FAILURE]: getFailed,
  [types.CREATE_IMAGE_SUCCESS]: createImageSuccess,
  [types.GET_ACCESS_TOKEN_FAILURE]: getFailed,
  [types.GET_ACCESS_TOKEN_SUCCESS]: getAccessTokenSuccess,
  [types.SEARCH_RECEIVER_FAILURE]: getFailed,
  [types.SEARCH_RECEIVER_SUCCESS]: searchReceiverSuccess,
  [types.UPDATE_SEARCH_RECEIVER]: updateSearchReceiver,
  [types.ADD_RECEIVER_LIST]: addReceiverList,
  [types.UPDATE_CURRENT_USER_MERCHANT_SUCCESS]: merchantsUpdateCurrentUserSuccess,
});
