import "antd/dist/antd.less";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { Loader } from "../../components/loaders/global/loader";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../../redux/auth";

export default function Authorize() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location?.search?.length > 0) {
      const fetchData = async () => {
        const dataFromUrl = qs.parse(location?.search ?? "");
        fetch(
          process.env.REACT_APP_BASE_API_URL + "/users/authorize/mallcomm",
          {
            method: "POST",
            mode: "cors",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              centreId: dataFromUrl["Centre ID"],
              storeId: dataFromUrl["Store ID"],
              storeName: dataFromUrl["Store Name"],
              roleId: dataFromUrl["Role ID"],
              profileId: dataFromUrl["Profile ID"],
              firstName: dataFromUrl["First Name"],
              lastName: dataFromUrl["Last Name"],
            }),
          }
        )
          .then((response) => {
            response
              .json()
              .then((res) => {
                if (res?.message) {
                  dispatch(AuthActions.autoLogin(res?.message));
                }
              })
              .catch((err) => {
                console.log(err, err?.message);
              });
          })
          .catch((err) => console.log(err, err?.message));
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [location]);

  return <Loader displayed />;
}
