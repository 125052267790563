import { createAction } from "../../utils/index";

// Types
export const types = {
  RESET_REDUCERS: "RESET_REDUCERS",
  UPDATE_GLOBAL_STYLE: "UPDATE_GLOBAL_STYLE",

  ADD_SUBSCRIBE: "ADD_SUBSCRIBE",
  DELETE_SUBSCRIBE: "DELETE_SUBSCRIBE",

  UPDATE_ACTIVE_COURSE: "UPDATE_ACTIVE_COURSE"
};

// Actions
const actions = {
  resetReducers: () => createAction(types.RESET_REDUCERS),
  updateGlobalStyle: (data) =>
    createAction(types.UPDATE_GLOBAL_STYLE, { data }),

  addSubscribe: (data) => createAction(types.ADD_SUBSCRIBE, { data }),
  deleteSubscribe: (data) => createAction(types.DELETE_SUBSCRIBE, { data }),

  updateActiveCourse: (activeCourse) =>createAction(types.UPDATE_ACTIVE_COURSE,{activeCourse})
};

export default actions;
