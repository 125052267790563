import {methods} from "../utils";
import {authenticatedMutation, authenticatedQuery, service} from "./middleware";
import { gql } from "apollo-boost";
import moment from "moment-timezone";
import _ from "lodash";

const { POST } = methods;

const RETURNING_MERCHANT = `id
    created_at
    updated_at
    company
    fragile
    food
    package_types_id
    payment_deadline
    discount
    taxe
    bank_ID
    matriculation
    type
    notes
    price_bon
    package_types {
      id
      icon_fa
      icon
      desc
      name
      volumn
      weight
      team_id
      merchant_id
    }
    package_type_receivers {
      package_type {
        id
        icon_fa
        icon
        desc
        name
        volumn
        weight
      }
      receiver_recipient_id
      package_type_id
      receiver_merchant_id
    }
    default_packages {
      package {
        id
        fragile
        is_default
        kind_id
        declared_value
        package_type_id
        volumn
        weight
        number
      }
    }
    users(where: {role: {_eq: "merchant_manager"}}) {
      id
      name
      email
      phone_number
      address
      team_id
      new_user
      created_at
      updated_at
      role
    }
`;

/**
 * GRAPHQL QUERIES
 */
const queries = {
  statisticsMerchant: gql`
    query courses_stats($id: Int!, $gte: timestamptz!, $lte: timestamptz!) {
      result: courses_aggregate(
        where: {
          merchant_id: { _eq: $id }
          _and: [
            { tasks: { start_date: { _gte: $gte } } }
            { tasks: { start_date: { _lte: $lte } } }
          ]
        }
      ) {
        aggregate {
          count
          sum {
            nb_bon
          }
        }
      }
    }
  `,
  searchMerchants: (
    value,
    team_id
  ) => `view_users(where: {_or: [{address_value: {_ilike: "%${value}%"}}, {email: {_ilike: "%${value}%"}}, {name: {_ilike: "%${value}%"}}, {phone_number: {_ilike: "%${value}%"}}, {company: {_ilike: "%${value}%"}}], _and: {team_id: {_eq: ${team_id}}}}) {
      id
      created_at
      updated_at
      merchant_id
      name
      address
      phone_number
      company
      email
      company
      fragile
      food
      package_types_id
      notes
      price_bon
      default_packages
      package_type_receivers
    }`,
  moreMerchants: (ids) => `merchants(where: {id: {_in: [${ids.join(",")}]}}) {
      ${RETURNING_MERCHANT}
  }`,
  merchant: (id) => `merchants(where: {id: {_eq: ${id}}}) {
        ${RETURNING_MERCHANT}
  }`,
  merchants: (team_id) => `merchants(where: {users: {team_id: {${
    team_id ? "_eq: " + team_id : "_is_null: true"
  }}}}) {
        ${RETURNING_MERCHANT}
    }`,
  getMerchantByEmail: (
    email
  ) => `merchants(where: {users: {email: {_eq: "${email}"}}}) {
      id
    }`,
  getMerchantById: (
    merchant_id
  ) => `merchants(where: {id: {_eq: ${merchant_id}}}) {
      ${RETURNING_MERCHANT}
    }`,
  merchantsToInvoice: (
    team_id
  ) => `merchants(where: {users: {team_id: {_eq: ${team_id}}}, _or: [
        {courses: {state: {_is_null: true}}},
        {courses: {state: {_eq: 0}}}
    ]}) {
      ${RETURNING_MERCHANT}
    }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  importMerchants: (merchants) => `mutation {
    insert_merchants(objects: [${merchants.map(
      (v) => `{
      company: ${v.company ? `"${v.company}"` : `null`},
      users: {data: {
        name: ${v.name ? `"${v.name}"` : `null`},
        role: "merchant_manager",
        email: ${merchants.email ? `"${merchants.email}"` : `null`},
        address: ${
          merchants.address
            ? `${JSON.stringify(merchants.address).replace(
                /"(\w+)"\s*:/g,
                "$1:"
              )}`
            : `null`
        },
        phone_number: ${v.phone_number ? `"${v.phone_number}"` : `null`},
        team_id: ${v.team_id ? `${v.team_id}` : `null`},
      }}
    }`
    )}]) {
      returning {
        id
        created_at
        updated_at
        company
        fragile
        food
        package_types_id
        notes
        payment_deadline
        discount
        taxe
        bank_ID
        matriculation
        type
        users(where: {role: {_eq: "merchant_manager"}}) {
          id
          email
          name
          phone_number
          address
          team_id
          new_user
          created_at
          updated_at
          role
        }
      }
    }
  }`,
  merchantCreate: gql`mutation insert_merchant_one(
    $merchant: merchants_insert_input!
   ) {
    insert_merchants_one(object: $merchant)
    {
        ${RETURNING_MERCHANT}
    }
  }`,
  validateMerchantInfo: (data) => `mutation {
    update_users(where: {id: {_eq: ${data.id}}},
    _set: {
      name: ${data.name ? `"${data.name}"` : `null`},
      new_user: ${data.new_user !== undefined ? `"${data.new_user}"` : `true`},
      phone_number: ${data.phone_number ? `"${data.phone_number}"` : `null`},
      address: ${
        data.address
          ? `${JSON.stringify(data.address).replace(/"(\w+)"\s*:/g, "$1:")}`
          : `null`
      },
    }) 
      {
        returning {
          id
          name
          email
          phone_number
          address
          team_id
          new_user
          created_at
          updated_at
          role
        }
      },
    update_merchants(where: {id: {_eq: ${data.merchant_id}}},
     _set: {
      bank_ID: ${data.bank_ID !== undefined ? `"${data.bank_ID}"` : "null"},
      company: ${data.company !== undefined ? `"${data.company}"` : "null"},
    }) 
      {
        returning {
          ${RETURNING_MERCHANT}
        }
      }
  }`,
  merchantQuickUpdate: gql`mutation update_merchant(
        $userId: Int!
        $merchantId: Int!
        $user: users_set_input
        $merchant: merchants_set_input
    ) {
    update_users(where: {id: {_eq: $userId}}, _set: $user) 
      {
        returning {
          id
          role
          created_at
          updated_at
          name
          new_user
          phone_number
          team_id
          address
          email
          team {
              id
              name
          }
          merchant {
              id
              bank_ID
              company
              notes
              group_id
          }
          dispatchers {
              id
              bank_ID
              matriculation
          }
        }
      }
    update_merchants(where: {id: {_eq: $merchantId}}, _set: $merchant) 
      {
        returning {
          ${RETURNING_MERCHANT}
        }
      },
  }`,
  merchantUpdate: gql`mutation update_merchant(
    $userId: Int!
    $merchantId: Int!
    $user: users_set_input
    $merchant: merchants_set_input
   ) {
    update_users(where: {id: {_eq: $userId}},
    _set: $user) 
      {
        returning {
          id
          name
          email
          phone_number
          address
          team_id
          new_user
          created_at
          updated_at
          role
        }
      },
    update_merchants(where: {id: {_eq: $merchantId}},
     _set: $merchant) 
      {
        returning {
          ${RETURNING_MERCHANT}
        }
      }
  }`,
  merchantDelete: gql`
    mutation update_merchants($id: Int!, $deleted_at: timestamptz!) {
      update_merchants(
        where: { id: { _eq: $id } }
        _set: { deleted_at: $deleted_at }
      ) {
        returning {
          id
        }
      }
    }
  `,
  merchantsDelete: gql`
    mutation update_merchants($ids: [Int!], $deleted_at: timestamptz!) {
      update_merchants(
        where: { id: { _in: $ids } }
        _set: { deleted_at: $deleted_at }
      ) {
        returning {
          id
        }
      }
    }
  `,
};

const routes = {
  userMerchantCreate:
    process.env.REACT_APP_BASE_API_URL + "/users/merchantUser",
};

const services = {
  statisticsMerchant: (values) => authenticatedQuery(queries.statisticsMerchant, values),
  searchMerchants: ({ value, team_id }) =>
    authenticatedQuery(queries.searchMerchants(value, team_id)),
  moreMerchants: (ids) => authenticatedQuery(queries.moreMerchants(ids)),
  merchant: (merchant) => authenticatedQuery(queries.merchant(merchant.id)),
  merchants: (merchant) => authenticatedQuery(queries.merchants(merchant.team_id)),
  merchantsToInvoice: (team_id) => authenticatedQuery(queries.merchantsToInvoice(team_id)),
  getMerchantById: (merchant_id) => authenticatedQuery(queries.getMerchantById(merchant_id)),
  getMerchantByEmail: (email) => authenticatedQuery(queries.getMerchantByEmail(email)),
  importMerchants: (values) => {
    return authenticatedMutation(mutations.importMerchants(values));
  },
  merchantCreate: (merchant) => {
    return authenticatedMutation(mutations.merchantCreate, {
      merchant: {
        ..._.pick(merchant, [
          "food",
          "fragile",
          "package_types_id",
          "notes",
          "company",
          "type",
          "matriculation",
          "taxe",
          "bank_ID",
          "discount",
          "price_bon",
          "payment_deadline",
        ]),
        users: {
          data: {
            ..._.pick(merchant, [
              "name",
              "email",
              "phone_number",
              "address",
              "team_id",
            ]),
            role: "merchant_manager",
          },
        },
      },
    });
  },
  merchantQuickUpdate: (merchant) => {
    return authenticatedMutation(mutations.merchantQuickUpdate, {
      userId: merchant.user_id,
      user: _.pick(merchant, [
        "name",
        "email",
        "phone_number",
        "address",
        "team_id",
      ]),
      merchantId: merchant.id,
      merchant: _.pick(merchant, ["company", "type", "notes"]),
    });
  },
  merchantUpdate: (merchant) => {
    return authenticatedMutation(mutations.merchantUpdate, {
      userId: merchant.user_id,
      user: _.pick(merchant, [
        "name",
        "email",
        "phone_number",
        "address",
        "team_id",
      ]),
      merchantId: merchant.id,
      merchant: _.pick(merchant, [
        "food",
        "fragile",
        "package_types_id",
        "notes",
        "company",
        "type",
        "matriculation",
        "taxe",
        "bank_ID",
        "discount",
        "price_bon",
        "payment_deadline",
      ]),
    });
  },
  validateMerchantInfo: (merchant) => {
    return authenticatedMutation(mutations.validateMerchantInfo(merchant));
  },
  merchantDelete: (data) => {
    return authenticatedMutation(mutations.merchantDelete, {
      id: data,
      deleted_at: moment().format(),
    });
  },
  merchantsDelete: (data) => {
    return authenticatedMutation(mutations.merchantsDelete, {
      ids: data,
      deleted_at: moment().format(),
    });
  },
  createUserMerchant: (body) =>
    service(POST, routes.userMerchantCreate, body, {}, {}),
  //updateUserMerchant: merchant => { return authenticatedMutation(mutations.userMerchantsUpdate(merchant)) },
};

export default services;
