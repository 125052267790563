import { createSelector } from "reselect";

export const selectPackageType = createSelector(
  (state) => state.packageType || {},
  (packageType) => {
    return packageType;
  }
);

export const selectMergePackageTypes = createSelector(
  (state) => ({
    packageTypes: Object.values(state?.packageType?.packageTypes || {}),
    packageTypesTeam: Object.values(state?.packageType?.packageTypesTeam || {}),
  }),
  ({ packageTypes, packageTypesTeam }) => {
      const tmp = {}
      packageTypes?.forEach((p) => {
          tmp[p.id] = p
      })
      packageTypesTeam?.forEach((p) => {
          tmp[p.id] = p
      })
    return tmp;
  }
);

export const selectPackageTypeByMerchant = createSelector(
    (state) => Object.values(state?.packageType?.packageTypes || {}),
    (packageType) => {
        return packageType;
    }
);

export const selectPackageKindsByKind = createSelector(
    (state) => Object.values(state?.packageType?.packageKinds || {}),
    (packageKinds) => {
        const tmp = {}
        packageKinds.forEach((v) => {
            tmp[v.kind] = v;
        });
        return tmp;
    }
);

export const selectPackageTypeByMerchantById = createSelector(
    (state) => Object.values(state?.packageType?.packageTypes || {}),
    (packageType) => {
        const tmp = {}
        packageType.forEach((v) => tmp[v.id] = v)
        return tmp;
    }
);
