export const taskModel = [
    "uid",
    "address",
    "company",
    "email",
    "weight",
    "message",
    "filename",
    "sign_filename",
    "history",
    "state",
    "coursier_id",
    "start_date",
    "end_date",
    "name",
    "order",
    "order_coursier",
    "nb_bon",
    "notes",
    "phone",
    "type"
]
