import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
  authenticatedSubscription,
  service
} from "./middleware";
import {methods, query} from "../utils";
import { gql } from "apollo-boost";
import {omit, pick} from "lodash";
import {taskModel} from "./Model/models"

const { POST } = methods;

const RETURNING_COURSE = `
  id
  identifier
  reference
  devis
  order
  price
  cost
  state
  merchant_id
  coursier_id
  team_id
  created_at
  created_by
  updated_at
  updated_by
  rejected_teams
  nb_bon
  invoice_courses {
    invoice_id
  }
  userByCreatedBy {
    role
  }
  userByUpdatedBy {
    role
  }
  merchant {
    id
    created_at
    updated_at
    company
    is_contractor
    fragile
    food
    package_types_id
    notes
    payment_deadline
    discount
    taxe
    bank_ID
    matriculation
    type
    group_id
    price_bon
    default_packages {
      package {
        id
        fragile
        is_default
        kind_id
        declared_value
        package_type_id
        volumn
        weight
        number
      }
    }
    package_type_receivers {
      package_type {
        id
        icon_fa
        icon
        desc
        name
        volumn
        weight
      }
      receiver_recipient_id
      package_type_id
      receiver_merchant_id
    }
    users(where: {role: {_eq: "merchant_manager"}}) {
      id
      email
      name
      phone_number
      address
      team_id
      new_user
      created_at
      updated_at
      role
    }
  }
  tasks {
    created_at
    uid
    address
    company
    hub_id
    merchant_id
    recipient_id
    order
    message
    filename
    sign_filename
    coursier_id
    coursier {
      availability
      user {
        name
      }
    }
    history
    order_coursier
    start_date
    end_date
    email
    state
    id
    name
    nb_bon
    notes
    phone
    type
    co2
    packages {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
        kind
      }
      package_type {
        name
      }
      fragile
      task_id
      pickup_id
      delivery_id
      declared_value
      number
    }
    packagesByDeliveryId {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
        kind
      }
      package_type {
        name
      }
      fragile
      task_id
      pickup_id
      delivery_id
      package_type_id
      declared_value
      number
    }
    packagesByPickupId {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
        kind
      }
      package_type {
        name
      }
      fragile
      task_id
      pickup_id
      delivery_id
      package_type_id
      declared_value
      number
    }
    packagesByDeliveryId_aggregate {
      aggregate {
        sum {
          weight
          volumn
        }
      }
    }
    packagesByPickupId_aggregate {
      aggregate {
        sum {
          weight
          volumn
        }
      }
    }
  }
`;

/**
 * GRAPHQL QUERIES
 */
const queries = {
  allCoursesCounts: gql`
    query tasks(
      $whereNotAttributeDay: tasks_bool_exp!
      $whereAttributeDay: tasks_bool_exp!
      $whereInProgressDay: tasks_bool_exp!
      $whereValidateDay: tasks_bool_exp!
      $whereErrorDay: tasks_bool_exp!
      $whereNotAttributeWeek: tasks_bool_exp!
      $whereAttributeWeek: tasks_bool_exp!
      $whereInProgressWeek: tasks_bool_exp!
      $whereValidateWeek: tasks_bool_exp!
      $whereErrorWeek: tasks_bool_exp!
    ) {
      tasks_0_day: tasks_aggregate(where: $whereNotAttributeDay) {
        aggregate {
          count
        }
      }
      tasks_1_day: tasks_aggregate(where: $whereAttributeDay) {
        aggregate {
          count
        }
      }
      tasks_2_day: tasks_aggregate(where: $whereInProgressDay) {
        aggregate {
          count
        }
      }
      tasks_3_day: tasks_aggregate(where: $whereValidateDay) {
        aggregate {
          count
        }
      }
      tasks_4_day: tasks_aggregate(where: $whereErrorDay) {
        aggregate {
          count
        }
      }
      tasks_0_week: tasks_aggregate(where: $whereNotAttributeWeek) {
        aggregate {
          count
        }
      }
      tasks_1_week: tasks_aggregate(where: $whereAttributeWeek) {
        aggregate {
          count
        }
      }
      tasks_2_week: tasks_aggregate(where: $whereInProgressWeek) {
        aggregate {
          count
        }
      }
      tasks_3_week: tasks_aggregate(where: $whereValidateWeek) {
        aggregate {
          count
        }
      }
      tasks_4_week: tasks_aggregate(where: $whereErrorWeek) {
        aggregate {
          count
        }
      }
    }
  `,
  getGeoByCoursiersAndTasks: gql`query geolocation(
    $coursierIds: [Int!]
    $taskIds: [Int!]
  ) {
    geolocation(where: {coursier_id: {_in: coursierIds}, task_id: {_in: $taskIds}}) {
      timestamp
      uid
      coordinates
      task_id
      coursier_id
    }
  }`,
  coursesCounts: gql`
    query courses(
      $wherePlanned: courses_bool_exp!
      $whereProcessing: courses_bool_exp!
    ) {
      courses_planned: courses_aggregate(where: $wherePlanned) {
        aggregate {
          count
        }
      }
      courses_processing: courses_aggregate(where: $whereProcessing) {
        aggregate {
          count
        }
      }
    }
  `,
  getDeliveryStatus: `delivery_status {
      id
      status
    }`,
  getCoursesIdentifier: `courses {
      id
      identifier
    }`,
  count: gql`
    query courses_aggregate(
      $where: courses_bool_exp!
      $limit: Int
      $offset: Int
    ) {
      courses_aggregate(where: $where, limit: $limit, offset: $offset) {
        aggregate {
          count
        }
      }
    }
  `,
  one: gql`query course(
    $id: Int!
  ) {
    courses(where: {id: {_eq: $id}}) {
      ${RETURNING_COURSE}
    }
  }`,
  more: gql`query courses(
      $ids: [Int!]
  ) {
      courses(where: {id: {_in: $ids}}) {
        ${RETURNING_COURSE}
      }
  }`,
  all: gql`
    subscription courses($where: courses_bool_exp!, $limit: Int, $offset: Int) {
      courses(
        where: $where
        limit: $limit
        offset: $offset
        order_by: { created_at: desc }
      ) {
        id
        devis
        identifier
        reference
        team_id
        created_at
        created_by
        updated_at
        nb_bon
        price
        state
        rejected_teams
        attribution_date
        merchant {
          id
          company
          users(where: { role: { _eq: "merchant_manager" } }) {
            team_id
            name
          }
        }
        orderByOrderId {
          delivery_status {
            status
          }
        }
        tasks {
          id
          course_id
          updated_at
          co2
          order_coursier
          order
          history
          packagesByDeliveryId_aggregate {
            aggregate {
              sum {
                weight
                volumn
              }
            }
          }
          packagesByPickupId_aggregate {
            aggregate {
              sum {
                weight
                volumn
              }
            }
          }
          coursier_id
          coursier {
            availability
            user {
              name
            }
          }
          state
          nb_bon
          type
          uid
          address
          company
          name
          phone
          start_date
          end_date
          notes
          message
          filename
          sign_filename
          merchant_id
          recipient_id
          hub_id
          packagesByDeliveryId {
            id
            uid
            delivery_status_id
            weight
            volumn
            kind_id
            package_kind {
              id
              kind
            }
            package_type {
              name
            }
            fragile
            task_id
            pickup_id
            delivery_id
            package_type_id
            declared_value
            number
          }
          packagesByPickupId {
            id
            uid
            delivery_status_id
            weight
            volumn
            kind_id
            package_kind {
              id
              kind
            }
            package_type {
              name
            }
            fragile
            task_id
            pickup_id
            delivery_id
            package_type_id
            declared_value
            number
          }
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  deleteTask: gql`
    mutation delete_tasks($ids: [Int!]) {
      delete_pallets(where: { packages: { id: { _in: $ids } } }) {
        returning {
          id
        }
      }
      delete_packages(
        where: {
          _or: [
            { delivery_id: { _in: $ids } }
            { pickup_id: { _in: $ids } }
            { task_id: { _in: $ids } }
          ]
        }
      ) {
        returning {
          id
        }
      }
      delete_tasks(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `,
  delete: (id) => `mutation {
    delete_courses(where: {id: {_eq: ${id}}})
    {
      returning {
        id
      }
    }
  }`,
  modifyTask: gql`mutation update_task(
    $id: Int!
    $task: tasks_set_input!
  ) {
      update_tasks(where: {id: {_eq: $id}}, _set:$task) {
        returning {
          uid
          address
          company
          email
          weight
          message
          filename
          sign_filename
          volumn
          history
          coursier_id
          start_date
          end_date
          state
          id
          name
          order
          order_coursier
          nb_bon
          notes
          phone
          type
          packages {
            id
            uid
            delivery_status_id
            weight
            volumn
            kind_id
            package_kind {
              id
            }
            fragile
            task_id
          }
        }
      }
    }`,
  createTask: gql`mutation insert_task(
    $task: tasks_insert_input!
  ) {
      insert_tasks_one(object: $task) {
        uid
        address
        company
        email
        weight
        message
        filename
        sign_filename
        history
        coursier_id
        start_date
        end_date
        state
        id
        name
        order
        order_coursier
        nb_bon
        notes
        phone
        type
        packages {
          id
          uid
          delivery_status_id
          weight
          volumn
          kind_id
          package_kind {
            id
          }
          fragile
          task_id
        }
      }
    }`,
  create: gql`mutation insert_courses_one(
    $course: courses_insert_input!
   ) {
    insert_courses_one(object: $course)
    {
      ${RETURNING_COURSE}
    }
  }`,
  modifyOrder: (mutation) => mutation,
  modify: (data, tasks) => `mutation {
    ${tasks
        .map((v, i) => {
          if (v.key === v.id) {
            return `update_tasks_${i}: update_tasks(where: {id: {_eq: ${
                v.id
            }}}, _set: {
            company: "${v.company || ""}",
            name: "${v.name || ""}",
            phone: "${v.phone || ""}",
            email: "${v.email || ""}",
            history: ${v.history ? JSON.stringify(v.history).replace(/"(\w+)"\s*:/g, "$1:") : "null"}, 
            uid: ${v.uid ? `"${v.uid}"` : null},
            hub_id: ${v.hub_id || null},
            merchant_id: ${v.merchant_id || null},
            recipient_id: ${v.recipient_id || null},
            weight: ${v.weight ? `"${v.weight}"` : null},
            volumn: ${v.volumn ? `"${v.volumn}"` : null},
            order: ${v.order !== undefined ? v.order : null},
            coursier_id: ${v.coursier_id ? `${v.coursier_id}` : `null`},
            address: ${
                v.address
                    ? JSON.stringify(v.address).replace(/"(\w+)"\s*:/g, "$1:")
                    : "null"
            },
            start_date: ${
                v.start_date
                    ? `"${v.start_date.format()}"`
                    : "null"
            },
            end_date: ${
                v.end_date ? `"${v.end_date.format()}"` : "null"
            },
            state: ${
                v.state !== null && v.state !== undefined
                    ? `"${v.state}"`
                    : `null`
            },
            nb_bon: ${v.nb_bon ? JSON.stringify(v.nb_bon) : "null"},
            notes: ${v.notes ? JSON.stringify(v.notes) : "null"},
            type: "${v.type || ""}",
          }) {
            returning {
              id
            }
          }`;
          } else {
            return `insert_tasks_${i}: insert_tasks(objects: {
          company: "${v.company || ""}",
          name: "${v.name || ""}",
          course_id: ${data.id ? data.id : null},
          phone: "${v.phone || ""}",
          email: "${v.email || ""}",
          uid: ${v.uid ? `"${v.uid}"` : null},
          hub_id: ${v.hub_id || null},
          merchant_id: ${v.merchant_id || null},
          recipient_id: ${v.recipient_id || null},
          weight: ${v.weight ? `"${v.weight}"` : null},
          volumn: ${v.volumn ? `"${v.volumn}"` : null},
          order: ${v.order !== undefined ? v.order : null},
          coursier_id: ${v.coursier_id ? `${v.coursier_id}` : `null`},
          address: ${
                v.address
                    ? JSON.stringify(v.address).replace(/"(\w+)"\s*:/g, "$1:")
                    : "null"
            },
          start_date: ${
                v.start_date
                    ? `"${v.start_date.format()}"`
                    : "null"
            },
          end_date: ${
                v.end_date ? `"${v.end_date.format()}"` : "null"
            },
          state: ${
                v.state !== null && v.state !== undefined ? `"${v.state}"` : `null`
            },
          nb_bon: ${v.nb_bon ? JSON.stringify(v.nb_bon) : "null"},
          notes: ${v.notes ? JSON.stringify(v.notes) : "null"},
          type: "${v.type || ""}",
        }) {
          returning {
            id
          }
        }`;
          }
        })
        .join(",")}
    update_courses(where: {id: {_eq: ${data.id}}}, _set: {
      devis: ${data.devis},
      price: ${data.price},
      cost: ${data.cost},
      coursier_id: ${data.coursier_id ? `${data.coursier_id}` : `null`},
      nb_bon: ${data.nb_bon ? `${data.nb_bon}` : `null`},
      merchant_id: ${data.merchant_id ? `${data.merchant_id}` : `null`},
      team_id: ${data.team_id ? `${data.team_id}` : `null`},
      updated_by: ${data.updated_by},
    })
    {
      returning {
         ${RETURNING_COURSE}
      }
    }
  }`,
  modifyOnly: gql`mutation update_courses(
        $courseId: Int!
        $course: courses_set_input
    ) {
      update_courses(where: {id: {_eq: $courseId}}, _set: $course)
      {
        returning {
           ${RETURNING_COURSE}
        }
      }
    }`,
  swapOrderCoursier: (values) => `mutation {
  first: update_tasks(where: {course_id: {_eq: ${values.first_course_id}}, coursier_id: {_eq: ${values.coursier_id}}}, _set: {order_coursier: ${values.second_course_order_coursier}}) {
    returning {
      course_id
      order_coursier
    }
  }
  second: update_tasks(where: {course_id: {_eq: ${values.second_course_id}}, coursier_id: {_eq: ${values.coursier_id}}}, _set: {order_coursier: ${values.first_course_order_coursier}}) {
    returning {
      course_id
      order_coursier
    }
  }
  }`,
};

const routes = {
  generatePdfTask: process.env.REACT_APP_FLYDRIVE_API_URL + "/generatePdfTask",
  generateReceipt: process.env.REACT_APP_FLYDRIVE_API_URL + "/generateReceipt",
  getRecipientCourseByUID:
    process.env.REACT_APP_BASE_API_URL + "/task/recipientCourse",
  rateTask: process.env.REACT_APP_BASE_API_URL + "/task/rateTask",
  reorderCourses: process.env.REACT_APP_BASE_API_URL + "/task/reorderCourses",
  updateTaskByRecipient:
    process.env.REACT_APP_BASE_API_URL + "/task/updateByUID",
  autoAssign: process.env.REACT_APP_BASE_API_URL + "/course/assign",
  accept: process.env.REACT_APP_BASE_API_URL + "/course/accept",
  deny: process.env.REACT_APP_BASE_API_URL + "/course/deny",
  importCoursesShop: process.env.REACT_APP_BASE_API_URL + "/course/importCoursesShop"
};

const services = {
  importCoursesShop: (body) => authenticatedService(POST, routes.importCoursesShop, body),
  getAllCoursesCounts: (variables) =>
      authenticatedQuery(queries.allCoursesCounts, variables),
  getDeliveryStatus: () => authenticatedQuery(queries.getDeliveryStatus),
  updateTaskByRecipient: (values) =>
    service(POST, routes.updateTaskByRecipient, values, {}, {}),
  rateTask: (values) => service(POST, routes.rateTask, values, {}, {}),
  generateReceipt: ({ id, merchant_id, taskIds, qrcodePath }) =>
    service(
      POST,
      routes.generateReceipt,
      { course_id: id, merchant_id, taskIds, qrcodePath },
      {},
      {}
    ),
  getCoursesCounts: (variables) => authenticatedQuery(queries.coursesCounts, variables),
  generatePdfTask: ({ task, isDymo }) =>
    service(POST, routes.generatePdfTask, { delivery: task, isDymo }, {}, {}),
  getRecipientCourseByUID: (uid) =>
    service(POST, routes.getRecipientCourseByUID, { uid }, {}, {}),
  create: (values) =>
    authenticatedMutation(mutations.create, {course: values}),
  modify: ({ filter, tasksTab }) =>
      authenticatedMutation(mutations.modify(filter, tasksTab)),
  modifyTask: ({ values }) => authenticatedMutation(mutations.modifyTask,
     {
      task: pick(values, taskModel),
      id: values.id
    }),
  createTask: ({ values }) => authenticatedMutation(mutations.createTask,
      {
        task:pick(values, taskModel)
      }),
  accept: (course_id) =>
    service(POST, routes.accept, { course_id }, {}, {}),
  deny: ({ course, team_id }) =>
    service(
      POST,
      routes.deny,
      { course_id: course?.id, team_id },
      {},
      {}
    ),
  autoAssign: ({ course_ids, merchant_id }) =>
    service(POST, routes.autoAssign, { course_ids, merchant_id }, {}, {}),
  modifyOnly: ({ values }) =>
      authenticatedMutation(mutations.modifyOnly, {courseId: values.id, course: omit(values, "id")}),
  delete: ({ id }) => authenticatedMutation(mutations.delete(id)),
  getCount: (values) =>
    authenticatedQuery(queries.count, values),
  getMore: (ids) => authenticatedQuery(queries.more, { ids }),
  getAll: ({ where, limit, offset, updateQuery, errorQuery }, dispatch) =>
    authenticatedSubscription(
      queries.all,
      updateQuery,
      errorQuery,
      {where, limit, offset},
      dispatch
    ),
  getCoursesForExport: ({ where, token }) => query(queries.all, {where}, {Authorization: `Bearer ${token}`}),
  getOne: ({ id, updateQuery, errorQuery }, dispatch) =>
    authenticatedSubscription(queries.one, updateQuery, errorQuery, {id}, dispatch),
  getGeoByCoursiersAndTasks: ({
    coursierIds,
    taskIds,
    updateQuery,
    errorQuery,
  }, dispatch) =>
    authenticatedSubscription(
      queries.getGeoByCoursiersAndTasks,
      updateQuery,
      errorQuery,
      {coursierIds, taskIds},
      dispatch
    ),
  deleteTask: (ids) => authenticatedMutation(mutations.deleteTask, { ids }),
  modifyCourseOrder: (data) =>
      authenticatedMutation(mutations.modifyOrder(data.mutation)),
  getCoursesIdentifier: () => authenticatedQuery(queries.getCoursesIdentifier),
  swapOrderCoursier: (data) =>
    service(POST, routes.reorderCourses, data, {}, {}),
};

export default services;
