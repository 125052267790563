import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: []
};

const getVehicleTypesSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
      tmp[p.id] = p
  })
  return {...state, list: tmp};
};

const createVehicleTypeSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp};
};

const modifyVehicleTypeSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp};
};

const deleteVehicleTypeSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  delete tmp[data.id]
  return {...state, list: tmp};
};

const getFailed = (state = initialState) => {
  return {...state};
};

const vehiclesFailed = (state = initialState) => {
  return {...state};
};

export default createReducer(initialState, {
  [types.GET_VEHICLE_TYPES_FAILURE]: getFailed,
  [types.GET_VEHICLE_TYPES_SUCCESS]: getVehicleTypesSuccess,
  [types.CREATE_VEHICLE_TYPE_FAILURE]: vehiclesFailed,
  [types.CREATE_VEHICLE_TYPE_SUCCESS]: createVehicleTypeSuccess,
  [types.MODIFY_VEHICLE_TYPE_FAILURE]: vehiclesFailed,
  [types.MODIFY_VEHICLE_TYPE_SUCCESS]: modifyVehicleTypeSuccess,
  [types.DELETE_VEHICLE_TYPE_FAILURE]: vehiclesFailed,
  [types.DELETE_VEHICLE_TYPE_SUCCESS]: deleteVehicleTypeSuccess,
});
