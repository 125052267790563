export const task = [
    "company",
    "name",
    "phone",
    "email",
    "uid",
    "hub_id",
    "merchant_id",
    "recipient_id",
    "weight",
    "volumn",
    "coursier_id",
    "order",
    "order_coursier",
    "address",
    "start_date",
    "end_date",
    "state",
    "nb_bon",
    "notes",
    "type"
]