import {
  QrcodeOutlined,
  MailOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

// Reactour pour dispatcher  //
export const tourDispatcher = [
  {
    selector: '[data-tour="step-1"]',
    content: <p>
        CREER une nouvelle course, ou EXPORTER un fichier .xlsx de l'ensemble
        des informations de vos courses.
      </p>
  },
  {
    selector: '[data-tour="step-2"]',
    content: <p>RECHERCHER précisément une course affectée à un client.</p>
  },

  {
    selector: '[data-tour="step-3"]',
    content: <p>
        Ici, une visualisation graphique, au jour ou à la semaine, pour suivi de
        vos livraison. Vous pouvez télécharger une image .png du graphique.
      </p>
  },
  {
    selector: '[data-tour="step-4"]',
    content: <p>
        Ici SUIVEZ l'ensemble de vos courses mises à jour en temps réel. Vous
        pouvez les FILTRER par Statut, Coursier, Date et Donneur d'ordre. Dans
        la colonne ACTIONS, vous pouvez Dupliquer, voir un aperçu de la course
        ou AFFICHER identifiant et QRCode de la livraison.{" "}
      </p>
  },
];
// Reactour pour CMR
export const tourCRM = [
  {
    selector: '[data-tour="step-01"]',
    content: <p>Créez un nouveau client.</p>
  },
  {
    selector: '[data-tour="step-02"]',
    content: <p>Pour RECHERCHER précisément un client.</p>
  },

  {
    selector: '[data-tour="step-03"]',
    content: <p>
        Ici vous avez toutes les informations des clients. Cliquez sur une ligne
        pour accéder à la fiche client et la modifier ou sur la poubelle pour la
        supprimer !Attention! Cette action est irréversible.
      </p>
  },
];

export const tourRecipients = [
  {
    selector: '[data-tour="step-101"]',
    content: <p>Créez un nouveau destinataire.</p>,
  },
  {
    selector: '[data-tour="step-102"]',
    content: <p>Pour RECHERCHER un destinataire.</p>,
  },

  {
    selector: '[data-tour="step-103"]',
    content: <p>
        Ici vous avez toutes les informations destinataires. Cliquez sur une
        ligne pour accéder à la fiche du destinataire et la modifier ou sur la
        poubelle pour le supprimer !Attention! Cette action est irréversible.
      </p>
  },
];
// Reactour pour Facturation (invoice)
export const tourInvoice = [
  {
    selector: '[data-tour="step-202"]',
    content: <p>Pour vous aider à chercher vos facture.</p>,
  },

  {
    selector: '[data-tour="step-203"]',
    content: <p>
        En cliquant sur une ligne vous pourrez éditer la facture correspondante,
        la modifier, en changer le statut et l'envoyer par mail.
      </p>
  },

  {
    selector: '[data-tour="step-204"]',
    content: <p>
        Vous permettras d'envoyer la facture par mail à votre client, sans
        sortir de l'application.
      </p>
  },

  {
    selector: '[data-tour="step-205"]',
    content: (
      <p>
        Cliquez MAINTENANT ici, pour voir l'aide à la facturation.
      </p>
    ),
  },

  {
    selector: '[data-tour="step-206"]',
    content: (
      <p>
        L'aide à la facturation vous permet d'éditer vos factures sans sortir de
        l'application. Vous pouvez sélectionner vos clients en cliquant sur la
        ligne les concernant, et en modifier les informations en cliquant sur le
        bouton CONTINUER.
      </p>
    ),
  },
];
// Reactour pour Tarification
export const tourTarification = [
  {
    selector: '[data-tour="step-301"]',
    content: (
      <p>
        Pour vous aider à chercher vos tarifications de colis et sauvegarder vos modifications.
      </p>
    ),
  },
  {
    selector: '[data-tour="step-302"]',
    content: <p>Renseignez ici votre tarification puis la sauvegarder.</p>,
  },
];
// Reactour pour PackageType
export const tourPackageType = [
  {
    selector: '[data-tour="step-401"]',
    content: (
      <p>
        Cette page vous permet de créer ou suprimer vos types de colis et de les modifier simplement. Vous pourrez personnaliser le nom et l'icone du colis.
      </p>
    ),
  },
];
// Reactour pour Nouvelle Course à partir de dispatch
export const tourNewCourse = [
  {
    selector: '[data-tour="step-501"]',
    content: <p>Cliquer pour choisir un coursier.</p>,
  },
  {
    selector: '[data-tour="step-502"]',
    content: <p>Donneur d'ordre data-tour="step-502".</p>,
  },
  {
    selector: '[data-tour="step-503"]',
    content: <p>Indiquez ici le nombre de bons de la course".</p>,
  },
  {
    selector: '[data-tour="step-504"]',
    content: <p>data-tour="step-504" Nouvelle tâche add-task".</p>,
  },
];

// Reactour pour show (côté marchand)
export const stepsHome = [
  {
    selector: '[data-tour="step-1"]',
    content: (
      <p>Cliquez ici pour commencer la création d'une nouvelle course.</p>
    ),
  },
  {
    selector: '[data-tour="step-2"]',
    content: <p>Triez vos courses par statut ici. </p>,
  },
  {
    selector: '[data-tour="step-3"]',
    content: <p>Vous pouvez aussi trier vos courses sur une période donnée.</p>,
  },
  {
    selector: '[data-tour="step-4"]',
    content: <p>Recherchez vos courses en fonction des clients.</p>,
  },
  {
    selector: '[data-tour="step-5"]',
    content: (
      <p>Vous trouverez ici le détail et les informations de vos livraisons.</p>
    ),
  },
  // A voir ne fonctionne pas
  {
    selector: '[data-tour="step-6"]',
    content: (
      <ul className="icon-list">
        <li>
          <p>
            <QrcodeOutlined className="pop-icons"></QrcodeOutlined> - Afficher
            le QRCode
          </p>
          <p>
            <MailOutlined className="pop-icons"></MailOutlined> - Recevoir le QR
            Code par mail
          </p>
          <p>
            <DownloadOutlined className="pop-icons"></DownloadOutlined> -
            Imprimer le QR Code
          </p>
          <p>
            <PrinterOutlined className="pop-icons"></PrinterOutlined> - Imprimer
            l'étiquette
          </p>
          <p>
            <FileDoneOutlined className="pop-icons"></FileDoneOutlined> -
            Afficher la signature
          </p>
        </li>
      </ul>
    ),
  },
  // A voir ne fonctionne pas
  {
    selector: '[data-tour="step-7"]',
    content: <p>Contacter nous en cliquant ici!</p>,
  },
];
export const stepsCrm = [
  {
    selector: '[data-tour="step-11"]',
    content: (
      <p>
        En cliquant sur une ligne vous pourrez modifier les informations d'un
        client.
      </p>
    ),
  },
  {
    selector: '[data-tour="step-12"]',
    content: <p>Un nouveau client ? Ajoutez-le en cliquant ici!</p>,
  },
  {
    selector: '[data-tour="step-13"]',
    content: <p>Vous pouvez supprimer un client juste ici!</p>,
  },
];
export const collectSteps = [
  {
    selector: '[data-tour="step-18"]',
    content: <p>Selectionnez votre adresse de collecte ici.</p>,
  },
  {
    selector: '[data-tour="step-19"]',
    content: (
      <p>
        Si besoin vous pouvez ajouter des indications supplémentaires pour le
        livreur ici.
      </p>
    ),
  },
];

export const recapSteps = [
  {
    selector: '[data-tour="step-16"]',
    content: <p>Vous pouvez ajouter une autre livraison à votre course ici</p>,
  },
  {
    selector: '[data-tour="step-17"]',
    content: (
      <p>
        Effectuez des dernières modifications avant de confirmer votre course!{" "}
      </p>
    ),
  },
  {
    selector: '[data-tour="step-15"]',
    content: <p>Pour modifier votre livraison, cliquez ici</p>,
  },
];

export const deliverySteps = [
  {
    selector: '[data-tour="step-8"]',
    content: (
      <p>
        Pour continuer dans le processus de création de votre course vous devez
        renseigner cette partie{" "}
      </p>
    ),
  },
  {
    selector: '[data-tour="step-9"]',
    content: <p>En cas de besoin vous pouvez modifier votre collecte ici</p>,
  },
];
