import { createAction } from "../../utils";

// Types
export const types = {
  LOGIN_REFRESH: "LOGIN_REFRESH",
  AUTO_LOGIN: "AUTO_LOGIN",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  SUBSCRIBE_REQUEST: "SUBSCRIBE_REQUEST",
  SUBSCRIBE_FAILURE: "SUBSCRIBE_FAILURE",
  SUBSCRIBE_SUCCESS: "SUBSCRIBE_SUCCESS",

  UPDATE_PASSWORD: "UPDATE_PASSWORD",

  MAIL_FORGOT_PWD_REQUEST: "MAIL_FORGOT_PWD_REQUEST",

  LOGOUT: "LOGOUT",
  GRAPHQL_RETRY: "GRAPHQL_RETRY"
};

// Actions
const actions = {
  graphqlRetry: (services) => createAction(types.GRAPHQL_RETRY, {services}),

  loginRefresh: () => createAction(types.LOGIN_REFRESH),

  mailForgotPwdRequest: ({ email }) =>
    createAction(types.MAIL_FORGOT_PWD_REQUEST, { email }),

  loginRequest: (username, password) =>
    createAction(types.LOGIN_REQUEST, { username, password }),

  loginSuccess: ({
    token_type,
    token,
    refresh_token,
    authorization,
    role,
    username,
    team_id,
  }) =>
    createAction(types.LOGIN_SUCCESS, {
      token_type,
      token,
      refresh_token,
      authorization,
      role,
      username,
      team_id,
    }),

  loginFailure: () => createAction(types.LOGIN_FAILURE),

  subscribeRequest: (values) =>
    createAction(types.SUBSCRIBE_REQUEST, { values }),

  subscribeSuccess: () => createAction(types.SUBSCRIBE_SUCCESS),

  subscribeFailure: () => createAction(types.SUBSCRIBE_FAILURE),

  updatePassword: ({ user, password }) =>
    createAction(types.UPDATE_PASSWORD, { user, password }),

  autoLogin: (data) => createAction(types.AUTO_LOGIN, data),

  logout: () => createAction(types.LOGOUT),
};
export default actions;
