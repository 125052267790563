import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  packageTypes: [],
  packageTypesTeam: [],
  packageKinds: [],
  loading: false,
  hasMore: true,
};

const getPackageTypesTeamSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
    tmp[p.id] = p
  })
  return {...state, packageTypesTeam: tmp, loading: false};
};

const getPackageTypesSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
    tmp[p.id] = p
  })
  return {...state, packageTypes: tmp, loading: false};
};

const updatePackageTypeSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.packageTypes
  tmp[data.id] = data
  return {...state, packageTypes: tmp, loading: false};
};

const deletePackageTypeSuccess = (state = initialState, action) => {
  const {data} = action
  const packageTypes = state.packageTypes
  const tmp = packageTypes.filter(v => v.id !== data.id)
  return {...state, packageTypes: tmp, loading: false};
};

const createPackageTypeSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.packageTypes
  tmp[data.id] = data
  return {...state, packageTypes: tmp, loading: false};
};

const getPackageKindSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
    tmp[p.id] = p
  })
  return {...state, packageKinds: tmp, loading: false};
};

const getFailed = (state = initialState) => {
  return {...state};
};

export default createReducer(initialState, {
  [types.UPDATE_PACKAGE_TYPE_FAILURE]: getFailed,
  [types.UPDATE_PACKAGE_TYPE_SUCCESS]: updatePackageTypeSuccess,
  [types.CREATE_PACKAGE_TYPE_FAILURE]: getFailed,
  [types.CREATE_PACKAGE_TYPE_SUCCESS]: createPackageTypeSuccess,
  [types.DELETE_PACKAGE_TYPE_FAILURE]: getFailed,
  [types.DELETE_PACKAGE_TYPE_SUCCESS]: deletePackageTypeSuccess,
  [types.GET_PACKAGE_TYPES_FAILURE]: getFailed,
  [types.GET_PACKAGE_TYPES_SUCCESS]: getPackageTypesSuccess,
  [types.GET_PACKAGE_KIND_FAILURE]: getFailed,
  [types.GET_PACKAGE_KIND_SUCCESS]: getPackageKindSuccess,
  [types.GET_PACKAGE_TYPES_TEAM_FAILURE]: getFailed,
  [types.GET_PACKAGE_TYPES_TEAM_SUCCESS]: getPackageTypesTeamSuccess,
  [types.CREATE_DP_FAILURE]: getFailed,
  [types.DELETE_DP_FAILURE]: getFailed,
});
