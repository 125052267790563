import React, {lazy} from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import Authorize from "../ui/screens/authorize/Authorize";
import { Spin } from "antd";
import { Suspense } from "react";
import { history } from "./history";
import store from "./store";

const Timeslot = lazy(() => import("../ui/screens/timeslot/index"));
const PricingGrid = lazy(() => import("../ui/screens/pricingGrid/index"));
const Recipients = lazy(() => import("../ui/screens/recipients/index"));
const PackageType = lazy(() => import("../ui/screens/packageType/index"));
const InvoiceMail = lazy(() => import("../ui/screens/invoiceMail/index"));
const InvoiceDetail = lazy(() =>
  import("../ui/screens/invoiceDetail/index")
);
const InvoiceSelect = lazy(() =>
  import("../ui/screens/invoiceSelect/index")
);
const Invoice = lazy(() => import("../ui/screens/invoice/index"));
const Merchant = lazy(() => import("../ui/screens/merchant/index"));
const Profil = lazy(() => import("../ui/screens/Profil/index"));
const CourseMerchant = lazy(() =>
  import("../ui/screens/courseMerchant/index")
);
const Course = lazy(() => import("../ui/screens/course/index"));
const Coursiers = lazy(() => import("../ui/screens/coursiers/index"));
const CRMShop = lazy(() => import("../ui/screens/CRMShop/index"));
const Track = lazy(() => import("../ui/screens/track/index"));
const CRM = lazy(() => import("../ui/screens/CRM/index"));
const Home = lazy(() => import("../ui/screens/home/index"));
const ForgotPassword = lazy(() =>
  import("../ui/screens/forgotPassword/index")
);
const Vehicles = lazy(() => import("../ui/screens/vehicles/index"));
const Dashboard = lazy(() => import("../ui/screens/dashboard/index"));
const Admin = lazy(() => import("../ui/screens/admin/index"));
const Subscribe = lazy(() => import("../ui/screens/subscribe/index"));
const Authentication = lazy(() =>
  import("../ui/screens/authentication/index")
);
const NotFound = lazy(() => import("../ui/screens/notFound/index"));
const ErrorBoundary = lazy(() => import("../ui/screens/error/index"));

const AuthRoute = ({ component: Component, ...rest }) => {
  const {
    auth: { token, role },
    user,
    router,
  } = store.getState();
  let roleUser = role;
  let current_user = user.current_user.group_id;
  let pathname = router.location.pathname;
  let url;

  if (current_user === process.env.REACT_APP_GROUP_ID_TDP) {
    if (window.location.hostname === "localhost") {
      url = process.env.REACT_APP_HOST_TDP + pathname;
      window.location.href = url;
    }
    if (window.location.hostname === "shop-dev.deki.pro") {
      window.location.href =
        "https://terrassesduport.shop-dev.deki.pro/#" + pathname;
    }
    if (window.location.hostname === "shop.deki.pro") {
      window.location.href =
        "https://terrassesduport.shop.deki.pro/#" + pathname;
    }
  }

  if (
    process.env.REACT_APP_TYPE === "merchant" &&
    (role === "merchant_manager" || role === "merchant_staff")
  ) {
    roleUser = "merchant";
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        token && !(process.env.REACT_APP_TYPE !== roleUser) ? (
          <ErrorBoundary>
            <Component {...props} {...rest} />
          </ErrorBoundary>
        ) : (
          <Redirect to={{ pathname: "/login", state: { ...rest.location } }} />
        )
      }
    />
  );
};

// Main router which handle user navigation
const Router = ({ context }) => {
  document.title =
    process.env.REACT_APP_TYPE === "dispatcher"
      ? process.env.REACT_APP_TITLE_TDC
      : process.env.REACT_APP_TITLE_MERCHANT;
  return (
    <ConnectedRouter history={history} context={context}>
      <Suspense
        fallback={
          <div
            style={{
              height: window.innerHeight,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spin style={{ justifySelf: "center", alignSelf: "center" }} />
          </div>
        }
      >
        <Switch>
          <Route exact path={"/login"} component={Authentication} />
          <Route exact path={"/subscribe"} component={Subscribe} />
          <Route exact path={"/forgot_password"} component={ForgotPassword} />
          <Route exact path={"/track"} component={Track} />
          <Route exact path={"/track/:uid"} component={Track} />
          <Route exact path={"/authenticate"} component={Authorize} />
          <Route exact path={"/timeslot/:uid"} component={Timeslot} />
          <AuthRoute exact path={"/"} component={Home} />
          <AuthRoute exact path={"/dispatcher"} component={Admin} />
          <AuthRoute exact path={"/course"} component={Course} />
          <AuthRoute
            exact
            path={"/courseMerchant"}
            component={CourseMerchant}
          />
          <AuthRoute exact path={"/merchant/:id"} component={Merchant} />
          <AuthRoute
            exact
            path={"/courseMerchant/:id"}
            component={CourseMerchant}
          />
          <AuthRoute exact path={"/course/:id"} component={Course} />
          <AuthRoute exact path={"/crm"} component={CRM} />
          <AuthRoute exact path={"/pricing_grid"} component={PricingGrid} />
          <AuthRoute exact path={"/crmShop"} component={CRMShop} />
          <AuthRoute exact path={"/profil"} component={Profil} />
          <AuthRoute exact path={"/coursiers"} component={Coursiers} />
          <AuthRoute exact path={"/vehicles"} component={Vehicles} />
          <AuthRoute exact path={"/invoice"} component={Invoice} />
          <AuthRoute exact path={"/recipients"} component={Recipients} />
          <AuthRoute exact path={"/dashboard"} component={Dashboard} />
          <AuthRoute exact path={"/packageType"} component={PackageType} />
          <AuthRoute
            exact
            path={"/invoice/select/:id"}
            component={InvoiceSelect}
          />
          <AuthRoute
            exact
            path={"/invoice/info/:id"}
            component={InvoiceDetail}
          />
          <AuthRoute exact path={"/invoice/mail/:id"} component={InvoiceMail} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
};

export default Router;
