import { methods } from "../utils";
import { encode } from "base-64";
import {authenticatedQuery, service} from "./middleware";

const { POST, GET } = methods;

const queries = {
  getCompleteUser: (email) => `users(where: {email: {_eq: "${email}"}}) {
        id
        role
        created_at
        updated_at
        name
        new_user
        phone_number
        team_id
        address
        email
        team {
            id
            name
        }
        merchant {
            id
            bank_ID
            company
            notes
            group_id
            fragile
            package_types_id
            food
        }
        dispatchers {
            id
            bank_ID
            matriculation
        }
  }`,
};

const routes = {
  merchant: process.env.REACT_APP_BASE_API_URL + "/users/merchant",
  user: process.env.REACT_APP_BASE_API_URL + "/users",
  login: process.env.REACT_APP_BASE_API_URL + "/auth/authorize",
  userPassword: process.env.REACT_APP_BASE_API_URL + "/users/renew_pwd",
  forgotPassword: process.env.REACT_APP_BASE_API_URL + "/users/forgot_pwd_mail",
  getToken: process.env.REACT_APP_BASE_API_URL + "/auth/token",
};

const services = {
  mailForgotPwd: (body) => service(POST, routes.forgotPassword, body, {}, {}),
  subscribeMerchant: (body) => service(POST, routes.merchant, body, {}, {}),
  login: ({ username, password }) =>
    service(
      GET,
      routes.login,
      {},
      {
        prompt: "none",
        response_type: "code",
        redirect_uri: process.env.REACT_APP_BASE_API_URL,
        client_id: "deki",
      },
      {
        Authorization: "Basic " + encode(username + ":" + password),
      }
    ),
  getToken: ({ username, password, code }) =>
    service(
      POST,
      routes.getToken,
      "grant_type=authorization_code&code=" + code,
      {},
      {
        Authorization: "Basic " + encode(username + ":" + password),
      }
    ),
  refreshToken: ({ authorization, refresh_token }) =>
    service(
      POST,
      routes.getToken,
      "grant_type=refresh_token&refresh_token=" + refresh_token,
      {},
      {
        Authorization: "Basic " + authorization,
      }
    ),
  getUser: ({ email }) => service(GET, routes.user, {}, { email: email }, {}),
  getMerchant: ({ email }) => service(GET, routes.merchant, { email }, {}, {}),

  updatePassword: (body) => service(POST, routes.userPassword, body, {}, {}),

  getCompleteUser: ({ email }) => authenticatedQuery(queries.getCompleteUser(email)),
};
export default services;
