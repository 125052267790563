import {methods, query} from '../utils';
import {authenticatedMutation, authenticatedQuery, service} from './middleware';
import {gql} from "apollo-boost";

const { POST } = methods;

const RETURNING_VIEW_RECIPIENT = `
  id
  created_at
  created_by
  updated_at
  company
  email
  notes
  name
  phone_number
  address
  team_id
  type
  merchant_id
  package_type_receivers
`

const RETURNING_VIEW_USER = `
  id
  created_at
  updated_at
  merchant_id
  name
  address
  phone_number
  company
  email
  company
  fragile
  food
  package_types_id
  notes
  price_bon
  default_packages
  package_type_receivers
`

const RETURNING_USER = `
  id
  role
  picture_name
  created_at
  updated_at
  firstname
  lastname
  name
  new_user
  phone_number
  team_id
  address
  email
  merchant {
    id
    bank_ID
    company
    group_id
    notes
    package_types {
        id
        icon_fa
        icon
        desc
        name
        volumn
        weight
        team_id
        merchant_id
      }
    package_type_receivers {
        package_type {
          id
          icon_fa
          icon
          desc
          name
          volumn
          weight
        }
        receiver_recipient_id
        package_type_id
        receiver_merchant_id
      }
    default_packages {
        package {
          id
          fragile
          is_default
          kind_id
          declared_value
          package_type_id
          volumn
          weight
          number
        }
      }
  }
  dispatchers {
    id
  }
`

/**
 * GRAPHQL QUERIES
 */
const queries = {
  search: (
    value,
    team_id
  ) => `view_recipients(where: {_or: [{address_value: {_ilike: "%${value}%"}}, {email: {_ilike: "%${value}%"}}, {name: {_ilike: "%${value}%"}}, {phone_number: {_ilike: "%${value}%"}}, {company: {_ilike: "%${value}%"}}], _and: {team_id: {_eq: ${team_id}}}}) {
      ${RETURNING_VIEW_RECIPIENT}
    }
    view_users(where: {_or: [{address_value: {_ilike: "%${value}%"}}, {email: {_ilike: "%${value}%"}}, {name: {_ilike: "%${value}%"}}, {phone_number: {_ilike: "%${value}%"}}, {company: {_ilike: "%${value}%"}}], _and: {team_id: {_eq: ${team_id}}}}) {
      ${RETURNING_VIEW_USER}
    }
    `,
  getArea: (point) => `getzonewithpoint(args: {point: "SRID=4326;${point}"}) {
    id
    name
    team_id
    is_admin
  }`,
  user: gql`query user(
    $id: Int!
  ) {
    users(where: {id: {_eq: $id}}) {
      ${RETURNING_USER}
    }
  }`,
  users: `users {
    ${RETURNING_USER}
  }`,
  dispatchersByTeamID: (
    team_id
  ) => `dispatchers(where: {user: {team_id: {_eq: ${team_id}}}}) {
    user {
      email
    }
  }`,
  getAccessToken: (
    id
  ) => `access_tokens(where: {user_id: {_eq: ${id}}, expiration: {_gt: "now()"}}) {
        value
        created_at
    }
    `,
  count: ` users_aggregate {
    aggregate {
      count
    }
  }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  updateUser: (values) => `mutation {
    update_users(where: {id: {_eq: "${values.id}"}},
    _set: {
      name: ${values.name ? `"${values.name}"` : `null`}, 
      phone_number: ${values.phone_number ? `"${values.phone_number}"` : `null`},
      address: ${values.address ? `${JSON.stringify(values.address).replace(/"(\w+)"\s*:/g, '$1:')}` : `null`},
      ${values.email ? `email: "${values.email}"` : ``}
    }) 
     {
       returning {
        ${RETURNING_USER}
       }
     }
     ${values.merchant_id ? `,
       update_merchants(where: {id: {_eq: ${values.merchant_id}}},
      _set: {
        company: ${values.company ? `"${values.company}"` : `null`},
        notes: ${values.notes ? `"${values.notes}"` : `null`},
        package_types_id: ${values.package_types_id ? `"${values.package_types_id}"` : `null`},
        fragile: ${![undefined, null].includes(values.fragile) ? `"${values.fragile}"` : `null`},
        food: ${values.food ? `"${values.food}"` : `null`},
      }) 
       {
         returning {
            id
            bank_ID
            company
            notes
            package_types_id
            fragile
            food
        }
       }
     `: ''}
     ${values.dispatcher_id ? `,
       update_dispatchers(where: {id: {_eq: ${values.dispatcher_id}}},
      _set: {
        bank_ID: ${values.bank_ID ? `"${values.bank_ID}"` : `null`},
        matriculation: ${values.matriculation ? `"${values.matriculation}"` : `null`},
      }) 
       {
         returning {
            id
            bank_ID
            matriculation
        }
       }
     `: ''}
  }`
}

const routes = {
  area: process.env.REACT_APP_BASE_API_URL + "/postgis/area",
  uploadProfile: process.env.REACT_APP_FILE_API_URL + "/picture/",
  clearProfilePicture: process.env.REACT_APP_FILE_API_URL + "/picture/clear",
};

const services = {
  search: ({ value, team_id }) => authenticatedQuery(queries.search(value, team_id)),
  users: () => authenticatedQuery(queries.users),
  user: (id) => authenticatedQuery(queries.user, { id }),
  getAccessToken: (id) => authenticatedQuery(queries.getAccessToken(id)),
  dispatchersByTeamID: (team_id) => authenticatedQuery(queries.dispatchersByTeamID(team_id)),
  updateUser: (values) => authenticatedMutation(mutations.updateUser(values)),
  getArea: ({ point }) => query(queries.getArea(point)),
  uploadProfile: (form) => service(POST, routes.uploadProfile, form),
  clearProfilePicture: (id) =>
    service(POST, routes.clearProfilePicture, { id }),
};
export default services
