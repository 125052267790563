import { call, put, select, all } from "redux-saga/effects";
import { TasksService, PackageService, MerchantsService } from "../services";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import typeColis from "./typeColis";
import { showError } from "./notifications-helper";
import { default as TasksActions } from "../redux/tasks/actions";
import { LoaderActions } from "../redux/loader";
import { Tag, Tooltip, Button, Popover } from "antd";
import dico from "./dico";
import { InfoOutlined } from "@ant-design/icons";
import foodDico from "./dico";
import { formatTypePackage } from "./file-tools";
import _, { get } from "lodash";
import "../ui/components/PackagesPanel/style.css"
import React from "react";
import "./style.css";
import {getTranslate} from "react-localize-redux";
import RecipientsAction from "../redux/recipients/actions";
import {MerchantsActions} from "../redux/merchants";

const isHostTDP = window.location.hostname === process.env.REACT_APP_HOST_TDP;

export function* generateIdentifier() {
  let baseIdentifier = moment().format("YYYY-MM-DD");
  let courses = {};
  let identifier = null;
  const [error, response] = yield call(TasksService.getCoursesIdentifier);
  if (response && !error) {
    response.courses.forEach((v) => {
      if (v.identifier) {
        courses[v.identifier] = v;
      }
    });
  } else {
    return null;
  }
  let gen = Math.random().toString(26);
  let r = gen.substring(gen.length - 2, gen.length);
  identifier = baseIdentifier + r.toUpperCase();
  for (; courses[identifier] && courses[identifier].id; ) {
    let gen = Math.random().toString(26);
    let r = gen.substring(gen.length - 2, gen.length);
    identifier = baseIdentifier + r.toUpperCase();
  }
  return identifier;
}

export function* getMerchantById(merchant_id) {
  const [error, response] = yield call(
    MerchantsService.getMerchantById,
    merchant_id
  );
  if (response && !error) {
    const tmp = response.merchants[0];
    return { ...(0 in tmp.users ? tmp.users[0] : {}), ...tmp };
  } else {
    return null;
  }
}

export function* createPackageCourse(tasksPackages, course, strings) {
  let tmpTaskByUID = {};
  let tmpKindByID = {};
  let tmpDSByState = {};
  let tmpPackages = [];
  let pickupID = null;
  const [errorKinds, responseKinds] = yield call(
    PackageService.getPackageKinds
  );
  const [errorDS, responseDS] = yield call(TasksService.getDeliveryStatus);
  if (errorKinds || errorDS) {
    showError("create_task_error", strings, null, errorKinds || errorDS);
    yield put(TasksActions.createCourseFailure());
    yield put(LoaderActions.loaded());
    return false;
  }
  tasksPackages.forEach((v) => (tmpTaskByUID[v.uid] = v));
  responseKinds.package_kinds.forEach((v) => {
    tmpKindByID[v.kind] = v.id;
  });
  responseDS.delivery_status.forEach((v) => {
    tmpDSByState[v.status] = v.id;
  });
  course.tasks.forEach((v) => {
    if (v.type === "collecte") {
      pickupID = v.id;
    } else if (
      tmpTaskByUID[v.uid].packages &&
      tmpTaskByUID[v.uid].packages.length > 0
    ) {
      tmpPackages = [
        ...tmpPackages,
        ...tmpTaskByUID[v.uid].packages.map((p) => {
          /*let packageWeight = get(p, "weight", null);
          if (packageWeight == null) {
            packageWeight = get(
              typeColis,
              [get(tmpPTByID, p.package_type_id), "weight"],
              null
            );
          }*/
          return {
            ...p,
            uid: uuidv4(),
            delivery_id: v.id,
            delivery_status_id: tmpDSByState["in_delivery"],
            kind_id: tmpKindByID[p.food],
            package_type_id: p.package_type_id,
            number: p.key,
            market_value: p.market_value,
            weight: get(p, "weight", null),
            volumn: get(p, "volumn", null),
          };
        }),
      ];
    }
  });

  tmpPackages = tmpPackages.map((p) => ({ ...p, pickup_id: pickupID }));
  if (tmpPackages.length > 0) {
    var [errorPackage, responsePackage] = yield call(
      PackageService.createPackage,
      { packages: tmpPackages }
    );
    if (responsePackage && !errorPackage)
      return responsePackage.insert_packages.returning;
    else return false;
  } else return tmpPackages;
}

export function firstCharUpper(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function displayPackage(
  item,
  course_id = null,
  packageTypes,
  packageKinds,
  strings,
  merchantGroupId = null,
  hideInfo = false
) {
  let kind = get(packageKinds, [item.kind_id, "kind"], null)
  let package_type = formatTypePackage(
      item.package_type_id,
      packageTypes,
      false
  )
  if (!kind) {
    kind = get(item, ["kind", "kind"], null)
  }
  if (!package_type) {
    package_type = get(item, ["package_type", "name"], null)
  }
  if (course_id) {
    return (
      <div className="div-display-package">
        <Tag className={"number-package"}>{item.number || item.key}</Tag>
        {!merchantGroupId && (
          <>
            {item.fragile && (
              <Tag className={"fragile-container-tag"}>
                <span className={"fragile-text-tag"}>
                  {strings("fragile").toUpperCase()}
                </span>
              </Tag>
            )}
            {kind && (
              <Tag className={"text-package"}>
                {dico[kind]}
              </Tag>
            )}
            {package_type && <Tag className={"text-package"}>{package_type}</Tag>}
            {item.weight && (
              <Tooltip title={strings("weight")}>
                <Tag className={"text-package"}>{item.weight} Kg</Tag>
              </Tooltip>
            )}
            {item.volumn && (
              <Tooltip title={strings("volumn")}>
                <Tag className={"text-package"}>{item.volumn} cm3</Tag>
              </Tooltip>
            )}
            {/*item.declared_value && (
              <Tooltip title={strings("market_value")}>
                <Tag className={"text-package"}>
                  {item.declared_value.toFixed(2)} {strings("money")}
                </Tag>
              </Tooltip>
            )*/}
            {item.uid && !hideInfo && (
              <Popover
                title={strings("uid_package")}
                content={
                  <>
                    <Tag>{item.uid}</Tag>
                  </>
                }
              >
                <Button
                  size="small"
                  shape={"circle"}
                  icon={<InfoOutlined />}
                />
              </Popover>
            )}
          </>
        )}
        {merchantGroupId && (
          <>
            {item.fragile && (
              <Tag className={"fragile-container-tag"}>
                <span className={"fragile-text-tag"}>
                  {strings("fragile").toUpperCase()}
                </span>
              </Tag>
            )}
            {item.food && (
              <Tag className={"text-package"}>{foodDico[item.food]}</Tag>
            )}
            {item.package_type_id && (
              <Tag className={"text-package"}>
                {formatTypePackage(
                  item.package_type_id,
                  packageTypes,
                  isHostTDP
                )}
              </Tag>
            )}
            {item.kind_id && packageKinds[item.kind_id] && (
              <Tag className={"text-package"}>
                {dico[packageKinds[item.kind_id].kind]}
              </Tag>
            )}
            {item.weight && (
              <Tooltip title={strings("weight")}>
                <Tag className={"text-package"}>
                  {
                    typeColis[
                      packageTypes[item.package_type_id]?.icon
                    ]?.weightTDP
                  }{" "}
                  Kg
                </Tag>
              </Tooltip>
            )}
            {item.volumn && (
              <Tooltip title={strings("volumn")}>
                <Tag className={"text-package"}>
                  {
                    typeColis[
                      packageTypes[item.package_type_id]?.icon
                    ]?.volumeTDP
                  }{" "}
                  cm3
                </Tag>
              </Tooltip>
            )}
            {/*item.declared_value && (
              <Tooltip title={strings("market_value")}>
                <Tag className={"text-package"}>
                  {item.declared_value.toFixed(2)} {strings("money")}
                </Tag>
              </Tooltip>
            )*/}
            {item.uid && !hideInfo && (
              <Popover
                title={strings("uid_package")}
                content={
                  <>
                    <Tag>{item.uid}</Tag>
                  </>
                }
              >
                <Button
                  size="small"
                  shape={"circle"}
                  icon={<InfoOutlined />}
                />
              </Popover>
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="div-display-package">
        <Tag className={"number-package"}>{item.key}</Tag>
        {item.fragile && (
          <Tag className={"fragile-container-tag align-self-center"}>
            <span className={"fragile-text-tag"}>
              {strings("fragile").toUpperCase()}
            </span>
          </Tag>
        )}
        {item.food && (
          <Tag className={"text-package"}>{foodDico[item.food]}</Tag>
        )}
        {item.package_type_id && (
          <Tag className={"text-package"}>
            {formatTypePackage(
              item.package_type_id,
              packageTypes,
              isHostTDP
            )}
          </Tag>
        )}
        {item.package_kind_id &&
          packageKinds[item.package_kind_id] && (
            <Tag className={"text-package"}>
              {dico[packageKinds[item.package_kind_id].kind]}
            </Tag>
          )}
        {item.weight && (
          <Tooltip title={strings("weight")}>
            <Tag className={"text-package"}>{item.weight} Kg</Tag>
          </Tooltip>
        )}
        {item.volumn && (
          <Tooltip title={strings("volumn")}>
            <Tag className={"text-package"}>{item.volumn} cm3</Tag>
          </Tooltip>
        )}
        {/*item.declared_value && (
          <Tooltip title={strings("market_value")}>
            <Tag className={"text-package"}>
              {item.declared_value.toFixed(2)} {strings("money")}
            </Tag>
          </Tooltip>
        )*/}
      </div>
    );
  }
}

export function* updatePackagesCourseDispatch(tasksPackages, course, strings) {
  let tmpTaskByUID = {};
  let tmpDSByState = {};
  let tmpPackages = [];
  const [errorDS, responseDS] = yield call(TasksService.getDeliveryStatus);
  if (errorDS) {
    return false;
  }

  course.tasks.forEach((v) => {
    tmpTaskByUID[v.uid] = v;
  });
  responseDS.delivery_status.forEach((v) => {
    tmpDSByState[v.status] = v.id;
  });

  for (let i = 0, l = tasksPackages.length; i < l; i++) {
    let tmpTask = tmpTaskByUID[tasksPackages[i].uid];
    if (tmpTask.packagesByPickupId && tmpTask.packagesByPickupId.length) {
      for (let a = 0, le = tmpTask.packagesByPickupId.length; a < le; a++) {
        const pdata = tmpTask.packagesByPickupId[a];
        const taskWithUid = tasksPackages[i].packagesByPickupId.filter(
          (d) => d.uid === pdata.uid
        )[0];
        if (!taskWithUid) {
          tmpPackages[pdata.id] = {
            ...(tmpPackages[pdata.id] || pdata),
            pickup_id: null,
          };
        }
      }
    }
    if (tmpTask.packagesByDeliveryId && tmpTask.packagesByDeliveryId.length) {
      for (let a = 0, le = tmpTask.packagesByDeliveryId.length; a < le; a++) {
        const pdata = tmpTask.packagesByDeliveryId[a];
        const taskWithUid = tasksPackages[i].packagesByDeliveryId.filter(
          (d) => d.uid === pdata.uid
        )[0];
        if (!taskWithUid) {
          tmpPackages[pdata.id] = {
            ...(tmpPackages[pdata.id] || pdata),
            delivery_id: null,
          };
        }
      }
    }
    if (
      tasksPackages[i].packagesByPickupId &&
      tasksPackages[i].packagesByPickupId.length
    ) {
      for (
        let a = 0, le = tasksPackages[i].packagesByPickupId.length;
        a < le;
        a++
      ) {
        const adata = tasksPackages[i].packagesByPickupId[a];
        let tmpP = {
          ...adata,
          ...(tmpPackages[adata.key] || {}),
          uid: adata.uid ? adata.uid : uuidv4(),
          pickup_id: tmpTaskByUID[tasksPackages[i].uid].id,
          kind_id: adata.kind_id,
          package_type_id: adata.package_type_id,
          number: adata.id ? adata.number : adata.key,
          market_value: adata.declared_value,
          weight: adata.weight,
          volumn: adata.volumn,
        };
        if (!adata.id) {
          tmpP = {
            ...tmpP,
            delivery_status_id: tmpDSByState["in_delivery"],
          };
        }
        tmpPackages[adata.key] = tmpP;
      }
    }
    if (
      tasksPackages[i].packagesByDeliveryId &&
      tasksPackages[i].packagesByDeliveryId.length
    ) {
      for (
        let a = 0, le = tasksPackages[i].packagesByDeliveryId.length;
        a < le;
        a++
      ) {
        const adata = tasksPackages[i].packagesByDeliveryId[a];
        let tmpP = {
          ...adata,
          ...(tmpPackages[adata.key] || {}),
          uid: adata.uid ? adata.uid : uuidv4(),
          delivery_id: tmpTaskByUID[tasksPackages[i].uid].id,
          kind_id: adata.kind_id,
          package_type_id: adata.package_type_id,
          number: adata.id ? adata.number : adata.key,
          market_value: adata.declared_value,
          weight: adata.weight,
          volumn: adata.volumn,
        };
        if (!adata.id) {
          tmpP = {
            ...tmpP,
            delivery_status_id: tmpDSByState["in_delivery"],
          };
        }
        tmpPackages[adata.key] = tmpP;
      }
    }
  }
  let deletePackage = tmpPackages.filter((v) => !v.pickup_id && !v.delivery_id);
  tmpPackages = tmpPackages.filter((v) => v.pickup_id || v.delivery_id);

  if (deletePackage && deletePackage.length) {
    var [errorPackageDelete] = yield call(PackageService.deletePackages, {
      packagesID: deletePackage.map((v) => v.id),
    });
    if (errorPackageDelete) {
      return false;
    }
  }

  if (tmpPackages.length > 0) {
    var [errorPackage, responsePackage] = yield call(
      PackageService.updatePackages,
      { packages: tmpPackages }
    );
    if (responsePackage && !errorPackage) return responsePackage;
    else return false;
  } else return tmpPackages;
}

export function* createPackagesCourseDispatch(tasksPackages, course, strings) {
  let tmpTaskByUID = {};
  let tmpDSByState = {};
  let tmpPackages = [];
  const [errorDS, responseDS] = yield call(TasksService.getDeliveryStatus);
  if (errorDS) {
    showError("create_task_error", strings);
    yield put(TasksActions.createFailure());
    yield put(LoaderActions.loaded());
    return false;
  }
  course.tasks.forEach((v) => (tmpTaskByUID[v.uid] = v));
  responseDS.delivery_status.forEach((v) => {
    tmpDSByState[v.status] = v.id;
  });
  tasksPackages.forEach((v) => {
    if (v.packagesByPickupId && v.packagesByPickupId.length) {
      v.packagesByPickupId.forEach((a) => {
        tmpPackages[a.key] = {
          ...a,
          ...(tmpPackages[a.key] || {}),
          uid: uuidv4(),
          pickup_id: tmpTaskByUID[v.uid].id,
          delivery_status_id: tmpDSByState["in_delivery"],
          kind_id: a.kind_id,
          package_type_id: a.package_type_id,
          number: a.number,
          market_value: a.declared_value,
          weight: a.weight,
          volumn: a.volumn,
        };
      });
    }
    if (v.packagesByDeliveryId && v.packagesByDeliveryId.length) {
      v.packagesByDeliveryId.forEach((a) => {
        tmpPackages[a.key] = {
          ...a,
          ...(tmpPackages[a.key] || {}),
          uid: uuidv4(),
          delivery_id: tmpTaskByUID[v.uid].id,
          delivery_status_id: tmpDSByState["in_delivery"],
          kind_id: a.kind_id,
          package_type_id: a.package_type_id,
          number: a.number,
          market_value: a.declared_value,
          weight: a.weight,
          volumn: a.volumn,
        };
      });
    }
  });
  if (tmpPackages.length > 0) {
    var [errorPackage, responsePackage] = yield call(
      PackageService.createPackage,
      { packages: tmpPackages }
    );
    if (responsePackage && !errorPackage)
      return responsePackage.insert_packages.returning;
    else return false;
  } else return tmpPackages;
}

export const printCompleteName = (merchant, user, strings) => {
  let res = "";
  if (merchant) {
    if (merchant.company && merchant.company.length > 0) {
      res += `[${merchant.company}] `;
    }
    if (user?.name && user.name.length > 0) {
      res += user.name;
    }
    return res;
  } else {
    return strings("any_contractor");
  }
};

export const refactoHourDelivery = (start, end) => {
  let startHour = start.get("hour");
  let tmpStart = moment(start);
  let tmpEnd = moment(end);
  if (startHour >= 0 && startHour <= 12) {
    tmpStart = tmpStart.set("hours", 14);
    tmpEnd = tmpEnd.set("hours", 18);
  } else if (startHour >= 14) {
    tmpStart = tmpStart.add(1, "days").set("hours", 8);
    tmpEnd = tmpEnd.add(1, "days").set("hours", 12);
  }
  if (tmpStart.day() === 0) {
    tmpStart = tmpStart.add(1, "days");
    tmpEnd = tmpEnd.add(1, "days");
  }
  return { start: tmpStart, end: tmpEnd };
};

export const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
      <Tag
          color={value}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3, borderColor: "#0A5447" }}
      >
        <div className={"tag-render-type"}>{label}</div>
      </Tag>
  );
};

function* errorHandle(type, strings) {
  switch(type) {
    case "recipient": {
      showError("recipient_update_error", strings);
      yield put(RecipientsAction.recipientsUpdateFailure());
      yield put(LoaderActions.loaded());
      break;
    }
    case "merchant": {
      showError("merchant_update_error", strings);
      yield put(MerchantsActions.merchantsUpdateFailure());
      yield put(LoaderActions.loaded());
      break;
    }
    default:
      break;
  }
}

export function* createOrUpdateDefaultPackage(values, data, type) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const tmpKindByID = {};
  const [errorKinds, responseKinds] = yield call(
      PackageService.getPackageKinds
  );

  if (errorKinds) {
    errorHandle(type, strings)
    return null
  }
  responseKinds.package_kinds.forEach((v) => {
    tmpKindByID[v.kind] = v.id;
  });
  const requests = []

  if (values?.defaultPackage?.length > 0) {
    try {
      for (let i = 0, l = values.defaultPackage.length; i < l; i++) {
        const pack = values.defaultPackage[i].package
        let kind_id = get(pack, "kind_id", null);
        if (pack?.food) {
          kind_id = tmpKindByID[pack.food];
        }
        if (pack?.id) {
          requests.push({isCreate: false, data: {
              package: {
                ..._.pick(pack, [
                  "weight",
                  "fragile",
                  "kind_id",
                  "package_type_id",
                  "declared_value",
                ]),
                kind_id,
              },
              id: pack.id,
            }})
        } else {
          let filter = {}
          switch (type) {
            case "merchant":
              filter = {...filter, merchant_id: values.id}
              break;
            case "recipient":
              filter = {...filter, recipient_id: values.id}
              break;
            default:
              break;
          }
          requests.push({isCreate: true, data: {
              defaultPackage: {
                ...filter,
                package: {
                  data: {
                    ..._.pick(pack, [
                      "weight",
                      "fragile",
                      "kind_id",
                      "package_type_id",
                      "declared_value",
                    ]),
                    delivery_status_id: 1,
                    is_default: true,
                    kind_id,
                  },
                },
              },
            }})
        }
      }
      if (requests && requests.length > 0) {
        const responses = yield all(
            requests.map(({isCreate, data}) => {
              if (isCreate) {
                return call(PackageService.createDefaultPackage, data)
              } else {
                return call(PackageService.updatePackage, data)
              }
            })
        );
        const results = responses.map((r) => {
          if (r[0]) {
            return "ERROR"
          }
          if (r[1].insert_default_package_one) {
            return r[1].insert_default_package_one
          } else if (r[1].update_packages_by_pk) {
            return {
                ...values.defaultPackage.find((d) => d.package.id === r[1].update_packages_by_pk.id),
                package: r[1].update_packages_by_pk,
            }
          } else {
            return "ERROR"
          }
        })
        if (results.includes("ERROR")) {
          showError("recipient_update_error", strings);
          yield put(RecipientsAction.recipientsUpdateFailure());
          yield put(LoaderActions.loaded());
          return null
        }
        return results
      }
    } catch (err) {
      console.log("CATCH ERROR", err);
    }
  }
}

export const drawPackage = ({ item, strings, packageType, packageKinds, showNumber = false }) => {
  let food = get(packageKinds, [item.kind_id, "kind"], null)
  if (food || item.food) {
    food = foodDico[food || item.food]
  }
  return (
    <div>
      {showNumber && <Tag className={"number-package-small"}>{get(item, "number", 0)}</Tag>}
      {item.fragile && (
        <Tag className={"fragile-container-tag"}>
          <span className={"fragile-text-tag"}>
            {strings("fragile").toUpperCase()}
          </span>
        </Tag>
      )}
      {food && <Tag className={"text-package"}>{food}</Tag>}
      {item.package_type_id && (
        <Tag className={"text-package"}>
          {formatTypePackage(item.package_type_id, packageType, isHostTDP)}
        </Tag>
      )}
      {/*item.declared_value && (
            <Tooltip title={strings("market_value")}>
              <Tag className={"text-package"}>
                {item.declared_value.toFixed(2)} {strings("money")}
              </Tag>
            </Tooltip>
          )*/}
      {item.weight && (
        <Tooltip title={strings("weight")}>
          <Tag className={"text-package"}>
            {item.weight}
            {strings("kilo")}
          </Tag>
        </Tooltip>
      )}
    </div>
  );
};

export const getColorType = (type) => {
  switch (type) {
    case "livraison/collecte":
      return "#750303";
    case "livraison":
      return "#57ab27";
    case "collecte":
      return "#63226d";
    default:
      return "green";
  }
};

