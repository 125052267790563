import { createSelector } from 'reselect'
import moment from "moment-timezone";

export const selectMerchants = createSelector(
    (state) => Object.values(state.merchants.list),
    (merchants) => {
        return merchants.map((v) => {return ({key: v.id, user_id: (0 in v.users ? (v.users[0]?.id ?? "") : ""), code: `m-${v.id}`, ...v, ...(0 in v.users ? v.users[0] : {}), phone: (0 in v.users ? (v.users[0]?.phone_number ?? "") : ""), id: v.id})})
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
    }
)

export const selectCurrentMerchant = createSelector(
    (state) => state.merchants.currentMerchant,
    (merchant) => {
        const tmp = merchant
        if (merchant?.package_types?.length > 0) {
            tmp.package_types_ids = merchant.package_types.map((v) => v.id)
            tmp.package_types_ids_by_merchant = merchant.package_types.filter((v) => v.merchant_id).map((v) => v.id)
        }
        return tmp
    }
)

export const selectStatisticsMerchant = createSelector(
    (state) => state.merchants.statisticsMerchant,
    (statistics) => {
        return statistics
    }
)

export const selectSearchMerchants = createSelector(
    (state) => Object.values(state.merchants.searchMerchants),
    (merchants) => {
        return merchants.map((v) => {return ({key: v.id, user_id: (0 in v.users ? (v.users[0]?.id ?? "") : ""), code: `m-${v.id}`, ...v, ...(0 in v.users ? v.users[0] : {}), phone: (0 in v.users ? (v.users[0]?.phone_number ?? "") : ""), id: v.id})})
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
    }
)

export const selectMoreMerchants = createSelector(
    (state) => Object.values(state.merchants.moreMerchants),
    (merchants) => {
        return merchants.map((v) => {return ({key: v.id, user_id: (0 in v.users ? (v.users[0]?.id ?? "") : ""), code: `m-${v.id}`, ...v, ...(0 in v.users ? v.users[0] : {}), phone: (0 in v.users ? (v.users[0]?.phone_number ?? "") : ""), id: v.id})})
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
    }
)

export const selectMerchantsToInvoice = createSelector(
    (state) => Object.values(state.merchants.merchantsToInvoice),
    (merchants) => {
        return merchants.map((v) => ({...v, key: v.id, ...(0 in v.users ? v.users[0] : {})}))
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
    }
)
