import {authenticatedQuery} from "./middleware";

/**
 * GRAPHQL QUERIES
 */
const queries = {
    getAreas: `area_team {
        id
        created_at
        updated_at
        coordinates
        name
        team_id
    }`,
}
/**
 * GRAPHQL MUTATION
 */

const services = {
    getAreas: () => authenticatedQuery(queries.getAreas()),
}
export default services;