import {authenticatedMutation, authenticatedQuery} from "./middleware";

/**
 * GRAPHQL QUERIES
 */
const queries = {
  vehicleTypes: (team_id) => `vehicle_types(where: {team_id: {_eq: ${team_id}}}) {
        id
        created_at
        updated_at
        name
        volumn
        weight
    }`,
}
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  createVehicleType: (values) => `mutation {
    insert_vehicle_types(objects: {
      name: ${values.name ? `"${values.name}"` : `null`},
      weight: ${values.weight || null},
      volumn: ${values.volumn || null},
      team_id: ${values.team_id || null},
    }) {
      returning {
        id
        created_at
        updated_at
        name
        volumn
        weight
      }
    }
  }`,
  modifyVehicleType: (values) => `mutation {
    update_vehicle_types(where: {id: {_eq: ${values.id}}}, _set: {
      name: ${values.name ? `"${values.name}"` : `null`},
      weight: ${values.weight || null},
      volumn: ${values.volumn || null},
      team_id: ${values.team_id || null},
    }) {
      returning {
        id
        created_at
        updated_at
        name
        volumn
        weight
      }
    }
  }`,
  deleteVehicleType: (id) => `mutation {
    delete_vehicle_types(where: {id: {_eq: ${id}}}) {
      returning {
        id
      }
    }
  }`
}
const services = {
  getVehicleTypes: ({team_id}) => authenticatedQuery(queries.vehicleTypes(team_id)),
  createVehicleType: (values) => authenticatedMutation(mutations.createVehicleType(values)),
  modifyVehicleType: (values) => authenticatedMutation(mutations.modifyVehicleType(values)),
  deleteVehicleType: (id) => authenticatedMutation(mutations.deleteVehicleType(id)),
}

export default services