import { takeLatest, put, call } from "redux-saga/effects";
import { UserService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as UserAction } from "./actions";
import { select } from "redux-saga/effects";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import { getBase64FromUrl } from "../../utils/file-tools";
import moment from "moment-timezone";

function* getUsersRequest() {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  //yield put(LoaderActions.loading())
  const [error, result] = yield call(UserService.users);
  if (result) {
    yield put(UserAction.getUsersSuccess(result.users));
    //yield put(LoaderActions.loaded())
  } else if (error) {
    showError("get_users_error", strings, null, error);
    yield put(UserAction.getUsersFailure());
    //yield put(LoaderActions.loaded())
  }
}

function* getCurrentUserRequest({ id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(UserService.user, id);
  if (result) {
    yield put(UserAction.getCurrentUserSuccess({user: result.users[0]}));
  } else if (error) {
    showError("get_users_error", strings, null, error);
    yield put(UserAction.getCurrentUserFailure());
  }
  yield put(LoaderActions.loaded());
}

function* updateUserRequest({ values }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(UserService.updateUser, values);
  if (result) {
    showSuccess("update_user_success", strings);
    yield put(
      UserAction.updateUserSuccess(
        result.update_users.returning[0],
        result.update_merchants ? result.update_merchants.returning[0] : null,
        result.update_dispatchers
          ? result.update_dispatchers.returning[0]
          : null
      )
    );
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("update_user_error", strings, null, error);
    yield put(UserAction.updateUserFailure());
    yield put(LoaderActions.loaded());
  }
}

function* createImageRequest({ file }) {
  const { current_user } = yield select((state) => state.user);
  yield put(LoaderActions.loading());
  const form = new FormData();
  form.append("file", file);
  form.append("user", JSON.stringify(current_user));
  const [error, response] = yield call(UserService.uploadProfile, form);
  if (response && response.data) {
    let base64 = null;
    yield getBase64FromUrl(
      process.env.REACT_APP_FILE_API_URL + "/pictures/" + response.data.name
    ).then((val) => {
      base64 = val;
    });
    yield put(UserAction.createImageSuccess(base64));
    yield put(LoaderActions.loaded());
  } else if (error) {
    //showError()
    yield put(LoaderActions.loaded());
  }
}

function* clearImageRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(UserService.clearProfilePicture, id);
  if (response && response.data) {
    yield put(UserAction.createImageSuccess(null));
    yield put(LoaderActions.loaded());
  } else if (error) {
    //showError()
    yield put(LoaderActions.loaded());
  }
}

function* getAccessTokenRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(UserService.getAccessToken, id);
  if (response) {
    let token = response.access_tokens
      .map((v) => {
        return {
          ...v,
          created_at: moment(v.created_at, "YYYY/MM/DDTHH:mm").unix(),
        };
      })
      .sort((a, b) => b.created_at > a.created_at)[0].value;
    yield put(UserAction.getAccessTokenSuccess(token));
    yield put(LoaderActions.loaded());
  } else if (error) {
    yield put(UserAction.getAccessTokenFailure(null));
    yield put(LoaderActions.loaded());
  }
}

function* searchReceiverRequest({ value }) {
  const locale = yield select((state) => state.locale);
  const { team_id } = yield select((state) => state.auth);
  const strings = getTranslate(locale);
  if (!value || value.length === 0) {
    yield put(UserAction.searchReceiverSuccess([]));
    return;
  }
  const [error, result] = yield call(UserService.search, { value, team_id });
  if (result) {
    const receiverList = [];
    result.view_recipients.forEach((recipient) => {
      receiverList.push({ ...recipient, code: `r-${recipient.id}` });
    });
    result.view_users.forEach((merchant) => {
      receiverList.push({
        ...merchant,
        id: merchant.merchant_id,
        key: merchant.merchant_id,
        user_id: merchant.id,
        users: [{ id: merchant.id }],
        code: `m-${merchant.merchant_id}`,
      });
    });
    yield put(UserAction.searchReceiverSuccess(receiverList));
  } else {
    if (!error.loginError) {
      showError("search_user_error", strings, null, error);
    }
    yield put(UserAction.searchReceiverFailure());
  }
}

const sagas = [
  takeLatest(types.GET_USERS_REQUEST, getUsersRequest),
  takeLatest(types.GET_CURRENT_USER_REQUEST, getCurrentUserRequest),
  takeLatest(types.UPDATE_USER_REQUEST, updateUserRequest),
  takeLatest(types.CREATE_IMAGE_REQUEST, createImageRequest),
  takeLatest(types.CLEAR_IMAGE_REQUEST, clearImageRequest),
  takeLatest(types.GET_ACCESS_TOKEN_REQUEST, getAccessTokenRequest),
  takeLatest(types.SEARCH_RECEIVER_REQUEST, searchReceiverRequest),
];

export default sagas;
