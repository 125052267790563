import moment from 'moment-timezone';
import { notification } from 'antd';
import plageHours from './plage-hours';
import TypeColis from './typeColis';
import {
	stepsHome,
	stepsCrm,
	collectSteps,
	recapSteps,
	deliverySteps,
	tourDispatcher,
	tourCRM,
	tourRecipients,
	tourInvoice,
	tourTarification,
	tourPackageType,
	tourNewCourse,
} from './steps';

export const getBase64FromUrl = async (url) => {
	const data = await fetch(url);
	const blob = await data.blob();
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			const base64data = reader.result;
			resolve(base64data);
		};
	});
};

export const receiverExist = (data, list) => {
	const tmp = list.filter((v) => {
		if (!v) {
			return false;
		}
		if (
			v.address &&
			data.address &&
			v.address.value
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase() ===
			data.address.value
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase() &&
			(((!v.name || v.name.length === 0) && (!data.name || data.name.length === 0)) ||
				v.name === data.name) &&
			(((!v.phone_number || v.phone_number.length === 0) &&
				(!data.phone || data.phone.length === 0)) ||
				v.phone_number === data.phone) &&
			(((!v.company || v.company.length === 0) &&
				(!data.company || data.company.length === 0)) ||
				v.company === data.company)
		) {
			return true;
		} else {
			return false;
		}
	});
	if (
		data &&
		data.address &&
		data.address.value &&
		((data.name && data.name.length > 0) || (data.company && data.company.length > 0)) &&
		tmp.length > 0
	) {
		return tmp;
	} else {
		return [];
	}
};

export const isJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

const normalizeString = (str) =>
	str
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');

export const filterOptionReceiver = (v, o) => {
	const value = v
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');
	const arrayData = o.children
		.filter((a) => a)
		.map((v) => {
			return v.props.children;
		});
	return (
		(arrayData[0] && normalizeString(arrayData[0]).includes(value)) ||
		(arrayData[1] && arrayData[1].includes(v)) ||
		(arrayData[2] && normalizeString(arrayData[2]).includes(value)) ||
		(arrayData[3] && normalizeString(arrayData[3]).includes(value))
	);
};

export const getById = (id, list) => {
	if (!id) return null;
	const tmp = list.filter((v) => v && v.id === id);
	return tmp.length > 0 ? tmp[0] : null;
};

export const checkDate = (row, strings) => {
	if (row.date && (!row.start || !row.end)) {
		notification.error({
			message: strings('error_date'),
			description: strings('hour_start_end_required'),
		});
		return true;
	}
	return false;
};

export const checkEmailRequired = (email, strings) => {
	if (!email || email.length === 0) {
		notification.error({
			message: strings('email_required'),
			description: strings('email_required_to_mail'),
		});
		return true;
	}
	return false;
};

export const checkEmailOrPhoneRequired = ({ email, phone_number }, strings) => {
	if ((!email || email.length === 0) && (!phone_number || phone_number.length === 0)) {
		notification.error({
			message: strings('email_or_phone_required'),
			description: strings('email_or_phone_required_to_mail'),
		});
		return true;
	}
	return false;
};

export const updateDataForPackage = (row, packages) => {
	if (row.packages_pickup || row.packages_delivery) {
		switch (row.type) {
			case 'collecte':
				row.packagesByPickupId = row.packages_pickup.map((v) => ({
					...packages.filter((p) => p.key === v)[0],
				}));
				break;
			case 'livraison':
				row.packagesByDeliveryId = row.packages_delivery.map((v) => ({
					...packages.filter((p) => p.key === v)[0],
				}));
				break;
			case 'livraison/collecte':
				row.packagesByDeliveryId = row.packages_delivery.map((v) => ({
					...packages.filter((p) => p.key === v)[0],
				}));
				row.packagesByPickupId = row.packages_pickup.map((v) => ({
					...packages.filter((p) => p.key === v)[0],
				}));
				break;
			default:
				break;
		}
	}
	return row;
};

export function addDataWithCode(value, list) {
	let tmp = {};
	let receiver = list.filter((v) => v.code === value)[0];
	if (value) {
		const splt = value.split('-');
		if (splt[0] === 'r') {
			tmp.recipient_id = parseInt(splt[1]);
			tmp.hub_id = null;
			tmp.merchant_id = null;
		} else if (splt[0] === 'm') {
			tmp.merchant_id = parseInt(splt[1]);
			tmp.hub_id = null;
			tmp.recipient_id = null;
		}
		tmp.company = receiver.company || null;
		tmp.name = receiver.name || null;
		tmp.phone = receiver.phone || null;
		tmp.phone_number = receiver.phone_number || null;
		tmp.address = receiver.address || null;
		tmp.email = receiver.email || null;
	}
	return tmp;
}

export const getByCode = (code, list) => {
	if (!code) return null;
	const tmp = list.filter((v) => v && v.code === code);
	return tmp.length > 0 ? tmp[0] : null;
};

export const calcRequestTime = (title, start_date) => {
	if (start_date) {
		var duration = moment.duration(moment().diff(start_date));
		console.log('duration: ' + title, duration.milliseconds());
	}
};

export const rowOwnBySupervisor = (course, current_user_id) =>
	!course?.merchant?.users[0]?.team_id && course.created_by !== current_user_id;

export const checkPhoneFormat =
	(strings) =>
		({ getFieldValue }) => ({
			validator(rule, value) {
				var regexp = new RegExp('^0[6-7]{1}(([0-9]{2}){4})|(([0-9]{2}){4})|((-[0-9]{2}){4})$');
				if (!value || value.length === 0) {
					return Promise.resolve();
				} else if (!regexp.test(value)) {
					return Promise.reject(strings('format_not_correct'));
				} else if (value[0] === '+' && value.length !== 12) {
					return Promise.reject(strings('phone_must_be_composed_of_12_long'));
				} else if (value[0] !== '+' && value.length !== 10) {
					return Promise.reject(strings('phone_must_be_10_digits_long'));
				} else {
					return Promise.resolve();
				}
			},
		});

export const checkEmailFormat = (strings) => () => ({
	validator(_rule, value) {
		// eslint-disable-next-line
		const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

		if (emailRegex.test(value)) {
			console.log('emailRegex.test(value)', emailRegex.test(value));
			return Promise.resolve();
		} else {
			console.log('emailRegex.test(value)', emailRegex.test(value));
			return Promise.reject(strings('format_not_correct'));
		}
	},
});

export const checkSamePassword = (strings, form) => () => ({
	async validator(_rule, value) {
		const row = await form.getFieldsValue();

		if (!value || value.length === 0) {
			return Promise.reject(strings('password_required'));
		} else if (value !== row.password) {
			return Promise.reject(strings('password_different'));
		} else {
			return Promise.resolve();
		}
	},
});

export const formatPlageHour = (value, strings) => {
	if (!value) return null;
	if (typeof value === 'number') {
		return plageHours[value].text;
	} else if (typeof value === 'string') {
		const tmp = JSON.parse(value);
		return `${strings('between')} ${tmp[0]} ${strings('and')} ${tmp[1]}`;
	}
	return null;
};

export const formatPlageHourData = (value) => {
	const tmp = JSON.parse(value);
	const splitStart = tmp[0].split(':');
	const splitEnd = tmp[1].split(':');
	return [splitStart, splitEnd];
};

export const formatTypePackage = (value, packageTypes, isHostTDP) => {
	if (!packageTypes || packageTypes.length === 0) return null;
	const packageData = packageTypes[value];
	if (isHostTDP) {
		return TypeColis[packageData.icon].nameTDP;
	} else {
		return packageData?.name || null;
	}
};

export function displaySteps(routerPath, activeCourse) {
	switch (routerPath) {
		case '/':
			return stepsHome;
		case '/courseMerchant':
			if (activeCourse === 0) {
				return collectSteps;
			} else if (activeCourse === 1) {
				return deliverySteps;
			} else {
				return recapSteps;
			}
		case '/crmShop':
			return stepsCrm;

		case '/dispatcher':
			return tourDispatcher;
		case '/crm':
			return tourCRM;
		case '/recipients':
			return tourRecipients;
		case '/invoice':
			return tourInvoice;
		case '/pricing_grid':
			return tourTarification;
		case '/packageType':
			return tourPackageType;
		case '/course':
			return tourNewCourse;

		default:
			return null;
	}
}
