import React, { Fragment }  from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux'
import {ApolloProvider} from '@apollo/react-hooks';
import { Router, store } from './core'
import './assets/fonts/style.css'
import './assets/style.css'
import './assets/text.css'
import { GlobalLoader } from './ui/components'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import client from './utils/apolloClient';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// import Tour from './ui/components/sideMenu/TourProvider';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Adjust this value in production if there is too much logs
    tracesSampleRate: 1.0,
  });

  Sentry.configureScope(scope => {
    scope.setUser({
      team_id: store.getState()?.auth?.team_id,
      email: store.getState()?.auth?.email
    })
  });
}

moment.tz.setDefault("Europe/France")

ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store} context={ReactReduxContext}>
        <Fragment>
          <GlobalLoader>
            {/* <Tour> */}
              <Router context={ReactReduxContext} />
            {/* </Tour> */}
          </GlobalLoader>
        </Fragment>
      </Provider>
    </ApolloProvider>
 ,
  document.getElementById("root")
);
