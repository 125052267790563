import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
  requestSuccess: 0
};

const getCrmShopSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
      tmp[p.id] = p
  })
  return {...state, list: tmp, requestSuccess: 1, loading: false};
};

const createCrmShopSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp, requestSuccess: 1, loading: false};
};

const updateCrmShopSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp, requestSuccess: 1, loading: false};
};

const deleteCrmShopSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  delete tmp[data.id]
  return {...state, list: tmp, loading: false};
};

const getFailed = (state = initialState) => {
  return {...state};
};

const resetCrmShopReduc = (state = initialState) => {
  return {...state, requestSuccess: 0, loading: false}
}

const crmShopFailed = (state = initialState) => {
  return {...state, requestSuccess: 2, loading: false};
};

export default createReducer(initialState, {
  [types.GET_CRM_SHOP_FAILURE]: crmShopFailed,
  [types.GET_CRM_SHOP_SUCCESS]: getCrmShopSuccess,
  [types.CREATE_CRM_SHOP_FAILURE]: crmShopFailed,
  [types.CREATE_CRM_SHOP_SUCCESS]: createCrmShopSuccess,
  [types.UPDATE_CRM_SHOP_FAILURE]: crmShopFailed,
  [types.UPDATE_CRM_SHOP_SUCCESS]: updateCrmShopSuccess,
  [types.DELETE_CRM_SHOP_FAILURE]: getFailed,
  [types.DELETE_CRM_SHOP_SUCCESS]: deleteCrmShopSuccess,
  [types.RESET_CRM_SHOP_REDUC]: resetCrmShopReduc,
});
