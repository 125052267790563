import { takeLatest, put, call, select } from 'redux-saga/effects';
import { HubsService } from '../../services';
import { LoaderActions } from '../loader';
import { types, default as HubsActions } from './actions';
import { showSuccess, showError} from  '../../utils/notifications-helper'
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import {default as AuthActions} from "../auth/actions";
import moment from "moment-timezone"

function* createHubRequest({values, callback}) {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  yield put(LoaderActions.loading())

  const [error, result] = yield call(HubsService.createHub, values)
  if (result) {
    showSuccess("hub_create_success", strings)
    yield put(HubsActions.createHubSuccess(result.insert_hubs.returning[0]))
    if (callback) {callback()}
    yield put(push('/hub/' + result.insert_hubs.returning[0].id))
    yield put(LoaderActions.loaded())
  } else {
    showError("hub_create_error", strings, null, error)
    yield put(HubsActions.createHubFailure())
    yield put(LoaderActions.loaded())
  }
  yield put(LoaderActions.loaded())
}

function* deleteHubRequest({values}) {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  yield put(LoaderActions.loading())
  let mutation = `mutation {
    delete_hub_users(where: {hub_id: {_eq: ${values.key}}}) {
      returning {
        hub_id
      }
    }
    delete_users(where: {hub_users: {hub_id: {_eq: ${values.key}}}}) {
      returning {
        id
      }
    }
    update_hubs(where: {id: {_eq: ${values.key}}}, _set: {
      deleted_at: "${moment().format()}"
    }) {
      returning {
        id
      }
    }
  }`
  const [error, result] = yield call(HubsService.deleteHub, mutation)
  if (result) {
    showSuccess("hub_delete_success", strings)
    yield put(HubsActions.deleteHubSuccess(result.delete_hubs.returning[0]))
    yield put(LoaderActions.loaded())
  } else {
    showError("hub_delete_error", strings, null, error)
    yield put(HubsActions.deleteHubFailure())
    yield put(LoaderActions.loaded())
  }
  yield put(LoaderActions.loaded())
}

function* updateHubRequest({values}) {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  yield put(LoaderActions.loading())
  let tmp = {
    ...values,
    address: values.address || null,
    name: values.name || "",
  }

  const [error, result] = yield call(HubsService.updateHub, tmp)
  if (result) {
    showSuccess("hub_update_success", strings)
    yield put(HubsActions.updateHubSuccess(result.update_hubs.returning[0]))
    yield put(LoaderActions.loaded())
  } else {
    showError("hub_update_error", strings, null, error)
    yield put(HubsActions.updateHubFailure())
    yield put(LoaderActions.loaded())
  }
  yield put(LoaderActions.loaded())
}

function* getHubsRequest() {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(HubsService.getHubs)
  if (result) {
    yield put(HubsActions.getHubsSuccess(result.hubs))
    yield put(LoaderActions.loaded())
  } else {
    if (!error.loginError) {
      showError("get_hubs_error", strings, null, error)
    }
    yield put(HubsActions.getHubsFailure())
    yield put(LoaderActions.loaded())
  }
}

function* getHubRequest({id}) {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  yield put(LoaderActions.loading())
  const [error, result] = yield call(HubsService.getHub, id)
  if (result) {
    yield put(HubsActions.getHubSuccess(result.hubs[0]))
    yield put(LoaderActions.loaded())
  } else {
    if (!error.loginError) {
      showError("get_hub_error", strings, null, error)
    }
    yield put(HubsActions.getHubFailure())
    yield put(LoaderActions.loaded())
  }
}

function* createHubUserRequest({values}) {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  yield put(LoaderActions.loading())

  const [error, result] = yield call(HubsService.createHubUser, values)
  if (result) {
    showSuccess("hub_user_create_success", strings)
    delete result.data.user.password
    yield put(HubsActions.createHubUserSuccess(result.data))
    yield put(LoaderActions.loaded())
  } else {
    if (error.response.data.errors.constraint === 'users_email_unique') {
      showError("email_already_exist", strings)
    } else {
      showError("hub_user_create_error", strings, null, error)
    }
    yield put(HubsActions.createHubUserFailure())
    yield put(LoaderActions.loaded())
  }
  yield put(LoaderActions.loaded())
}

function* deleteHubUserRequest({id}) {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  yield put(LoaderActions.loading())
  let mutation = `mutation {
    delete_hub_users(where: {user_id: {_eq: ${id}}}) {
      returning {
        hub_id
        user_id
      }
    }
    delete_users(where: {id: {_eq: ${id}}}) {
      returning {
        id
      }
    }
  }`
  const [error, result] = yield call(HubsService.deleteHubUser, mutation)
  if (result) {
    showSuccess("hub_user_delete_success", strings)
    yield put(HubsActions.deleteHubUserSuccess(result.delete_hub_users.returning[0]))
    yield put(LoaderActions.loaded())
  } else {
    showError("hub_user_delete_error", strings, null, error)
    yield put(HubsActions.deleteHubUserFailure())
    yield put(LoaderActions.loaded())
  }
  yield put(LoaderActions.loaded())
}

function* updateHubUserRequest({values}) {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  yield put(LoaderActions.loading())

  let tmp = {
    ...values,
    address: values.address || null,
    name: values.name || "",
  }
  if (values.password && values.password.length > 0) {
    let data = {
      user: values.user,
      password: values.password
    }
    yield put(AuthActions.updatePassword(data))
  }

  const [error, result] = yield call(HubsService.updateHubUser, tmp)
  if (result) {
    showSuccess("hub_user_update_success", strings)
    yield put(HubsActions.updateHubUserSuccess(result.update_hub_users.returning[0]))
    yield put(LoaderActions.loaded())
  } else {
    if (error.response.data.errors.constraint === 'users_email_unique') {
      showError("email_already_exist", strings)
    } else {
      showError("hub_user_update_error", strings, null, error)
    }
    yield put(HubsActions.updateHubUserFailure())
    yield put(LoaderActions.loaded())
  }
  yield put(LoaderActions.loaded())
}

function* getPackagesKindsRequest() {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(HubsService.getPackagesKinds)
  if (result) {
    yield put(HubsActions.getPackagesKindsSuccess(result.package_kinds))
    //yield put(LoaderActions.loaded())
  } else {
    if (!error.loginError) {
      showError("get_packages_kinds_error", strings, null, error)
    }
    yield put(HubsActions.getPackagesKindsFailure())
    //yield put(LoaderActions.loaded())
  }
}

const sagas = [
  takeLatest(types.CREATE_HUB_REQUEST, createHubRequest),
  takeLatest(types.DELETE_HUB_REQUEST, deleteHubRequest),
  takeLatest(types.UPDATE_HUB_REQUEST, updateHubRequest),
  takeLatest(types.CREATE_HUB_USER_REQUEST, createHubUserRequest),
  takeLatest(types.DELETE_HUB_USER_REQUEST, deleteHubUserRequest),
  takeLatest(types.UPDATE_HUB_USER_REQUEST, updateHubUserRequest),
  takeLatest(types.GET_HUBS_REQUEST, getHubsRequest),
  takeLatest(types.GET_HUB_REQUEST, getHubRequest),
  takeLatest(types.GET_PACKAGES_KINDS_REQUEST, getPackagesKindsRequest),
];

export default sagas
