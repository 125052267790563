import {gql} from "apollo-boost";
import moment from "moment-timezone";
import {authenticatedMutation, authenticatedQuery} from "./middleware";

const RETURNING_DEFAULT_PACKAGE = `
  merchant_id
  recipient_id
  package_id
  package {
    id
    fragile
    is_default
    kind_id
    declared_value
    package_type_id
    volumn
    weight
    number
  }
`

const RETURNING_PACKAGE = `
    id
    pickup_id
    delivery_id
    uid
    weight
    declared_value
    number
    volumn
    fragile
    kind_id
    package_type_id
`

const RETURNING_PACKAGE_TYPE = `
    id
    created_at
    updated_at
    name
    team_id
    weight
    volumn
    desc
    icon
    icon_fa
    merchant_id
`
/**
 * GRAPHQL QUERIES
 */
const queries = {
    getPackageKinds: `package_kinds {
      id
      kind
    }`,
    packageTypesByTeamId: gql`query package_types(
    $where: package_types_bool_exp!
  ) {
      package_types(where: $where) {
        ${RETURNING_PACKAGE_TYPE}
      }
    }
  `,
    packageTypesByMerchantId: gql`query package_types(
    $where: package_types_bool_exp!
  ) {
      package_types(where: $where) {
        ${RETURNING_PACKAGE_TYPE}
      }
    }
  `,
    packageTypesByRecipientId: gql`query package_types(
    $id: Int!
  ) {
      package_types(where: { _or: [{package_type_receivers: {receiver_recipient_id: { _eq: $id } }}, {icon: {_eq: "other"}}]}) {
        ${RETURNING_PACKAGE_TYPE}
      }
    }
  `,
    packageTypesTeam: gql`query package_types(
    $team_id: Int!
  ) {
      package_types(where: { team_id: { _eq: $team_id } }) {
        ${RETURNING_PACKAGE_TYPE}
      }
    }
  `
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
    createPackageType: gql`
    mutation insert_package_types_one(
      $packageType: package_types_insert_input!
    ) {
      insert_package_types_one(object: $packageType) {
        ${RETURNING_PACKAGE_TYPE}
      }
    }
  `,
    updatePackageType: gql`
    mutation update_package_types_by_pk($id: Int!, $packageType: package_types_set_input!) {
      update_package_types_by_pk(pk_columns: { id: $id }, _set: $packageType) {
        ${RETURNING_PACKAGE_TYPE}
      }
    }
  `,
    updatePackageTypesRecipient: gql`
    mutation update_package_type_receiver($recipientId: Int!, $packages: [package_type_receiver_insert_input!]!) {
      delete_package_type_receiver(where: { receiver_recipient_id: {_eq: $recipientId }}) {
        returning {
          package_type_id
        }
      }
      insert_package_type_receiver(objects: $packages) {
        returning {
          package_type {
            id
            icon_fa
            icon
            desc
            name
            volumn
            weight
          }
          receiver_recipient_id
          package_type_id
          receiver_merchant_id
        }
      }
    }
  `,
    deletePackageType: gql`
    mutation update_package_types($ids: [Int!], $packageType: package_types_set_input!) {
      update_package_types(where: { id: {_in: $ids} }, _set: $packageType) {
        returning {
          id
        }
      }
    }
  `,
    deleteDefaultPackage: gql`
    mutation delete_default_package($packageId: Int!, $where: default_package_bool_exp!) {
      delete_default_package(where: $where) {
        returning {
          ${RETURNING_DEFAULT_PACKAGE}
        }
      }
      delete_packages(where: { id: {_eq: $packageId}}) {
        returning {
          id
        }
      }
    }
  `,
    createDefaultPackage: gql`
    mutation insert_default_package_one(
      $defaultPackage: default_package_insert_input!
    ) {
      insert_default_package_one(object: $defaultPackage) {
        ${RETURNING_DEFAULT_PACKAGE}
      }
    }
  `,
    createPackage: (packages) => `mutation {
    insert_packages(objects: [${packages
        .map(
            (p) => `{
      pickup_id: ${p.pickup_id || null},
      delivery_id: ${p.delivery_id || null},
      delivery_status_id: ${p.delivery_status_id || null},
      package_type_id: ${p.package_type_id || null},
      uid: "${p.uid}",
      number: ${p.number || null},
      declared_value: ${p.declared_value || null},
      weight: ${p.weight ? `"${p.weight}"` : null},
      volumn: ${p.volumn ? `"${p.volumn}"` : null},
      fragile: ${
                p.fragile === null || p.fragile === undefined ? false : p.fragile
            },
      kind_id: ${p.kind_id || null}
    }`
        )
        .join(",")}])
    {
      returning {
        ${RETURNING_PACKAGE}
      }
    }
  }`,
    updatePackages: (packages) => `mutation {
    ${
        packages.filter((v) => !v.id).length > 0
            ? `
      insert_packages(objects: [${packages
                .filter((v) => !v.id)
                .map(
                    (p) => `{
        pickup_id: ${p.pickup_id || null},
        delivery_id: ${p.delivery_id || null},
        delivery_status_id: ${p.delivery_status_id || null},
        package_type_id: ${p.package_type_id || null},
        uid: "${p.uid}",
        number: ${p.number || null},
        declared_value: ${p.declared_value || null},
        weight: ${p.weight ? `"${p.weight}"` : null},
        volumn: ${p.volumn ? `"${p.volumn}"` : null},
        fragile: ${
                        p.fragile === null || p.fragile === undefined ? false : p.fragile
                    },
        kind_id: ${p.kind_id || null}
      }`
                )
                .join(",")}])
      {
        returning {
          ${RETURNING_PACKAGE}
        }
      }
    `
            : ""
    }
    ${packages
        .filter((v) => v.id)
        .map(
            (data, i) => `
      update_package_index_${i}: update_packages(where: {id: {_eq: ${
                data.id
            }}}, _set: {
        pickup_id: ${data.pickup_id || null},
        delivery_id: ${data.delivery_id || null},
        package_type_id: ${data.package_type_id || null},
        uid: "${data.uid}",
        number: ${data.number || null},
        declared_value: ${data.declared_value || null},
        weight: ${data.weight ? `"${data.weight}"` : null},
        volumn: ${data.volumn ? `"${data.volumn}"` : null},
        fragile: ${
                data.fragile === null || data.fragile === undefined
                    ? false
                    : data.fragile
            },
        kind_id: ${data.kind_id || null}
      })
      {
        returning {
          ${RETURNING_PACKAGE}
        }
      }
    `
        )
        .join(",")}
  }`,
    updatePackage: gql`
    mutation update_package($id: Int!, $package: packages_set_input!) {
      update_packages_by_pk(pk_columns: { id: $id }, _set: $package) {
        ${RETURNING_PACKAGE}
      }
    }
  `,
    deletePackages: (packages) => `mutation {
    delete_packages(where: {id: {_in: [${packages.join(",")}]}})
    {
      returning {
        ${RETURNING_PACKAGE}
      }
    }
  }`,
};

const services = {
    getPackageKinds: () => authenticatedQuery(queries.getPackageKinds),
    packageTypesByTeamId: (where) =>
        authenticatedQuery(queries.packageTypesByTeamId, {where}),
    packageTypesByMerchantId: (where) =>
        authenticatedQuery(queries.packageTypesByMerchantId, {where}),
    packageTypesByRecipientId: (id) =>
        authenticatedQuery(queries.packageTypesByRecipientId, {id}),
    updatePackageTypesRecipient: (values) =>
        authenticatedMutation(mutations.updatePackageTypesRecipient, values),
    createPackageType: (values) =>
        authenticatedMutation(mutations.createPackageType, {packageType: values}),
    updatePackageType: ({values, id}) =>
        authenticatedMutation(mutations.updatePackageType, {packageType: values, id}),
    deletePackageType: (ids) =>
        authenticatedMutation(mutations.deletePackageType, {
            ids,
            packageType: {deleted_at: moment().format()},
        }),
    deleteDefaultPackage: (values) =>
        authenticatedMutation(mutations.deleteDefaultPackage, values),
    deletePackages: ({packagesID}) =>
        authenticatedMutation(mutations.deletePackages(packagesID)),
    updatePackages: ({packages}) =>
        authenticatedMutation(mutations.updatePackages(packages)),
    updatePackage: (values) => authenticatedMutation(mutations.updatePackage, values),
    createDefaultPackage: (values) =>
        authenticatedMutation(mutations.createDefaultPackage, values),
    createPackage: ({packages}) => authenticatedMutation(mutations.createPackage(packages)),
    packageTypesTeam: (values) => authenticatedQuery(queries.packageTypesTeam, values),
};
export default services;
