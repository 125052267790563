import { methods } from "../utils";
import {authenticatedMutation, authenticatedQuery, service} from "./middleware";
import { gql } from "apollo-boost";

const { POST } = methods;

/**
 * GRAPHQL QUERIES
 */
const queries = {
    all: (team_id) => `coursier${
    team_id ? `(where: {user: {team_id: {_eq: "${team_id}"}}})` : ""
  } {
        id
        created_at
        updated_at
        user_id
        availability
        vehicle_type_id
        vehicle_type {
          name
          weight
          volumn
        }
        user {
          id
          email
          name
          picture_name
          phone_number
          address
          team_id
        }
    }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  modify: (values) => `mutation {
    update_users(where: {id: {_eq: ${values.user_id}}}, _set: {
      name: ${values.name ? `"${values.name}"` : `null`},
      email: ${
        values.email && values.email.length > 0 ? `"${values.email}"` : `null`
      },
      phone_number: ${
        values.phone_number ? `"${values.phone_number}"` : `null`
      },
      address: ${
        values.address
          ? `${JSON.stringify(values.address).replace(/"(\w+)"\s*:/g, "$1:")}`
          : `null`
      },
    }) {
      returning {
        id
        name
        email
        phone_number
        address
        picture_name
      }
    },
    update_coursier(where: {id: {_eq: ${values.id}}}, _set: {user_id: ${
    values.user_id
  }, vehicle_type_id: ${values.vehicle_type_id}}) {
      returning {
        id
        created_at
        updated_at
        user_id
        availability
        vehicle_type_id
        vehicle_type {
          name
          weight
          volumn
        }
        user {
          id
          email
          name
          phone_number
          address
          picture_name
          team_id
        }
      }
    }
  }`,
  delete: gql`
    mutation update_coursier($id: Int!, $deleted_at: timestamptz!) {
      update_coursier(
        where: { id: { _eq: $id } }
        _set: { deleted_at: $deleted_at }
      ) {
        returning {
          id
        }
      }
    }
  `,
};

const routes = {
  create: process.env.REACT_APP_BASE_API_URL + "/users/createCoursier",
};

const services = {
  getAll: ({ team_id }) => authenticatedQuery(queries.all(team_id)),
  create: (body) => service(POST, routes.create, body, {}, {}),
  modify: (values) => authenticatedMutation(mutations.modify(values)),
  delete: (values) => authenticatedMutation(mutations.delete, values),
};
export default services;
