import { takeLatest, put, call, select } from "redux-saga/effects";
import { withCallback } from "redux-saga-callback";
import { PackageService, RecipientsService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as RecipientsAction } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getNameZoneByAddressRequest } from "../../utils/zone-helper";
import { getTranslate } from "react-localize-redux";
import { UserActions } from "../user";
import _ from "lodash";
import {createOrUpdateDefaultPackage} from "../../utils/function-helper";

function* getRecipientsByMerchantIDRequest({ merchant_id }) {
  yield put(LoaderActions.loading())
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(
    RecipientsService.recipientsByMerchantID,
    merchant_id
  );
  if (result) {
    let tmp = result.recipients;
    for (let i = 0, l = tmp.length; i < l; i++) {
      if (tmp[i].address && tmp[i].address.value) {
        const res = yield getNameZoneByAddressRequest({
          value: tmp[i].address,
        });
        if (res) {
          tmp[i].zone = res.name;
        }
      }
    }
    yield put(RecipientsAction.getRecipientsSuccess(tmp));
  } else if (error) {
    showError("get_recipients_error", strings, null, error);
    yield put(RecipientsAction.getRecipientsFailure());
  }
  yield put(LoaderActions.loaded())
}

function* getRecipientsRequest({ team_id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(RecipientsService.recipients, { team_id });
  if (result) {
    yield put(RecipientsAction.getRecipientsSuccess(result.recipients));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_recipients_error", strings, null, error);
    yield put(RecipientsAction.getRecipientsFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getBeneficiariesRequest({ contractor_id }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  const [error, result] = yield call(
    RecipientsService.recipientsByMerchantID,
    contractor_id
  );
  if (result) {
    let tmp = result.recipients;
    for (let i = 0, l = tmp.length; i < l; i++) {
      if (tmp[i].address && tmp[i].address.value) {
        const res = yield getNameZoneByAddressRequest({
          value: tmp[i].address,
        });
        if (res) {
          tmp[i].zone = res.name;
        }
      }
    }
    yield put(RecipientsAction.getBeneficiariesSuccess(tmp));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_recipients_error", strings, null, error);
    yield put(RecipientsAction.getBeneficiariesFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getRecipientRequest({ id }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  const [error, result] = yield call(RecipientsService.recipient, { id });
  if (result) {
    let tmp = result.recipients[0];
    const res = yield getNameZoneByAddressRequest({ value: tmp.user.address });
    if (res) {
      tmp.zone = res.name;
    }
    yield put(RecipientsAction.getRecipientSuccess(tmp));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_recipient_error", strings, null, error);
    yield put(RecipientsAction.getRecipientFailure());
    yield put(LoaderActions.loaded());
  }
}

function* recipientsCreateRequest({ values, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  const [error, result] = yield call(
    RecipientsService.recipientCreate,
    _.pick(values, [
      "company",
      "merchant_id",
      "created_by",
      "notes",
      "type",
      "name",
      "address",
      "phone_number",
      "email",
      "team_id",
      "opening_hours"
    ])
  );
  if (result) {
    let tmp = result.insert_recipients_one;
    if (values.package_types_ids) {
      const [errorPackageTypes, resultPackageTypes] = yield call(
          PackageService.updatePackageTypesRecipient, {
            packages: values.package_types_ids.map(v => ({package_type_id: v, receiver_recipient_id: tmp.id})),
            recipientId: tmp.id
          }
      );
      console.log({resultPackageTypes})
      console.log({errorPackageTypes})
      if (!errorPackageTypes) {
        tmp.package_type_receivers = resultPackageTypes.insert_package_type_receiver.returning[0]
      }
    }
    if (tmp.address && tmp.address.value) {
      const res = yield getNameZoneByAddressRequest({ value: tmp.address });
      if (res) {
        tmp.zone = res.name;
      }
    }
    const tmpResponse = { ...tmp, key: tmp.id, code: `r-${tmp.id}` };
    if (values?.defaultPackage?.length > 0) {
      const resPackage = yield createOrUpdateDefaultPackage(values, tmp, "recipient");
      if (!resPackage) return;
      tmpResponse.default_packages = resPackage;
    }
    if (callback) {
      callback(`r-${tmp.id}`, tmpResponse);
    }
    yield put(RecipientsAction.recipientsCreateSuccess(tmpResponse));
    yield put(UserActions.addReceiverList(tmpResponse));
    showSuccess("recipient_create_success", strings);
    yield put(LoaderActions.loaded());
    return [false, tmpResponse];
  } else {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("recipient_create_error", strings, null, error);
    }
    yield put(RecipientsAction.recipientsCreateFailure());
    yield put(LoaderActions.loaded());
    return [true, false];
  }
}

function* updateRecipientsRequest({ values, callback }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(RecipientsService.recipientUpdate, {
    recipient: _.pick(values, [
      "name",
      "email",
      "phone_number",
      "address",
      "notes",
      "company",
      "type",
      "opening_hours"
    ]),
    id: values.id,
  });
  if (result) {
    let tmp = result.update_recipients_by_pk;
    if (values.package_types_ids) {
      const [errorPackageTypes, resultPackageTypes] = yield call(
          PackageService.updatePackageTypesRecipient, {
            packages: values.package_types_ids.map(v => ({package_type_id: v, receiver_recipient_id: tmp.id})),
            recipientId: tmp.id
          }
      );
      if (!errorPackageTypes) {
        tmp.package_type_receivers = resultPackageTypes.insert_package_type_receiver.returning
      }
    }
    if (tmp.address && tmp.address.value) {
      const res = yield getNameZoneByAddressRequest({ value: tmp.address });
      if (res) {
        tmp.zone = res.name;
      }
    }
    if (values?.defaultPackage?.length > 0) {
      const resPackage = yield call(createOrUpdateDefaultPackage, values, tmp, "recipient");
      if (!resPackage) return;
      tmp.default_packages = resPackage;
    }
    const tmpResponse = { ...tmp, key: tmp.id, code: `r-${tmp.id}` };
    yield put(RecipientsAction.recipientsUpdateSuccess(tmpResponse));
    yield put(UserActions.updateSearchReceiver(tmpResponse));
    yield put(RecipientsAction.updateSearchRecipient(tmpResponse));
    if (callback) {
      callback(`r-${tmp.id}`, tmpResponse);
    }
    showSuccess("recipient_update_success", strings);
    yield put(LoaderActions.loaded());
  } else if (error) {
    if (error.message.includes("users_email_unique")) {
      showError("email_unique", strings, null, error);
    } else {
      showError("recipient_update_error", strings, null, error);
    }
    yield put(RecipientsAction.recipientsUpdateFailure());
    yield put(LoaderActions.loaded());
  }
}

function* deleteRecipientRequest({ data, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(
    RecipientsService.recipientDelete,
    data.key
  );
  if (result) {
    showSuccess("recipient_delete_success", strings);
    yield put(
      RecipientsAction.deleteRecipientSuccess(
        result.update_recipients.returning[0]
      )
    );
    if (callback) {
      callback();
    }
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("recipient_delete_error", strings, null, error);
    yield put(RecipientsAction.deleteRecipientFailure());
    yield put(LoaderActions.loaded());
  }
}

function* deleteRecipientsRequest({ data, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(RecipientsService.recipientsDelete, data);

  if (result) {
    showSuccess("recipients_delete_success", strings);
    yield put(
      RecipientsAction.deleteRecipientsSuccess(
        result.update_recipients.returning
      )
    );
    if (callback) {
      callback();
    }
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("recipients_delete_error", strings, null, error);
    yield put(RecipientsAction.deleteRecipientFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getMoreRecipientsRequest({ ids }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  if (ids.length === 0) {
    yield put(RecipientsAction.getMoreRecipientsSuccess([]));
    yield put(LoaderActions.loaded());
    return;
  }
  const [error, result] = yield call(RecipientsService.moreRecipients, ids);
  if (result) {
    yield put(RecipientsAction.getMoreRecipientsSuccess(result.recipients));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("get_recipients_error", strings, null, error);
    yield put(RecipientsAction.getRecipientsFailure());
    yield put(LoaderActions.loaded());
  }
}

function* searchRecipientsRequest({ value }) {
  const locale = yield select((state) => state.locale);
  const { merchant_id } = yield select((state) => state.user.current_user);
  const strings = getTranslate(locale);
  if (!value || value.length === 0) {
    yield put(RecipientsAction.searchRecipientsSuccess([]));
    return;
  }
  const [error, result] = yield call(RecipientsService.searchRecipients, {
    value,
    merchant_id,
  });
  if (result) {
    yield put(RecipientsAction.searchRecipientsSuccess(result.view_recipients));
  } else {
    if (!error.loginError) {
      showError("search_user_error", strings, null, error);
    }
    yield put(RecipientsAction.searchRecipientsFailure());
  }
}

const sagas = [
  takeLatest(types.SEARCH_RECIPIENTS_REQUEST, searchRecipientsRequest),
  takeLatest(
    types.RECIPIENTS_CREATE_REQUEST,
    withCallback(recipientsCreateRequest)
  ),
  takeLatest(types.RECIPIENTS_UPDATE_REQUEST, updateRecipientsRequest),
  takeLatest(types.RECIPIENT_DELETE_REQUEST, deleteRecipientRequest),
  takeLatest(types.RECIPIENTS_DELETE_REQUEST, deleteRecipientsRequest),
  takeLatest(types.GET_RECIPIENTS_REQUEST, getRecipientsRequest),
  takeLatest(types.GET_RECIPIENT_REQUEST, getRecipientRequest),
  takeLatest(types.GET_BENEFICIARIES_REQUEST, getBeneficiariesRequest),
  takeLatest(
    types.GET_RECIPIENTS_BY_MERCHANT_ID_REQUEST,
    getRecipientsByMerchantIDRequest
  ),
  takeLatest(types.GET_MORE_RECIPIENTS_REQUEST, getMoreRecipientsRequest),
];
export default sagas;
