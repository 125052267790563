import { createAction } from "../../utils";

// Types
export const types = {
  GET_PACKAGE_TYPES_REQUEST: "GET_PACKAGE_TYPES_REQUEST",
  GET_PACKAGE_TYPES_SUCCESS: "GET_PACKAGE_TYPES_SUCCESS",
  GET_PACKAGE_TYPES_FAILURE: "GET_PACKAGE_TYPES_FAILURE",

  GET_PACKAGE_KIND_REQUEST: "GET_PACKAGE_KIND_REQUEST",
  GET_PACKAGE_KIND_SUCCESS: "GET_PACKAGE_KIND_SUCCESS",
  GET_PACKAGE_KIND_FAILURE: "GET_PACKAGE_KIND_FAILURE",

  GET_PACKAGE_TYPES_TEAM_REQUEST: "GET_PACKAGE_TYPES_TEAM_REQUEST",
  GET_PACKAGE_TYPES_TEAM_SUCCESS: "GET_PACKAGE_TYPES_TEAM_SUCCESS",
  GET_PACKAGE_TYPES_TEAM_FAILURE: "GET_PACKAGE_TYPES_TEAM_FAILURE",

  CREATE_PACKAGE_TYPE_REQUEST: "CREATE_PACKAGE_TYPE_REQUEST",
  CREATE_PACKAGE_TYPE_SUCCESS: "CREATE_PACKAGE_TYPE_SUCCESS",
  CREATE_PACKAGE_TYPE_FAILURE: "CREATE_PACKAGE_TYPE_FAILURE",

  UPDATE_PACKAGE_TYPE_REQUEST: "UPDATE_PACKAGE_TYPE_REQUEST",
  UPDATE_PACKAGE_TYPE_SUCCESS: "UPDATE_PACKAGE_TYPE_SUCCESS",
  UPDATE_PACKAGE_TYPE_FAILURE: "UPDATE_PACKAGE_TYPE_FAILURE",

  DELETE_PACKAGE_TYPE_REQUEST: "DELETE_PACKAGE_TYPE_REQUEST",
  DELETE_PACKAGE_TYPE_SUCCESS: "DELETE_PACKAGE_TYPE_SUCCESS",
  DELETE_PACKAGE_TYPE_FAILURE: "DELETE_PACKAGE_TYPE_FAILURE",

  GET_PACKAGE_TYPE_BY_MERCHANT_REQUEST: "GET_PACKAGE_TYPE_BY_MERCHANT_REQUEST",

  GET_PACKAGE_TYPE_BY_RECIPIENT_REQUEST: "GET_PACKAGE_TYPE_BY_RECIPIENT_REQUEST",

  GET_PACKAGE_TYPE_BY_TEAM_REQUEST: "GET_PACKAGE_TYPE_BY_TEAM_REQUEST",
  CREATE_DP_REQUEST: "CREATE_DP_REQUEST",
  CREATE_DP_SUCCESS: "CREATE_DP_SUCCESS",
  CREATE_DP_FAILURE: "CREATE_DP_FAILURE",

  DELETE_DP_REQUEST: "DELETE_DP_REQUEST",
  DELETE_DP_FAILURE: "DELETE_DP_FAILURE"
};

// Actions
const actions = {
  getPackageTypeByMerchantRequest: (merchant_id) =>
    createAction(types.GET_PACKAGE_TYPE_BY_MERCHANT_REQUEST, { merchant_id }),

  getPackageTypeByRecipientRequest: (recipient_id) =>
      createAction(types.GET_PACKAGE_TYPE_BY_RECIPIENT_REQUEST, { recipient_id }),

  getPackageTypeByTeamRequest: (team_id) =>
      createAction(types.GET_PACKAGE_TYPE_BY_TEAM_REQUEST, { team_id }),

  createPackageTypeRequest: (values, callback) =>
    createAction(types.CREATE_PACKAGE_TYPE_REQUEST, { values, callback }),
  createPackageTypeSuccess: (data) =>
    createAction(types.CREATE_PACKAGE_TYPE_SUCCESS, { data }),
  createPackageTypeFailure: () =>
    createAction(types.CREATE_PACKAGE_TYPE_FAILURE),

  createDefaultPackageRequest: (values = null, callback) =>
      createAction(types.CREATE_DP_REQUEST, { values, callback }),
  createDefaultPackageFailure: () =>
      createAction(types.CREATE_DP_FAILURE),

  deleteDefaultPackageRequest: (values = null, callback) =>
      createAction(types.DELETE_DP_REQUEST, { values, callback }),
  deleteDefaultPackageFailure: () =>
      createAction(types.DELETE_DP_FAILURE),

  updatePackageTypeRequest: (values, callback) =>
    createAction(types.UPDATE_PACKAGE_TYPE_REQUEST, { values, callback }),
  updatePackageTypeSuccess: (data) =>
    createAction(types.UPDATE_PACKAGE_TYPE_SUCCESS, { data }),
  updatePackageTypeFailure: () =>
    createAction(types.UPDATE_PACKAGE_TYPE_FAILURE),

  deletePackageTypeRequest: (ids, callback) =>
    createAction(types.DELETE_PACKAGE_TYPE_REQUEST, { ids, callback }),
  deletePackageTypeSuccess: (data) =>
    createAction(types.DELETE_PACKAGE_TYPE_SUCCESS, { data }),
  deletePackageTypeFailure: () =>
    createAction(types.DELETE_PACKAGE_TYPE_FAILURE),

  getPackageTypesRequest: () => createAction(types.GET_PACKAGE_TYPES_REQUEST),
  getPackageTypesSuccess: (data) =>
    createAction(types.GET_PACKAGE_TYPES_SUCCESS, { data }),
  getPackageTypesFailure: () => createAction(types.GET_PACKAGE_TYPES_FAILURE),

  getPackageKindRequest: () => createAction(types.GET_PACKAGE_KIND_REQUEST),
  getPackageKindSuccess: (data) =>
    createAction(types.GET_PACKAGE_KIND_SUCCESS, { data }),
  getPackageKindFailure: () => createAction(types.GET_PACKAGE_KIND_FAILURE),

  getPackageTypesTeamRequest: (team_id) =>
    createAction(types.GET_PACKAGE_TYPES_TEAM_REQUEST, { team_id }),
  getPackageTypesTeamSuccess: (data) =>
    createAction(types.GET_PACKAGE_TYPES_TEAM_SUCCESS, { data }),
  getPackageTypesTeamFailure: () =>
    createAction(types.GET_PACKAGE_TYPES_TEAM_FAILURE),
};

export default actions;
