import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { push } from 'connected-react-router'
import SearchSelect from './SearchSelect'

const mapStateToProps = ({locale, user}) => ({
  strings: getTranslate(locale),
  user: user,
})

const mapDispatchToProps = dispatch => ({
  navigateTo: (path, param) => dispatch(push(path, { data: param })),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchSelect)
