import { createAction } from "../../utils";

// Types
export const types = {
  GET_ALL_REQUEST: "coursiers_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "coursiers_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "coursiers_GET_ALL_FAILURE",

  CREATE_REQUEST: "coursiers_CREATE_REQUEST",
  CREATE_SUCCESS: "coursiers_CREATE_SUCCESS",
  CREATE_FAILURE: "coursiers_CREATE_FAILURE",

  MODIFY_REQUEST: "coursiers_MODIFY_REQUEST",
  MODIFY_SUCCESS: "coursiers_MODIFY_SUCCESS",
  MODIFY_FAILURE: "coursiers_MODIFY_FAILURE",

  DELETE_REQUEST: "coursiers_DELETE_REQUEST",
  DELETE_SUCCESS: "coursiers_DELETE_SUCCESS",
  DELETE_FAILURE: "coursiers_DELETE_FAILURE",

  SET_LOADING: "coursiers_SET_LOADING"
};

// Actions
const actions = {
  setLoading: (loading) =>
      createAction(types.SET_LOADING, { loading }),

  getAllRequest: (team_id) =>
    createAction(types.GET_ALL_REQUEST, { team_id }),
  getAllSuccess: (data) =>
    createAction(types.GET_ALL_SUCCESS, { data }),
  getAllFailure: () => createAction(types.GET_ALL_FAILURE),

  createRequest: (values, callback = null) =>
    createAction(types.CREATE_REQUEST, { values, callback }),
  createSuccess: (data) =>
    createAction(types.CREATE_SUCCESS, { data }),
  createFailure: () => createAction(types.CREATE_FAILURE),

  modifyRequest: (values, record, callback = null) =>
    createAction(types.MODIFY_REQUEST, { values, record, callback }),
  modifySuccess: (data) =>
    createAction(types.MODIFY_SUCCESS, { data }),
  modifyFailure: () => createAction(types.MODIFY_FAILURE),

  deleteRequest: (values, callback = null) =>
    createAction(types.DELETE_REQUEST, { values, callback }),
  deleteSuccess: (data) =>
    createAction(types.DELETE_SUCCESS, { data }),
  deleteFailure: () => createAction(types.DELETE_FAILURE),
};
export default actions;
