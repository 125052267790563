import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  style: {},
  subscribe: {},
  activeCourse: 1
};

// region Reducers
const loginAttempt = (state = initialState, action) => {
  const {data} = action;
  return {...state, style: data};
};

const addSubscribe = (state = initialState, action) => {
  const {data} = action;
  const tmp = state.subscribe;
  tmp[data.key] = data.data
  return {...state, subscribe: tmp};
};

const deleteSubscribe = (state = initialState, action) => {
  const {data} = action;
  const tmp = state.subscribe;
  delete tmp[data]
  return {...state, subscribe: tmp};
};

const updateActiveCourse =(state = initialState, action) =>{
    const {activeCourse} = action; 
    return{...state, activeCourse}
}
// endregion

export default createReducer(initialState, {
  [types.UPDATE_GLOBAL_STYLE]: loginAttempt,
  [types.ADD_SUBSCRIBE]: addSubscribe,
  [types.DELETE_SUBSCRIBE]: deleteSubscribe,
  [types.UPDATE_ACTIVE_COURSE]: updateActiveCourse
});
