import {methods} from '../utils';
import {authenticatedMutation, authenticatedQuery, service} from './middleware';

const { POST } = methods;

/**
 * GRAPHQL QUERIES
 */
const queries = {
  getPackagesKinds: `package_kinds {
    id
    kind
  }`,
  getHubbersByHubId: (id) => `users(where: {hub_users: {hub_id: {_eq: ${id}}}}) {
      id
      name
      address
      email
      phone_number
    }`,
  hubs: `hubs(where: {deleted_at: {_is_null: true}}) {
      id
      name
      address
      email
      hub_users {
        user_id
        hub_id
        user {
          id
          name
          email
        }
      }
    }`,
  hub: (id) => `hubs(where: {id: {_eq: ${id}}}) {
      id
      name
      address
      hub_users {
        user_id
        hub_id
        user {
          id
          name
          email
        }
      }
    }`,
}
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  createHub: (hub) => `mutation {
    insert_hubs(objects: {
      name: ${hub.name ? `"${hub.name}"` : `null`},
      address: ${hub.address ? `${JSON.stringify(hub.address).replace(/"(\w+)"\s*:/g, '$1:')}` : `null`},
    })
    {
      returning {
        id
        name
        address
        hub_users {
          user_id
          hub_id
          user {
            id
            name
            email
          }
        }
      }
    }
  }`,
  updateHub: (hub) => `mutation {
    update_hubs(where: {id: {_eq: ${hub.id}}},
    _set: {
      name: ${hub.name ? `"${hub.name}"` : `null`},
      address: ${hub.address ? `${JSON.stringify(hub.address).replace(/"(\w+)"\s*:/g, '$1:')}` : `null`},
    }) 
      {
        returning {
          id
          name
          address
          hub_users {
            user_id
            hub_id
            user {
              id
              name
              email
            }
          }
        }
      },
  }`,

  updateHubUser: (hub) => `mutation {
    update_users(where: {id: {_eq: ${hub.id}}},
    _set: {
      name: ${hub.name ? `"${hub.name}"` : `null`},
      email: ${hub.email ? `"${hub.email.toLowerCase()}"` : `null`},
    }) 
      {
        returning {
          id
        }
      },
    update_hub_users(where: {user_id: {_eq: ${hub.id}}},
    _set: {
      user_id: ${hub ? hub.id : `null`},
    }) 
      {
        returning {
          user_id
          hub_id
          user {
            id
            name
            email
          }
        }
      },
  }`,

  deleteHub: mutation => mutation,
  deleteHubUser: mutation => mutation,
}

const routes = {
  createHubUser: process.env.REACT_APP_BASE_API_URL + '/users/hub'
}

const services = {
  getHubbersByHubId: (id) => authenticatedQuery(queries.getHubbersByHubId(id)),
  getHubs: () => authenticatedQuery(queries.hubs),
  getHub: (id) => authenticatedQuery(queries.hub(id)),
  getPackagesKinds: () => authenticatedQuery(queries.getPackagesKinds),
  createHubUser: (body) => service(POST, routes.createHubUser, body,{} ,{}),
  updateHubUser: hub => { return authenticatedMutation(mutations.updateHubUser(hub)) },
  deleteHubUser: mutationRow => { return authenticatedMutation(mutations.deleteHubUser(mutationRow)) },
  createHub: hub => { return authenticatedMutation(mutations.createHub(hub)) },
  updateHub: hub => { return authenticatedMutation(mutations.updateHub(hub)) },
  deleteHub: mutationRow => { return authenticatedMutation(mutations.deleteHub(mutationRow)) },
}

export default services
