import { createAction } from '../../utils';

// Types
export const types = {
	GET_ALL_REQUEST: 'courses_GET_ALL_REQUEST',
	GET_ALL_SUCCESS: 'courses_GET_ALL_SUCCESS',
	GET_ALL_FAILURE: 'courses_GET_ALL_FAILURE',

	GET_ONE_REQUEST: 'courses_GET_ONE_REQUEST',
	GET_ONE_SUCCESS: 'courses_GET_ONE_SUCCESS',
	GET_ONE_FAILURE: 'courses_GET_ONE_FAILURE',

	GET_REQUEST: 'courses_GET_REQUEST',
	GET_SUCCESS: 'courses_GET_SUCCESS',
	GET_FAILURE: 'courses_GET_FAILURE',

	DELETE_ORDER_SUCCESS: 'courses_DELETE_ORDER_SUCCESS',

	CREATE_REQUEST: 'courses_CREATE_REQUEST',
	CREATE_SUCCESS: 'courses_CREATE_SUCCESS',
	CREATE_FAILURE: 'courses_CREATE_FAILURE',

	MODIFY_REQUEST: 'courses_MODIFY_REQUEST',
	MODIFY_SUCCESS: 'courses_MODIFY_SUCCESS',
	MODIFY_FAILURE: 'courses_MODIFY_FAILURE',

	MODIFY_ONLY_REQUEST: 'courses_MODIFY_ONLY_REQUEST',
	MODIFY_ONLY_SUCCESS: 'courses_MODIFY_ONLY_SUCCESS',
	MODIFY_ONLY_FAILURE: 'courses_MODIFY_ONLY_FAILURE',

	DELETE_TASK_REQUEST: 'courses_DELETE_TASK_REQUEST',
	DELETE_TASK_SUCCESS: 'courses_DELETE_TASK_SUCCESS',
	DELETE_TASK_FAILURE: 'courses_DELETE_TASK_FAILURE',

	DELETE_SUCCESS: 'courses_DELETE_SUCCESS',

	SET_CURRENT: 'courses_SET_CURRENT',

	ACCEPT: 'courses_ACCEPT',
	DENY: 'courses_DENY',

	RESET_TASKS_REDUC: 'courses_RESET_TASKS_REDUC',

	RESET_CURRENT_REDUC: 'courses_RESET_CURRENT_REDUC',

	SET_ACTIVE_TAB_REDUC: 'SET_ACTIVE_TAB_REDUC',

	MODIFY_TASK_REQUEST: 'courses_MODIFY_TASK_REQUEST',
	MODIFY_TASK_SUCCESS: 'courses_MODIFY_TASK_SUCCESS',
	MODIFY_TASK_FAILURE: 'courses_MODIFY_TASK_FAILURE',

	UPDATE_ORDER_REQUEST: 'courses_UPDATE_ORDER_REQUEST',
	UPDATE_ORDER_SUCCESS: 'courses_UPDATE_ORDER_SUCCESS',
	UPDATE_ORDER_FAILURE: 'courses_UPDATE_ORDER_FAILURE',

	UPDATE_STATE_REDUC: 'courses_UPDATE_STATE_REDUC',

	GENERATE_PDF_TASK_REQUEST: 'courses_GENERATE_PDF_TASK_REQUEST',
	GENERATE_PDF_TASK_SUCCESS: 'courses_GENERATE_PDF_TASK_SUCCESS',
	GENERATE_PDF_TASK_FAILURE: 'courses_GENERATE_PDF_TASK_FAILURE',
	GENERATE_MAIL_PDF_TASK_REQUEST: 'courses_GENERATE_MAIL_PDF_TASK_REQUEST',

	UPDATE_FILTERS_REDUC: 'courses_UPDATE_FILTERS_REDUC',

	GET_RECIPIENT_COURSE_BY_UID_REQUEST: 'courses_GET_RECIPIENT_COURSE_BY_UID_REQUEST',
	GET_RECIPIENT_COURSE_BY_UID_SUCCESS: 'courses_GET_RECIPIENT_COURSE_BY_UID_SUCCESS',
	GET_RECIPIENT_COURSE_BY_UID_FAILURE: 'courses_GET_RECIPIENT_COURSE_BY_UID_FAILURE',

	GET_GEO_BY_COURSIERS_AND_TASKS_REQUEST: 'courses_GET_GEO_BY_COURSIERS_AND_TASKS_REQUEST',
	GET_GEO_BY_COURSIERS_AND_TASKS_SUCCESS: 'courses_GET_GEO_BY_COURSIERS_AND_TASKS_SUCCESS',
	GET_GEO_BY_COURSIERS_AND_TASKS_FAILURE: 'courses_GET_GEO_BY_COURSIERS_AND_TASKS_FAILURE',

	RATE_TASK_BY_UID_REQUEST: 'courses_RATE_TASK_BY_UID_REQUEST',
	RATE_TASK_BY_UID_SUCCESS: 'courses_RATE_TASK_BY_UID_SUCCESS',
	RATE_TASK_BY_UID_FAILURE: 'courses_RATE_TASK_BY_UID_FAILURE',

	GET_MORE_REQUEST: 'courses_GET_MORE_REQUEST',
	GET_MORE_SUCCESS: 'courses_GET_MORE_SUCCESS',
	GET_MORE_FAILURE: 'courses_GET_MORE_FAILURE',

	SWAP_ORDER_COURSIER: 'courses_SWAP_ORDER_COURSIER',

	GENERATE_RECEIPT_REQUEST: 'courses_GENERATE_RECEIPT_REQUEST',
	GENERATE_RECEIPT_SUCCESS: 'courses_GENERATE_RECEIPT_SUCCESS',
	GENERATE_RECEIPT_FAILURE: 'courses_GENERATE_RECEIPT_FAILURE',

	UPDATE_TASK_BY_RECIPIENT_REQUEST: 'courses_UPDATE_TASK_BY_RECIPIENT_REQUEST',
	UPDATE_TASK_BY_RECIPIENT_SUCCESS: 'courses_UPDATE_TASK_BY_RECIPIENT_SUCCESS',

	SET_LOADING: 'courses_SET_LOADING',

	GET_STATISTICS_PLATFORM_REQUEST: 'courses_GET_STATISTICS_PLATFORM_REQUEST',
	GET_STATISTICS_PLATFORM_SUCCESS: 'courses_GET_STATISTICS_PLATFORM_SUCCESS',
	GET_STATISTICS_PLATFORM_FAILURE: 'courses_GET_STATISTICS_PLATFORM_FAILURE',

	GET_DONE_COURSE_REQUEST: 'GET_DONE_COURSE_REQUEST',
	GET_DONE_COURSE_SUCCESS: 'GET_DONE_COURSE_SUCCESS',
	GET_DONE_COURSE_FAILURE: 'GET_DONE_COURSE_FAILURE',

	GET_PLANNED_COURSE_REQUEST: 'GET_PLANNED_COURSE_REQUEST',
	GET_PLANNED_COURSE_SUCCESS: 'GET_PLANNED_COURSE_SUCCESS',
	GET_PLANNED_COURSE_FAILURE: 'GET_PLANNED_COURSE_FAILURE',

	GET_PROCESSING_COURSE_REQUEST: 'GET_PROCESSING_COURSE_REQUEST',
	GET_PROCESSING_COURSE_SUCCESS: 'GET_PROCESSING_COURSE_SUCCESS',
	GET_PROCESSING_COURSE_FAILURE: 'GET_PROCESSING_COURSE_FAILURE',

	GET_STATISTICS_REQUEST: 'courses_GET_STATISTICS_REQUEST',
	GET_STATISTICS_SUCCESS: 'courses_GET_STATISTICS_SUCCESS',
	GET_STATISTICS_FAILURE: 'courses_GET_STATISTICS_FAILURE',

	GET_STATISTICS_SHOP_REQUEST: 'courses_GET_STATISTICS_SHOP_REQUEST',
	GET_STATISTICS_SHOP_SUCCESS: 'courses_GET_STATISTICS_SHOP_SUCCESS',
	GET_STATISTICS_SHOP_FAILURE: 'courses_GET_STATISTICS_SHOP_FAILURE',

	IMPORT_COURSES_SHOP: 'IMPORT_COURSES_SHOP',
};

// Actions
const actions = {
	generateMailPdfTaskRequest: (task) => createAction(types.GENERATE_MAIL_PDF_TASK_REQUEST, { task }),
	generatePdfTaskRequest: (task, isDymo) => createAction(types.GENERATE_PDF_TASK_REQUEST, { task, isDymo }),
	generatePdfTaskSuccess: (data) => createAction(types.GENERATE_PDF_TASK_SUCCESS, { data }),
	generatePdfTaskFailure: () => createAction(types.GENERATE_PDF_TASK_FAILURE),

	importCoursesShop: (values) => createAction(types.IMPORT_COURSES_SHOP, { values }),

	getStatisticsCoursesRequest: () => createAction(types.GET_STATISTICS_PLATFORM_REQUEST),
	getStatisticsCoursesSuccess: (data) => createAction(types.GET_STATISTICS_PLATFORM_SUCCESS, { data }),
	getStatisticsCoursesFailure: () => createAction(types.GET_STATISTICS_PLATFORM_FAILURE),

	updateTaskByRecipientRequest: ({ uid, start_date, end_date, callback }) =>
		createAction(types.UPDATE_TASK_BY_RECIPIENT_REQUEST, {
			uid,
			start_date,
			end_date,
			callback,
		}),
	updateTaskByRecipientSuccess: (data) => createAction(types.UPDATE_TASK_BY_RECIPIENT_SUCCESS, { data }),

	generateReceiptRequest: ({ id, merchant_id, taskIds }) =>
		createAction(types.GENERATE_RECEIPT_REQUEST, { id, merchant_id, taskIds }),
	generateReceiptSuccess: (data) => createAction(types.GENERATE_RECEIPT_SUCCESS, { data }),
	generateReceiptFailure: () => createAction(types.GENERATE_RECEIPT_FAILURE),

	rateTaskByUIDRequest: ({ uid, rate = null, rate_note = null }) =>
		createAction(types.RATE_TASK_BY_UID_REQUEST, { uid, rate, rate_note }),
	rateTaskByUIDSuccess: (data) => createAction(types.RATE_TASK_BY_UID_SUCCESS, { data }),
	rateTaskByUIDFailure: () => createAction(types.RATE_TASK_BY_UID_FAILURE),

	updateFiltersReduc: (filters) => createAction(types.UPDATE_FILTERS_REDUC, { filters }),

	resetRequestSuccessReduc: () => createAction(types.RESET_TASKS_REDUC),

	setLoading: (loading) => createAction(types.SET_LOADING, { loading }),

	setActiveTab: (activeTab) => createAction(types.SET_ACTIVE_TAB_REDUC, { activeTab }),
	accept: (id) => createAction(types.ACCEPT, { id }),

	deny: (course) => createAction(types.DENY, { course }),

	resetCurrent: () => createAction(types.RESET_CURRENT_REDUC),

	updateStateReduc: (courses) => createAction(types.UPDATE_STATE_REDUC, { courses }),

	getMoreRequest: (ids) => createAction(types.GET_MORE_REQUEST, { ids }),
	getMoreSuccess: (data) => createAction(types.GET_MORE_SUCCESS, { data }),
	getMoreFailure: () => createAction(types.GET_MORE_FAILURE),

	getAllRequest: (values, dispatch = null, saveFilter = true) =>
		createAction(types.GET_ALL_REQUEST, { values, dispatch, saveFilter }),
	getAllSuccess: (data) => createAction(types.GET_ALL_SUCCESS, { data }),
	getAllFailure: () => createAction(types.GET_ALL_FAILURE),

	getRecipientCourseByUIDRequest: (uid) => createAction(types.GET_RECIPIENT_COURSE_BY_UID_REQUEST, { uid }),
	getRecipientCourseByUIDSuccess: (data) => createAction(types.GET_RECIPIENT_COURSE_BY_UID_SUCCESS, { data }),
	getRecipientCourseByUIDFailure: () => createAction(types.GET_RECIPIENT_COURSE_BY_UID_FAILURE),

	updateOrderRequest: (values) => createAction(types.UPDATE_ORDER_REQUEST, { values }),
	updateOrderSuccess: (data) => createAction(types.UPDATE_ORDER_SUCCESS, { data }),
	updateOrderFailure: () => createAction(types.UPDATE_ORDER_FAILURE),

	getOneRequest: (values, dispatch = null) => createAction(types.GET_ONE_REQUEST, { values, dispatch }),
	getOneSuccess: (data, tasks) => createAction(types.GET_ONE_SUCCESS, { data, tasks }),
	getOneFailure: () => createAction(types.GET_ONE_FAILURE),

	deleteTaskRequest: (values, deleteCourseId) => createAction(types.DELETE_TASK_REQUEST, { values, deleteCourseId }),
	deleteTaskSuccess: (data, deleteCourseId) => createAction(types.DELETE_TASK_SUCCESS, { data, deleteCourseId }),
	deleteTaskFailure: () => createAction(types.DELETE_TASK_FAILURE),

	createRequest: (values, course, team, price, isDevis = false, dispatch) =>
		createAction(types.CREATE_REQUEST, {
			values,
			course,
			team,
			price,
			isDevis,
			dispatch,
		}),
	createSuccess: (data, isCurrent) => createAction(types.CREATE_SUCCESS, { data, isCurrent }),
	createFailure: () => createAction(types.CREATE_FAILURE),

	modifyRequest: (values, course, team, deleteByTable = false, isImport = false) =>
		createAction(types.MODIFY_REQUEST, {
			values,
			course,
			team,
			isImport,
			deleteByTable,
		}),
	modifySuccess: (data) => createAction(types.MODIFY_SUCCESS, { data }),
	modifyFailure: () => createAction(types.MODIFY_FAILURE),

	modifyOnlyRequest: (values, callback = null) => createAction(types.MODIFY_ONLY_REQUEST, { values, callback }),
	modifyOnlySuccess: (data) => createAction(types.MODIFY_ONLY_SUCCESS, { data }),
	modifyOnlyFailure: () => createAction(types.MODIFY_ONLY_FAILURE),

	modifyTaskRequest: (values, isNewAssign = false, isOrderCoursierRequest = false) =>
		createAction(types.MODIFY_TASK_REQUEST, {
			values,
			isNewAssign,
			isOrderCoursierRequest,
		}),
	modifyTaskSuccess: (data) => createAction(types.MODIFY_TASK_SUCCESS, { data }),
	modifyTaskFailure: () => createAction(types.MODIFY_TASK_FAILURE),

	deleteTasksSuccess: (ids) => createAction(types.DELETE_ORDER_SUCCESS, { ids }),

	deleteSuccess: (course) => createAction(types.DELETE_SUCCESS, { course }),

	setCurrentRequest: (values) => createAction(types.SET_CURRENT, { values }),

	getGeoByCoursiersAndTasksRequest: (values, dispatch = null) =>
		createAction(types.GET_GEO_BY_COURSIERS_AND_TASKS_REQUEST, {
			values,
			dispatch,
		}),
	getGeoByCoursiersAndTasksSuccess: (data) => createAction(types.GET_GEO_BY_COURSIERS_AND_TASKS_SUCCESS, { data }),
	getGeoByCoursiersAndTasksFailure: () => createAction(types.GET_GEO_BY_COURSIERS_AND_TASKS_FAILURE),

	swapOrderCoursier: (values, dispatch) => createAction(types.SWAP_ORDER_COURSIER, { values, dispatch }),

	getDoneCourseRequest: (values, dispatch) => createAction(types.GET_DONE_COURSE_REQUEST, { values, dispatch }),
	getDoneCourseSuccess: (data) => createAction(types.GET_DONE_COURSE_SUCCESS, { data }),
	getDoneCourseFailure: () => createAction(types.GET_DONE_COURSE_FAILURE),

	getPlannedCourseRequest: (values, dispatch) => createAction(types.GET_PLANNED_COURSE_REQUEST, { values, dispatch }),
	getPlannedCourseSuccess: (data) => createAction(types.GET_PLANNED_COURSE_SUCCESS, { data }),
	getPlannedCourseFailure: () => createAction(types.GET_PLANNED_COURSE_FAILURE),

	getProcessingCourseRequest: (values, dispatch) =>
		createAction(types.GET_PROCESSING_COURSE_REQUEST, { values, dispatch }),
	getProcessingCourseSuccess: (data) => createAction(types.GET_PROCESSING_COURSE_SUCCESS, { data }),
	getProcessingCourseFailure: () => createAction(types.GET_PROCESSING_COURSE_FAILURE),

	getStatisticsRequest: () => createAction(types.GET_STATISTICS_REQUEST),
	getStatisticsSuccess: (data) => createAction(types.GET_STATISTICS_SUCCESS, { data }),
	getStatisticsFailure: () => createAction(types.GET_STATISTICS_FAILURE),
};

export default actions;
