import { takeLatest, put, call, select } from "redux-saga/effects";
import { TeamsService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as TeamsActions } from "./actions";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";

function* getTeamsRequest() {
  //  yield put(LoaderActions.loading())
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(TeamsService.getTeams);
  if (result) {
    yield put(TeamsActions.getTeamsSuccess(result.teams));
    //    yield put(LoaderActions.loaded())
  } else if (error) {
    showError("get_teams_error", strings);
    yield put(TeamsActions.getTeamsFailure());
    //    yield put(LoaderActions.loaded())
  }
}

function* getTeamRequest({ id }) {
  //yield put(LoaderActions.loading())
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(TeamsService.getTeam, id);
  if (result) {
    yield put(TeamsActions.getTeamSuccess(result.teams[0]));
    //yield put(LoaderActions.loaded())
  } else if (error) {
    showError("get_teams_error", strings);
    yield put(TeamsActions.getTeamFailure());
    //yield put(LoaderActions.loaded())
  }
}

function* updateTeamRequest({ id, zipcode, email }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(TeamsService.updateTeam, { id, zipcode, email });
  if (result) {
    showSuccess("update_team_success", strings);
    yield put(TeamsActions.getTeamSuccess(result.update_teams.returning[0]));
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("update_team_error", strings);
    yield put(TeamsActions.getTeamFailure());
    yield put(LoaderActions.loaded());
  }
}

const sagas = [
  takeLatest(types.GET_TEAMS_REQUEST, getTeamsRequest),
  takeLatest(types.GET_TEAM_REQUEST, getTeamRequest),
  takeLatest(types.UPDATE_TEAM_REQUEST, updateTeamRequest),
];

export default sagas;
