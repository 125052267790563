import { createReducer } from 'reduxsauce';
import { types } from './actions';
import _ from 'lodash';

const initialState = {
  list: [],
  moreMerchants: [],
  searchMerchants: [],
  loading: false,
  hasMore: true,
  merchantsToInvoice: [],
  currentMerchant: {},
  requestSuccess: 0,
  currentTab: "1",
  emailAvailable: true,
  statisticsMerchant: null
};

const failed = (state = initialState) => {
  return {...state};
};

const getMerchantsSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, list: values, requestSuccess: 1, loading: false};
};

const getMerchantSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, currentMerchant: data, requestSuccess: 1, loading: false};
};

const merchantsCreateSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  values[data.id] = data
  return {...state, list: values, requestSuccess: 1, loading: false};
};

const merchantsUpdateSuccess = (state = initialState, action) => {
  const {data} = action
  const { list, moreMerchants, searchMerchants } = state
  const values = list
  const valuesMore = moreMerchants
  const valuesSearch = searchMerchants
  values[data.id] = data
  if (valuesMore[data.id]) {
    valuesMore[data.id] = data
  }
  if (valuesSearch[data.id]) {
    valuesSearch[data.id] = data
  }
  return {...state, list: values, moreMerchants: valuesMore, searchMerchants: valuesSearch, requestSuccess: 1, loading: false};
};

const merchantsUpdateCurrentMerchantSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, currentMerchant: data, requestSuccess: 1, loading: false};
};

const merchantsDeleteSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  data.forEach((val) => {
    delete values[val.id]
  })
  return {...state, list: values, loading: false};
};

const importMerchantsSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, list: values, loading: false};
};

const merchantDeleteSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  delete values[data.id]
  return {...state, list: values, loading: false};
};

const createUserMerchantSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  values[data.id] = {...values[data.id], ...data}
  return {...state, list: values, loading: false};
};

const modifyUserMerchantSuccess = (state = initialState, action) => {
  const {data, client} = action
  const { list } = state
  const values = list
  values[client.id] = {...values[client.id], ...{user: data}}
  return {...state, list: values, loading: false};
};

const setCurrentTabReduc = (state = initialState, action) => {
  const {tabKey} = action
  return {...state, currentTab: tabKey, loading: false};
};


const resetMerchantsReduc = (state = initialState) => {
  return {...state, requestSuccess: 0, loading: false}
}

const resetCurrentMerchant = (state = initialState) => {
  return {...state, currentMerchant: {}, loading: false}
}

const merchantsFailed = (state = initialState) => {
  return {...state, requestSuccess: 2, loading: false};
};

const setEmailAvailable = (state = initialState, {emailAvailable}) => {
  return {...state, emailAvailable}
}

const getMoreMerchantsSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, moreMerchants: values, loading: false};
};

const searchMerchantsSuccess = (state = initialState, action) => {
  const {data} = action;
  let tmp = []
  let formatData = data.map((v) => ({
    key: v.merchant_id,
    code: `m-${v.merchant_id}`,
    ...({...(_.omit(v, ['name', 'phone_number', 'email', 'address', 'new_user', 'merchant_id'])), id: v.merchant_id}),
    users: [{id: v.id, ..._.pick(v, ['name', 'phone_number', 'email', 'address', 'new_user', 'role'])}]
  }))
  formatData.forEach((v) => {
    tmp[v.id] = v
  })
  return {...state, searchMerchants: tmp};
};

const getMerchantsToInvoiceSuccess = (state = initialState, action) => {
  const {data} = action;
  let tmp = []
  data.forEach((v) => {
    tmp[v.id] = v
  })
  return {...state, merchantsToInvoice: tmp};
};
const getStatisticsMerchantSuccess = (state = initialState, action) => {
  const {data} = action;
  return {...state, statisticsMerchant: data};
};

export default createReducer(initialState, {
  [types.GET_MERCHANTS_FAILURE]: merchantsFailed,
  [types.GET_MERCHANTS_SUCCESS]: getMerchantsSuccess,
  [types.GET_MERCHANT_FAILURE]: merchantsFailed,
  [types.GET_MERCHANT_SUCCESS]: getMerchantSuccess,
  [types.MERCHANTS_CREATE_FAILURE]: merchantsFailed,
  [types.MERCHANTS_CREATE_SUCCESS]: merchantsCreateSuccess,
  [types.MERCHANTS_UPDATE_FAILURE]: merchantsFailed,
  [types.MERCHANTS_UPDATE_SUCCESS]: merchantsUpdateSuccess,
  [types.IMPORT_MERCHANTS_FAILURE]: failed,
  [types.IMPORT_MERCHANTS_SUCCESS]: importMerchantsSuccess,
  [types.MERCHANT_DELETE_FAILURE]: failed,
  [types.MERCHANT_DELETE_SUCCESS]: merchantDeleteSuccess,
  [types.RESET_MERCHANTS_REDUC]: resetMerchantsReduc,
  [types.CREATE_USER_MERCHANT_FAILURE]: failed,
  [types.CREATE_USER_MERCHANT_SUCCESS]: createUserMerchantSuccess,
  [types.UPDATE_USER_MERCHANT_FAILURE]: failed,
  [types.UPDATE_USER_MERCHANT_SUCCESS]: modifyUserMerchantSuccess,
  [types.UPDATE_CURRENT_MERCHANT_SUCCESS]: merchantsUpdateCurrentMerchantSuccess,
  [types.RESET_CURRENT_MERCHANT]: resetCurrentMerchant,
  [types.SET_CURRENT_TAB_REDUC]: setCurrentTabReduc,
  [types.CHECK_EMAIL_AVAILABLE_SUCCESS]: setEmailAvailable,
  [types.GET_MORE_MERCHANTS_SUCCESS]: getMoreMerchantsSuccess,
  [types.SEARCH_MERCHANTS_FAILURE]: failed,
  [types.SEARCH_MERCHANTS_SUCCESS]: searchMerchantsSuccess,
  [types.MERCHANTS_DELETE_SUCCESS]: merchantsDeleteSuccess,
  [types.GET_MERCHANTS_TO_INVOICE_FAILURE]: failed,
  [types.GET_MERCHANTS_TO_INVOICE_SUCCESS]: getMerchantsToInvoiceSuccess,
  [types.GET_STATISTICS_MERCHANT_FAILURE]: failed,
  [types.GET_STATISTICS_MERCHANT_SUCCESS]: getStatisticsMerchantSuccess,
});
