import {takeLatest, put, call, select} from 'redux-saga/effects';
import {VehiclesService} from '../../services';
import {LoaderActions} from '../loader';
import {types, default as VehiclesActions} from './actions';
import {showSuccess, showError} from '../../utils/notifications-helper'
import {getTranslate} from 'react-localize-redux';

function* getVehicleTypesRequest({team_id}) {
    yield put(LoaderActions.loading())
    const locale = yield select(state => state.locale);
    const strings = getTranslate(locale)

    const [error, result] = yield call(VehiclesService.getVehicleTypes, {team_id})
    if (result) {
        yield put(VehiclesActions.getVehicleTypesSuccess(result.vehicle_types))
        yield put(LoaderActions.loaded())
    } else if (error) {
        showError("get_vehicle_types_error", strings, null, error)
        yield put(VehiclesActions.getVehicleTypesFailure())
        yield put(LoaderActions.loaded())
    }
}

function* createVehicleTypeRequest({values, callback}) {
    const locale = yield select(state => state.locale);
    const auth = yield select(state => state.auth);
    const strings = getTranslate(locale)
    yield put(LoaderActions.loading())

    const [error, result] = yield call(VehiclesService.createVehicleType, {...values, team_id: auth.team_id})
    if (result) {
        showSuccess("create_vehicle_type_success", strings)
        if (callback) {callback()}
        yield put(VehiclesActions.createVehicleTypeSuccess(result.insert_vehicle_types.returning[0]))
        yield put(LoaderActions.loaded())
    } else if (error)  {
        showError("create_vehicle_type_error", strings, null, error)
        yield put(VehiclesActions.createVehicleTypeFailure())
        yield put(LoaderActions.loaded())
    }
}

function* modifyVehicleTypeRequest({values, record, callback}) {
    const locale = yield select(state => state.locale);
    const strings = getTranslate(locale)
    yield put(LoaderActions.loading())

    const [error, result] = yield call(VehiclesService.modifyVehicleType, {...record, ...values})
    if (result) {
        showSuccess("vehicle_type_update_success", strings)
        if (callback) {callback()}
        yield put(VehiclesActions.modifyVehicleTypeSuccess(result.update_vehicle_types.returning[0]))
        yield put(LoaderActions.loaded())
    } else if (error)  {
        showError("vehicle_type_update_error", strings, null, error)
        yield put(VehiclesActions.modifyVehicleTypeFailure())
        yield put(LoaderActions.loaded())
    }
}

function* deleteVehicleTypeRequest({values, callback}) {
    yield put(LoaderActions.loading())
    const locale = yield select(state => state.locale);
    const strings = getTranslate(locale)

    const [error, result] = yield call(VehiclesService.deleteVehicleType, values.id)
    if (result) {
        showSuccess("vehicle_type_delete_success", strings)
        if (callback) {callback()}
        yield put(VehiclesActions.deleteVehicleTypeSuccess(result.delete_vehicle_types.returning[0]))
        yield put(LoaderActions.loaded())
    } else if (error)  {
        showError("vehicle_type_delete_error", strings, null, error)
        yield put(VehiclesActions.deleteVehicleTypeFailure())
        yield put(LoaderActions.loaded())
    }
}

const sagas = [
    takeLatest(types.GET_VEHICLE_TYPES_REQUEST, getVehicleTypesRequest),
    takeLatest(types.CREATE_VEHICLE_TYPE_REQUEST, createVehicleTypeRequest),
    takeLatest(types.MODIFY_VEHICLE_TYPE_REQUEST, modifyVehicleTypeRequest),
    takeLatest(types.DELETE_VEHICLE_TYPE_REQUEST, deleteVehicleTypeRequest),
]
export default sagas;
