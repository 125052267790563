import React from "react";
import "./style.css";
import Icon from "../IconsList/Icon";

const CardPackageType = ({ data, color = "#0A5447" }) => {
  return (
    <div>
      {data?.icon_fa && (
        <Icon
          name={data.icon_fa.name}
          color={color}
          size={3}
          prefix={data.icon_fa.prefix}
        />
      )}
      {data?.name && <p className="text-card" style={{color: "#0A5447"}}>{data.name}</p>}
    </div>
  );
};

export default CardPackageType;
