import { resettableReducer } from 'reduxsauce'
import { AuthReducers, AuthSagas } from './auth'
import { UserReducers, UserSagas } from './user'
import { MerchantsReducers, MerchantsSagas } from './merchants'
import { PackageReducers, PackageSagas } from './package'
import { AreasReducers, AreasSagas } from './areas'
import { InvoicesReducers, InvoicesSagas } from './invoice'
import { TeamsReducers, TeamsSagas } from './teams'
import { PricingReducers, PricingSagas } from './pricing'
import { RecipientsReducers, RecipientsSagas } from './recipients'
import { TasksReducers, TasksSagas } from './tasks'
import { CoursiersReducers, CoursiersSagas } from './coursiers'
import { VehiclesReducers, VehiclesSagas } from './vehicles'
import { CrmShopReducers, CrmShopSagas } from './crmShop'
import { CommonTypes, CommonReducers } from './common'
import { LoaderReducers } from './loader'
import { HubsReducers, HubsSagas } from './hubs'
/* import { CitiesSagas } from '../../../deki-backoffice/src/redux/cities'
import { OrdersSagas } from '../../../deki-backoffice/src/redux/orders' */

// Specify reset action type rule
const resettable = resettableReducer(CommonTypes.RESET_REDUCERS)

// Merge our redux together
const reducers = {
  auth: resettable(AuthReducers),
  common: resettable(CommonReducers),
  recipients: resettable(RecipientsReducers),
  loader: resettable(LoaderReducers),
  merchants: resettable(MerchantsReducers),
  user: resettable(UserReducers),
  pricing: resettable(PricingReducers),
  packageType: resettable(PackageReducers),
  areas: resettable(AreasReducers),
  tasks: resettable(TasksReducers),
  teams: resettable(TeamsReducers),
  coursiers: resettable(CoursiersReducers),
  vehicles: resettable(VehiclesReducers),
  crmShop: resettable(CrmShopReducers),
  invoices: resettable(InvoicesReducers),
  hubs: resettable(HubsReducers), 
}

export const rootSaga = [
  ...AuthSagas,
  ...RecipientsSagas,
  ...MerchantsSagas,
  ...UserSagas,
  ...PackageSagas,
  ...AreasSagas,
  ...TasksSagas,
  ...PricingSagas,
  ...TeamsSagas,
  ...CoursiersSagas,
  ...VehiclesSagas,
  ...CrmShopSagas,
  ...InvoicesSagas,
  ...HubsSagas,
];

export default reducers
