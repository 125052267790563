import { createSelector } from 'reselect'

export const selectLoadingCoursiers = createSelector(
    (state) => state.coursiers.loading,
    (loading) => {
        const tmp = loading
        return tmp
    }
)

export const selectCoursiers = createSelector(
    (state) => state.coursiers.list,
    (list) => {
        return list
    }
)