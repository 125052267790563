import {WebSocketLink} from "apollo-link-ws";
import {split} from "apollo-link";
import {getMainDefinition} from "apollo-utilities";
import ApolloClient from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import {onError} from "apollo-link-error";
import {HttpLink} from "apollo-link-http";
import {ApolloLink, concat} from "@apollo/react-hooks";
import store from '../core/store';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_HASURA_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
    const {token} = store.getState().auth
    operation.setContext({
        headers: {
            authorization: (token ? `Bearer ${token}` : null)
        }
    });

    return forward(operation);
})

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}
const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
            );

        if (networkError) console.log(`[Network error]: ${networkError}`);
    }
);

const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_HASURA_URL_WS, // use wss for a secure endpoint
    options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
            const {token} = await store.getState().auth
            return token ? { headers: { authorization: `Bearer ${token}` } } : {}
        },
    }
});
const link = split(
    // split based on operation type
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    concat(authMiddleware, httpLink),
    errorLink
);

export const changeSubscriptionToken = token => {
    if (wsLink.subscriptionClient.connectionParams.authToken === token) {
        return
    }
    wsLink.subscriptionClient.connectionParams.authToken = token
    wsLink.subscriptionClient.close()
    wsLink.subscriptionClient.connect()
}

export default new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    lazy: true,
    queryDeduplication: false,
    defaultOptions: defaultOptions,
});