import { createSelector } from 'reselect'
import moment from "moment-timezone";

export const selectHubs = createSelector(
    (state) => Object.values(state.hubs.list),
    (hubs) => {
        return hubs.map((v) => ({ key: v.id, ...v}))
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
    }
)
