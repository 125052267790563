import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  hubUsers: [],
  loading: false,
  currentHub: null,
  currentTab: "1",
  requestSuccess: 0,
  kinds: {}
};

const failed = (state = initialState) => {
  return {...state};
};

const createHubSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  values[data.id] = data
  return {...state, list: values, requestSuccess: 1, loading: false};
};

const updateHubUserSuccess = (state = initialState, action) => {
  const {data} = action
  const { hubUsers } = state
  const values = hubUsers

  values[data.user_id] = data
  return {...state, hubUsers: values, currentHub: data, requestSuccess: 1, loading: false};
};

const deleteHubUserSuccess = (state = initialState, action) => {
  const {data} = action
  const { hubUsers } = state
  const values = hubUsers
  delete values[data.user_id]
  return {...state, hubUsers: values, loading: false};
};

const createHubUserSuccess = (state = initialState, action) => {
  const {data} = action
  const { hubUsers } = state
  const values = hubUsers
  values[data.user_id] = data
  return {...state, hubUsers: values, requestSuccess: 1, loading: false};
};

const updateHubSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  values[data.id] = data
  return {...state, list: values, currentHub: data, requestSuccess: 1, loading: false};
};

const deleteHubSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  delete values[data.id]
  return {...state, list: values, loading: false};
};

const getHubsSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, list: values, loading: false};
};

const getHubSuccess = (state = initialState, action) => {
  const {data} = action
  let users = {}
  data.hub_users.forEach(v => {
    users[v.user_id] = v
  })
  return {...state, currentHub: data, hubUsers: users, loading: false};
};

const hubFailed = (state = initialState) => {
  return {...state, requestSuccess: 2, loading: false};
};

const resetHubReduc = (state = initialState) => {
  return {...state, requestSuccess: 0, loading: false}
}

const resetCurrentHub = (state = initialState) => {
  return {...state, currentHub: null, loading: false}
}

const setCurrentTabReduc = (state = initialState, action) => {
  const {values} = action
  return {...state, currentTab: values, loading: false}
}

const getPackagesKindsSuccess = (state = initialState, action) => {
  const {data} = action
  let tmp = {}
  data.forEach((v) => {
    tmp[v.id] = v
  })
  return {...state, kinds: tmp, loading: false}
}

export default createReducer(initialState, {
  [types.CREATE_HUB_FAILURE]: hubFailed,
  [types.CREATE_HUB_SUCCESS]: createHubSuccess,
  [types.UPDATE_HUB_FAILURE]: hubFailed,
  [types.UPDATE_HUB_SUCCESS]: updateHubSuccess,
  [types.DELETE_HUB_FAILURE]: failed,
  [types.DELETE_HUB_SUCCESS]: deleteHubSuccess,
  [types.CREATE_HUB_USER_FAILURE]: hubFailed,
  [types.CREATE_HUB_USER_SUCCESS]: createHubUserSuccess,
  [types.UPDATE_HUB_USER_FAILURE]: hubFailed,
  [types.UPDATE_HUB_USER_SUCCESS]: updateHubUserSuccess,
  [types.DELETE_HUB_USER_FAILURE]: failed,
  [types.DELETE_HUB_USER_SUCCESS]: deleteHubUserSuccess,
  [types.GET_HUBS_FAILURE]: failed,
  [types.GET_HUBS_SUCCESS]: getHubsSuccess,
  [types.GET_HUB_FAILURE]: failed,
  [types.GET_HUB_SUCCESS]: getHubSuccess,
  [types.RESET_HUB_REDUC]: resetHubReduc,
  [types.RESET_CURRENT_HUB]: resetCurrentHub,
  [types.SET_CURRENT_TAB_REDUC]: setCurrentTabReduc,
  [types.GET_PACKAGES_KINDS_FAILURE]: failed,
  [types.GET_PACKAGES_KINDS_SUCCESS]: getPackagesKindsSuccess,
});
