import { createAction } from "../../utils";

// Types
export const types = {
  GET_CRM_SHOP_REQUEST: "GET_CRM_SHOP_REQUEST",
  GET_CRM_SHOP_SUCCESS: "GET_CRM_SHOP_SUCCESS",
  GET_CRM_SHOP_FAILURE: "GET_CRM_SHOP_FAILURE",

  DELETE_CRM_SHOP_REQUEST: "DELETE_CRM_SHOP_REQUEST",
  DELETE_CRM_SHOP_SUCCESS: "DELETE_CRM_SHOP_SUCCESS",
  DELETE_CRM_SHOP_FAILURE: "DELETE_CRM_SHOP_FAILURE",

  CREATE_CRM_SHOP_REQUEST: "CREATE_CRM_SHOP_REQUEST",
  CREATE_CRM_SHOP_SUCCESS: "CREATE_CRM_SHOP_SUCCESS",
  CREATE_CRM_SHOP_FAILURE: "CREATE_CRM_SHOP_FAILURE",

  UPDATE_CRM_SHOP_REQUEST: "UPDATE_CRM_SHOP_REQUEST",
  UPDATE_CRM_SHOP_SUCCESS: "UPDATE_CRM_SHOP_SUCCESS",
  UPDATE_CRM_SHOP_FAILURE: "UPDATE_CRM_SHOP_FAILURE",

  RESET_CRM_SHOP_REDUC: "RESET_CRM_SHOP_REDUC",
};

// Actions
const actions = {
  resetRequestSuccessReduc: () => createAction(types.RESET_CRM_SHOP_REDUC),

  getCrmShopRequest: (user_id) =>
    createAction(types.GET_CRM_SHOP_REQUEST, { user_id }),
  getCrmShopSuccess: (data) =>
    createAction(types.GET_CRM_SHOP_SUCCESS, { data }),
  getCrmShopFailure: () => createAction(types.GET_CRM_SHOP_FAILURE),

  createCrmShopRequest: (values) =>
    createAction(types.CREATE_CRM_SHOP_REQUEST, { values }),
  createCrmShopSuccess: (data) =>
    createAction(types.CREATE_CRM_SHOP_SUCCESS, { data }),
  createCrmShopFailure: () => createAction(types.CREATE_CRM_SHOP_FAILURE),

  deleteCrmShopRequest: (values) =>
    createAction(types.DELETE_CRM_SHOP_REQUEST, { values }),
  deleteCrmShopSuccess: (data) =>
    createAction(types.DELETE_CRM_SHOP_SUCCESS, { data }),
  deleteCrmShopFailure: () => createAction(types.DELETE_CRM_SHOP_FAILURE),

  updateCrmShopRequest: (values) =>
    createAction(types.UPDATE_CRM_SHOP_REQUEST, { values }),
  updateCrmShopSuccess: (data) =>
    createAction(types.UPDATE_CRM_SHOP_SUCCESS, { data }),
  updateCrmShopFailure: () => createAction(types.UPDATE_CRM_SHOP_FAILURE),
};

export default actions;
