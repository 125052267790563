import "antd/dist/antd.less";
import React, { useImperativeHandle, useState, useEffect } from "react";
import ModalPackage from "./ModalPackage";
import "./style.css";
import { getTranslate } from "react-localize-redux";
import { useSelector } from "react-redux";

const PackagesPanel = (
  {
    packages = [],
    packageType,
    packageKinds,
    pricing,
    navigateTo,
    current_user,
    handleUpdatePackages,
    handleDeletePackages,
    receiver,
  },
  ref
) => {
  const [receiverData, setReceiverData] = useState(null);
  const [modal, setModal] = useState(null);
  const strings = useSelector(({ locale }) => getTranslate(locale));
  const [tmpDelivery, setTmpDelivery] = useState(null);

  useImperativeHandle(ref, () => ({
    setModal: (values) => {
      setModal(values);
    },
    setReceiver: (value) => {
      setReceiverData(value)
    }
  }));

  useEffect(() => {
    setReceiverData(receiver)
  }, [receiver])

  const handleCancelModal = () => {
    setModal(null);
    setTmpDelivery(null);
  };

  const onValidate = (formData) => {
    const tmp = packages;
    if (formData && formData.food) {
        const kind = Object.values(packageKinds || {}).find((p) => p.kind === formData.food)
        formData.kind_id = kind ? kind.id : null
    }
    if (!modal?.package?.key) {
      const sortPackages = packages.sort((a, b) => b.number - a.number);
      const number = 0 in sortPackages ? sortPackages[0].number : 0;
      tmp.push({ key: number + 1, number: number + 1, ...formData });
    } else {
      const index = tmp.findIndex((v) => v.key === modal.package.key);
      if (index >= 0) {
        tmp[index] = { ...modal.package, ...formData };
      }
    }
    handleUpdatePackages(tmp.sort((a, b) => a.key - b.key));
    setModal(null);
    setTmpDelivery(null);
  };

  return (
      <ModalPackage
        receiver={receiverData || tmpDelivery}
        onValidate={onValidate}
        navigateTo={navigateTo}
        data={modal}
        visible={!!modal}
        onCancel={handleCancelModal}
        current_user={current_user}
        pricing={pricing}
        strings={strings}
        packageType={packageType}
        packageKinds={packageKinds}
      />
  );
};

export default PackagesPanel;
