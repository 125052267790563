import {call} from "redux-saga/effects";
import {PricingService, UserService} from "../services";

export function* getNameZoneByAddressRequest({value}) {
    let zone = null;
    if (!value || !value.data) {
        return zone;
    }
    const [newError, newResponse] = yield call(UserService.getArea, {
        point: `POINT(${value.data.geometry.coordinates[0]} ${value.data.geometry.coordinates[1]})`,
    });
    if (newError) {
        return null;
    }
    if (
        newResponse &&
        newResponse.getzonewithpoint[0] &&
        newResponse.getzonewithpoint[0].name
    ) {
        zone = {
            name: newResponse.getzonewithpoint[0].name,
            address: value,
        };
    }
    return zone;
}

export async function getNameZoneByAddress(value, teamId = null) {
    let zone = null;
    if (!value || !value.data) {
        return zone;
    }
    const [newError, newResponse] = await UserService.getArea({
        point: `POINT(${value.data.geometry.coordinates[0]} ${value.data.geometry.coordinates[1]})`,
    });
    if (newError) {
        return null;
    }
    if (newResponse && newResponse.getzonewithpoint) {
        const index = newResponse.getzonewithpoint.findIndex(
            (el) => teamId ? el.team_id === teamId : el.is_admin === true
        );
        if (index > -1) {
            return {
                ...newResponse.getzonewithpoint[index],
                address: value,
            };
        }
    }
    return null;
}

export async function getZonePricingByAddress(value, team_id) {
    const data = await getNameZoneByAddress(value, team_id);
    if (data && data.id) {
        // eslint-disable-next-line
        const [_, response] = await PricingService.getZonePricing(data.id);
        if (response && response.zone_pricing) {
            return response.zone_pricing[0];
        }
    }
    return null;
}
