import { createAction } from "../../utils";

// Types
export const types = {
  GET_CURRENT_USER_REQUEST: "GET_CURRENT_USER_REQUEST",
  GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
  GET_CURRENT_USER_FAILURE: "GET_CURRENT_USER_FAILURE",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  UPDATE_CURRENT_USER_SUCCESS: "UPDATE_CURRENT_USER_SUCCESS",

  CREATE_IMAGE_REQUEST: "CREATE_IMAGE_REQUEST",
  CREATE_IMAGE_SUCCESS: "CREATE_IMAGE_SUCCESS",
  CREATE_IMAGE_FAILURE: "CREATE_IMAGE_FAILURE",

  VALIDATE_MERCHANT_INFO_SUCCESS: "VALIDATE_MERCHANT_INFO_SUCCESS",
  VALIDATE_MERCHANT_INFO_FAILURE: "VALIDATE_MERCHANT_INFO_FAILURE",

  CLEAR_IMAGE_REQUEST: "CLEAR_IMAGE_REQUEST",
  CLEAR_IMAGE_SUCCESS: "CLEAR_IMAGE_SUCCESS",
  CLEAR_IMAGE_FAILURE: "CLEAR_IMAGE_FAILURE",

  GET_ACCESS_TOKEN_REQUEST: "GET_ACCESS_TOKEN_REQUEST",
  GET_ACCESS_TOKEN_SUCCESS: "GET_ACCESS_TOKEN_SUCCESS",
  GET_ACCESS_TOKEN_FAILURE: "GET_ACCESS_TOKEN_FAILURE",

  SEARCH_RECEIVER_REQUEST: "SEARCH_RECEIVER_REQUEST",
  SEARCH_RECEIVER_SUCCESS: "SEARCH_RECEIVER_SUCCESS",
  SEARCH_RECEIVER_FAILURE: "SEARCH_RECEIVER_FAILURE",

  UPDATE_SEARCH_RECEIVER: "UPDATE_SEARCH_RECEIVER",
  ADD_RECEIVER_LIST: "ADD_RECEIVER_LIST",
  SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
  UPDATE_CURRENT_USER_MERCHANT_SUCCESS: "UPDATE_CURRENT_USER_MERCHANT_SUCCESS"

};

// Actions
const actions = {
  updateSearchReceiver: (data) =>
    createAction(types.UPDATE_SEARCH_RECEIVER, { data }),

  merchantsUpdateCurrentUserSuccess: (merchant) =>
      createAction(types.UPDATE_CURRENT_USER_MERCHANT_SUCCESS, { merchant }),

  getCurrentUserRequest: (id) =>
      createAction(types.GET_CURRENT_USER_REQUEST, { id }),
  getCurrentUserSuccess: (user) =>
    createAction(types.GET_CURRENT_USER_SUCCESS, { user }),
  getCurrentUserFailure: () => createAction(types.GET_CURRENT_USER_FAILURE),

  updateCurrentUserSuccess: (data) =>
    createAction(types.UPDATE_CURRENT_USER_SUCCESS, { data }),

  getAccessTokenRequest: (id) =>
    createAction(types.GET_ACCESS_TOKEN_REQUEST, { id }),
  getAccessTokenSuccess: (data) =>
    createAction(types.GET_ACCESS_TOKEN_SUCCESS, { data }),
  getAccessTokenFailure: () => createAction(types.GET_ACCESS_TOKEN_FAILURE),

  getUsersRequest: () => createAction(types.GET_USERS_REQUEST),
  getUsersSuccess: (data) => createAction(types.GET_USERS_SUCCESS, { data }),
  getUsersFailure: () => createAction(types.GET_USERS_FAILURE),

  updateUserRequest: (values) =>
    createAction(types.UPDATE_USER_REQUEST, { values }),
  updateUserSuccess: (data, merchant = null, dispatcher = null) =>
    createAction(types.UPDATE_USER_SUCCESS, { data, merchant, dispatcher }),
  updateUserFailure: () => createAction(types.UPDATE_USER_FAILURE),

  createImageRequest: (file) =>
    createAction(types.CREATE_IMAGE_REQUEST, { file }),
  createImageSuccess: (data) =>
    createAction(types.CREATE_IMAGE_SUCCESS, { data }),
  createImageFailure: () => createAction(types.CREATE_IMAGE_FAILURE),

  clearImageRequest: (id) => createAction(types.CLEAR_IMAGE_REQUEST, { id }),
  clearImageSuccess: (data) =>
    createAction(types.CLEAR_IMAGE_SUCCESS, { data }),
  clearImageFailure: () => createAction(types.CLEAR_IMAGE_FAILURE),

  validateMerchantInfoSuccess: (data) =>
    createAction(types.VALIDATE_MERCHANT_INFO_SUCCESS, { data }),
  validateMerchantInfoFailure: () =>
    createAction(types.VALIDATE_MERCHANT_INFO_FAILURE),

  searchReceiverRequest: (value) =>
    createAction(types.SEARCH_RECEIVER_REQUEST, { value }),
  searchReceiverSuccess: (data) =>
    createAction(types.SEARCH_RECEIVER_SUCCESS, { data }),
  searchReceiverFailure: () => createAction(types.SEARCH_RECEIVER_FAILURE),

  addReceiverList: (data) => createAction(types.ADD_RECEIVER_LIST, { data }),
  sendMessageRequest: (data) =>
    createAction(types.SEND_MESSAGE_REQUEST, { data }),
};
export default actions;
