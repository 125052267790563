import { connect } from 'react-redux';
import { Loader } from './loader';
import {resolveId} from "../../../../utils";

const mapStateToProps = state => ({
  screen: resolveId(state.router),
  displayed: state.loader.displayed,
  nbLoaderStart: state.loader.nbLoaderStart,
  displayedInfo: state.loader.displayedInfo,
  maxRequest: state.loader.maxRequest,
  end: state.loader.end
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
