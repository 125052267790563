import { takeLatest, put, call, select } from "redux-saga/effects";
import { InvoicesService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as InvoicesActions } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import { TasksActions } from "../tasks";
import moment from "moment-timezone";
import { push } from "connected-react-router";
import { getBase64FromUrl } from "../../utils/file-tools";

function* getInvoicesRequest({ team_id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(InvoicesService.getInvoices, { team_id });
  if (result) {
    yield put(InvoicesActions.getInvoicesSuccess(result.invoice));
  } else if (error) {
    showError("get_invoices_error", strings, null, error);
    yield put(InvoicesActions.getInvoicesFailure());
  }
  yield put(LoaderActions.loaded());
}

function* getInvoiceRequest({ id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(InvoicesService.getInvoice, { id });
  if (result) {
    yield put(InvoicesActions.getInvoiceSuccess(result.invoice[0]));
  } else if (error) {
    showError("get_invoice_error", strings, null, error);
    yield put(InvoicesActions.getInvoiceFailure());
  }
  yield put(LoaderActions.loaded());
}

function* createInvoiceRequest({ invoice, courses, path, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(InvoicesService.createInvoice, {
    invoice,
    courses,
  });
  if (result) {
    yield put(
      InvoicesActions.createInvoiceSuccess(result.insert_invoice.returning[0])
    );
    yield put(
      TasksActions.updateStateReduc(result.update_courses.returning)
    );
    if (callback) {
      callback();
    }
    yield put(LoaderActions.loaded());
    yield put(push(path + "/" + result.insert_invoice.returning[0].id));
  } else if (error) {
    showError("create_invoice_error", strings, null, error);
    yield put(InvoicesActions.createInvoiceFailure());
    yield put(LoaderActions.loaded());
  }
}

function* modifyInvoiceRequest({ invoice, courses, path, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(InvoicesService.modifyInvoice, {
    invoice,
    courses,
  });
  if (result) {
    yield put(
      InvoicesActions.modifyInvoiceSuccess(result.update_invoice.returning[0])
    );
    yield put(
      TasksActions.updateStateReduc([
        ...result.update_courses_1.returning,
        ...result.update_courses_2.returning,
      ])
    );
    if (callback) {
      callback();
    }
    yield put(LoaderActions.loaded());
    yield put(push(path + "/" + result.update_invoice.returning[0].id));
  } else if (error) {
    showError("modify_invoice_error", strings, null, error);
    yield put(InvoicesActions.modifyInvoiceFailure());
    yield put(LoaderActions.loaded());
  }
}

function* updateInvoiceRequest({ values, isCurrent, loading }) {
  if (loading) yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const { current_user } = yield select((state) => state.user);
  const strings = getTranslate(locale);
  let invoice = {}
  if (values.state !== null && values.state !== undefined) {
    invoice = {...invoice, state: values.state};
    if (values.state === 2) {
      invoice = {...invoice, validate_by: current_user.id, validate_date: moment().format()};
    }
    if (values.state === 0) {
      invoice = {...invoice, validate_by: null, validate_date: null};
    }
    if (values.state === 1) {
      invoice = {...invoice, sent_date: moment(values.sent_date).format()};
    }
  }
  const [error, result] = yield call(InvoicesService.updateInvoice, {
    invoice,
    id: values.id,
  });
  if (result) {
    yield put(
      InvoicesActions.createInvoiceSuccess(result.update_invoice_by_pk)
    );
    if (isCurrent) {
      yield put(
        InvoicesActions.updateCurrentInvoiceReduc(result.update_invoice_by_pk)
      );
    }
    if (loading) yield put(LoaderActions.loaded());
  } else if (error) {
    showError("update_invoice_error", strings, null, error);
    yield put(InvoicesActions.createInvoiceFailure());
    if (loading) yield put(LoaderActions.loaded());
  }
}

function* generatePDFRequest({ values, isCurrent, download }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const { imageName } = yield select((state) => state.user);
  const strings = getTranslate(locale);
  let base64 = null;
  if (imageName) {
    yield getBase64FromUrl(imageName).then((val) => {
      base64 = val;
    });
    values.imageName = base64;
  }
  const [error, result] = yield call(InvoicesService.generatePDF, values);
  if (result) {
    yield put(InvoicesActions.generatePDFSuccess(result.data, isCurrent));
    if (download) {
      var link = document.createElement("a");
      link.href =
        process.env.REACT_APP_FILE_API_URL + "/download/" + result.data.name;
      link.download = result.data.name;
      link.dispatchEvent(new MouseEvent("click"));
    }
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("generate_pdf_error", strings, null, error);
    yield put(InvoicesActions.generatePDFFailure());
    yield put(LoaderActions.loaded());
  }
}

function* deleteInvoiceRequest({ values, isCurrent }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(InvoicesService.deleteInvoice, values);

  if (result) {
    yield put(
      InvoicesActions.deleteInvoiceSuccess(result.delete_invoice.returning)
    );
    yield put(
      TasksActions.updateStateReduc(result.update_courses.returning)
    );
    if (isCurrent) {
      yield put(InvoicesActions.updateCurrentInvoiceReduc(null));
      yield put(push("/invoice"));
    }
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("delete_invoice_error", strings, null, error);
    yield put(InvoicesActions.deleteInvoiceFailure());
    yield put(LoaderActions.loaded());
  }
}

function* sendInvoiceMailRequest({ values }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  let urls = [];
  for (var i = 0, l = values.data.length; i < l; i++) {
    if (values.data[i].imageName) {
      let base64 = null;
      yield getBase64FromUrl(values.data[i].imageName).then((val) => {
        base64 = val;
      });
      values.data[i].imageName = base64;
    }
    const [error, result] = yield call(
      InvoicesService.generatePDF,
      values.data[i]
    );
    if (result) {
      yield put(InvoicesActions.generatePDFSuccess(result.data, false));
      urls.push(result.data.name);
    } else if (error) {
      showError("generate_pdf_error", strings, null, error);
      yield put(InvoicesActions.generatePDFFailure());
    }
  }
  values.urls = urls;
  const [errorMail, resultMail] = yield call(
    InvoicesService.sendInvoiceMail,
    values
  );
  if (resultMail) {
    for (var a = 0, le = values.data.length; a < le; a++) {
      yield put(
        InvoicesActions.updateInvoiceRequest({
          ...values.data[a],
          sent_date: moment(),
          state: 1,
        }, false, false)
      );
    }
    showSuccess("send_invoice_success", strings);
    yield put(LoaderActions.loaded());
  } else if (errorMail) {
    showError("send_invoice_error", strings, null, errorMail);
    yield put(LoaderActions.loaded());
  }
}

const sagas = [
  takeLatest(types.GET_INVOICES_REQUEST, getInvoicesRequest),
  takeLatest(types.GET_INVOICE_REQUEST, getInvoiceRequest),
  takeLatest(types.UPDATE_INVOICE_REQUEST, updateInvoiceRequest),
  takeLatest(types.MODIFY_INVOICE_REQUEST, modifyInvoiceRequest),
  takeLatest(types.CREATE_INVOICE_REQUEST, createInvoiceRequest),
  takeLatest(types.DELETE_INVOICE_REQUEST, deleteInvoiceRequest),
  takeLatest(types.GENERATE_PDF_REQUEST, generatePDFRequest),
  takeLatest(types.SEND_INVOICE_MAIL_REQUEST, sendInvoiceMailRequest),
];
export default sagas;
