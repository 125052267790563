import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  moreRecipients: [],
  searchRecipients: [],
  loading: false,
  hasMore: true,
  currentRecipient: {},
  beneficiaries: {},
  requestSuccess: 0,
  currentTab: "1"
};

const updateDefaultPackageRecipientReduc = (state = initialState, action) => {
  const {data} = action
  const { list, moreRecipients, searchRecipients } = state
  const values = list
  const valuesMore = moreRecipients
  const valuesSearch = searchRecipients
  const listMerch = values[data.recipient_id]
  const moreReci = valuesMore[data.recipient_id]
  const searchReci = valuesSearch[data.recipient_id]
  if (values[data.recipient_id]) {
    values[data.recipient_id] = {...listMerch, default_packages: [...listMerch.default_packages, data]};
  }
  if (valuesMore[data.recipient_id] && moreReci) {
    valuesMore[data.recipient_id] = { ...moreReci, default_packages: [...moreReci.default_packages, data] };
  }
  if (valuesSearch[data.recipient_id] && searchReci) {
    valuesSearch[data.recipient_id] = { ...searchReci, default_packages: [...searchReci.default_packages, data] };
  }
  return {...state, list: values, moreMerchants: valuesMore, searchMerchants: valuesSearch, requestSuccess: 1, loading: false};
};

const deleteDefaultPackageRecipientReduc = (state = initialState, action) => {
  const {data} = action
  const { list, moreRecipients, searchRecipients } = state
  const values = list
  const valuesMore = moreRecipients
  const valuesSearch = searchRecipients
  const listReci = values[data.recipient_id]
  const moreReci = valuesMore[data.recipient_id]
  const searchReci = valuesSearch[data.recipient_id]
  if (values[data.recipient_id]) {
    values[data.recipient_id] = { ...listReci, default_packages: listReci.default_packages.filter((p) => p.package.id !== data.package.id) };
  }
  if (valuesMore[data.recipient_id] && moreReci) {
    valuesMore[data.recipient_id] = { ...moreReci, default_packages: moreReci.default_packages.filter((p) => p.package.id !== data.package.id) };
  }
  if (valuesSearch[data.recipient_id] && searchReci) {
    valuesSearch[data.recipient_id] = { ...searchReci, default_packages: searchReci.default_packages.filter((p) => p.package.id !== data.package.id) };
  }
  return {...state, list: values, moreMerchants: valuesMore, searchMerchants: valuesSearch, requestSuccess: 1, loading: false};
};

const failed = (state = initialState) => {
  return {...state};
};

const getRecipientsSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, list: values, loading: false};
};

const getBeneficiariesSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, beneficiaries: data, loading: false};
};

const getRecipientSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, currentRecipient: data, requestSuccess: 1, loading: false};
};

const recipientsCreateSuccess = (state = initialState, action) => {
  const {data} = action
  const { list, moreRecipients } = state
  const values = list
  const valuesMore = moreRecipients
  if (process.env.REACT_APP_TYPE === "merchant") {
  valuesMore[data.id] = data
  }
  values[data.id] = data
  return {...state, list: values, moreRecipients: valuesMore, requestSuccess: 1, loading: false};
};

const recipientsUpdateSuccess = (state = initialState, action) => {
  const {data} = action
  const { list, moreRecipients } = state
  const values = list
  const valuesMore = moreRecipients
  values[data.id] = data
  if (valuesMore[data.id]) {
    valuesMore[data.id] = data
  }
  return {...state, list: values, moreRecipients: valuesMore, requestSuccess: 1, loading: false};
};

const recipientsUpdateCurrentRecipientSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, currentRecipient: data, requestSuccess: 1, loading: false};
};

const recipientDeleteSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  delete values[data.id]
  return {...state, list: values, loading: false};
};

const recipientsDeleteSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  data.forEach((val) => {
    delete values[val.id]
  })
  return {...state, list: values, loading: false};
};

const setCurrentTabReduc = (state = initialState, action) => {
  const {tabKey} = action
  return {...state, currentTab: tabKey, loading: false};
};


const resetRecipientsReduc = (state = initialState) => {
  return {...state, requestSuccess: 0, loading: false}
}

const resetCurrentRecipient = (state = initialState) => {
  return {...state, currentRecipient: {}, loading: false}
}

const recipientsFailed = (state = initialState) => {
  return {...state, requestSuccess: 2, loading: false};
};

const getMoreRecipientsSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, moreRecipients: values, loading: false};
};

const updateSearchRecipient = (state = initialState, action) => {
  const {data} = action
  let tmp = state.searchRecipients
  if (tmp[data.id]) {
    tmp[data.id] = data
  }
  return {...state, searchRecipients: tmp, loading: false};
};

const searchRecipientsSuccess = (state = initialState, action) => {
  const {data} = action;
  let tmp = []
  data.forEach((v) => {
    tmp[v.id] = v
  })
  return {...state, searchRecipients: tmp};
};

export default createReducer(initialState, {
  [types.GET_RECIPIENTS_FAILURE]: recipientsFailed,
  [types.GET_RECIPIENTS_SUCCESS]: getRecipientsSuccess,
  [types.GET_RECIPIENT_FAILURE]: recipientsFailed,
  [types.GET_RECIPIENT_SUCCESS]: getRecipientSuccess,
  [types.RECIPIENTS_CREATE_FAILURE]: recipientsFailed,
  [types.RECIPIENTS_CREATE_SUCCESS]: recipientsCreateSuccess,
  [types.RECIPIENTS_UPDATE_FAILURE]: recipientsFailed,
  [types.RECIPIENTS_UPDATE_SUCCESS]: recipientsUpdateSuccess,
  [types.RECIPIENT_DELETE_FAILURE]: failed,
  [types.RECIPIENT_DELETE_SUCCESS]: recipientDeleteSuccess,
  [types.RECIPIENTS_DELETE_SUCCESS]: recipientsDeleteSuccess,
  [types.GET_BENEFICIARIES_FAILURE]: failed,
  [types.GET_BENEFICIARIES_SUCCESS]: getBeneficiariesSuccess,
  [types.RESET_RECIPIENTS_REDUC]: resetRecipientsReduc,
  [types.UPDATE_CURRENT_RECIPIENT_SUCCESS]: recipientsUpdateCurrentRecipientSuccess,
  [types.RESET_CURRENT_RECIPIENT]: resetCurrentRecipient,
  [types.SET_CURRENT_TAB_REDUC]: setCurrentTabReduc,
  [types.GET_MORE_RECIPIENTS_SUCCESS]: getMoreRecipientsSuccess,
  [types.SEARCH_RECIPIENTS_FAILURE]: failed,
  [types.SEARCH_RECIPIENTS_SUCCESS]: searchRecipientsSuccess,
  [types.UPDATE_SEARCH_RECIPIENT]: updateSearchRecipient,
  [types.UPDATE_DP_RECIPIENT_REDUC]: updateDefaultPackageRecipientReduc,
  [types.DELETE_DP_RECIPIENT_REDUC]: deleteDefaultPackageRecipientReduc
});
