import {ReactComponent as IconIsFood} from "../assets/images/svg/IconIsFood.svg";
import React from "react";
import {ReactComponent as IconIsNoFood} from "../assets/images/svg/IconIsNoFood.svg";

export const styleCard = (select, disabled = false) => {
    let tmp = {
        width: "45%",
        height: "90px", /* Avant modif = 100 */
        margin: "6px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "space-between",
        border: "1px solid #C4C4C4",
        boxSizing: "border-box",
        borderRadius: "6px",
        padding: "2px"
    };
    if (!disabled) {
        tmp = {
            ...tmp,
            cursor: "pointer",

        }
    }
    if (select) {
        tmp = {
            ...tmp,
            background: "#DDECD4",
            border: "2px solid #005347",
        };
    }
    return tmp;
};

export const isFoodIcon = (colorBackground = null) => {
    let noColorBackground = "#C4C4C4";
    return (
        <IconIsFood fill={colorBackground ?? noColorBackground}
                    stroke={colorBackground ? "#005347" : undefined}/>
    );
};

export const isNoFoodIcon = (colorBackground = null) => {
    let noColorBackground = "#C4C4C4";
    return (
        <IconIsNoFood fill={colorBackground ?? noColorBackground}
                      stroke={colorBackground ? "#005347" : undefined}/>
    );
};

export const fragileSvgIcon = (colorBackground = null, borderColor = null) => {
    let noColorBackground = "#C4C4C4";
    return (
        <svg
            width="21"
            height="36"
            viewBox="0 0 21 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5 33.7159V33.3148V21.6302V21.197L11.9287 21.1353C16.7668 20.4382 20.5 16.2757 20.5 11.25C20.5 10.8194 20.4642 10.3581 20.3894 9.84143L20.383 9.79743L20.3845 9.75299C20.3849 9.74235 20.3841 9.72962 20.382 9.71658L20.3812 9.71178L20.3812 9.71178L18.9969 0.711778L18.9968 0.710987C18.9785 0.590492 18.8742 0.5 18.75 0.5H12.1494C12.0308 0.5 11.9279 0.584004 11.9043 0.701028C11.9043 0.7011 11.9043 0.701173 11.9042 0.701245L11.005 5.20055C10.9903 5.27426 11.0094 5.35077 11.0568 5.40873C11.1038 5.46598 11.1755 5.49998 11.2499 5.49998H14.537H15.3462L14.9842 6.22365L12.0985 11.9929L11.1538 11.7195L11.498 8.27493L11.4982 8.27276C11.5055 8.20322 11.4827 8.13399 11.4361 8.0826L11.4353 8.08169C11.3887 8.03004 11.3218 7.99995 11.2499 7.99995H7.61414H7.03763L7.11917 7.42924L8.06838 0.785407L8.06839 0.785346C8.07864 0.713721 8.05713 0.640645 8.00966 0.585848L8.00965 0.585835C7.96307 0.532061 7.89324 0.5 7.82079 0.5H2.25C2.12578 0.5 2.02143 0.590491 2.00306 0.71108L2.00294 0.711853L0.617224 9.71185L0.616884 9.71406C0.614373 9.72987 0.614483 9.73868 0.614713 9.74226L0.618049 9.79438L0.610547 9.84605C0.535751 10.3612 0.5 10.8211 0.5 11.2501C0.5 16.2758 4.23312 20.4383 9.0713 21.1353L9.5 21.1971V21.6302V33.3148V33.7159L9.10847 33.8029L3.69652 35.0056L11.5 33.7159ZM11.5 33.7159L11.8915 33.8029M11.5 33.7159L11.8915 33.8029M11.8915 33.8029L17.3035 35.0056M11.8915 33.8029L17.3035 35.0056M17.3035 35.0056L17.3041 35.0057C17.4279 35.0337 17.5118 35.1501 17.4985 35.2775C17.4838 35.4049 17.3764 35.5 17.25 35.5H3.74998C3.62351 35.5 3.51612 35.4049 3.5014 35.2775C3.48816 35.1502 3.57193 35.0337 3.69588 35.0057L17.3035 35.0056Z"
                fill={colorBackground ?? noColorBackground}
                stroke={borderColor ?? noColorBackground}
            />
        </svg>
    );
};