import { Form, Button, Select, Tooltip, Spin, Input } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { filterOptionReceiver } from '../../../utils/file-tools';
import debounce from 'lodash/debounce';
import './style.css';
import { useSelector } from 'react-redux';

const SearchSelect = ({
	strings,
	title,
	requiredText,
	label,
	handleClickReceiver,
	select,
	moreReceiver = [],
	receiverData,
	searchReceiverRequest,
	setReceiverData,
	form,
}) => {
	const { Option } = Select;
	const [loading, setLoading] = useState(false);
	const receiver = useSelector(select);
	const [allData, setAllData] = useState([]);
	const [isValid, setIsValid] = useState(false);
	const style = { margin: '0px 0px 5px' };

	const searchUser = (value) => {
		if (searchReceiverRequest) {
			setLoading(true);
			searchReceiverRequest(value);
		}
	};

	useEffect(() => {
		setLoading(false);
		let tmp = [];
		receiver.forEach((v) => (tmp[v.code] = v));
		moreReceiver.forEach((v) => (tmp[v.code] = v));
		setAllData(Object.values(tmp));
		// eslint-disable-next-line
	}, [receiver]);

	useEffect(() => {
		let tmp = [];
		receiver.forEach((v) => (tmp[v.code] = v));
		moreReceiver.forEach((v) => (tmp[v.code] = v));
		setAllData(Object.values(tmp));
		// eslint-disable-next-line
	}, [moreReceiver]);

	const fetchUser = debounce(searchUser, 800);

	const onChange = (value) => {
		if (value === undefined) {
			setReceiverData(null);
			setIsValid(false);
			return;
		}
		let tmp = allData.filter((v) => v.code === value)[0];
		setReceiverData(tmp);
		setIsValid(true);
		if (tmp && tmp.notes) {
			form.setFieldsValue({
				notes: tmp.notes,
			});
		}
	};

	return (
		<>
			{label ? <p className={'title-required'}>{label}</p> : null}
			<Input.Group compact>
				<Form.Item
					className={' width-87'}
					style={style}
					dependencies={['receiver_code']}
					help={
						!isValid
							? 'Destinataire introuvable. Veuillez en ajouter un en cliquant sur le bouton +'
							: undefined
					}
					validateStatus={!isValid ? 'error' : ''}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								try {
									if (!allData.some((v) => v.code === value) || !value || !isValid) {
										return Promise.reject(
											'Destinataire introuvable. Veuillez en ajouter un en cliquant sur le bouton +',
										);
									}

									return Promise.resolve();
								} catch (err) {
									return Promise.reject(
										'Destinataire introuvable. Veuillez en ajouter un en cliquant sur le bouton +',
									);
								}
							},
						},
					]}
					name={'receiver_code'}
					id={'scroll-course-delivery'}
				>
					<Select
						id={'select-double-search'}
						showSearch
						placeholder={title}
						getPopupContainer={(triggerNode) => triggerNode.parentElement}
						optionFilterProp="children"
						allowClear
						onSearch={fetchUser}
						loading={loading}
						onChange={onChange}
						notFoundContent={loading ? <Spin size="small" /> : null}
						filterOption={filterOptionReceiver}
					>
						{allData
							.filter((v) => v)
							.map((m, index) => (
								<Option key={`receiver-${index}`} value={m.code}>
									<div>
										{`${m.company && m.company.length > 0 ? `[${m.company}] ` : ''}${
											m.name && m.name.length > 0 ? `${m.name}` : ''
										}`}
									</div>
									{m.address && m.address.text && (
										<div style={{ fontSize: 10 }}>{`${m.address.text}`}</div>
									)}
									{m.phone && m.phone.length > 0 && (
										<div style={{ fontSize: 10 }}>{`${m.phone}`}</div>
									)}
									{m.email && m.email.length > 0 && (
										<div style={{ fontSize: 10 }}>{`${m.email}`}</div>
									)}
								</Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item className={'no-margin'}>
					<Tooltip title={strings('update')}>
						<Button
							onClick={() => handleClickReceiver(false)}
							disabled={!receiverData}
							icon={<EditOutlined />}
						/>
					</Tooltip>
				</Form.Item>
				<Form.Item className={'no-margin'}>
					<Tooltip title={strings('add_receiver')}>
						<Button
							htmlType={'button'}
							onClick={(e) => {
								handleClickReceiver(true);
							}}
							icon={<PlusOutlined />}
						/>
					</Tooltip>
				</Form.Item>
			</Input.Group>
		</>
	);
};

export default SearchSelect;
