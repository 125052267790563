import { createReducer } from "reduxsauce";
import { types } from "./actions";
import _ from "lodash";

const initialState = {
  tasks: [],
  courses: {},
  moreCourses: {},
  geolocations: [],
  filter: {
    date: null,
    showDekiCourse: false,
    pagination: {
      current: 1,
      pageSize: 15,
    },
  },
  current_course: {},
  requestSuccess: 0,
  track_course: null,
  stats: {},
  countProcessing : 0,
  countPlanned : 0,
  activeTab : "1"
};

const getStatisticsSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, ...data };
};

const getAllSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = {};

  data.forEach((p) => {
    if (p) tmp[p.id] = p;
  });
  return { ...state, courses: { ...tmp }, loading: false };
};

const setLoading = (state = initialState, action) => {
  const { loading } = action;
  return { ...state, loading };
};

const getMoreSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = {};

  data.forEach((p) => {
    if (p) tmp[p.id] = p;
  });
  return { ...state, moreCourses: tmp, loading: false };
};

const getOneSuccess = (state = initialState, action) => {
  const { data, tasks } = action;
  const tmpTasks = state.tasks;
  tasks.forEach((v) => {
    if (v) tmpTasks[v.id] = v;
  });
  return { ...state, current_course: data, tasks: tmpTasks, loading: false };
};

const createSuccess = (state = initialState, action) => {
  const { data, isCurrent } = action;
  const tmp = state.courses;
  tmp[data.id] = data;
  return {
    ...state,
    courses: tmp,
    requestSuccess: 1,
    current_course: isCurrent ? data : state.current_course,
    loading: false,
  };
};

const resetTasksReduc = (state = initialState) => {
  return { ...state, requestSuccess: 0, loading: false };
};

const tasksFailed = (state = initialState) => {
  return { ...state, requestSuccess: 2, loading: false };
};

const deleteOrderSuccess = (state = initialState, action) => {
  const { ids } = action;
  const { tasks } = state;
  for (let i = 0, l = ids.length; i < l; i++) {
    delete tasks[ids[i]];
  }
  return { ...state, tasks: tasks, loading: false };
};

const deleteSuccess = (state = initialState, action) => {
  const { course } = action;
  const { courses } = state;
  let tmp = courses ? courses.filter((v) => v.id !== course.id) : [];
  let tmpDic = {};
  tmp.forEach((p) => {
    if (p) tmpDic[p.id] = p;
  });
  return { ...state, courses: tmpDic, loading: false };
};

const updateFiltersReduc = (state = initialState, action) => {
  const { filters } = action;
  return {
    ...state,
    filter: { ...state.filter, ...filters },
    requestSuccess: 1
  };
};

const setCurrent = (state = initialState, action) => {
  const { values } = action;
  return { ...state, current_course: values, loading: false };
};

const setActiveTabReduc = (state = initialState, action) => {
  const { activeTab } = action;
  return { ...state, activeTab };
};

const deleteTaskSuccess = (state = initialState, action) => {
  const { courses } = state;
  const { data, deleteCourseId } = action;
  let tmp = courses;
  if (deleteCourseId) {
    tmp = tmp.filter((p) => p.id !== deleteCourseId);
  } else {
    tmp = tmp.map((p) => {
      p.tasks = p.tasks.filter((v) => v.id !== data.id);
      return p;
    });
  }
  return { ...state, courses: tmp, loading: false };
};

const getFailed = (state = initialState) => {
  return { ...state, loading: false };
};

const resetCurrent = (state = initialState) => {
  return { ...state, current_course: {} };
};

const modifyTaskSuccess = (state = initialState, action) => {
  const { data } = action;
  const { courses } = state;
  const tmp = {};
  Object.values(courses).forEach((course) => {
    let tmpCourse = course;
    if (course) {
      tmpCourse.tasks = course.tasks.map((task) => {
        if (data.id === task.id) {
          return {
            ...task,
            task: data,
          };
        } else {
          return task;
        }
      });
      tmp[course.id] = tmpCourse;
    }
  });
  return { ...state, courses: tmp, requestSuccess: 1 };
};

const updateOrderSuccess = (state = initialState, action) => {
  const { data } = action;
  const { courses } = state;
  const tmp = courses;
  data.forEach((v, i) => {
    tmp[v.id] = {
      ...tmp[v.id],
      order: v.order,
    };
  });
  return { ...state, courses: tmp, requestSuccess: 1 };
};

const modifyOnlySuccess = (state = initialState, action) => {
  const { data } = action;
  const { courses, moreCourses } = state;
  const tmp = courses;
  tmp[data.id] = data;
  if (moreCourses[data.id]) {
    moreCourses[data.id] = data;
  }
  return {
    ...state,
    courses: tmp,
    moreCourses: moreCourses,
    requestSuccess: 1,
  };
};

const getRecipientCourseByUIDSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, track_course: data, requestSuccess: 1 };
};

const updateTaskByRecipientSuccess = (state = initialState, action) => {
  const { data } = action;
  const { track_course } = state;
  const tmp = { ...track_course };
  tmp.tasks = tmp.tasks.map((task) => {
    if (task.uid === data.uid) {
      return {
        ...task,
        start_date: data.start_date,
        end_date: data.end_date,
      };
    } else {
      return task;
    }
  });
  return { ...state, track_course: tmp, requestSuccess: 1 };
};
const getStatisticsPlatformSuccess = (state = initialState, action) => {
  const { data } = action;
  return{...state, ...data}
}
const rateTaskByUIDSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, track_course: data, requestSuccess: 1 };
};

const updateStateReduc = (state = initialState, action) => {
  const { courses } = action;
  const tmp = state.courses;
  Object.values(courses).forEach((v) => {
    tmp[v.id] = { ...tmp[v.id], ...v };
  });
  return { ...state, courses: tmp, requestSuccess: 1 };
};

const generatePdfTaskSuccess = (state = initialState, action) => {
  return { ...state };
};

const getGeoByCoursierAndTaskSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, geolocations: _.groupBy(data?.geolocation, "task_id") };
};

const getGeoByCoursierAndTaskFailure = (state = initialState) => {
  return { ...state, geolocations: {} };
};

export default createReducer(initialState, {
  [types.RESET_CURRENT_REDUC]: resetCurrent,
  [types.UPDATE_STATE_REDUC]: updateStateReduc,
  [types.RESET_TASKS_REDUC]: resetTasksReduc,
  [types.GET_ALL_FAILURE]: tasksFailed,
  [types.GET_ALL_SUCCESS]: getAllSuccess,
  [types.GET_ONE_FAILURE]: getFailed,
  [types.GET_ONE_SUCCESS]: getOneSuccess,
  [types.DELETE_ORDER_SUCCESS]: deleteOrderSuccess,
  [types.CREATE_FAILURE]: tasksFailed,
  [types.CREATE_SUCCESS]: createSuccess,
  [types.DELETE_SUCCESS]: deleteSuccess,
  [types.DELETE_TASK_SUCCESS]: deleteTaskSuccess,
  [types.SET_CURRENT]: setCurrent,
  [types.MODIFY_TASK_SUCCESS]: modifyTaskSuccess,
  [types.MODIFY_TASK_FAILURE]: tasksFailed,
  [types.UPDATE_ORDER_FAILURE]: tasksFailed,
  [types.UPDATE_ORDER_SUCCESS]: updateOrderSuccess,
  [types.MODIFY_ONLY_FAILURE]: tasksFailed,
  [types.MODIFY_ONLY_SUCCESS]: modifyOnlySuccess,
  [types.GENERATE_PDF_TASK_FAILURE]: tasksFailed,
  [types.GENERATE_PDF_TASK_SUCCESS]: generatePdfTaskSuccess,
  [types.UPDATE_FILTERS_REDUC]: updateFiltersReduc,
  [types.GET_GEO_BY_COURSIERS_AND_TASKS_SUCCESS]:
    getGeoByCoursierAndTaskSuccess,
  [types.GET_GEO_BY_COURSIERS_AND_TASKS_FAILURE]:
    getGeoByCoursierAndTaskFailure,
  [types.GET_RECIPIENT_COURSE_BY_UID_FAILURE]: tasksFailed,
  [types.GET_RECIPIENT_COURSE_BY_UID_SUCCESS]: getRecipientCourseByUIDSuccess,
  [types.RATE_TASK_BY_UID_FAILURE]: tasksFailed,
  [types.RATE_TASK_BY_UID_SUCCESS]: rateTaskByUIDSuccess,
  [types.GET_MORE_FAILURE]: getFailed,
  [types.GET_MORE_SUCCESS]: getMoreSuccess,
  [types.UPDATE_TASK_BY_RECIPIENT_SUCCESS]: updateTaskByRecipientSuccess,
  [types.SET_LOADING]: setLoading,
  [types.SET_ACTIVE_TAB_REDUC]: setActiveTabReduc,
  [types.GET_STATISTICS_FAILURE]: getFailed,
  [types.GET_STATISTICS_SUCCESS]: getStatisticsSuccess,
  [types.GET_STATISTICS_PLATFORM_FAILURE]: getFailed,
  [types.GET_STATISTICS_PLATFORM_SUCCESS]: getStatisticsPlatformSuccess,
  [types.SET_ACTIVE_TAB_REDUC]: setActiveTabReduc,
});
