import { createAction } from "../../utils";

// Types
export const types = {
  GET_VEHICLE_TYPES_REQUEST: "GET_VEHICLE_TYPES_REQUEST",
  GET_VEHICLE_TYPES_SUCCESS: "GET_VEHICLE_TYPES_SUCCESS",
  GET_VEHICLE_TYPES_FAILURE: "GET_VEHICLE_TYPES_FAILURE",

  RESET_VEHICLES_REDUC: "RESET_VEHICLES_REDUC",

  CREATE_VEHICLE_TYPE_REQUEST: "CREATE_VEHICLE_TYPE_REQUEST",
  CREATE_VEHICLE_TYPE_SUCCESS: "CREATE_VEHICLE_TYPE_SUCCESS",
  CREATE_VEHICLE_TYPE_FAILURE: "CREATE_VEHICLE_TYPE_FAILURE",

  MODIFY_VEHICLE_TYPE_REQUEST: "MODIFY_VEHICLE_TYPE_REQUEST",
  MODIFY_VEHICLE_TYPE_SUCCESS: "MODIFY_VEHICLE_TYPE_SUCCESS",
  MODIFY_VEHICLE_TYPE_FAILURE: "MODIFY_VEHICLE_TYPE_FAILURE",

  DELETE_VEHICLE_TYPE_REQUEST: "DELETE_VEHICLE_TYPE_REQUEST",
  DELETE_VEHICLE_TYPE_SUCCESS: "DELETE_VEHICLE_TYPE_SUCCESS",
  DELETE_VEHICLE_TYPE_FAILURE: "DELETE_VEHICLE_TYPE_FAILURE",
};

const actions = {
  getVehicleTypesRequest: (team_id) =>
    createAction(types.GET_VEHICLE_TYPES_REQUEST, { team_id }),
  getVehicleTypesSuccess: (data) =>
    createAction(types.GET_VEHICLE_TYPES_SUCCESS, { data }),
  getVehicleTypesFailure: () => createAction(types.GET_VEHICLE_TYPES_FAILURE),

  createVehicleTypeRequest: (values, callback = null) =>
    createAction(types.CREATE_VEHICLE_TYPE_REQUEST, { values, callback }),
  createVehicleTypeSuccess: (data) =>
    createAction(types.CREATE_VEHICLE_TYPE_SUCCESS, { data }),
  createVehicleTypeFailure: () =>
    createAction(types.CREATE_VEHICLE_TYPE_FAILURE),

  modifyVehicleTypeRequest: (values, record, callback = null) =>
    createAction(types.MODIFY_VEHICLE_TYPE_REQUEST, {
      values,
      record,
      callback,
    }),
  modifyVehicleTypeSuccess: (data) =>
    createAction(types.MODIFY_VEHICLE_TYPE_SUCCESS, { data }),
  modifyVehicleTypeFailure: () =>
    createAction(types.MODIFY_VEHICLE_TYPE_FAILURE),

  deleteVehicleTypeRequest: (values, callback = null) =>
    createAction(types.DELETE_VEHICLE_TYPE_REQUEST, { values, callback }),
  deleteVehicleTypeSuccess: (data) =>
    createAction(types.DELETE_VEHICLE_TYPE_SUCCESS, { data }),
  deleteVehicleTypeFailure: () =>
    createAction(types.DELETE_VEHICLE_TYPE_FAILURE),

  resetRequestSuccessReduc: () => createAction(types.RESET_VEHICLES_REDUC),
};
// Actions
export default actions;
