import { createAction } from "../../utils";

// Types
export const types = {
  GET_AREAS_REQUEST: "GET_AREAS_REQUEST",
  GET_AREAS_SUCCESS: "GET_AREAS_SUCCESS",
  GET_AREAS_FAILURE: "GET_AREAS_FAILURE",

  CHECK_AREAS_REQUEST: "CHECK_AREAS_REQUEST",
  CHECK_AREAS_SUCCESS: "CHECK_AREAS_SUCCESS",
  CHECK_AREAS_FAILURE: "CHECK_AREAS_FAILURE",
};

// Actions
const actions = {
  getAreasRequest: () => createAction(types.GET_AREAS_REQUEST),
  getAreasSuccess: (data) => createAction(types.GET_AREAS_SUCCESS, { data }),
  getAreasFailure: () => createAction(types.GET_AREAS_FAILURE),

  checkContainAreasRequest: (values) =>
    createAction(types.CHECK_AREAS_REQUEST, { values }),
  checkContainAreasSuccess: (data, zone) =>
    createAction(types.CHECK_AREAS_SUCCESS, { data, zone }),
  checkContainAreasFailure: () => createAction(types.CHECK_AREAS_FAILURE),
};
export default actions;
